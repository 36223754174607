.v2-text-label {
  color: #919191;
}
.v2-text {
  font-family: Graphik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.operator-job-details {
  font-size: 15px;
  background-color: #01a8af;
  color: white;
  border-radius: 17px;
  display: inline-block;
  margin: 10px 0;
  padding: 10px 15px;
}
.operator-job-details:hover {
  color: white;
  font-size: 16px;
  background-color: #07595c;
}
.schedules {
  background: #20b9bd !important;
  padding: 3px 46px !important;
  color: #fff !important;
  border-radius: 30px !important;
  font-size: 14px !important;
  display: inline-block !important;
  white-space: nowrap !important;
  margin: 0 5px 5px !important;
  border: none;
}

.detailsapprove {
  float: none;
  background: #1cb7bb;
  padding: 5px 35px;
  color: #fff;
  border-radius: 30px;
  font-size: 16px;
  border: none;
}
.detailsapprove:hover {
  background: #007679;
  color: #fff;
  text-decoration: none;
}
.schedules:hover {
  background: #007679 !important;
  color: #fff !important;
  text-decoration: none !important;
}
.schedules:disabled {
  background: #ccc !important;
  color: #555 !important;
  cursor: not-allowed !important;
}
.success-colors {
  background: green !important;
}
.reject-colors {
  background: red !important;
}

.badge-button {
  padding: 0 !important;
  font-size: 15px !important;
  line-height: 27px !important;
  width: 120px !important;
  margin-top: 20px !important;
}
.hyper-a {
  text-decoration: underline !important;
  color: #0c86f1 !important;
}

.hyper-a:hover {
  color: #088873 !important;
}
.n-1 {
  top: 58px !important;
  left: 1422px !important;

  position: fixed !important;
  display: block !important;
}
.n-2 {
  left: 87.8261% !important;
}

.unseen {
  background-color: red !important;
  color: white !important;
  margin-left: 5px !important;
  cursor: pointer !important;
}

.bells {
  font-size: 26px !important;
  /* margin-top: 6px !important; */

  color: #007679 !important;
}
.app-store img {
  height: 2.2rem;
}

.download-img {
  margin-right: 1rem;
}
.text-center {
  text-align: center;
}
#react-paginate ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

#react-paginate li {
  display: inline-block;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #007679 !important;
  border-color: #007679 !important;
}

#client-page .clearing .pagination li {
  list-style-type: none;
  font-size: 10px !important;
  color: #000;
  font-weight: 200 !important;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAB0CAIAAADLmM+sAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkJGOUVCM0NFQTJGNTExRUJBMDBERTc1MURGRjE2RjEyIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkJGOUVCM0NGQTJGNTExRUJBMDBERTc1MURGRjE2RjEyIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QkY5RUIzQ0NBMkY1MTFFQkEwMERFNzUxREZGMTZGMTIiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QkY5RUIzQ0RBMkY1MTFFQkEwMERFNzUxREZGMTZGMTIiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7Ejrc1AAAAo0lEQVR42sSRWwqEMAxFe9z/3tyEVdGq9a31ncKIDDPzO4HLTRNI4VwVhiHHcbDvu9e2bazryrIsOOeY59lrHEeGYaDve7quo21bmqbBWktVVZRliTGGPM/Jsow0TUmSBK01URQF1x/Bdd/7r/4pmSulbn/uXm/xfwu4/Zuk3vsPM2FFHMeenTAUlsK0KArPWHjXde35Sw6Sh2QzTZO6sjwFGADdU6+kPhYXpAAAAABJRU5ErkJggg==)
    no-repeat right center;
  display: inline-block;
  min-height: 28px !important;
  vertical-align: top;
}
.bid-exp {
  padding: 5px !important;
  font-size: 10px !important;
  line-height: 20px !important;
  margin-top: 8px !important;
}
.invoice {
  width: 800px;
  margin: auto;
  padding: 10px;
  color: #333;
  border: 1px solid #eee;
}
.invoice .header-text {
  text-align: center;
  font-size: 24px;
  color: #333;
  font-weight: bold;
  margin-bottom: 20px;
}
.invoice .invoice-right {
  border: 1px dotted #333;
  float: right;
  padding: 3px;
}
.invoice .invoice-right span {
  font-weight: bold;
}
.invoice .invoice-left {
  font-size: 20px;
  border-bottom: 2px solid #000;
}
.invoice .invoice-left span {
  font-size: 20px;
  font-weight: bold;
}
.invoice .invoice-left p {
  font-size: 14px;
  padding: 0;
  margin: 0;
}
.invoice .invoice-block {
  padding: 5px 0;
}
.invoice .invoice-block .col-sm-4 div {
  padding: 2px 0;
}
.invoice .invoice-block .col-sm-4 span,
.invoice .invoice-block .col-sm-3 span {
  font-weight: bold;
}
.invoice .invoice-block-tr th,
.invoice .invoice-block-tr1 td,
.invoice .invoice-block-tr2 td {
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  padding: 10px 5px;
}
.invoice .invoice-block-tr td {
  padding: 10px 5px;
}
.invoice .invoice-block-tr1 td {
  font-size: 16px;
  font-weight: bold;
  padding: 20px 5px;
}
.invoice .invoice-block-tr1 td span {
  font-weight: normal;
}
.invoice .invoice-block-tr2 td {
  font-size: 20px;
  font-weight: normal;
  padding: 20px 5px;
}
.invoice .invoice-block-tr2 td span {
  font-weight: bold;
}
.invoice .invoice-block-tr td span {
  font-weight: bold;
}
.invoice .text-align {
  text-align: center;
}
.invoice .text-align1 {
  text-align: right;
  font-size: 12px;
}
.invoice .signature {
  text-align: center;
  font-size: 12px;
  float: right;
  width: 300px;
  padding: 80px 0 0;
}
.faqHeader {
  font-size: 27px;
  margin: 20px;
}

.panel-heading [data-toggle='collapse']:after {
  font-family: 'Glyphicons Halflings';
  content: '\e072'; /* "play" icon */
  float: right;
  color: #f58723;
  font-size: 18px;
  line-height: 22px;
  /* rotate "play" icon from > (right arrow) to down arrow */
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.panel-heading [data-toggle='collapse'].collapsed:after {
  /* rotate "play" icon from > (right arrow) to ^ (up arrow) */
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  color: #454444;
}
.head-quater1 {
  font-size: 24px;
  font-family: 'Poppins';
}
