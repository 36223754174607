/*--------------------------------------------------------------
# Client Page
--------------------------------------------------------------*/
@media only screen and (max-width: 1769px) {
  #client-page .dashrightcont .container {
    width: 90%;
  }
}

@media only screen and (min-width: 1770px) {
  .hero {
    position: absolute;
    top: 36%;
  }
}

@media only screen and (min-width: 1570px) {
  .hero {
    position: absolute;
    top: 25%;
  }
}

@media only screen and (max-width: 1569px) {
  .hero {
    position: absolute;
    top: 20%;
  }

  #client-page .container {
    width: 100%;
  }

  #client-page .batcurrent2 {
    padding: 40px 5px;
  }

  #client-page .battext1 {
    padding: 45px 5px;
  }

  #client-page .batreview {
    padding: 30px 5px;
  }

  #client-page .batcurrent1 {
    padding: 43px 5px;
  }

  #client-page .detailsservice a.detailsdiv {
    margin: 47px 10px 0 0;
    text-align: center;
  }

  #client-page a.detailsdiv {
    padding: 10px 25px;
  }

  #client-page a.review {
    padding: 10px 15px;
    margin: 0;
    display: inline-block;
    min-width: 130px;
    line-height: 20px;
  }

  #client-page .disputes-img img {
    width: 100px;
  }
}

@media only screen and (max-width: 1369px) {
  #client-page .cargoheading {
    padding: 36px 18px;
  }

  #client-page .clearingtable td {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 1169px) {
  #client-page a.review,
  #clearing-agent-page a.review,
  #lawear-page a.review,
  #superviser-page a.review,
  #truck-inspector-page a.review {
    padding: 2px 13px !important;
    margin: 0;
    margin-top: 0 !important;
    line-height: 25px !important;
  }

  #client-page a.review {
    display: inline-block;
    min-width: 150px;
  }

  #client-page .clearingtable td {
    padding-left: 0;
    padding-right: 0;
    font-size: 15px;
  }

  #client-page .container {
    width: 100%;
  }

  #client-page .menu li a {
    font-size: 15px;
    padding: 13px 20px;
  }

  #client-page .dashrightcont .personal-info {
    padding: 20px;
  }

  #client-page .detailsservice a.detailsdiv {
    margin: 47px 15px;
    font-size: 14px;
  }

  #client-page .batreview a.review {
    font-size: 14px;
    padding: 10px 15px;
  }
}

@media only screen and (max-width: 1180px) {
  .video-banner {
    height: 76rem;
  }
}

@media only screen and (max-width: 1023px) {
  #client-page .dashrightcont {
    padding: 20px;
  }

  #client-page .dashrightcont .profilecont {
    display: block;
  }

  #client-page .dashrightcont .profilecontleft,
  #client-page .dashrightcont .profilecontright {
    width: 100%;
  }

  #client-page .dashrightcont .profilecontright {
    border-top: 1px solid #e3e3e3;
    border-left: none;
  }

  #client-page .cargoheading {
    padding: 36px 18px;
  }

  #client-page .progressdiv1 .progressheading {
    width: 47%;
  }

  #client-page .dayleft {
    width: 25%;
    padding: 30px 15px;
    font-size: 14px;
  }

  #client-page .batleft {
    width: 15%;
    padding: 10px;
  }

  #client-page .battext {
    width: 19%;
    padding: 45px 16px;
  }

  #client-page .batreview {
    width: 22%;
    padding: 30px 15px;
  }

  #client-page .batcurrent {
    width: 16%;
    padding: 43px 18px;
  }

  #client-page .detailsservice a.detailsdiv {
    margin: 47px 18px;
    padding: 10px 27px;
    font-size: 14px;
  }

  #client-page .batreview a.review {
    margin-left: 0;
    margin-right: 0;
    font-size: 12px;
    padding: 6px 18px;
  }
}

@media only screen and (max-width: 890px) {
  .video-banner {
    height: 100vh;
  }
}

@media only screen and (max-width: 768px) {
  #client-page .container {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  /* .hero {
    position: relative;
  } */

  #client-page .container {
    width: 100%;
  }

  #client-page footer {
    position: static;
  }

  #client-page .bodycont {
    padding: 40px 0 20px;
  }

  #client-page .bodycont h2 {
    font-size: 35px;
  }

  #client-page .personal-info {
    padding: 60px 15px;
  }

  #client-page a.review {
    margin-bottom: 10px;
    min-width: 150px;
  }

  #client-page .profilecontleft,
  #client-page .profilecontright {
    width: 100%;
  }

  #client-page .bodycont h3 {
    font-size: 25px;
  }

  #client-page #outcont {
    padding-bottom: 0;
  }

  #client-page .footertop {
    padding: 30px 0;
  }

  #client-page .footertop h3 {
    padding: 0 0 10px;
    margin-bottom: 15px;
  }

  #client-page .footerdiv {
    padding-bottom: 25px;
  }

  #client-page .profilecontleft,
  .profilecontright {
    padding: 15px;
    width: 100%;
  }

  #client-page .profilecont {
    display: block;
  }

  #client-page .profilecontright {
    border-top: 1px solid #e3e3e3;
    border-left: none;
  }

  #client-page a.submitbut {
    min-width: inherit;
  }

  #client-page .logo {
    float: left;
    margin: 15px 0;
  }

  #client-page .loginright {
    padding-top: 17px;
    padding-right: 40px;
  }

  #client-page .notification {
    margin: 0 0 0 5px;
  }

  #client-page .notification a {
    font-size: 28px;
  }

  #client-page .notification span {
    right: -4px;
    top: 0px;
    width: 16px;
    height: 16px;
    line-height: 15px;
    font-size: 11px;
    font-weight: bold;
  }

  #client-page a.topprofile img {
    width: 30px;
    border-radius: 100%;
    margin-right: 0;
    border: 1px solid #7db9bb;
    height: 30px;
  }

  #client-page .menu {
    margin-top: 28px;
  }

  #client-page .responsive-menu {
    top: 19px;
  }

  #client-page .logo {
    width: 150px;
  }

  #client-page .checkcontainer {
    text-align: left;
  }

  #client-page .paymentstep {
    padding: 0 0 20px;
  }

  #client-page .cardicon {
    float: right;
    width: 100%;
    text-align: left;
  }

  #client-page .cardicon img {
    width: 40px;
    border-radius: 100%;
    margin: 5px;
  }

  #client-page .logindiv {
    width: 96%;
    padding: 25px;
  }

  #client-page .dashcont {
    display: block;
  }

  #client-page .dashlink {
    width: 100%;
    padding: 25px 0;
  }

  #client-page .dashlink ul li a {
    padding: 15px 70px;
    font-size: 18px;
  }

  #client-page .dashlink ul li:last-child a {
    margin: 0;
  }

  #client-page .dashrightcont .container {
    width: 100%;
  }

  #client-page .upload-btn-wrapper {
    top: 14px;
    right: 12px;
  }

  #client-page .clearing li {
    display: block;
    background: none;
  }

  #client-page .cargoheading {
    padding: 15px;
  }

  #client-page .clearing li {
    min-height: 46px;
  }

  #client-page .starsec {
    padding: 20px;
  }

  #client-page .review {
    display: inline-block;
    margin: 15px 0;
    width: 100%;
    text-align: center;
  }

  #client-page .progressdiv1 .progressheading {
    width: 100%;
    padding: 36px 0 21px 70px;
  }

  #client-page .starsec1 {
    padding: 0;
  }

  #client-page .dayleft {
    width: 100%;
    text-align: left;
    padding: 0 25px;
  }

  #client-page .progressdiv1 a.detailsdiv {
    margin: 16px 15px;
    display: inline-block;
    float: none;
  }

  #client-page .tabheading h3,
  #client-page .tabheading h4 {
    float: none;
    text-align: center;
    padding: 5px 0;
  }

  #client-page .tabheading h4 {
    margin-bottom: 10px;
  }

  #client-page .batleft,
  #client-page .battext,
  #client-page .batcurrent2,
  #client-page .batcurrent2,
  #client-page .batcurrent1,
  #client-page .battext1 {
    width: 100%;
    padding: 5px;
  }

  .battext,
  #client-page .battext1 {
    padding: 20px 0;
    min-height: inherit;
  }

  #client-page .batreview {
    width: 100%;
    padding: 10px;
  }

  #client-page .batcurrent {
    width: 100%;
    padding: 0 24px;
    text-align: center;
  }

  #client-page .detailsservice a.detailsdiv {
    display: inline-block;
    float: none;
    margin: 20px;
  }

  #client-page .detailsservice {
    text-align: center;
  }

  #client-page #nonResponsiveTabs li {
    width: 100%;
    margin: 0 0 20px 0;
  }

  #client-page .servicetab .nav-tabs > li {
    width: 100%;
    margin-bottom: 5px;
  }

  #client-page .servicetab .nav > li > a {
    background-color: #eee;
  }

  #client-page .nav-tabs > li.active > a,
  #client-page .nav-tabs > li.active > a:focus,
  #client-page .nav-tabs > li.active > a:hover {
    color: #fff !important;
    cursor: default;
    background-color: #01a8ac !important;
    border: 1px solid #01a8ac !important;
  }

  #client-page .servicetab .tab-content {
    box-shadow: 0 0 5px #ccc;
    padding: 25px 10px;
    text-align: center;
  }

  #client-page .loginright {
    padding-top: 5px;
    padding-right: 0;
    /* width: 120px; */
    position: absolute;
    right: 60px;
  }

  #client-page .back-to-top {
    display: none;
  }

  #client-page .back-to-top.active {
    visibility: visible;
    opacity: 0;
  }

  #client-page .payment-details-block,
  #client-page .payment-details-block1 {
    width: 100%;
    padding: 0 5px;
    float: none;
    border: none;
  }

  #client-page .payment-info {
    padding: 5px;
  }

  #client-page .payment-details-block {
    background-color: #eee;
  }

  #client-page .navbar .login {
    margin-right: 10px;
  }

  #client-page .logindiv .submitbut {
    width: 100%;
  }

  #client-page a.submitbut {
    min-width: inherit;
  }

  #client-page .logindiv a {
    text-decoration: underline;
  }

  #client-page a.submitbut,
  #client-page a.backbut {
    padding: 15px;
  }

  #client-page .chat {
    display: none;
  }

  #client-page .tab {
    float: none;
    background-color: none;
    padding: 0;
    margin-top: 0;
    position: relative;
    z-index: 10;
  }

  #client-page .tabcontent {
    float: left;
    padding: 0px 30px !important;
    border-left: none;
    margin-top: 0;
    padding: 0;
  }

  #client-page .tab h4 {
    padding: 0 15px 0 13px;
  }

  #client-page .tab button {
    font-size: 20px;
  }

  #client-page .tab button:last-child {
    margin-top: 10px;
  }

  #client-page .disputes-img {
    margin-bottom: 20px;
    text-align: center;
  }

  #client-page .disputes-img img {
    width: 150px;
  }
}

@media only screen and (max-width: 540px) {
  #client-page .container {
    width: 100%;
  }

  #client-page .logo {
    width: 135px;
    margin: 15px 0 !important;
  }

  .dashboard-top.header-scrolled .logo img {
    margin-top: -10px;
    margin-left: -10px;
  }

  #client-page .loginright {
    padding-top: 5px;
    padding-right: 0;
    /* width: 120px; */
    position: absolute;
    right: 60px;
  }

  #client-page .notification {
    margin: 0 0 0 5px;
  }

  #client-page .notification a {
    font-size: 28px;
  }

  #client-page .notification span {
    right: -4px;
    top: 0px;
    width: 16px;
    height: 16px;
    line-height: 15px;
    font-size: 11px;
    font-weight: bold;
  }

  #client-page a.topprofile img {
    width: 30px;
    border-radius: 100%;
    margin-right: 0;
    border: 1px solid #7db9bb;
  }

  #client-page .menu {
    margin-top: 28px;
  }

  #client-page .responsive-menu {
    top: 13px;
  }

  #client-page .paymentstep {
    padding: 0;
  }

  #client-page .paymentstep li {
    padding: 0;
  }

  #client-page .paymentstep li span {
    margin: 0 auto 0;
  }

  #client-page .paymentstep li:first-child span {
    margin: 0 auto 0 0;
  }

  #client-page .paymentstep li:last-child span {
    margin: 0 0 0 auto;
  }

  #client-page .paymentstep li:first-child p {
    text-align: left;
  }

  #client-page .paymentstep li:last-child p {
    text-align: right;
  }

  #client-page .payment-method2 img {
    width: 97px;
  }

  #client-page .servicecheck label {
    margin: 5px;
  }

  #client-page .upload-btn-wrapper {
    top: 14px;
    right: 12px;
  }

  #client-page .progressheading {
    float: none;
    padding: 10px 0 40px 50px;
  }

  #client-page a.detailsdiv {
    float: none;
  }

  #client-page .progressdiv1 .progressheading {
    width: 100%;
    padding: 36px 0 21px 70px;
  }

  #client-page .dayleft {
    width: 100%;
    text-align: left;
    padding: 0 25px;
  }

  #client-page .progressdiv1 a.detailsdiv {
    margin: 16px 15px;
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# clearing-agent
--------------------------------------------------------------*/

@media only screen and (max-width: 1769px) {
  #clearing-agent-page .dashrightcont .container {
    width: 90%;
  }
}

@media only screen and (max-width: 1569px) {
  #clearing-agent-page .container {
    width: 100%;
  }

  #clearing-agent-page .cargoheading {
    padding: 36px 18px;
  }

  #clearing-agent-page .batcurrent2 {
    padding: 40px 5px;
  }

  #clearing-agent-page .battext1 {
    padding: 45px 5px;
  }

  #clearing-agent-page .batreview {
    padding: 30px 5px;
  }

  #clearing-agent-page .batcurrent1 {
    padding: 43px 5px;
  }

  #clearing-agent-page .detailsservice a.detailsdiv {
    margin: 47px 10px 0 0;
    text-align: center;
  }

  #clearing-agent-page a.detailsdiv {
    padding: 10px 25px;
  }

  #clearing-agent-page a.review {
    padding: 10px 15px;
    margin: 0;
  }

  #clearing-agent-page .disputes-img img {
    width: 100px;
  }
}

@media only screen and (max-width: 1169px) {
  #clearing-agent-page .container {
    width: 100%;
  }

  #clearing-agent-page .menu li a {
    font-size: 15px;
    padding: 13px 20px;
  }

  #clearing-agent-page .dashrightcont .personal-info {
    padding: 20px;
  }

  #clearing-agent-page .detailsservice a.detailsdiv {
    margin: 47px 15px;
    font-size: 14px;
  }

  #clearing-agent-page .batreview a.review {
    font-size: 14px;
    padding: 10px 15px;
  }
}

@media only screen and (max-width: 1023px) {
  #clearing-agent-page .dashrightcont {
    padding: 20px;
  }

  #clearing-agent-page .dashrightcont .profilecont {
    display: block;
  }

  #clearing-agent-page .dashrightcont .profilecontleft,
  .dashrightcont .profilecontright {
    width: 100%;
  }

  #clearing-agent-page .dashrightcont .profilecontright {
    border-top: 1px solid #e3e3e3;
    border-left: none;
  }

  #clearing-agent-page .cargoheading {
    padding: 36px 18px;
  }

  #clearing-agent-page .progressdiv1 .progressheading {
    width: 47%;
  }

  #clearing-agent-page .dayleft {
    width: 25%;
    padding: 30px 15px;
    font-size: 14px;
  }

  #clearing-agent-page .batleft {
    width: 15%;
    padding: 10px;
  }

  #clearing-agent-page .battext {
    width: 19%;
    padding: 45px 16px;
  }

  #clearing-agent-page .batreview {
    width: 22%;
    padding: 30px 15px;
  }

  #clearing-agent-page .batcurrent {
    width: 16%;
    padding: 43px 18px;
  }

  #clearing-agent-page .detailsservice a.detailsdiv {
    margin: 47px 18px;
    padding: 10px 27px;
    font-size: 14px;
  }

  #clearing-agent-page .batreview a.review {
    margin-left: 0;
    margin-right: 0;
    font-size: 12px;
    padding: 6px 18px;
  }
}

@media only screen and (max-width: 768px) {
  #clearing-agent-page .container {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  #clearing-agent-page .container {
    width: 100%;
  }

  #clearing-agent-page footer {
    position: static;
  }

  #clearing-agent-page .bodycont {
    padding: 85px 0 20px;
  }

  #clearing-agent-page .bodycont h2 {
    font-size: 35px;
  }

  #clearing-agent-page .personal-info {
    padding: 60px 15px;
  }

  #clearing-agent-page .bodycont h3 {
    font-size: 20px;
  }

  #clearing-agent-page #outcont {
    padding-bottom: 0;
  }

  #clearing-agent-page .footertop {
    padding: 30px 0;
  }

  #clearing-agent-page .footertop h3 {
    padding: 0 0 10px;
    margin-bottom: 15px;
  }

  #clearing-agent-page .footerdiv {
    padding-bottom: 25px;
  }

  #clearing-agent-page .profilecontleft,
  #clearing-agent-page .profilecontright {
    padding: 15px;
    width: 100%;
  }

  #clearing-agent-page .profilecont {
    display: block;
  }

  #clearing-agent-page .profilecontright {
    border-top: 1px solid #e3e3e3;
    border-left: none;
  }

  #clearing-agent-page a.submitbut {
    min-width: inherit;
  }

  #clearing-agent-page .logo {
    float: left;
    margin: 15px 0;
  }

  #clearing-agent-page .loginright {
    padding-top: 17px;
    padding-right: 40px;
  }

  #clearing-agent-page .notification {
    margin: 0 0 0 5px;
  }

  #clearing-agent-page .notification a {
    font-size: 28px;
  }

  #clearing-agent-page .notification span {
    right: -4px;
    top: 0px;
    width: 16px;
    height: 16px;
    line-height: 15px;
    font-size: 11px;
    font-weight: bold;
  }

  #clearing-agent-page a.topprofile img {
    width: 30px;
    border-radius: 100%;
    margin-right: 0;
    border: 1px solid #7db9bb;
  }

  #clearing-agent-page .menu {
    margin-top: 28px;
  }

  #clearing-agent-page .responsive-menu {
    top: 19px;
  }

  #clearing-agent-page .logo {
    width: 150px;
  }

  #clearing-agent-page .checkcontainer {
    text-align: left;
  }

  #clearing-agent-page .paymentstep {
    padding: 0 0 20px;
  }

  #clearing-agent-page .cardicon {
    float: right;
    width: 100%;
    text-align: left;
  }

  #clearing-agent-page .cardicon img {
    width: 40px;
    border-radius: 100%;
    margin: 5px;
  }

  #clearing-agent-page .logindiv {
    width: 96%;
    padding: 25px;
  }

  #clearing-agent-page .dashcont {
    display: block;
  }

  #clearing-agent-page .dashlink {
    width: 100%;
    padding: 25px 0;
  }

  #clearing-agent-page .dashlink ul li a {
    padding: 15px 70px;
    font-size: 18px;
  }

  #clearing-agent-page .dashlink ul li:last-child a {
    margin: 0;
  }

  #clearing-agent-page .dashrightcont .container {
    width: 100%;
  }

  #clearing-agent-page .upload-btn-wrapper {
    top: 14px;
    right: 12px;
  }

  #clearing-agent-page .clearing li {
    display: block;
    background: none;
  }

  #clearing-agent-page .cargoheading {
    padding: 15px;
  }

  #clearing-agent-page .clearing li {
    min-height: 46px;
  }

  #clearing-agent-page .starsec {
    padding: 20px;
  }

  #clearing-agent-page a.review {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  #clearing-agent-page .progressdiv1 .progressheading {
    width: 100%;
    padding: 36px 0 21px 70px;
  }

  #clearing-agent-page .starsec1 {
    padding: 0;
  }

  #clearing-agent-page .dayleft {
    width: 100%;
    text-align: left;
    padding: 0 25px;
  }

  #clearing-agent-page .progressdiv1 a.detailsdiv {
    margin: 16px 15px;
    display: inline-block;
    float: none;
  }

  #clearing-agent-page .tabheading h3,
  .tabheading h4 {
    float: none;
    text-align: center;
    padding: 5px 0;
  }

  #clearing-agent-page .tabheading h4 {
    margin-bottom: 10px;
  }

  #clearing-agent-page .batleft,
  #clearing-agent-page .battext,
  #clearing-agent-page .batcurrent2,
  #clearing-agent-page .batcurrent2,
  #clearing-agent-page .batcurrent1,
  #clearing-agent-page .battext1 {
    width: 100%;
    padding: 5px;
  }

  #clearing-agent-page .battext,
  #clearing-agent-page .battext1 {
    padding: 20px 0;
    min-height: inherit;
  }

  #clearing-agent-page .batreview {
    width: 100%;
    padding: 10px;
  }

  #clearing-agent-page .batcurrent {
    width: 100%;
    padding: 0 24px;
    text-align: center;
  }

  #clearing-agent-page .detailsservice a.detailsdiv {
    display: inline-block;
    float: none;
    margin: 20px;
  }

  #clearing-agent-page .detailsservice {
    text-align: center;
  }

  #clearing-agent-page #nonResponsiveTabs li {
    width: 100%;
    margin: 0 0 20px 0;
  }

  #clearing-agent-page .servicetab .nav-tabs > li {
    width: 100%;
    margin-bottom: 5px;
  }

  #clearing-agent-page .servicetab .nav > li > a {
    background-color: #eee;
  }

  #clearing-agent-page .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:focus,
  .nav-tabs > li.active > a:hover {
    color: #fff !important;
    cursor: default;
    background-color: #01a8ac !important;
    border: 1px solid #01a8ac !important;
  }

  #clearing-agent-page .servicetab .tab-content {
    box-shadow: 0 0 5px #ccc;
    padding: 25px 10px;
    text-align: center;
  }

  #clearing-agent-page .loginright {
    padding-top: 11px;
    padding-right: 0;
    width: 120px;
    position: absolute;
    right: 60px;
  }

  #clearing-agent-page .back-to-top {
    display: none;
  }

  #clearing-agent-page .back-to-top.active {
    visibility: visible;
    opacity: 0;
  }

  #clearing-agent-page .payment-details-block,
  #clearing-agent-page .payment-details-block1 {
    width: 100%;
    padding: 0 5px;
    float: none;
    border: none;
  }

  #clearing-agent-page .payment-info {
    padding: 5px;
  }

  #clearing-agent-page .payment-details-block {
    background-color: #eee;
  }

  #clearing-agent-page .navbar .login {
    margin-right: 10px;
  }

  #clearing-agent-page .logindiv .submitbut {
    width: 100%;
  }

  #clearing-agent-page a.submitbut {
    min-width: inherit;
  }

  #clearing-agent-page .logindiv a {
    text-decoration: underline;
  }

  #clearing-agent-page a.submitbut,
  #clearing-agent-page a.backbut {
    padding: 15px;
  }

  #clearing-agent-page .chat {
    display: none;
  }

  #clearing-agent-page .tab {
    float: none;
    background-color: none;
    padding: 0;
    margin-top: 0;
    z-index: 10;
  }

  #clearing-agent-page .tabcontent {
    float: left;
    padding: 0px 30px !important;
    width: 100%;
    border-left: none;
    margin-top: 0;
    padding: 0;
  }

  #clearing-agent-page .tab h4 {
    padding: 0 15px 0 13px;
  }

  #clearing-agent-page .tab button {
    font-size: 20px;
  }

  #clearing-agent-page .tab button:last-child {
    margin-top: 10px;
  }

  #clearing-agent-page .disputes-img {
    margin-bottom: 20px;
    text-align: center;
  }

  #clearing-agent-page .disputes-img img {
    width: 150px;
  }

  #clearing-agent-page .clearingtable td {
    padding: 37px 5px;
    font-size: 22px;
  }

  #clearing-agent-page a.detailsdiv {
    padding: 10px 5px;
    font-size: 12px;
  }

  #clearing-agent-page .header {
    padding: 5px 0;
    height: 70px;
  }

  #clearing-agent-page .header .logo img {
    margin-top: 5px;
  }
}

@media only screen and (max-width: 540px) {
  #clearing-agent-page .container {
    width: 100%;
  }

  #clearing-agent-page .logo {
    width: 135px;
    margin: 15px 0;
  }

  #clearing-agent-page .loginright {
    padding-top: 11px;
    padding-right: 0;
    width: 150px;
    position: absolute;
    right: 60px;
  }

  #clearing-agent-page .notification {
    margin: 0 0 0 5px;
  }

  #clearing-agent-page .notification a {
    font-size: 28px;
  }

  #clearing-agent-page .notification span {
    right: -4px;
    top: 0px;
    width: 16px;
    height: 16px;
    line-height: 15px;
    font-size: 11px;
    font-weight: bold;
  }

  #clearing-agent-page a.topprofile img {
    width: 30px;
    border-radius: 100%;
    margin-right: 0;
    height: 30px;
    border: 1px solid #7db9bb;
  }

  #clearing-agent-page .menu {
    margin-top: 28px;
  }

  #clearing-agent-page .responsive-menu {
    top: 13px;
  }

  #clearing-agent-page .paymentstep li {
    display: block;
    padding: 10px 0;
    background: url(../img/dotted1.png) no-repeat center 0 #fff;
    width: 100%;
  }

  #clearing-agent-page .paymentstep li:first-child {
    background-position: center 0;
    background-repeat: no-repeat;
  }

  #clearing-agent-page .paymentstep li:last-child {
    background-position: center 0;
    background-repeat: no-repeat;
  }

  #clearing-agent-page .paymentstep li p {
    background: #fff;
    padding: 0 0 26px;
  }

  #clearing-agent-page .payment-method2 img {
    width: 97px;
  }

  #clearing-agent-page .servicecheck label {
    margin: 5px;
  }

  #clearing-agent-page .upload-btn-wrapper {
    top: 14px;
    right: 12px;
  }

  #clearing-agent-page .progressheading {
    float: none;
    padding: 10px 0 40px 50px;
  }

  #clearing-agent-page a.detailsdiv {
    float: none;
  }

  #clearing-agent-page .progressdiv1 .progressheading {
    width: 100%;
    padding: 36px 0 21px 70px;
  }

  #clearing-agent-page .dayleft {
    width: 100%;
    text-align: left;
    padding: 0 25px;
  }

  #clearing-agent-page .progressdiv1 a.detailsdiv {
    margin: 16px 15px;
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# lawear
--------------------------------------------------------------*/
@media only screen and (max-width: 1769px) {
  #lawear-page .dashrightcont .container {
    width: 90%;
  }
}

@media only screen and (max-width: 1569px) {
  #lawear-page .container {
    width: 100%;
  }

  #lawear-page .cargoheading {
    padding: 36px 18px;
  }

  #lawear-page .batcurrent2 {
    padding: 40px 5px;
  }

  #lawear-page .battext1 {
    padding: 45px 5px;
  }

  #lawear-page .batreview {
    padding: 30px 5px;
  }

  #lawear-page .batcurrent1 {
    padding: 43px 5px;
  }

  #lawear-page .detailsservice a.detailsdiv {
    margin: 47px 10px 0 0;
    text-align: center;
  }

  #lawear-page a.detailsdiv {
    padding: 10px 25px;
  }

  #lawear-page a.review {
    padding: 10px 15px;
    margin: 0;
    display: inline-block;
    min-width: 130px;
  }

  #lawear-page .disputes-img img {
    width: 100px;
  }
}

@media only screen and (max-width: 1169px) {
  #lawear-page .container {
    width: 100%;
  }

  #lawear-page .menu li a {
    font-size: 15px;
    padding: 13px 20px;
  }

  #lawear-page .dashrightcont .personal-info {
    padding: 20px;
  }

  #lawear-page .detailsservice a.detailsdiv {
    margin: 47px 15px;
    font-size: 14px;
  }

  #lawear-page .batreview a.review {
    font-size: 14px;
    padding: 10px 15px;
  }
}

@media only screen and (max-width: 1023px) {
  #lawear-page .dashrightcont {
    padding: 20px;
  }

  #lawear-page .dashrightcont .profilecont {
    display: block;
  }

  #lawear-page .dashrightcont .profilecontleft,
  .dashrightcont .profilecontright {
    width: 100%;
  }

  #lawear-page .dashrightcont .profilecontright {
    border-top: 1px solid #e3e3e3;
    border-left: none;
  }

  #lawear-page .cargoheading {
    padding: 36px 18px;
  }

  #lawear-page .progressdiv1 .progressheading {
    width: 47%;
  }

  #lawear-page .dayleft {
    width: 25%;
    padding: 30px 15px;
    font-size: 14px;
  }

  #lawear-page .batleft {
    width: 15%;
    padding: 10px;
  }

  #lawear-page .battext {
    width: 19%;
    padding: 45px 16px;
  }

  #lawear-page .batreview {
    width: 22%;
    padding: 30px 15px;
  }

  #lawear-page .batcurrent {
    width: 16%;
    padding: 43px 18px;
  }

  #lawear-page .detailsservice a.detailsdiv {
    margin: 47px 18px;
    padding: 10px 27px;
    font-size: 14px;
  }

  #lawear-page .batreview a.review {
    margin-left: 0;
    margin-right: 0;
    font-size: 12px;
    padding: 6px 18px;
  }
}

@media only screen and (max-width: 768px) {
  #lawear-page .container {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  #lawear-page .container {
    width: 100%;
  }

  #lawear-page footer {
    position: static;
  }

  #lawear-page .bodycont {
    padding: 40px 0 20px;
  }

  #lawear-page .bodycont h2 {
    font-size: 35px;
  }

  #lawear-page .personal-info {
    padding: 60px 15px;
  }

  #lawear-page .bodycont h3 {
    font-size: 25px;
  }

  #lawear-page #outcont {
    padding-bottom: 0;
  }

  #lawear-page .footertop {
    padding: 30px 0;
  }

  #lawear-page .footertop h3 {
    padding: 0 0 10px;
    margin-bottom: 15px;
  }

  #lawear-page .footerdiv {
    padding-bottom: 25px;
  }

  #lawear-page .profilecontleft,
  #lawear-page .profilecontright {
    padding: 15px;
    width: 100%;
  }

  #lawear-page .profilecont {
    display: block;
  }

  #lawear-page .profilecontright {
    border-top: 1px solid #e3e3e3;
    border-left: none;
  }

  #lawear-page a.submitbut {
    min-width: inherit;
  }

  #lawear-page .logo {
    float: left;
    margin: 15px 0;
  }

  #lawear-page .loginright {
    padding-top: 17px;
    padding-right: 40px;
  }

  .notification {
    margin: 0 0 0 5px;
  }

  .notification a {
    font-size: 28px;
  }

  #lawear-page .notification span {
    right: -4px;
    top: 0px;
    width: 16px;
    height: 16px;
    line-height: 15px;
    font-size: 11px;
    font-weight: bold;
  }

  #lawear-page a.topprofile img {
    width: 30px;
    border-radius: 100%;
    margin-right: 0;
    border: 1px solid #7db9bb;
    height: 30px;
  }

  #lawear-page .menu {
    margin-top: 28px;
  }

  #lawear-page .responsive-menu {
    top: 19px;
  }

  #lawear-page .logo {
    width: 150px;
    margin: 15px 0;
  }

  #lawear-page .checkcontainer {
    text-align: left;
  }

  #lawear-page .paymentstep {
    padding: 0 0 20px;
  }

  #lawear-page .cardicon {
    float: right;
    width: 100%;
    text-align: left;
  }

  #lawear-page .cardicon img {
    width: 40px;
    border-radius: 100%;
    margin: 5px;
  }

  #lawear-page .logindiv {
    width: 96%;
    padding: 25px;
  }

  #lawear-page .dashcont {
    display: block;
  }

  #lawear-page .dashlink {
    width: 100%;
    padding: 25px 0;
  }

  #lawear-page .dashlink ul li a {
    padding: 15px 70px;
    font-size: 18px;
  }

  #lawear-page .dashlink ul li:last-child a {
    margin: 0;
  }

  #lawear-page .dashrightcont .container {
    width: 100%;
  }

  #lawear-page .upload-btn-wrapper {
    top: 14px;
    right: 12px;
  }

  #lawear-page .clearing li {
    display: block;
    background: none;
  }

  #lawear-page .cargoheading {
    padding: 15px;
  }

  #lawear-page .clearing li {
    min-height: 46px;
  }

  #lawear-page .starsec {
    padding: 20px;
  }

  #lawear-page a.review {
    display: inline-block;
    /* width: 100%; */
    text-align: center;
  }

  #lawear-page .progressdiv1 .progressheading {
    width: 100%;
    padding: 36px 0 21px 70px;
  }

  #lawear-page .starsec1 {
    padding: 0;
  }

  #lawear-page .dayleft {
    width: 100%;
    text-align: left;
    padding: 0 25px;
  }

  #lawear-page .progressdiv1 a.detailsdiv {
    margin: 16px 15px;
    display: inline-block;
    float: none;
  }

  #lawear-page .tabheading h3,
  .tabheading h4 {
    float: none;
    text-align: center;
    padding: 5px 0;
  }

  #lawear-page .tabheading h4 {
    margin-bottom: 10px;
  }

  #lawear-page .batleft,
  .battext,
  .batcurrent2,
  .batcurrent2,
  .batcurrent1,
  .battext1 {
    width: 100%;
    padding: 5px;
  }

  #lawear-page .battext,
  .battext1 {
    padding: 20px 0;
    min-height: inherit;
  }

  #lawear-page .batreview {
    width: 100%;
    padding: 10px;
  }

  #lawear-page .batcurrent {
    width: 100%;
    padding: 0 24px;
    text-align: center;
  }

  #lawear-page .detailsservice a.detailsdiv {
    display: inline-block;
    float: none;
    margin: 20px;
  }

  #lawear-page .detailsservice {
    text-align: center;
  }

  #lawear-page #nonResponsiveTabs li {
    width: 100%;
    margin: 0 0 20px 0;
  }

  #lawear-page .servicetab .nav-tabs > li {
    width: 100%;
    margin-bottom: 5px;
  }

  #lawear-page .servicetab .nav > li > a {
    background-color: #eee;
  }

  #lawear-page .nav-tabs > li.active > a,
  #lawear-page .nav-tabs > li.active > a:focus,
  #lawear-page .nav-tabs > li.active > a:hover {
    color: #fff !important;
    cursor: default;
    background-color: #01a8ac !important;
    border: 1px solid #01a8ac !important;
  }

  #lawear-page .servicetab .tab-content {
    box-shadow: 0 0 5px #ccc;
    padding: 25px 10px;
    text-align: center;
  }

  #lawear-page .loginright {
    padding-top: 11px;
    padding-right: 0;
    width: 120px;
    position: absolute;
    right: 60px;
  }

  #lawear-page .back-to-top {
    display: none;
  }

  #lawear-page .back-to-top.active {
    visibility: visible;
    opacity: 0;
  }

  #lawear-page .payment-details-block,
  #lawear-page .payment-details-block1 {
    width: 100%;
    padding: 0 5px;
    float: none;
    border: none;
  }

  #lawear-page .payment-info {
    padding: 5px;
  }

  #lawear-page .payment-details-block {
    background-color: #eee;
  }

  #lawear-page .navbar .login {
    margin-right: 10px;
  }

  #lawear-page .logindiv .submitbut {
    width: 100%;
  }

  #lawear-page a.submitbut {
    min-width: inherit;
  }

  #lawear-page .logindiv a {
    text-decoration: underline;
  }

  #lawear-page a.submitbut,
  #lawear-page a.backbut {
    padding: 15px;
  }

  #lawear-page .chat {
    display: none;
  }

  #lawear-page .tab {
    float: none;
    background-color: none;
    width: 100%;
    padding: 0;
    margin-top: 0;
    z-index: 10;
  }

  #lawear-page .tabcontent {
    float: left;
    padding: 0px 30px !important;
    width: 100%;
    border-left: none;
    margin-top: 0;
    padding: 0;
  }

  #lawear-page .tab h4 {
    padding: 0 15px 0 13px;
  }

  #lawear-page .tab button {
    font-size: 20px;
  }

  #lawear-page .tab button:last-child {
    margin-top: 10px;
  }

  #lawear-page .disputes-img {
    margin-bottom: 20px;
    text-align: center;
  }

  #lawear-page .disputes-img img {
    width: 150px;
  }

  #lawear-page .clearingtable td {
    padding: 37px 5px;
    font-size: 22px;
  }

  #lawear-page a.detailsdiv {
    padding: 10px 5px;
    font-size: 12px;
  }

  #lawear-page .header {
    padding: 5px 0;
    height: 60px;
  }
}

@media only screen and (max-width: 540px) {
  #lawear-page .container {
    width: 100%;
  }

  #lawear-page .logo {
    width: 135px;
    margin: 15px 0;
  }

  #lawear-page .loginright {
    padding-top: 11px;
    padding-right: 0;
    width: 120px;
    position: absolute;
    right: 60px;
  }

  #lawear-page .notification {
    margin: 0 0 0 5px;
  }

  #lawear-page .notification a {
    font-size: 28px;
  }

  #lawear-page .notification span {
    right: -4px;
    top: 0px;
    width: 16px;
    height: 16px;
    line-height: 15px;
    font-size: 11px;
    font-weight: bold;
  }

  #lawear-page a.topprofile img {
    width: 30px;
    border-radius: 100%;
    margin-right: 0;
    border: 1px solid #7db9bb;
  }

  #lawear-page .menu {
    margin-top: 28px;
  }

  #lawear-page .responsive-menu {
    top: 13px;
  }

  #lawear-page .paymentstep li {
    display: block;
    padding: 10px 0;
    background: url(../img/dotted1.png) no-repeat center 0 #fff;
    width: 100%;
  }

  #lawear-page .paymentstep li:first-child {
    background-position: center 0;
    background-repeat: no-repeat;
  }

  #lawear-page .paymentstep li:last-child {
    background-position: center 0;
    background-repeat: no-repeat;
  }

  #lawear-page .paymentstep li p {
    background: #fff;
    padding: 0 0 26px;
  }

  #lawear-page .payment-method2 img {
    width: 97px;
  }

  #lawear-page .servicecheck label {
    margin: 5px;
  }

  #lawear-page .upload-btn-wrapper {
    top: 14px;
    right: 12px;
  }

  #lawear-page .progressheading {
    float: none;
    padding: 10px 0 40px 50px;
  }

  #lawear-page a.detailsdiv {
    float: none;
  }

  #lawear-page .progressdiv1 .progressheading {
    width: 100%;
    padding: 36px 0 21px 70px;
  }

  #lawear-page .dayleft {
    width: 100%;
    text-align: left;
    padding: 0 25px;
  }

  #lawear-page .progressdiv1 a.detailsdiv {
    margin: 16px 15px;
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# Superviser Dashboard page
--------------------------------------------------------------*/
@media only screen and (max-width: 1769px) {
  #superviser-page .dashrightcont .container {
    width: 90%;
  }
}

@media only screen and (max-width: 1569px) {
  #superviser-page .container {
    width: 100%;
  }

  #superviser-page .cargoheading {
    padding: 36px 18px;
  }

  #superviser-page .batcurrent2 {
    padding: 40px 5px;
  }

  #superviser-page .battext1 {
    padding: 45px 5px;
  }

  #superviser-page .batreview {
    padding: 30px 5px;
  }

  #superviser-page .batcurrent1 {
    padding: 43px 5px;
  }

  #superviser-page .detailsservice a.detailsdiv {
    margin: 47px 10px 0 0;
    text-align: center;
  }

  #superviser-page a.detailsdiv {
    padding: 10px 25px;
  }

  #superviser-page a.review {
    padding: 10px 15px;
    margin: 0;
  }

  #superviser-page .disputes-img img {
    width: 100px;
  }

  #superviser-page .heading1 {
    padding: 36px 60px 0 0px;
    font-size: 18px;
  }

  #superviser-page .cargoheading1,
  .cargoheading2 {
    padding: 36px 15px;
    width: 160px !important;
    font-size: 18px;
  }

  #superviser-page .cargoheading {
    padding: 26px 44px;
    width: 300px;
    font-weight: 500;
  }
}

@media only screen and (min-width: 1569px) {
  #superviser-page .oparator2 {
    width: 370px;
  }

  #superviser-page .oparator1 {
    width: 250px;
  }

  #superviser-page .heading1 {
    padding: 36px 35px 0 10px;
  }

  #superviser-page .cargoheading1,
  #superviser-page .cargoheading2 {
    padding: 16px 75px;
  }

  #superviser-page .cargoheading {
    padding: 36px 64px;
  }
}

@media only screen and (max-width: 1169px) {
  #hero {
    background-color: #007679;
  }

  .hero h2 {
    font-size: 30px !important;
    margin: 80px 0 20px 0 !important;
  }

  #hero .heading-home {
    margin-left: 0 !important;
    width: 100% !important;
    font-size: 26px !important;
    line-height: 28px !important;
  }

  #superviser-page .container {
    width: 100%;
  }

  #superviser-page .menu li a {
    font-size: 15px;
    padding: 13px 20px;
  }

  #superviser-page .dashrightcont .personal-info {
    padding: 20px;
  }

  #superviser-page .detailsservice a.detailsdiv {
    margin: 47px 15px;
    font-size: 14px;
  }

  #superviser-page .batreview a.review {
    font-size: 14px;
    padding: 10px 15px;
  }
}

@media only screen and (max-width: 1023px) {
  #hero {
    background-color: #007679;
  }

  .hero h2 {
    font-size: 30px !important;
    margin: 80px 0 20px 0 !important;
  }

  #hero .heading-home {
    margin-left: 0 !important;
    width: 100% !important;
    font-size: 22px !important;
    line-height: 20px !important;
  }

  #superviser-page .dashrightcont {
    padding: 20px;
  }

  #superviser-page .dashrightcont .profilecont {
    display: block;
  }

  #superviser-page .dashrightcont .profilecontleft,
  .dashrightcont .profilecontright {
    width: 100%;
  }

  #superviser-page .dashrightcont .profilecontright {
    border-top: 1px solid #e3e3e3;
    border-left: none;
  }

  #superviser-page .oparator1,
  #superviser-page .oparator2 {
    width: 180px;
    font-size: 16px;
  }

  #superviser-page .cargoheading {
    padding: 36px 18px;
  }

  #superviser-page .progressdiv1 .progressheading {
    width: 47%;
  }

  #superviser-page .dayleft {
    width: 25%;
    padding: 30px 15px;
    font-size: 14px;
  }

  #superviser-page .batleft {
    width: 15%;
    padding: 10px;
  }

  #superviser-page .battext {
    width: 19%;
    padding: 45px 16px;
  }

  #superviser-page .batreview {
    width: 22%;
    padding: 30px 15px;
  }

  #superviser-page .batcurrent {
    width: 16%;
    padding: 43px 18px;
  }

  #superviser-page .detailsservice a.detailsdiv {
    margin: 47px 18px;
    padding: 10px 27px;
    font-size: 14px;
  }

  #superviser-page .batreview a.review {
    margin-left: 0;
    margin-right: 0;
    font-size: 12px;
    padding: 6px 18px;
  }
}

@media only screen and (max-width: 768px) {
  #superviser-page .container {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  #superviser-page .container {
    width: 100%;
  }

  #superviser-page footer {
    position: static;
  }

  #superviser-page .bodycont {
    padding: 85px 0 20px;
  }

  #superviser-page .bodycont h2 {
    font-size: 35px;
  }

  #superviser-page .personal-info {
    padding: 60px 15px;
  }

  #superviser-page .bodycont h3 {
    font-size: 25px;
  }

  #superviser-page #outcont {
    padding-bottom: 0;
  }

  #superviser-page .footertop {
    padding: 30px 0;
  }

  #superviser-page .footertop h3 {
    padding: 0 0 10px;
    margin-bottom: 15px;
  }

  #superviser-page .footerdiv {
    padding-bottom: 25px;
  }

  #superviser-page .profilecontleft,
  #superviser-page .profilecontright {
    padding: 15px;
    width: 100%;
  }

  #superviser-page .profilecont {
    display: block;
  }

  #superviser-page .profilecontright {
    border-top: 1px solid #e3e3e3;
    border-left: none;
  }

  #superviser-page a.submitbut {
    min-width: inherit;
  }

  #superviser-page .logo {
    float: left;
    margin: 15px 0;
  }

  #superviser-page .loginright {
    padding-top: 17px;
    padding-right: 40px;
  }

  .notification {
    margin: 0 0 0 5px;
  }

  .notification a {
    font-size: 28px;
  }

  #superviser-page .notification span {
    right: -4px;
    top: 0px;
    width: 16px;
    height: 16px;
    line-height: 15px;
    font-size: 11px;
    font-weight: bold;
  }

  #superviser-page a.topprofile img {
    width: 30px;
    border-radius: 100%;
    margin-right: 0;
    border: 1px solid #7db9bb;
    height: 30px;
  }

  #superviser-page .menu {
    margin-top: 28px;
  }

  #superviser-page .responsive-menu {
    top: 19px;
  }

  #superviser-page .logo {
    width: 150px;
  }

  #superviser-page .checkcontainer {
    text-align: left;
  }

  #superviser-page .paymentstep {
    padding: 0 0 20px;
  }

  #superviser-page .cardicon {
    float: right;
    width: 100%;
    text-align: left;
  }

  #superviser-page .cardicon img {
    width: 40px;
    border-radius: 100%;
    margin: 5px;
  }

  #superviser-page .logindiv {
    width: 96%;
    padding: 25px;
  }

  #superviser-page .dashcont {
    display: block;
  }

  #superviser-page .dashlink {
    width: 100%;
    padding: 25px 0;
  }

  #superviser-page .dashlink ul li a {
    padding: 15px 70px;
    font-size: 18px;
  }

  #superviser-page .dashlink ul li:last-child a {
    margin: 0;
  }

  #superviser-page .dashrightcont .container {
    width: 100%;
  }

  #superviser-page .upload-btn-wrapper {
    top: 14px;
    right: 12px;
  }

  #superviser-page .clearing li {
    display: block;
    background: none;
  }

  #superviser-page .cargoheading {
    padding: 15px;
  }

  #superviser-page .clearing li {
    min-height: 46px;
  }

  #superviser-page .starsec {
    padding: 20px;
  }

  #superviser-page a.review {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  #superviser-page .progressdiv1 .progressheading {
    width: 100%;
    padding: 36px 0 21px 70px;
  }

  #superviser-page .starsec1 {
    padding: 0;
  }

  #superviser-page .dayleft {
    width: 100%;
    text-align: left;
    padding: 0 25px;
  }

  #superviser-page .progressdiv1 a.detailsdiv {
    margin: 16px 15px;
    display: inline-block;
    float: none;
  }

  #superviser-page .tabheading h3,
  #superviser-page .tabheading h4 {
    float: none;
    text-align: center;
    padding: 5px 0;
  }

  #superviser-page .tabheading h4 {
    margin-bottom: 10px;
  }

  #superviser-page .batleft,
  #superviser-page .battext,
  #superviser-page .batcurrent2,
  #superviser-page .batcurrent2,
  #superviser-page .batcurrent1,
  #superviser-page .battext1 {
    width: 100%;
    padding: 5px;
  }

  #superviser-page .battext,
  #superviser-page .battext1 {
    padding: 20px 0;
    min-height: inherit;
  }

  #superviser-page .batreview {
    width: 100%;
    padding: 10px;
  }

  #superviser-page .batcurrent {
    width: 100%;
    padding: 0 24px;
    text-align: center;
  }

  #superviser-page .detailsservice a.detailsdiv {
    display: inline-block;
    float: none;
    margin: 20px;
  }

  #superviser-page .detailsservice {
    text-align: center;
  }

  #superviser-page #nonResponsiveTabs li {
    width: 100%;
    margin: 0 0 20px 0;
  }

  #superviser-page .servicetab .nav-tabs > li {
    width: 100%;
    margin-bottom: 5px;
  }

  #superviser-page .servicetab .nav > li > a {
    background-color: #eee;
  }

  #superviser-page .nav-tabs > li.active > a,
  #superviser-page .nav-tabs > li.active > a:focus,
  #superviser-page .nav-tabs > li.active > a:hover {
    color: #fff !important;
    cursor: default;
    background-color: #01a8ac !important;
    border: 1px solid #01a8ac !important;
  }

  #superviser-page .servicetab .tab-content {
    box-shadow: 0 0 5px #ccc;
    padding: 25px 10px;
    text-align: center;
  }

  #superviser-page .loginright {
    padding-top: 11px;
    padding-right: 0;
    width: 120px;
    position: absolute;
    right: 60px;
  }

  #superviser-page .back-to-top {
    display: none;
  }

  #superviser-page .back-to-top.active {
    visibility: visible;
    opacity: 0;
  }

  #superviser-page .payment-details-block,
  #superviser-page .payment-details-block1 {
    width: 100%;
    padding: 0 5px;
    float: none;
    border: none;
  }

  #superviser-page .payment-info {
    padding: 5px;
  }

  #superviser-page .payment-details-block {
    background-color: #eee;
  }

  #superviser-page .navbar .login {
    margin-right: 10px;
  }

  #superviser-page .logindiv .submitbut {
    width: 100%;
  }

  #superviser-page a.submitbut {
    min-width: inherit;
  }

  #superviser-page .logindiv a {
    text-decoration: underline;
  }

  #superviser-page a.submitbut,
  a.backbut {
    padding: 15px;
  }

  #superviser-page .chat {
    display: none;
  }

  #superviser-page .tab {
    float: none;
    background-color: none;
    width: 100%;
    padding: 0;
    margin-top: 0;
    z-index: 10;
  }

  #superviser-page .tabcontent {
    float: left;
    padding: 0px 10px !important;
    width: 100%;
    border-left: none;
    margin-top: 0;
    padding: 0;
  }

  #superviser-page .tab h4 {
    padding: 0 15px 0 13px;
  }

  #superviser-page .tab button {
    font-size: 20px;
  }

  #superviser-page .tab button:last-child {
    margin-top: 10px;
  }

  #superviser-page .disputes-img {
    margin-bottom: 20px;
    text-align: center;
  }

  #superviser-page .disputes-img img {
    width: 150px;
  }

  #superviser-page .clearingtable td {
    padding: 37px 5px;
    font-size: 22px;
  }

  #superviser-page a.detailsdiv {
    padding: 10px 5px;
    font-size: 12px;
  }

  #superviser-page .header {
    padding: 5px 0;
    height: 60px;
  }

  #superviser-page .header .logo img {
    margin-top: 0;
  }
}

@media only screen and (max-width: 540px) {
  #superviser-page .container {
    width: 100%;
  }

  #superviser-page .logo {
    width: 135px;
    margin: 15px 0 !important;
  }

  #superviser-page .loginright {
    padding-top: 11px;
    padding-right: 0;
    width: 120px;
    position: absolute;
    right: 60px;
  }

  #superviser-page .notification {
    margin: 0 0 0 5px;
  }

  #superviser-page .notification a {
    font-size: 28px;
  }

  #superviser-page .notification span {
    right: -4px;
    top: 0px;
    width: 16px;
    height: 16px;
    line-height: 15px;
    font-size: 11px;
    font-weight: bold;
  }

  #superviser-page a.topprofile img {
    width: 30px;
    border-radius: 100%;
    margin-right: 0;
    border: 1px solid #7db9bb;
    height: 30px;
  }

  #superviser-page .menu {
    margin-top: 28px;
  }

  #superviser-page .responsive-menu {
    top: 13px;
  }

  #superviser-page .paymentstep li {
    display: block;
    padding: 10px 0;
    background: url(../img/dotted1.png) no-repeat center 0 #fff;
    width: 100%;
  }

  #superviser-page .paymentstep li:first-child {
    background-position: center 0;
    background-repeat: no-repeat;
  }

  #superviser-page .paymentstep li:last-child {
    background-position: center 0;
    background-repeat: no-repeat;
  }

  #superviser-page .paymentstep li p {
    background: #fff;
    padding: 0 0 26px;
  }

  #superviser-page .payment-method2 img {
    width: 97px;
  }

  #superviser-page .servicecheck label {
    margin: 5px;
  }

  #superviser-page .upload-btn-wrapper {
    top: 14px;
    right: 12px;
  }

  #superviser-page .progressheading {
    float: none;
    padding: 10px 0 40px 50px;
  }

  #superviser-page a.detailsdiv {
    float: none;
  }

  #superviser-page .progressdiv1 .progressheading {
    width: 100%;
    padding: 36px 0 21px 70px;
  }

  #superviser-page .dayleft {
    width: 100%;
    text-align: left;
    padding: 0 25px;
  }

  #superviser-page .progressdiv1 a.detailsdiv {
    margin: 16px 15px;
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# Truck inspector page
--------------------------------------------------------------*/
@media only screen and (max-width: 1920px) {
  .video-banner {
    /* height: 100vh; */
  }

  video {
    object-fit: fill;
  }

  #truck-inspector-page .dashrightcont .container {
    width: 90%;
  }
}

@media only screen and (max-width: 1769px) {
  .video-banner {
    /* height: 100vh; */
  }

  video {
    object-fit: fill;
  }

  #truck-inspector-page .dashrightcont .container {
    width: 90%;
  }
}

@media only screen and (max-width: 1569px) {
  .video-banner {
    /* height: 100vh; */
  }

  video {
    object-fit: fill;
  }

  #truck-inspector-page .container {
    width: 100%;
  }

  #truck-inspector-page .cargoheading {
    padding: 36px 18px;
  }

  #truck-inspector-page .batcurrent2 {
    padding: 40px 5px;
  }

  #truck-inspector-page .battext1 {
    padding: 45px 5px;
  }

  #truck-inspector-page .batreview {
    padding: 30px 5px;
  }

  #truck-inspector-page .batcurrent1 {
    padding: 43px 5px;
  }

  #truck-inspector-page .detailsservice a.detailsdiv {
    margin: 47px 10px 0 0;
    text-align: center;
  }

  #truck-inspector-page a.detailsdiv {
    padding: 10px 25px;
  }

  #truck-inspector-page a.review {
    padding: 10px 15px;
    margin: 0;
  }

  #truck-inspector-page .disputes-img img {
    width: 100px;
  }
}

@media only screen and (max-width: 1369px) {
  #hero {
    background: none !important;
    top: 15%;
  }

  .video-banner {
    /* height: 100vh; */
  }

  video {
    object-fit: fill;
  }
}

@media only screen and (max-width: 980px) {
  #hero {
    background: none !important;
    /* top: 45%; */
  }
}

@media only screen and (max-width: 1169px) {
  #truck-inspector-page .container {
    width: 100%;
  }

  #truck-inspector-page .menu li a {
    font-size: 15px;
    padding: 13px 20px;
  }

  #truck-inspector-page .dashrightcont .personal-info {
    padding: 20px;
  }

  #truck-inspector-page .detailsservice a.detailsdiv {
    margin: 47px 15px;
    font-size: 14px;
  }

  #truck-inspector-page .batreview a.review {
    font-size: 14px;
    padding: 10px 15px;
  }
}

@media only screen and (max-width: 1023px) {
  #hero .flexx a {
    width: 20%;
  }

  #truck-inspector-page .dashrightcont {
    padding: 20px;
  }

  #truck-inspector-page .dashrightcont .profilecont {
    display: block;
  }

  #truck-inspector-page .dashrightcont .profilecontleft,
  .dashrightcont .profilecontright {
    width: 100%;
  }

  #truck-inspector-page .dashrightcont .profilecontright {
    border-top: 1px solid #e3e3e3;
    border-left: none;
  }

  #truck-inspector-page .cargoheading {
    padding: 36px 18px;
  }

  #truck-inspector-page .progressdiv1 .progressheading {
    width: 47%;
  }

  #truck-inspector-page .dayleft {
    width: 25%;
    padding: 30px 15px;
    font-size: 14px;
  }

  #truck-inspector-page .batleft {
    width: 15%;
    padding: 10px;
  }

  #truck-inspector-page .battext {
    width: 19%;
    padding: 45px 16px;
  }

  #truck-inspector-page .batreview {
    width: 22%;
    padding: 30px 15px;
  }

  #truck-inspector-page .batcurrent {
    width: 16%;
    padding: 43px 18px;
  }

  #truck-inspector-page .detailsservice a.detailsdiv {
    margin: 47px 18px;
    padding: 10px 27px;
    font-size: 14px;
  }

  #truck-inspector-page .batreview a.review {
    margin-left: 0;
    margin-right: 0;
    font-size: 12px;
    padding: 6px 18px;
  }
}

@media only screen and (max-width: 768px) {
  #truck-inspector-page .container {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  #truck-inspector-page .container {
    width: 100%;
  }

  #truck-inspector-page footer {
    position: static;
  }

  #truck-inspector-page .bodycont {
    padding: 85px 0 20px;
  }

  #truck-inspector-page .bodycont h2 {
    font-size: 35px;
  }

  #truck-inspector-page .personal-info {
    padding: 60px 15px;
  }

  #truck-inspector-page .bodycont h3 {
    font-size: 25px;
  }

  #truck-inspector-page #outcont {
    padding-bottom: 0;
  }

  #truck-inspector-page .footertop {
    padding: 30px 0;
  }

  #truck-inspector-page .footertop h3 {
    padding: 0 0 10px;
    margin-bottom: 15px;
  }

  #truck-inspector-page .footerdiv {
    padding-bottom: 25px;
  }

  #truck-inspector-page .profilecontleft,
  #truck-inspector-page .profilecontright {
    padding: 15px;
    width: 100%;
  }

  #truck-inspector-page .profilecont {
    display: block;
  }

  #truck-inspector-page .profilecontright {
    border-top: 1px solid #e3e3e3;
    border-left: none;
  }

  #truck-inspector-page a.submitbut {
    min-width: inherit;
  }

  #truck-inspector-page .logo {
    float: left;
    margin: 10px 0;
  }

  #truck-inspector-page .loginright {
    padding-top: 17px;
    padding-right: 40px;
  }

  #truck-inspector-page .notification {
    margin: 0 0 0 5px;
  }

  #truck-inspector-page .notification a {
    font-size: 28px;
  }

  #truck-inspector-page .notification span {
    right: -4px;
    top: 0px;
    width: 16px;
    height: 16px;
    line-height: 15px;
    font-size: 11px;
    font-weight: bold;
  }

  #truck-inspector-page a.topprofile img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin-right: 0;
    border: 1px solid #7db9bb;
  }

  #truck-inspector-page .menu {
    margin-top: 28px;
  }

  #truck-inspector-page .responsive-menu {
    top: 19px;
  }

  #truck-inspector-page .logo {
    width: 150px;
  }

  #truck-inspector-page .checkcontainer {
    text-align: left;
  }

  #truck-inspector-page .paymentstep {
    padding: 0 0 20px;
  }

  #truck-inspector-page .cardicon {
    float: right;
    width: 100%;
    text-align: left;
  }

  #truck-inspector-page .cardicon img {
    width: 40px;
    border-radius: 100%;
    margin: 5px;
  }

  #truck-inspector-page .logindiv {
    width: 96%;
    padding: 25px;
  }

  #truck-inspector-page .dashcont {
    display: block;
  }

  #truck-inspector-page .dashlink {
    width: 100%;
    padding: 25px 0;
  }

  #truck-inspector-page .dashlink ul li a {
    padding: 15px 70px;
    font-size: 18px;
  }

  #truck-inspector-page .dashlink ul li:last-child a {
    margin: 0;
  }

  #truck-inspector-page .dashrightcont .container {
    width: 100%;
  }

  #truck-inspector-page .upload-btn-wrapper {
    top: 14px;
    right: 12px;
  }

  #truck-inspector-page .clearing li {
    display: block;
    background: none;
  }

  #truck-inspector-page .cargoheading {
    padding: 15px;
  }

  #truck-inspector-page .clearing li {
    min-height: 46px;
  }

  #truck-inspector-page .starsec {
    padding: 20px;
  }

  #truck-inspector-page a.review {
    display: inline-block;
    margin: 15px 0;
    width: 100%;
    text-align: center;
  }

  #truck-inspector-page .progressdiv1 .progressheading {
    width: 100%;
    padding: 36px 0 21px 70px;
  }

  #truck-inspector-page .starsec1 {
    padding: 0;
  }

  #truck-inspector-page .dayleft {
    width: 100%;
    text-align: left;
    padding: 0 25px;
  }

  #truck-inspector-page .progressdiv1 a.detailsdiv {
    margin: 16px 15px;
    display: inline-block;
    float: none;
  }

  #truck-inspector-page .tabheading h3,
  #truck-inspector-page .tabheading h4 {
    float: none;
    text-align: center;
    padding: 5px 0;
  }

  #truck-inspector-page .tabheading h4 {
    margin-bottom: 10px;
  }

  #truck-inspector-page .batleft,
  #truck-inspector-page .battext,
  #truck-inspector-page .batcurrent2,
  #truck-inspector-page .batcurrent2,
  .batcurrent1,
  .battext1 {
    width: 100%;
    padding: 5px;
  }

  #truck-inspector-page .battext,
  #truck-inspector-page .battext1 {
    padding: 20px 0;
    min-height: inherit;
  }

  #truck-inspector-page .batreview {
    width: 100%;
    padding: 10px;
  }

  #truck-inspector-page .batcurrent {
    width: 100%;
    padding: 0 24px;
    text-align: center;
  }

  #truck-inspector-page .detailsservice a.detailsdiv {
    display: inline-block;
    float: none;
    margin: 20px;
  }

  #truck-inspector-page .detailsservice {
    text-align: center;
  }

  #truck-inspector-page #nonResponsiveTabs li {
    width: 100%;
    margin: 0 0 20px 0;
  }

  #truck-inspector-page .servicetab .nav-tabs > li {
    width: 100%;
    margin-bottom: 5px;
  }

  #truck-inspector-page .servicetab .nav > li > a {
    background-color: #eee;
  }

  #truck-inspector-page .nav-tabs > li.active > a,
  #truck-inspector-page .nav-tabs > li.active > a:focus,
  #truck-inspector-page .nav-tabs > li.active > a:hover {
    color: #fff !important;
    cursor: default;
    background-color: #01a8ac !important;
    border: 1px solid #01a8ac !important;
  }

  #truck-inspector-page .servicetab .tab-content {
    box-shadow: 0 0 5px #ccc;
    padding: 25px 10px;
    text-align: center;
  }

  #truck-inspector-page .loginright {
    padding-top: 11px;
    padding-right: 0;
    width: 120px;
    position: absolute;
    right: 60px;
  }

  #truck-inspector-page .back-to-top {
    display: none;
  }

  #truck-inspector-page .back-to-top.active {
    visibility: visible;
    opacity: 0;
  }

  #truck-inspector-page .payment-details-block,
  #truck-inspector-page .payment-details-block1 {
    width: 100%;
    padding: 0 5px;
    float: none;
    border: none;
  }

  #truck-inspector-page .payment-info {
    padding: 5px;
  }

  #truck-inspector-page .payment-details-block {
    background-color: #eee;
  }

  #truck-inspector-page .navbar .login {
    margin-right: 10px;
  }

  #truck-inspector-page .logindiv .submitbut {
    width: 100%;
  }

  #truck-inspector-page a.submitbut {
    min-width: inherit;
  }

  #truck-inspector-page .logindiv a {
    text-decoration: underline;
  }

  #truck-inspector-page a.submitbut,
  #truck-inspector-page a.backbut {
    padding: 15px;
  }

  #truck-inspector-page .chat {
    display: none;
  }

  #truck-inspector-page .tab {
    float: none;
    background-color: none;
    width: 100%;
    padding: 0;
    margin-top: 0;
    z-index: 10;
  }

  #truck-inspector-page .tabcontent {
    float: left;
    padding: 0px 30px !important;
    width: 100%;
    border-left: none;
    margin-top: 0;
    padding: 0;
  }

  #truck-inspector-page .tab h4 {
    padding: 0 15px 0 13px;
  }

  #truck-inspector-page .tab button {
    font-size: 20px;
  }

  #truck-inspector-page .tab button:last-child {
    margin-top: 10px;
  }

  #truck-inspector-page .disputes-img {
    margin-bottom: 20px;
    text-align: center;
  }

  #truck-inspector-page .disputes-img img {
    width: 150px;
  }

  #truck-inspector-page .clearingtable td {
    padding: 37px 5px;
    font-size: 22px;
  }

  #truck-inspector-page a.detailsdiv {
    padding: 10px 5px;
    font-size: 12px;
  }

  #truck-inspector-page .header {
    padding: 5px 0;
    height: 60px;
  }
}

@media only screen and (max-width: 767px) {
  #client-page .operator-block .starsec1 {
    padding: 30px 20px;
  }

  #client-page .operator-block .servicetab .nav-tabs li {
    width: 100%;
  }

  #client-page .tabcontent {
    float: left;
    padding: 0px 10px !important;
  }

  #hero {
    /* margin-top: -680px !important; */
    background: none !important;
    top: 2%;
    padding: 0;
  }

  .hero h2 {
    font-size: 16px !important;
    margin: 60px 0 20px 0 !important;
  }

  #hero .heading-home {
    width: 100% !important;
    margin-left: 0px !important;
    font-size: 14px !important;
    line-height: 16px !important;
  }

  #clearing-agent-page .clearingtable td {
    padding: 37px 5px;
    font-size: 22px;
  }

  #footer .call i {
    display: none;
  }
}

@media only screen and (max-width: 580px) {
  .mrl-1 {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media only screen and (max-width: 540px) {
  .hero {
    margin-top: 0;
    top: 0% !important;
    padding-top: 60px;
  }

  #truck-inspector-page .container {
    width: 100%;
  }

  #truck-inspector-page .logo {
    width: 135px;
    margin: 15px 0;
  }

  #truck-inspector-page .loginright {
    padding-top: 11px;
    padding-right: 0;
    width: 120px;
    position: absolute;
    right: 60px;
  }

  #truck-inspector-page .notification {
    margin: 0 0 0 5px;
  }

  #truck-inspector-page .notification a {
    font-size: 28px;
  }

  #truck-inspector-page .notification span {
    right: -4px;
    top: 0px;
    width: 16px;
    height: 16px;
    line-height: 15px;
    font-size: 11px;
    font-weight: bold;
  }

  #truck-inspector-page a.topprofile img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin-right: 0;
    border: 1px solid #7db9bb;
  }

  #truck-inspector-page .menu {
    margin-top: 28px;
  }

  #truck-inspector-page .responsive-menu {
    top: 13px;
  }

  #truck-inspector-page .paymentstep li {
    display: block;
    padding: 10px 0;
    background: url(../img/dotted1.png) no-repeat center 0 #fff;
    width: 100%;
  }

  #truck-inspector-page .paymentstep li:first-child {
    background-position: center 0;
    background-repeat: no-repeat;
  }

  #truck-inspector-page .paymentstep li:last-child {
    background-position: center 0;
    background-repeat: no-repeat;
  }

  #truck-inspector-page .paymentstep li p {
    background: #fff;
    padding: 0 0 26px;
  }

  #truck-inspector-page .payment-method2 img {
    width: 97px;
  }

  #truck-inspector-page .servicecheck label {
    margin: 5px;
  }

  #truck-inspector-page .upload-btn-wrapper {
    top: 14px;
    right: 12px;
  }

  #truck-inspector-page .progressheading {
    float: none;
    padding: 10px 0 40px 50px;
  }

  #truck-inspector-page a.detailsdiv {
    float: none;
  }

  #truck-inspector-page .progressdiv1 .progressheading {
    width: 100%;
    padding: 36px 0 21px 70px;
  }

  #truck-inspector-page .dayleft {
    width: 100%;
    text-align: left;
    padding: 0 25px;
  }

  #truck-inspector-page .progressdiv1 a.detailsdiv {
    margin: 16px 15px;
    display: inline-block;
  }

  #clearing-agent-page .submitbut {
    min-width: 220px;
  }

  #clearing-agent-page .modal .tabcontent {
    padding: 0 !important;
  }

  #hero {
    background: none !important;
    position: absolute !important;
    /* top: 85%; */
    padding-top: 50px;
  }

  .hero h2 {
    font-size: 18px !important;
    margin: 20px 0 20px 0 !important;
  }

  .video-banner {
    /* height: 100vh; */
  }

  video {
    object-fit: cover;
  }

  #hero .heading-home {
    width: 100% !important;
    margin-left: 0px !important;
    /* font-size: 24px !important; */
    line-height: 30px !important;
  }

  #hero .flexx a {
    width: 30%;
  }
}

@media (max-width: 450px) {
  #hero {
    margin-top: -5px !important;
    top: 9% !important;
  }

  .video-banner {
    height: 86rem;
  }

  .mobile-margin {
    margin-top: -400px;
  }

  .videot1 {
    height: 100% !important;
  }
}

/* new responsive styles from 02-02-2022 */

@media only screen and (max-width: 1769px) {
  #client-page .tab button {
    font-size: 23px;
    padding: 15px 25px 15px 30px;
  }

  #clearing-agent-page .tab button {
    font-size: 23px;
    padding: 15px 25px 15px 30px;
  }

  #lawear-page .tab {
    padding: 70px 0;
  }

  #lawear-page .tab button {
    font-size: 22px;
    padding: 15px 25px 15px 30px;
  }

  #lawear-page a.submitbut {
    padding: 10px !important;
    min-width: 180px !important;
    font-size: 18px !important;
  }

  #lawear-page a.backbut {
    padding: 10px !important;
    min-width: 180px !important;
    font-size: 18px !important;
  }

  #truck-inspector-page .clearingtable td {
    font-size: 20px;
    padding: 10px 20px;
  }

  #truck-inspector-page .clearingtable p,
  #truck-inspector-page .clearingtable span,
  #truck-inspector-page .clearingtable strong,
  #truck-inspector-page td.trackdate p {
    font-size: 20px;
  }
}

/* @media only screen and (min-width: 1770px) {
} */

@media only screen and (max-width: 1569px) {
  .simpleBtn {
    padding: 10px 20px;
    font-size: 16px;
  }

  .document {
    margin-bottom: 5px;
    font-size: 14px;
  }

  .complian-block .profilecont .select-controls,
  .complian-block .fileUploads,
  .v2-text {
    font-size: 14px;
  }

  .mx-15 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .star-container svg {
    width: 20px !important;
    height: 20px !important;
  }

  #client-page .clearingtable td {
    font-size: 16px;
  }

  #client-page .clearingtable table p {
    font-size: 16px;
  }

  #client-page .tab button {
    font-size: 20px;
    padding: 15px 25px 15px 30px;
  }

  #client-page .operator-info {
    padding: 20px 50px 20px 50px;
  }

  #client-page .operator-info h5 {
    font-size: 24px;
    padding: 0;
  }

  #client-page .operator-info h6 {
    font-size: 18px;
    padding: 0;
  }

  #client-page .disputes-block {
    margin-bottom: 30px;
  }

  /* #client-page a.backbut {
    padding: 5px 10px !important;
    min-width: 150px !important;
    font-size: 16px !important;
  }
  #client-page .submitbut {
    padding: 10px 20px !important;
    min-width: 150px !important;
    font-size: 16px !important;
  } */

  #clearing-agent-page .clearingtable td {
    font-size: 16px;
  }

  #clearing-agent-page .description1 h4 {
    font-size: 16px;
  }

  #clearing-agent-page .clearingtable table p {
    font-size: 16px;
  }

  #clearing-agent-page a.detailsdiv {
    padding: 5px;
    min-width: 130px;
    font-size: 16px;
  }

  #clearing-agent-page a.detailsdiv2 {
    font-size: 16px;
    padding: 10px 15px;
  }

  #clearing-agent-page .price {
    font-size: 18px;
  }

  #clearing-agent-page a.review {
    font-size: 16px;
    padding: 10px 15px;
  }

  #clearing-agent-page .personal-info {
    margin: 0 50px;
  }

  #clearing-agent-page .tab {
    padding: 80px 0;
  }

  #clearing-agent-page .tab button {
    font-size: 20px;
    padding: 15px 25px 15px 30px;
  }

  #clearing-agent-page .operator-info {
    padding: 20px 50px 20px 50px;
  }

  #clearing-agent-page .operator-info h5 {
    font-size: 24px;
    padding: 0;
  }

  #clearing-agent-page .operator-info h6 {
    font-size: 18px;
    padding: 0;
  }

  #clearing-agent-page .disputes-block {
    margin-bottom: 30px;
  }

  /* #clearing-agent-page a.backbut {
    padding: 10px 15px !important;
    min-width: 150px !important;
    font-size: 16px !important;
  }
  #clearing-agent-page .submitbut {
    padding: 10px 15px !important;
    min-width: 150px !important;
    font-size: 16px !important;
  }
  #clearing-agent-page .btn {
    padding: 10px 15px !important;
    font-size: 14px !important;
  } */

  #lawear-page .clearingtable td {
    font-size: 16px;
  }

  #lawear-page .clearingtable table p {
    font-size: 16px;
  }

  #lawear-page .tab {
    padding: 80px 0;
  }

  #lawear-page .tab button {
    font-size: 20px;
    padding: 15px 25px 15px 30px;
  }

  #lawear-page .operator-info {
    padding: 20px 50px 20px 50px;
  }

  #lawear-page .operator-info h5 {
    font-size: 24px;
    padding: 0;
  }

  #lawear-page .operator-info h6 {
    font-size: 18px;
    padding: 0;
  }

  #lawear-page .disputes-block {
    margin-bottom: 30px;
  }

  /* #lawear-page a.submitbut {
    padding: 5px 10px !important;
    min-width: 150px !important;
    font-size: 16px !important;    
  }
  #lawear-page a.backbut {
    padding: 5px 10px !important;
    min-width: 150px !important;
    font-size: 16px !important;
  } */
  /* Supervisor */
  #superviser-page .clearingtable td {
    font-size: 16px;
  }

  #superviser-page .clearingtable p {
    font-size: 16px;
  }

  #truck-inspector-page .clearingtable td {
    font-size: 16px;
  }

  #truck-inspector-page .clearingtable p,
  #truck-inspector-page .clearingtable span,
  #truck-inspector-page .clearingtable strong,
  #truck-inspector-page td.trackdate p {
    font-size: 16px;
  }

  #truck-inspector-page .tab {
    padding: 60px 0;
  }

  #truck-inspector-page .tab button {
    font-size: 20px;
    padding: 15px 25px 15px 30px;
  }

  #truck-inspector-page .trackcount {
    font-size: 16px;
  }

  .alert {
    padding: 0;
  }
}

/* @media only screen and (min-width: 1570px) {

} */

@media only screen and (max-width: 1369px) {
  .simpleBtn {
    padding: 10px 20px;
    font-size: 16px;
  }

  .document {
    margin-bottom: 5px;
    font-size: 12px;
  }

  .date-range-btn {
    font-size: 12px;
  }

  .star-container svg {
    width: 20px !important;
    height: 20px !important;
  }

  #client-page a.review {
    padding: 10px 5px;
    margin: 0;
    display: inline-block;
    min-width: 120px;
    line-height: 10px;
    font-size: 12px;
  }

  #client-page .clearingtable td {
    font-size: 14px;
  }

  #client-page .clearingtable table p {
    font-size: 14px;
  }

  #client-page .clearingtable h4 {
    font-size: 16px;
  }

  #client-page .bodycont h2 {
    font-size: 24px;
  }

  #client-page .operator-info {
    padding: 20px 50px 20px 50px;
  }

  #client-page .operator-info h5 {
    font-size: 20px;
    padding: 0;
  }

  #client-page .operator-info h6 {
    font-size: 16px;
    padding: 0;
  }

  #client-page .disputes-block {
    margin-bottom: 30px;
  }

  #client-page a.backbut {
    padding: 5px 10px !important;
    min-width: 150px !important;
    font-size: 16px !important;
  }

  #clearing-agent-page .tab button {
    font-size: 20px;
    padding: 15px 25px 15px 30px;
  }

  #clearing-agent-page .clearingtable td {
    font-size: 14px;
  }

  #clearing-agent-page .description1 h4 {
    font-size: 16px;
  }

  #clearing-agent-page .clearingtable table p {
    font-size: 14px;
  }

  #clearing-agent-page a.detailsdiv {
    padding: 5px;
    min-width: 130px;
    font-size: 14px;
  }

  #clearing-agent-page a.detailsdiv2 {
    font-size: 14px;
    padding: 5px 15px;
  }

  #clearing-agent-page .price {
    font-size: 16px;
  }

  #clearing-agent-page a.review {
    font-size: 14px;
    padding: 5px 15px;
  }

  #clearing-agent-page .personal-info {
    margin: 0 50px;
  }

  #clearing-agent-page .bodycont h2 {
    font-size: 24px;
  }

  #clearing-agent-page .operator-info {
    padding: 20px 50px 20px 50px;
  }

  #clearing-agent-page .operator-info h5 {
    font-size: 20px;
    padding: 0;
  }

  #clearing-agent-page .operator-info h6 {
    font-size: 16px;
    padding: 0;
  }

  #clearing-agent-page .disputes-block {
    margin-bottom: 30px;
  }

  #clearing-agent-page a.backbut {
    padding: 5px 10px !important;
    min-width: 150px !important;
    font-size: 16px !important;
  }

  /* #clearing-agent-page .submitbut {
    padding: 5px 10px !important;
    min-width: 150px !important;
    font-size: 16px !important;
  } */
  #clearing-agent-page .btn {
    padding: 5px 10px !important;
    font-size: 14px !important;
  }

  .truck-details-content h3 {
    font-size: 18px;
    padding: 0 0 10px 0;
  }

  #clearing-agent-page .modal-dialog {
    margin: 40px auto;
  }

  #clearing-agent-page .bodycont h3 {
    font-size: 18px;
  }

  #clearing-agent-page .tab {
    padding: 40px 0;
  }

  #lawear-page .clearingtable td {
    font-size: 14px;
  }

  #lawear-page .clearingtable table p {
    font-size: 14px;
  }

  #lawear-page h4 {
    font-size: 16px;
  }

  #lawear-page .tab {
    padding: 40px 0;
  }

  #lawear-page .tab button {
    font-size: 16px;
    padding: 15px 25px 15px 30px;
  }

  #lawear-page .operator-info {
    padding: 20px 50px 20px 50px;
  }

  #lawear-page .operator-info h5 {
    font-size: 20px;
    padding: 0;
  }

  #lawear-page .operator-info h6 {
    font-size: 16px;
    padding: 0;
  }

  #lawear-page .disputes-block {
    margin-bottom: 30px;
  }

  #lawear-page a.backbut {
    padding: 5px 10px !important;
    min-width: 150px !important;
    font-size: 16px !important;
  }

  #lawear-page .bodycont h2 {
    font-size: 24px;
  }

  /* Supervisor */
  #superviser-page .clearingtable td {
    font-size: 14px;
  }

  #superviser-page .clearingtable p {
    font-size: 14px;
  }

  #superviser-page .description1 h4 {
    font-size: 16px;
  }

  #superviser-page .bodycont h2 {
    font-size: 24px;
  }

  #truck-inspector-page .clearingtable td {
    font-size: 14px;
    padding: 10px;
  }

  #truck-inspector-page .clearingtable p,
  #truck-inspector-page .clearingtable span,
  #truck-inspector-page .clearingtable strong,
  #truck-inspector-page td.trackdate p {
    font-size: 14px;
  }

  #truck-inspector-page h4 {
    font-size: 16px;
  }

  #truck-inspector-page .tab {
    padding: 60px 0;
  }

  #truck-inspector-page .tab button {
    font-size: 16px;
    padding: 15px 25px 15px 30px;
  }

  #truck-inspector-page .trackcount {
    font-size: 14px;
  }

  #truck-inspector-page .bodycont h2 {
    font-size: 24px;
  }

  .alert {
    padding: 0;
  }
}

@media only screen and (max-width: 1169px) {
  a.detailsdiv2 {
    min-width: 150px;
    display: inline-block;
  }

  a.detailsdiv {
    display: inline-block;
    min-width: 150px;
    text-align: center;
  }
}

@media only screen and (max-width: 1023px) {
  #clearing-agent-page .tab {
    width: 30%;
  }

  #clearing-agent-page .tabcontent {
    width: 70%;
  }

  #client-page .tab {
    width: 30%;
  }

  #client-page .tabcontent {
    width: 70%;
  }

  #lawear-page .tab {
    width: 30%;
  }

  #lawear-page .tabcontent {
    width: 70%;
  }

  #superviser-page .tab {
    width: 30%;
  }

  #superviser-page .tabcontent {
    width: 70%;
  }

  #truck-inspector-page .tab {
    width: 30%;
  }

  #truck-inspector-page .tabcontent {
    width: 70%;
  }
}

@media only screen and (max-width: 980px) {
  #lawear-page a.review {
    min-width: 150px;
    display: inline-block;
  }

  a.detailsdiv2 {
    display: inline-block;
    min-width: 150px;
  }

  #clearing-agent-page a.detailsdiv {
    display: inline-block;
    min-width: 150px;
    text-align: center;
  }
}

/* @media only screen and (max-width: 768px) {

} */

@media only screen and (max-width: 767px) {
  #clearing-agent-page .tab {
    width: 0;
  }

  #clearing-agent-page .tabcontent {
    width: 100%;
  }

  #client-page .tab {
    width: 0;
  }

  #client-page .tabcontent {
    width: 100%;
  }

  #lawear-page .tab {
    width: 0;
  }

  #lawear-page .tabcontent {
    width: 100%;
  }

  #superviser-page .tab {
    width: 0;
  }

  #superviser-page .tabcontent {
    width: 100%;
  }

  #truck-inspector-page .tab {
    width: 0;
  }

  #truck-inspector-page .tabcontent {
    width: 100%;
  }
}

@media only screen and (max-width: 540px) {
  .valuable-clients .container {
    width: 100%;
  }

  .post-button {
    width: 340px;
    margin: 10px auto;
  }

  .services-block1 {
    margin-bottom: 0px;
  }

  .home-action-btn {
    width: 100%;
    margin: 20px 0 30px 0;
  }

  .services p {
    margin-bottom: 5px;
  }

  .services .services-block a {
    font-size: 16px;
  }

  .payment-policy {
    margin-top: 0;
    padding-top: 40px;
  }

  .heading-with-btn {
    flex-direction: column;
  }

  .heading-with-btn div:nth-child(1) h2 {
    margin-top: 0 !important;
    text-align: center !important;
  }

  .heading-with-btn div:nth-child(2) {
    text-align: center !important;
  }

  .heading-with-btn div:nth-child(2) button {
    float: none;
  }

  .heading-with-btn div:nth-child(2) select {
    float: none;
    width: 100%;
  }

  /* clearing agent */
  #clearing-agent-page .tab {
    width: 0;
  }

  #clearing-agent-page .tabcontent {
    width: 100%;
    padding: 0px 10px !important;
  }

  #clearing-agent-page .bodycont {
    padding: 40px 0 40px;
  }

  #clearing-agent-page .clearingtable td {
    min-width: 150px;
    padding: 10px;
    font-size: 16px;
    text-align: center;
  }

  #clearing-agent-page a.detailsdiv {
    padding: 10px 20px;
    display: inline-block;
  }

  #clearing-agent-page .description1 a.detailsdiv {
    margin-top: 5px;
    margin-bottom: 5px;
    display: inline-block;
  }

  #clearing-agent-page .header {
    padding: 0px 0;
  }

  #clearing-agent-page .personal-info {
    padding: 20px;
    margin: 0;
  }

  #clearing-agent-page .progressheading {
    padding: 0px 0 10px 50px;
  }

  #clearing-agent-page .progressheading span {
    top: 4px;
  }

  /* #clearing-agent-page .clearingtable tr td .list-inline {
    width: 225px;
  } */
  #clearing-agent-page .right-slide .modal-dialog {
    width: 100% !important;
  }

  #clearing-agent-page .modal-dialog {
    width: initial !important;
    margin: 30px 10px 80px;
  }

  #clearing-agent-page .clearingtable .driver-journal tbody {
    overflow-y: auto;
    overflow-x: hidden;
  }

  #clearing-agent-page .clearingtable .driver-journal thead {
    table-layout: inherit;
  }

  #clearing-agent-page .clearingtable .driver-journal tr {
    table-layout: inherit;
  }

  #clearing-agent-page .agent-bids-table table {
    width: 100%;
  }

  /* #clearing-agent-page .submitbut {
    min-width: 140px !important;
  } */
  /* client */
  #client-page .tab {
    width: 0;
  }

  #client-page .tabcontent {
    width: 100%;
  }

  #client-page .bodycont h2 {
    text-align: center;
    font-size: 30px;
    color: #007679;
    font-weight: 600;
    padding: 0 0 10px 0;
    margin: 0px !important;
  }

  #client-page .logindiv {
    width: 100%;
    padding: 10px;
  }

  #client-page .clearingtable td {
    min-width: 150px;
    padding: 10px;
    font-size: 16px;
  }

  #client-page .personal-info {
    padding: 20px 10px;
    margin: 0;
  }

  #client-page .progressheading {
    padding: 0px 0 10px 50px;
  }

  #client-page .progressheading span {
    top: 4px;
  }

  #client-page .profilecontright {
    padding: 15px;
  }

  #client-page .login-page {
    padding: 70px 0 20px;
  }

  #client-page .modal-dialog {
    width: initial !important;
    margin: 60px 10px 80px;
  }

  /* #client-page .reson-reject {
    padding: 0;
  } */
  #client-page .create-service-form {
    padding-top: 35px !important;
  }

  #client-page .create-service-form .container {
    padding: 5px;
  }

  #client-page .payment-info {
    border: none;
  }

  #client-page .bodycont h3 {
    font-size: 20px;
  }

  #client-page a.review {
    margin: 0;
  }

  #client-page .operator-info h6 {
    padding: 0;
  }

  #client-page .operator-info h4 {
    padding: 0;
  }

  /* Lawyer page */
  #lawear-page .tab {
    width: 0;
  }

  #lawear-page .tabcontent {
    width: 100%;
  }

  #lawear-page .clearingtable td {
    min-width: 150px;
    padding: 10px;
    font-size: 16px;
    text-align: center;
  }

  #lawear-page .header {
    padding: 0px 0;
  }

  #lawear-page .personal-info {
    padding: 20px;
    margin: 0;
  }

  #lawear-page .modal-dialog {
    width: initial !important;
    margin: 30px 10px 80px;
  }

  #lawear-page .progressheading {
    padding: 0px 0 10px 50px;
  }

  #lawear-page .progressheading span {
    top: 4px;
  }

  /* Supervisor */
  #superviser-page .tab {
    width: 0;
  }

  #superviser-page .tabcontent {
    width: 100%;
  }

  #superviser-page .header {
    padding: 0px 0;
  }

  #superviser-page .personal-info {
    padding: 20px;
    margin: 0;
  }

  #superviser-page .progressheading {
    padding: 0px 0 10px 50px;
  }

  #superviser-page .progressheading span {
    top: 4px;
  }

  #superviser-page .bodycont {
    padding: 40px 0 20px;
  }

  #superviser-page .clearingtable td {
    min-width: 150px;
    padding: 10px;
    font-size: 16px;
    text-align: center;
  }

  #superviser-page .bodycont h3 {
    font-size: 20px;
  }

  /* Truck inspector */
  #truck-inspector-page .tab {
    width: 0;
  }

  #truck-inspector-page .tabcontent {
    width: 100%;
  }

  #truck-inspector-page .header {
    padding: 0px 0;
  }

  #truck-inspector-page .personal-info {
    padding: 20px;
    margin: 0;
  }

  #truck-inspector-page .progressheading {
    padding: 0px 0 10px 50px;
  }

  #truck-inspector-page .progressheading span {
    top: 4px;
  }

  #truck-inspector-page .bodycont {
    padding: 40px 0 20px;
  }

  #truck-inspector-page .clearingtable td {
    min-width: 150px;
    padding: 10px;
    font-size: 16px;
    text-align: center;
  }

  #truck-inspector-page .dashrightcont {
    padding: 30px 0px;
  }

  #truck-inspector-page .modal-dialog {
    width: initial !important;
    margin: 30px 10px 80px;
  }

  .modal-dialog {
    width: initial !important;
    margin: 60px 10px 80px;
  }

  /* For Cormen Mobile Menu  */
  .cormen-btn {
    display: flex;
    flex-direction: column-reverse;
    position: fixed;
    bottom: 10px;
    left: 10px;
    float: left;
    z-index: 10;
  }

  .cormen-btn label {
    display: inline-block;
    width: 60px;
    height: 60px;
    position: relative;
    text-indent: 999px;
    font-size: 0;
    color: transparent;
    overflow: hidden;
    border-radius: 50%;
    background-color: #02a6aa;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  }

  .cormen-btn label::before {
    content: url('../img/list.svg');
    display: inline-block;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0;
    left: 0;
    text-indent: 0;
    overflow: visible;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    line-height: 60px;
    color: #fff;
    font-size: 20px;
  }

  .cormen {
    bottom: 80px !important;
  }

  #contact-us-map {
    width: 100%;
  }

  #landing-page .header .header-text {
    text-align: center;
  }

  #landing-page .simple-block {
    text-align: center;
  }

  #landing-page .services .services-block {
    padding: 10px;
  }

  .faq .accordion-item {
    padding: 0 10px;
  }

  .blog-section3 {
    padding: 0;
    margin: 10px 0;
  }

  .blog-section4 .send-comment-btn {
    text-align: center;
  }

  .mx-15 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  a.detailsdiv2 {
    padding: 10px;
    margin: 0;
  }

  .complian-block {
    padding: 0;
    border: none;
  }

  .complian-block .del-button {
    margin-left: 0 !important;
  }

  .bodycont h2 {
    font-size: 26px !important;
  }

  .operator-job-details {
    margin: 10px 0;
  }

  .head-line-pay {
    font-size: 16px !important;
    margin-top: 0;
    margin-bottom: 10px;
  }

  .head-p-model {
    padding: 10px !important;
  }

  .document-group {
    padding: 10px;
  }

  #myModalLabel2 {
    text-align: center;
    font-size: 20px;
    color: #007679;
    font-weight: 600;
    padding: 0 0 10px 0;
    margin: 0px !important;
  }

  .star-container svg {
    width: 20px !important;
    height: 20px !important;
  }

  .job-info {
    padding: 0;
  }

  .job-info h2 {
    font-size: 26px;
    color: #007679;
    padding: 0 0 10px;
    font-weight: 600;
    text-align: center;
    margin: 0;
  }

  .job-info h5 {
    font-size: 24px;
    color: #000;
    font-weight: 600;
  }

  .job-info h6 {
    font-size: 20px;
    color: #000;
    font-weight: 600;
  }

  .job-info h4 {
    font-size: 18px;
    color: #007679;
    font-weight: 600;
  }

  .modal-body {
    padding: 0 15px 15px;
  }

  .file-group {
    margin: 10px 0;
  }

  .nopadding-sm {
    padding: 0 !important;
  }

  .rej-job-details .buttonsec {
    right: 0;
    margin-bottom: 20px;
  }

  .relative-right-10 {
    right: 0;
    margin-bottom: 20px;
  }

  .operator-info {
    padding: 20px !important;
  }

  .job-status-container {
    overflow-x: scroll;
  }

  .job-status-card .card-body {
    padding: 10px !important;
  }

  .date-range-btn {
    float: left;
  }

  .date-range-picker {
    flex-direction: column;
  }

  .text-right-lg {
    text-align: initial !important;
  }

  .text-center-sm {
    text-align: center !important;
  }

  .document-verifying-msg {
    margin-top: 60px !important;
  }

  .document-verifying-msg h3 {
    font-size: 20px;
    padding: 0 0 20px 0;
  }

  .searchbar {
    width: 100%;
  }

  .about-goagent {
    top: 25px;
  }

  .approved-users-heading {
    flex-direction: column;
  }

  .approved-users-heading select {
    margin-left: 0;
  }

  .approved-users-heading .searchbar {
    margin-left: 0;
    align-self: flex-end;
  }

  main.inner-about-block {
    margin-top: 20px;
  }
}

/* For IPhone SE */
@media only screen and (max-width: 375px) {
  .about-goagent h2 {
    margin-top: 20px !important;
    font-size: 20px !important;
  }

  .about-goagent h3 {
    margin: 10px 0 10px !important;
    font-size: 16px !important;
  }

  .about-goagent .about-goagent-block {
    margin-bottom: 0px !important;
  }

  .about-goagent .about-goagent-block p {
    font-size: 12px !important;
  }
}

/* HOME PAGE HERO RESPONSIVE -- START */
/* For some mobile devices with small height and width */

/* width - 540 */
@media only screen and (max-width: 540px) and (max-height: 899px) {
  .hero h2 {
    font-size: 18px !important;
  }

  #hero .heading-home {
    font-size: 14px !important;
    line-height: 15px !important;
  }
}

@media only screen and (max-width: 540px) and (max-height: 749px) {
  #hero {
    background: none !important;
    /* top: 90%; */
  }

  .hero h2 {
    /* font-size: 24px !important; */
  }

  #hero .heading-home {
    /* font-size: 20px !important; */
  }
}

@media only screen and (max-width: 540px) and (max-height: 699px) {
  #hero {
    background: none !important;
    top: 95%;
  }

  .hero h2 {
    /* font-size: 24px !important; */
  }

  #hero .heading-home {
    /* font-size: 18px !important; */
  }
}

@media only screen and (max-width: 540px) and (max-height: 649px) {
  #hero {
    background: none !important;
    /* top: 95%; */
  }
}

@media only screen and (max-width: 540px) and (max-height: 599px) {
  #hero {
    background: none !important;
    top: 100%;
  }
}

@media only screen and (max-width: 540px) and (max-height: 549px) {
  #hero {
    background: none !important;
    top: 104%;
  }
}

/* Width - 499 */
@media only screen and (max-width: 499px) and (max-height: 849px) {
  #hero {
    top: 80%;
  }
}

@media only screen and (max-width: 499px) and (max-height: 799px) {
  #hero {
    top: 80%;
  }
}

@media only screen and (max-width: 499px) and (max-height: 749px) {
  #hero {
    top: 85%;
  }
}

@media only screen and (max-width: 499px) and (max-height: 699px) {
  #hero {
    top: 90%;
  }
}

@media only screen and (max-width: 499px) and (max-height: 659px) {
  #hero {
    top: 95%;
  }
}

@media only screen and (max-width: 499px) and (max-height: 599px) {
  #hero {
    top: 100%;
  }
}

/* Width - 479 */
@media only screen and (max-width: 479px) and (max-height: 849px) {
  #hero {
    top: 98%;
  }
}

@media only screen and (max-width: 479px) and (max-height: 799px) {
  #hero {
    top: 97%;
  }
}

@media only screen and (max-width: 479px) and (max-height: 749px) {
  #hero {
    top: 100%;
  }
}

@media only screen and (max-width: 479px) and (max-height: 699px) {
  #hero {
    top: 104%;
  }
}

@media only screen and (max-width: 479px) and (max-height: 659px) {
  #hero {
    top: 110%;
  }
}

@media only screen and (max-width: 479px) and (max-height: 599px) {
  #hero {
    top: 114%;
  }
}

/* Width - 449 */
@media only screen and (max-width: 449px) and (max-height: 849px) {
  #hero {
    top: 98%;
  }
}

@media only screen and (max-width: 449px) and (max-height: 799px) {
  #hero {
    top: 97%;
  }
}

@media only screen and (max-width: 449px) and (max-height: 749px) {
  #hero {
    top: 100%;
  }
}

@media only screen and (max-width: 449px) and (max-height: 699px) {
  #hero {
    top: 104%;
  }
}

@media only screen and (max-width: 449px) and (max-height: 659px) {
  #hero {
    top: 110%;
  }
}

@media only screen and (max-width: 449px) and (max-height: 599px) {
  #hero {
    top: 114%;
  }
}

/* Width - 419 - Pixel 2 XL */
@media only screen and (max-width: 419px) and (max-height: 849px) {
  #hero {
    top: 94%;
  }
}

/* Width - 399 */
@media only screen and (max-width: 399px) and (max-height: 849px) {
  #hero {
    top: 90%;
  }
}

@media only screen and (max-width: 399px) and (max-height: 799px) {
  #hero {
    top: 93%;
  }
}

@media only screen and (max-width: 399px) and (max-height: 749px) {
  #hero {
    top: 96%;
  }
}

@media only screen and (max-width: 399px) and (max-height: 699px) {
  #hero {
    top: 103%;
  }
}

@media only screen and (max-width: 399px) and (max-height: 659px) {
  #hero {
    top: 108%;
  }
}

@media only screen and (max-width: 399px) and (max-height: 599px) {
  #hero {
    top: 112%;
  }
}

/* Width - 349 */
@media only screen and (max-width: 349px) and (max-height: 849px) {
  #hero {
    top: 85%;
  }
}

@media only screen and (max-width: 349px) and (max-height: 799px) {
  #hero {
    top: 88%;
  }
}

@media only screen and (max-width: 349px) and (max-height: 749px) {
  #hero {
    top: 94%;
  }
}

@media only screen and (max-width: 349px) and (max-height: 699px) {
  #hero {
    top: 100%;
  }
}

@media only screen and (max-width: 349px) and (max-height: 659px) {
  #hero {
    top: 103%;
  }
}

@media only screen and (max-width: 349px) and (max-height: 649px) {
  #hero {
    top: 108%;
  }
}

@media only screen and (max-width: 349px) and (max-height: 599px) {
  #hero {
    top: 110%;
  }
}

/* Width - 320 - Iphone 5/SE */
@media only screen and (max-width: 320px) and (max-height: 599px) {
  #hero {
    top: 125%;
  }

  .hero h2 {
    /* font-size: 18px !important; */
  }

  #hero .heading-home {
    font-size: 14px !important;
    line-height: 20px;
  }

  #hero .trusted-text {
    font-size: 18px;
  }
}

/* HOME PAGE HERO RESPONSIVE -- END */

/* HOME PAGE HERO RESPONSIVE with Webinar Banner responsive code -- START */

@media only screen and (max-width: 767px) {
  [data-webinar-banner='true'] .header {
    top: 50px;
  }

  [data-webinar-banner='true'] #main {
    position: relative;
    top: 50px;
  }

  [data-webinar-banner='true'] #client-page footer {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 440px) {
  [data-webinar-banner='true'] .header {
    top: 50px;
  }
}

@media only screen and (max-width: 375px) and (max-height: 649px) {
  [data-webinar-banner='true'] #hero {
    top: 107%;
  }
}

@media only screen and (max-width: 360px) and (max-height: 640px) {
  [data-webinar-banner='true'] #hero {
    top: 113%;
  }
}

@media only screen and (max-width: 375px) {
  [data-webinar-banner='true'] .about-goagent {
    top: 80px;
  }
}

/* width - 540 */
@media only screen and (max-width: 540px) and (max-height: 899px) {
  [data-webinar-banner='true'] #hero {
    top: 83%;
  }

  [data-webinar-banner='true'] .about-goagent {
    top: 80px;
  }
}

@media only screen and (max-width: 540px) and (max-height: 749px) {
  [data-webinar-banner='true'] #hero {
    top: 90%;
  }
}

@media only screen and (max-width: 540px) and (max-height: 699px) {
  [data-webinar-banner='true'] #hero {
    top: 93%;
  }
}

@media only screen and (max-width: 540px) and (max-height: 679px) {
  [data-webinar-banner='true'] #hero {
    top: 96%;
  }
}

@media only screen and (max-width: 540px) and (max-height: 649px) {
  [data-webinar-banner='true'] #hero {
    top: 95%;
  }
}

@media only screen and (max-width: 540px) and (max-height: 599px) {
  [data-webinar-banner='true'] #hero {
    top: 103%;
  }
}

@media only screen and (max-width: 540px) and (max-height: 549px) {
  [data-webinar-banner='true'] #hero {
    top: 108%;
  }
}

/* Width - 479 */
@media only screen and (max-width: 479px) and (max-height: 849px) {
  [data-webinar-banner='true'] #hero {
    top: 98%;
  }
}

@media only screen and (max-width: 479px) and (max-height: 799px) {
  [data-webinar-banner='true'] #hero {
    top: 100%;
  }
}

@media only screen and (max-width: 479px) and (max-height: 749px) {
  [data-webinar-banner='true'] #hero {
    top: 108%;
  }
}

@media only screen and (max-width: 479px) and (max-height: 699px) {
  [data-webinar-banner='true'] #hero {
    top: 108%;
  }
}

@media only screen and (max-width: 479px) and (max-height: 659px) {
  [data-webinar-banner='true'] #hero {
    top: 113%;
  }
}

@media only screen and (max-width: 479px) and (max-height: 639px) {
  [data-webinar-banner='true'] #hero {
    top: 118%;
  }
}

@media only screen and (max-width: 479px) and (max-height: 599px) {
  [data-webinar-banner='true'] #hero {
    top: 122%;
  }
}

/* Width - 449 */
@media only screen and (max-width: 449px) and (max-height: 849px) {
  [data-webinar-banner='true'] #hero {
    top: 98%;
  }
}

@media only screen and (max-width: 449px) and (max-height: 799px) {
  [data-webinar-banner='true'] #hero {
    top: 97%;
  }
}

@media only screen and (max-width: 449px) and (max-height: 749px) {
  [data-webinar-banner='true'] #hero {
    top: 103%;
  }
}

@media only screen and (max-width: 449px) and (max-height: 699px) {
  [data-webinar-banner='true'] #hero {
    top: 108%;
  }
}

@media only screen and (max-width: 449px) and (max-height: 659px) {
  [data-webinar-banner='true'] #hero {
    top: 113%;
  }
}

@media only screen and (max-width: 449px) and (max-height: 639px) {
  [data-webinar-banner='true'] #hero {
    top: 114%;
  }
}

@media only screen and (max-width: 449px) and (max-height: 599px) {
  [data-webinar-banner='true'] #hero {
    top: 118%;
  }
}

/* Width - 419 - Pixel 2 XL */
@media only screen and (max-width: 419px) and (max-height: 849px) {
  [data-webinar-banner='true'] #hero {
    top: 126%;
  }
}

/* Width - 419 */
@media only screen and (max-width: 419px) and (max-height: 849px) {
  [data-webinar-banner='true'] #hero {
    top: 96%;
  }
}

@media only screen and (max-width: 419px) and (max-height: 799px) {
  [data-webinar-banner='true'] #hero {
    top: 96%;
  }
}

@media only screen and (max-width: 419px) and (max-height: 749px) {
  [data-webinar-banner='true'] #hero {
    top: 100%;
  }
}

@media only screen and (max-width: 419px) and (max-height: 699px) {
  [data-webinar-banner='true'] #hero {
    top: 105%;
  }
}

@media only screen and (max-width: 419px) and (max-height: 659px) {
  [data-webinar-banner='true'] #hero {
    top: 110%;
  }
}

@media only screen and (max-width: 419px) and (max-height: 639px) {
  [data-webinar-banner='true'] #hero {
    top: 116%;
  }
}

@media only screen and (max-width: 419px) and (max-height: 599px) {
  [data-webinar-banner='true'] #hero {
    top: 118%;
  }
}

/* Width - 399 */
@media only screen and (max-width: 399px) and (max-height: 849px) {
  [data-webinar-banner='true'] #hero {
    top: 97%;
  }
}

@media only screen and (max-width: 399px) and (max-height: 799px) {
  [data-webinar-banner='true'] #hero {
    top: 97%;
  }
}

@media only screen and (max-width: 399px) and (max-height: 749px) {
  [data-webinar-banner='true'] #hero {
    top: 102%;
  }
}

@media only screen and (max-width: 399px) and (max-height: 699px) {
  [data-webinar-banner='true'] #hero {
    top: 107%;
  }
}

@media only screen and (max-width: 399px) and (max-height: 659px) {
  [data-webinar-banner='true'] #hero {
    top: 110%;
  }
}

@media only screen and (max-width: 399px) and (max-height: 639px) {
  [data-webinar-banner='true'] #hero {
    top: 114%;
  }
}

@media only screen and (max-width: 399px) and (max-height: 599px) {
  [data-webinar-banner='true'] #hero {
    top: 122%;
  }
}

/* Width - 349 */
@media only screen and (max-width: 349px) and (max-height: 849px) {
  [data-webinar-banner='true'] #hero {
    top: 92%;
  }
}

@media only screen and (max-width: 349px) and (max-height: 799px) {
  [data-webinar-banner='true'] #hero {
    top: 96%;
  }
}

@media only screen and (max-width: 349px) and (max-height: 749px) {
  [data-webinar-banner='true'] #hero {
    top: 102%;
  }
}

@media only screen and (max-width: 349px) and (max-height: 699px) {
  [data-webinar-banner='true'] #hero {
    top: 107%;
  }
}

@media only screen and (max-width: 349px) and (max-height: 659px) {
  [data-webinar-banner='true'] #hero {
    top: 110%;
  }
}

@media only screen and (max-width: 349px) and (max-height: 649px) {
  [data-webinar-banner='true'] #hero {
    top: 112%;
  }
}

@media only screen and (max-width: 349px) and (max-height: 599px) {
  [data-webinar-banner='true'] #hero {
    top: 125%;
  }
}

/* PIXEL 5 */
@media only screen and (width: 393px) and (height: 851px) {
  [data-webinar-banner='true'] #hero {
    top: 92%;
  }
}

/* GALAXY S8+ */
@media only screen and (width: 360px) and (height: 740px) {
  [data-webinar-banner='true'] #hero {
    top: 100%;
  }
}

/* GALAXY S5 */
@media only screen and (width: 360px) and (height: 640px) {
  [data-webinar-banner='true'] #hero {
    top: 112%;
  }
}

/* GALAXY S5 */
@media only screen and (width: 320px) and (max-height: 568px) {
  [data-webinar-banner='true'] #hero {
    top: 126%;
  }
}

@media only screen and (width: 375px) and (height: 667px) {
  [data-webinar-banner='true'] #hero {
    top: 108%;
  }
}

/* HOME PAGE HERO RESPONSIVE with Webinar Banner responsive code -- END */

.video-banner {
  /* height: 100vh; */
}

video {
  object-fit: cover;
}

@media only screen and (max-height: 849px) {
  .about-goagent h2 {
    font-size: 26px;
  }

  .about-goagent .about-goagent-block {
    margin-bottom: 25px;
  }

  .about-goagent .about-goagent-block p {
    font-size: 20px;
    line-height: 34px;
    margin-bottom: 5px;
  }

  .about-goagent .about-goagent-block h3 {
    font-size: 24px;
    margin: 12px 0;
  }
}

@media only screen and (max-width: 767px) {
  .about-goagent .about-goagent-block h3 {
    margin: 10px 0 5px;
    font-size: 22px;
  }
}

@media only screen and (max-height: 749px) {
  .about-goagent h2 {
    font-size: 26px;
  }

  .about-goagent .about-goagent-block {
    margin-bottom: 25px;
  }

  .about-goagent .about-goagent-block p {
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 5px;
  }

  .about-goagent .about-goagent-block h3 {
    font-size: 20px;
    margin: 12px 0;
  }
}

@media only screen and (max-height: 640px) {
  .about-goagent h2 {
    font-size: 22px;
  }

  .about-goagent .about-goagent-block {
    margin-bottom: 20px;
  }

  .about-goagent .about-goagent-block p {
    font-size: 16px;
    line-height: 32px;
  }

  .about-goagent .about-goagent-block h3 {
    font-size: 16px;
    margin: 10px 0;
  }
}

@media only screen and (max-height: 549px) {
  .about-goagent h2 {
    font-size: 26px;
  }

  .about-goagent .about-goagent-block p {
    font-size: 16px;
    line-height: 28px;
  }

  .about-goagent .about-goagent-block h3 {
    font-size: 22px;
    margin: 10px 0;
  }
}

@media only screen and (max-height: 799px) {
  #home-page {
    height: 114rem;
    padding: 0;
  }

  .video-banner {
    /* height: 110vh; */
  }

  .about-goagent-video {
    position: relative;
  }

  main.inner-about-block {
    margin-top: 60px;
  }
}

@media only screen and (max-height: 736px) {
  .about-goagent h2 {
    font-size: 24px;
  }

  .about-goagent h3 {
    font-size: 18px;
  }

  .about-goagent .about-goagent-block p {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-height: 590px) {
  #home-page {
    height: 105%;
  }

  .video-banner {
    /* height: 125vh; */
  }

  .about-goagent-video {
    position: relative;
  }

  main.inner-about-block {
    margin-top: 100px;
  }
}

@media only screen and (max-height: 320px) {
  .about-goagent h2 {
    font-size: 18px;
  }

  .about-goagent h3 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .webinar-banner a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 540px) {
  .webinar-banner a {
    font-size: 14px;
  }

  .webinar-banner .new-tag {
    margin-right: 0;
  }
}

@media only screen and (max-width: 380px) {
  .webinar-banner a {
    font-size: 14px;
  }

  .webinar-banner .new-tag {
    margin-right: 0;
  }
}
