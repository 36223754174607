.chat-bar-collapsible {
  position: fixed;
  bottom: 0;
  right: 50px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 10;
}
.chat-bar-collapsible-client {
  position: fixed;
  bottom: 0;
  left: 20%;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 10;
}
.collapsible {
  background-color: rgb(82, 151, 255);
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 350px;
  text-align: left;
  outline: none;
  font-size: 18px;
  border-radius: 10px 10px 0px 0px;
  border: 3px solid white;
  border-bottom: none;
}

.collapsible-mobile {
  background-color: rgb(82, 151, 255);
  color: white;
  cursor: pointer;
  text-align: left;
  outline: none;
  font-size: 30px;
  border-radius: 10px 10px 0px 0px;
  border: 3px solid white;
  border-bottom: none;
  position: fixed;
  bottom: 20px;
  right: 10px;
}

.second-chat {
  background-color: #a94442;
  color: white;
  cursor: pointer;
  text-align: left;
  outline: none;
  font-size: 30px;
  border-radius: 10px 10px 0px 0px;
  border: 3px solid white;
  border-bottom: none;
  position: fixed;
  bottom: 20px;
  right: 70px;
}

.second-chat-heading {
  background-color: #a94442;
}

.chat-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #f1f1f1;
}

.full-chat-block {
  width: 350px;
  background: white;
  text-align: center;
  overflow: auto;
  scrollbar-width: none;
  height: max-content;
  transition: max-height 0.2s ease-out;
}

.outer-container {
  min-height: 500px;
  bottom: 0%;
  position: relative;
}

.chat-container {
  max-height: 500px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  scroll-behavior: smooth;
  hyphens: auto;
}

.chat-container::-webkit-scrollbar {
  display: none;
}

.chat-bar-input-block {
  display: flex;
  float: left;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background-color: rgb(235, 235, 235);
  border-radius: 10px 10px 0px 0px;
  padding: 10px 0px 10px 10px;
}

.chat-bar-icons {
  display: flex;
  justify-content: space-evenly;
  box-sizing: border-box;
  width: 25%;
  float: right;
  font-size: 20px;
}

#chat-icon:hover {
  opacity: 0.7;
}

/* Chat bubbles */

#userInput {
  width: 75%;
}

.input-box {
  float: left;
  border: none;
  box-sizing: border-box;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  font-size: 16px;
  color: #000;
  background-color: white;
  outline: none;
}

.userText {
  color: white;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: normal;
  text-align: right;
  clear: both;
}

.userText span {
  line-height: 1.5em;
  display: inline-block;
  background: #5ca6fa;
  padding: 10px;
  border-radius: 8px;
  border-bottom-right-radius: 2px;
  max-width: 80%;
  margin-right: 10px;
  animation: floatup 0.5s forwards;
}

.botText {
  color: #000;
  font-family: Helvetica;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
}

.botText span {
  line-height: 1.5em;
  display: inline-block;
  background: #e0e0e0;
  padding: 10px;
  border-radius: 8px;
  border-bottom-left-radius: 2px;
  max-width: 80%;
  margin-left: 10px;
  animation: floatup 0.5s forwards;
}

@keyframes floatup {
  from {
    transform: translateY(14px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@media only screen and (max-width: 1369px) {
  .collapsible {
    font-size: 16px;
    width: 300px;
    padding: 15px;
  }
}
@media screen and (max-width: 600px) {
  .full-chat-block {
    width: 100%;
    border-radius: 0px;
  }
  .chat-bar-collapsible {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
  }
  .collapsible {
    width: 100%;
    border: 0px;
    border-top: 3px solid white;
    border-radius: 0px;
  }
}

/*--------------------------------------------------------------
# Custom style for Chat pagination & scroll to bottom
--------------------------------------------------------------*/
.scroll-to-bottom {
  position: sticky;
  right: 10px;
  bottom: 10px;
  float: right;
  z-index: 99999;
  background: #01a8af;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
  box-shadow: 0px 0px 10px 10px white;
}

.scroll-to-bottom i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.scroll-to-bottom:hover {
  background: #333;
  color: #fff;
}

@media only screen and (max-width: 540px) {
  .chat-bar-collapsible-client {
    width: 100%;
    left: initial;
  }
}

.chat-bar-collapsible .MuiBadge-root {
  width: 100%;
}

.chat-bar-collapsible-client .MuiBadge-root {
  width: 100%;
}
