/* Generated by Font Squirrel (http://www.fontsquirrel.com) */

/*
 * Montserrat
================================================================================ */

@font-face {
  font-family: 'montserrat-regular';
  src: url('font/montserrat/montserrat-regular-webfont.eot');
  src: url('font/montserrat/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('font/montserrat/montserrat-regular-webfont.woff') format('woff'),
    url('font/montserrat/montserrat-regular-webfont.ttf') format('truetype'),
    url('font/montserrat/montserrat-regular-webfont.svg#montserratregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'montserrat-bold';
  src: url('font/montserrat/montserrat-bold-webfont.eot');
  src: url('font/montserrat/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('font/montserrat/montserrat-bold-webfont.woff') format('woff'),
    url('font/montserrat/montserrat-bold-webfont.ttf') format('truetype'),
    url('font/montserrat/montserrat-bold-webfont.svg#montserratbold') format('svg');
  font-weight: normal;
  font-style: normal;
}

html,
body,
#root,
#home-page {
  height: 100%;
}

#webinar {
  background: #161415 url(../img/bg.jpg) no-repeat center center fixed;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  background-size: cover !important;

  font: 15px/30px 'montserrat-regular', sans-serif;
  font-weight: normal;
  color: #575859;

  min-height: 100%;
  padding-top: 4%;
}

#webinar h3 {
  font-size: 24px;
  line-height: 36px;
}

#webinar h4 {
  font-size: 18px;
  line-height: 32px;
}

@media only screen and (max-width: 1769px) {
  #webinar {
    min-height: 100%;
    padding-top: 4%;
  }
}

@media only screen and (max-width: 1569px) {
  #webinar {
    min-height: 100%;
    padding-top: 3%;
  }
}

@media only screen and (max-width: 1369px) {
  #webinar {
    min-height: 100%;
    padding-top: 3%;
  }
}

@media only screen and (max-width: 1169px) {
  #webinar {
    min-height: 100%;
    padding-top: 3%;
  }
}

@media only screen and (max-width: 900px) {
  #webinar {
    padding-top: 9%;
  }
}

@media only screen and (max-width: 768px) {
  #webinar {
    padding-top: 13%;
  }
}

@media only screen and (max-width: 600px) {
  #webinar {
    min-height: auto;
    padding-top: 60px;
  }
}

@media only screen and (max-width: 400px) {
  #webinar {
    padding-top: 54px;
  }
}

/* main
------------------------------------------ */
#webinar main.row {
  max-width: 700px;
}

#webinar main {
  text-align: center;
}

#webinar main::after {
  content: '';
  display: block;
  height: 150px;
}

#webinar main h1 {
  font: 38px/1.2em 'montserrat-bold', sans-serif;
  color: #000;
  margin-bottom: 12px;
  padding: 0;
}

#webinar main p {
  font: 17px/36px 'montserrat-regular', sans-serif;
  color: #fff;
  margin-bottom: 18px;
  padding: 0;
}

#webinar main hr {
  border: solid #fff;
  border-width: 5px 0 0;
  margin: 19px auto 12px;
  height: 0;
  width: 100px;
}

#webinar main .site-header .logo {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 36px 0;
  padding: 0;
}

#webinar main .site-header .logo a {
  display: block;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
  width: 300px;
  height: 80px;
  background: url('../img/logo/logo@2x.png') no-repeat;
  background-size: contain;
}

@media only screen and (max-width: 900px) {
  #webinar main.row {
    width: 85%;
  }

  #webinar main h1 {
    font: 35px/1.2em 'montserrat-bold', sans-serif;
  }

  #webinar main p {
    font: 16px/30px 'montserrat-regular', sans-serif;
  }

  #webinar main .site-header .logo {
    margin: 0 0 30px 0;
  }

  #webinar main .site-header .logo a {
    width: 200px;
    height: 72px;
  }
}

@media only screen and (max-width: 768px) {
  #webinar main.row {
    width: 92%;
  }

  #webinar main h1 {
    font: 32px/1.2em 'montserrat-bold', sans-serif;
  }

  #webinar main p {
    font: 15px/30px 'montserrat-regular', sans-serif;
  }

  #webinar main .site-header .logo {
    margin: 0 0 24px 0;
  }
}

@media only screen and (max-width: 500px) {
  #webinar main.row {
    width: 96%;
  }

  #webinar main h1 {
    font: 30px/1.2em 'montserrat-bold', sans-serif;
  }

  #webinar main p {
    line-height: 27px;
  }

  #webinar main::after {
    height: 78px;
  }
}

@media only screen and (max-width: 400px) {
  #webinar main.row {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }

  #webinar main h1 {
    font: 28px/1.2em 'montserrat-bold', sans-serif;
  }

  #webinar main p {
    font: 14px/27px 'montserrat-regular', sans-serif;
  }

  #webinar main .site-header .logo a {
    width: 200px;
    height: 66px;
  }
}

/* form */
#webinar input,
#webinar button {
  -webkit-font-smoothing: antialiased;
}

#webinar input[type='text'],
#webinar input[type='password'],
#webinar input[type='email'],
#webinar textarea,
#webinar select {
  display: block;
  padding: 12px 15px;
  margin: 0 0 12px 0;
  border: 0;
  outline: none;
  vertical-align: middle;
  color: #a3a4a6;
  font-family: 'montserrat-regular', sans-serif;
  font-size: 14px;
  line-height: 24px;
  border-radius: 3px;
  max-width: 100%;
  background: transparent;
  border: 3px solid #a9aaab;
}

#webinar textarea {
  min-height: 162px;
}

#webinar input[type='text']:focus,
#webinar input[type='password']:focus,
#webinar input[type='email']:focus,
textarea:focus {
  background: white;
}
/* mailchimp form
------------------------------------------ */
#mc-signup {
  clear: both;
  width: 90%;
  margin: 6px auto 30px;
}

#mc-signup input {
  display: inline-block;
  float: left;
  border: none;
  font-size: 15px;
  font-family: 'montserrat-bold', sans-serif;
}

#mc-signup input[type='email'],
#mc-signup input[type='text'],
#mc-signup select {
  height: 54px;
  background: rgba(255, 255, 255, 0.25);
  color: #000;
  font-weight: bold;
  line-height: 30px;
  border-radius: 3px;
  width: 100%;
  margin: 5px 5px 10px 5px;
  border: none;
}
#mc-signup select {
  width: 100%;
  margin: 10px 5px;
  height: 54px;
  color: #757575;
  font-weight: bold;
  line-height: 30px;
  border: none;
  font-weight: bold;
}

#mc-signup button[type='submit'] {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 30px;
  height: 54px;
  padding: 12px 25px 12px 25px;
  background: white;
  border-radius: 3px;
  margin: 5px;
  width: 100%;
  text-align: center;
  color: #000;
  font-weight: bold;
  border: none;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#mc-signup button[type='submit']:hover {
  background: #fbca08;
}

@media only screen and (max-width: 900px) {
  #mc-signup {
    width: 70%;
  }

  #mc-signup input[type='email'] {
    width: 60%;
  }

  #mc-signup input[type='submit'] {
    width: 40%;
  }
}

@media only screen and (max-width: 768px) {
  #mc-signup {
    width: 80%;
    margin-bottom: 30px;
  }

  #mc-signup input[type='email'] {
    width: 55%;
  }

  #mc-signup input[type='submit'] {
    width: 45%;
  }
}

@media only screen and (max-width: 600px) {
  #mc-signup {
    width: 100%;
    margin-bottom: 24px;
    padding-left: 15px;
    padding-right: 15px;
  }

  #mc-signup input[type='email'],
  #mc-signup input[type='text'],
  #mc-signup select {
    width: 100%;
    float: none;
    margin: 5px !important;
    border-radius: 3px;
    text-align: center;
  }

  #mc-signup input[type='submit'] {
    width: 100%;
    float: none;
    border-radius: 3px;
  }
}

@media only screen and (max-width: 400px) {
  #mc-signup {
    padding-left: 0;
    padding-right: 0;
  }
}

#webinar #main-content {
  margin-bottom: 80px;
}

.twelve,
.row .twelve {
  width: 100%;
}

/* 
/* 07. =footer
/* =================================================================== */
footer.webinar-footer {
  clear: both;
  font: 12px/24px 'montserrat-regular', sans-serif;
  background: #000;
  padding: 18px 30px;
  color: #303030;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999992;
}

footer.webinar-footer a,
footer.webinar-footer a:visited {
  color: #525252;
}

footer.webinar-footer a:hover,
footer.webinar-footer a:focus {
  color: #fff;
}

/* copyright 
  ------------------------------------------ */
.footer-copyright {
  margin: 0;
  padding: 0;
  float: left;
}

.footer-copyright li {
  display: inline-block;
  margin: 0;
  padding: 0;
  line-height: 24px;
}

.footer-copyright li::before {
  content: '|';
  padding-left: 6px;
  padding-right: 10px;
  color: #2c2c2c;
}

.footer-copyright li:first-child:before {
  display: none;
}

/* social links */
.footer-social {
  font-size: 18px;
  margin: 0;
  padding: 0;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.8);
  float: right;
}

.footer-social li {
  display: inline-block;
  margin: 0 10px;
  padding: 0;
}

.footer-social li a {
  color: #02a6aa;
}

.footer-social li a:hover {
  color: white;
}

@media only screen and (max-width: 768px) {
  footer.webinar-footer {
    padding-top: 24px;
    text-align: center;
  }

  .footer-copyright {
    float: none;
  }

  .footer-social {
    float: none;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 600px) {
  footer.webinar-footer {
    position: static;
    padding-bottom: 30px;
  }

  .footer-copyright li {
    display: block;
    margin: 0;
    padding: 0;
    line-height: 24px;
  }

  .footer-copyright li::before {
    content: none;
  }
}

@media only screen and (max-width: 400px) {
  .footer-social {
    font-size: 17px;
  }

  .footer-social li {
    margin: 0 6px;
  }
}

#webinar .mb-5 {
  margin-bottom: 10px !important;
}

@media only screen and (max-width: 600px) {
  #webinar .mb-5 {
    margin-bottom: 5px !important;
  }
}

.registration-date,
.color-white {
  color: white;
}

.webinar-title,
.color-dark {
  color: #000;
}

@media only screen and (max-height: 900px) {
  #webinar main .site-header .logo {
    margin: 0 0 10px 0;
  }

  #webinar main .site-header .logo a {
    width: 250px;
    height: 70px;
  }
}

@media only screen and (max-height: 768px) {
  .webinar-title {
    font-size: 34px;
  }
  #webinar main .site-header .logo {
    margin: 0 0 10px 0;
  }

  #webinar main .site-header .logo a {
    width: 250px;
    height: 70px;
  }
  #webinar main hr {
    margin: 10px auto 12px;
  }
}

@media only screen and (max-height: 620px) {
  #content-wrap {
    padding-top: 1%;
  }
  .webinar-title {
    font-size: 28px;
  }
  #webinar main .site-header .logo {
    margin: 0 0 10px 0;
  }

  #webinar main .site-header .logo a {
    width: 200px;
    height: 50px;
  }
  #webinar main hr {
    margin: 10px auto 12px;
  }

  #webinar h3 {
    font-size: 20px;
  }
  #webinar main p {
    font: 15px/30px 'montserrat-regular', sans-serif;
  }
}

.content-center {
  margin: 0 auto !important;
}

#mc-signup.input-field {
  color: #000 !important;
}

.fw-bold {
  font-weight: bold;
}
