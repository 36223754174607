.modal-open .modal.show {
  opacity: 1;
}
.modal-open .modal-backdrop.show {
  opacity: 0.5;
}
.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-width {
  width: 600px !important;
}

a,
button,
.c-pointer,
.auth-link {
  cursor: pointer;
}

input[type='date']:before {
  content: attr(placeholder) !important;
  color: #aaa;
  margin-right: 0.5em;
}
input[type='date']:focus:before,
input[type='date']:valid:before {
  content: '';
}

#custom-button {
  padding: 10px;
  color: white;
  background-color: #01a8af;
  border: 1px solid #01a8af;
  border-radius: 5px;
  cursor: pointer;
}

#custom-button:hover {
  background-color: #01a8af;
}

#custom-text {
  margin-left: 10px;
  font-family: sans-serif;
  color: #aaa;
}
/* overrite button color*/
.editButton {
  color: white !important;
}
.editButton:focus {
  outline: 0 !important;
}
.deleteButton {
  background-color: rgb(223, 25, 25) !important;
  color: white !important;
  border-color: rgb(223, 25, 25) !important;
}
.deleteButton:focus {
  outline: 0 !important;
}

.c-pointer {
  cursor: pointer;
}

.head-p-model {
  padding: 20px !important;
  margin-top: 0 !important;
  /* width: 400px !important; */
  margin: auto !important;
  text-align: left !important;
  border: 1px solid #eee !important;
}

.head-line {
  font-size: 16px !important;
  text-align: left !important;
  margin-top: 0;
  color: #007679;
  font-weight: bold;
}
.head-line-pay {
  font-size: 22px !important;
  text-align: center !important;
  margin-top: 0;
  color: #007679;
  font-weight: bold;
}
.head-line-payment {
  font-size: 20px !important;
  text-align: center !important;
  margin-top: 0;
  font-weight: bold;
}
.edit-model-button {
  min-width: 100px !important;
  padding: 5px 15px !important;
  float: left !important;
}

.next-model-button {
  min-width: 100px !important;
  padding: 5px 15px !important;
  float: right !important;
}

/* TRUCK DETAILS PAGE */
.truck-details .bodycont .truck-details-content {
  margin: 0 10px;
}

.truck-details-content h3 {
  padding: 0 0 20px 0 !important;
}

/* Show documents */
.client-docs .txt-detail {
  text-align: left;
  padding: 10px 20px;
  background-color: #eee;
  width: 100% !important;
  border-radius: 8px;
  font-size: 17px;
  color: #333;
}

.client-docs .form-group,
.client-docs strong {
  font-weight: bold;
  font-family: Helvetica, sans-serif;
  font-size: 17px;
  color: #333;
}

.job-status-card li .active1 {
  color: #01a8ac !important;
}
.job-status-card .card-body {
  padding: 10px 30px !important;
}
/* Tracker ID */

.tracker-id .MuiTextField-root {
  padding-top: 10px;
}

.tracker-id .MuiAutocomplete-input,
.tracker-id .MuiAutocomplete-option {
  font-size: 14px;
}

.truck-details-content h3 {
  text-align: left !important;
}

/* MUI Badge style */
#client-page .MuiBadge-badge,
#clearing-agent-page .MuiBadge-badge,
#lawear-page .MuiBadge-badge,
#superviser-page .MuiBadge-badge,
#truck-inspector-page .MuiBadge-badge {
  font-size: 14px;
}

.color-secondary {
  background-color: var(--secondary-color) !important;
  /* color: #555 !important; */
}

a.detailsdiv2 {
  padding: 10px 31px;
  background: #007679;
  font-size: 15px;
  font-weight: 500;
  border-radius: 18px;
  color: #fff;
}
.detailsdiv2:hover {
  background: #01a8af;
  color: #fff;
  text-decoration: none;
}

#client-page .delete-btn {
  padding: 15px 45px;
  min-width: 250px;
  border-radius: 35px;
  border: none;
  background: red !important;
  color: #fff;
  font-size: 16px;
  margin: 0 8px 8px 0;
  transition: all 0.2s ease-in-out 0s;
}

#client-page .delete-btn i {
  color: white !important;
}

.svg-img7 {
  position: relative;
  top: -18px;
  left: -22px;
}

.svg-img7-text-span {
  position: relative;
  top: 90px;
  left: -10px;
}

.simpleBtn {
  padding: 15px 30px;
  border-radius: 35px;
  border: none;
  background: #007679;
  color: #fff;
  font-size: 16px;
  transition: all 0.2s ease-in-out 0s;
}

/* Documents All */
.document {
  text-align: left;
  margin-bottom: 15px;
  font-size: 16px;
  min-height: 40px;
}

.document span {
  color: #919191;
}

.document p {
  margin: 0 0 5px;
}

.document-row {
  font-size: 16px;
  background: #eee;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 15px;
}
.document-row .col-6 {
  padding: 0 10px;
}

.document-row .document {
  margin: 0;
}
/* Driver Journal table */
#clearing-agent-page .clearingtable .driver-journal {
  margin-bottom: 20px;
}
#clearing-agent-page .clearingtable .driver-journal tbody {
  display: block;
  height: 400px;
  overflow: auto;
}
#clearing-agent-page .clearingtable .driver-journal thead {
  display: table;
  table-layout: fixed;
  width: calc(100% - 1em);
}
#clearing-agent-page .clearingtable .driver-journal tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
#clearing-agent-page .clearingtable .driver-journal td {
  font-size: 16px;
  padding: 5px;
}

/* File Upload */

.form-new .fileUploads,
.add-truck-form .fileUploads {
  background: #f3f2f2;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color: #333;
  font-size: 16px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px;
  position: relative;
  text-align: left;
  width: 100%;
  cursor: pointer;
  margin-bottom: 5px;
}
.fileUploads:hover,
.fileUploads:active,
.fileUploads:focus {
  background: #007679;
  cursor: pointer;
  color: #fff;
}
/* .fileUploads input.uploads {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 100%;
  height: 46px;
  cursor: pointer;
} */

/* input[type='file'] {
  position: fixed;
  right: 100%;
  bottom: 100%;
} */
.custom-file-uploads {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.document-group {
  padding: 5px 20px;
  border: 1px solid #b5b5b5;
  border-radius: 5px;
}

.document-group-heading {
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
}

.mx-15 {
  margin-right: 15rem !important;
  margin-left: 15rem !important;
}

.form-document {
  text-align: left;
  margin-bottom: 5px;
  font-size: 14px;
}

.v2-text {
  font-size: 16px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #333;
}

.form-new .text-left {
  text-align: left;
  padding: 10px 20px;
  background-color: #eee;
  width: 100% !important;
  border-radius: 8px;
}

.add-truck-form .text-left {
  text-align: left;
  padding: 5px 10px;
  border-radius: 8px;
  background-color: inherit;
}
#clearing-agent-page .add-truck-form .form-control {
  padding: 10px 15px !important;
  height: 46px !important;
}

.file-group {
  margin: 10px;
}

.date-range-btn {
  padding: 5px 10px;
  border-radius: 5px;
  background: #007679;
  color: #fff;
  font-size: 16px;
  transition: all 0.2s ease-in-out 0s;
  cursor: pointer;
  float: right;
}

.registration {
  width: 100%;
}
.registration .next-btn {
  min-width: 100px !important;
  padding: 5px 15px !important;
  float: right !important;
}

.registration .back-btn {
  min-width: 100px !important;
  padding: 5px 15px !important;
  float: left !important;
}

/* new responsive styles from 02-02-2022 */

/* For Headings with button or other element */
.heading-with-btn {
  display: flex;
}
.heading-with-btn div:nth-child(1) {
  flex: 4;
}
.heading-with-btn div:nth-child(1) h2 {
  margin: 0 !important;
  text-align: right !important;
}
.heading-with-btn div:nth-child(2) {
  flex: 4;
}
.heading-with-btn div:nth-child(2) button {
  float: right;
}
.heading-with-btn div:nth-child(2) select {
  float: right;
  width: 300px;
}

.cormen-btn {
  display: none;
}

.blog-section4 {
  margin-bottom: 20px;
}
.blog-section4 .send-comment-btn {
  text-align: left;
}

.alert {
  margin-top: 10px;
  margin-bottom: 10px !important;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 5px 10px !important;
}

.wrap-div {
  white-space: normal;
}

#superviser-page {
  height: 100vh;
}

#superviser-page .main-block {
  height: 100%;
}

.clear-all-btn {
  float: right;
  color: #337ab7;
}

.reviews-list {
  padding: 0;
  list-style: none;
  text-align: left;
  border: 1px solid #eee;
}
.reviews-list li {
  padding: 10px;
}
.reviews-list li:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.reviews-list li p {
  margin: 10px 0;
}

/* auth-rep-form */
#authRepModal {
  background: #0006;
}

.suspension-modal h3 {
  margin-top: 0;
}

.job-info {
  padding: 0 40px;
}

.job-info h2 {
  font-size: 30px;
  color: #007679;
  padding: 0 0 10px;
  font-weight: 600;
  text-align: center;
  margin: 0;
}

.job-info h5 {
  font-size: 30px;
  color: #000;
  font-weight: 600;
}

.job-info h6 {
  font-size: 22px;
  color: #000;
  font-weight: 600;
}

.job-info h4 {
  font-size: 18px;
  color: #007679;
  font-weight: 600;
}

.profile-camera {
  cursor: pointer;
}

#client-page .create-service-form .container-label {
  font-size: 18px;
  color: #007679;
}
#client-page .create-service-form .vehicle-label {
  font-size: 16px;
  color: #007679;
}

.file-selected {
  background-color: #01a8af;
}

.job_amount_fee {
  margin-bottom: 20px;
}

.btn-light {
  background: #01a8af !important;
}

.relative-right-10 {
  position: relative;
  right: 10%;
}

.job-status-container {
  border: 1px solid #cfcfcf;
  padding: 10px;
}

.text-right-lg {
  text-align: right !important;
}

.client-approval-docs {
  text-align: left;
  padding: 10px 20px;
  border: 1px solid #cfcfcf;
  border-radius: 8px;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.bid-list .pagination {
  font-size: 12px;
}

.badge-button {
  margin-top: 10px !important;
}

.font-bold {
  font-weight: bold !important;
}

.feature-na-modal .message {
  text-align: center;
  padding: 0 !important;
}

.document-verifying-msg h3 {
  text-align: center;
  font-size: 24px;
  color: #007679;
  font-weight: 600;
  padding: 0 0 10px 0;
  margin: 0px !important;
}

/* Password Component related */
.password-field {
  font-size: 14px !important;
}

.MuiInput-underline:before,
.MuiInput-underline:after {
  border-bottom: none !important;
}

.flat-btn {
  padding: 5px 10px;
  border-radius: 5px;
  background: #007679;
  color: #fff;
  font-size: 16px;
  transition: all 0.2s ease-in-out 0s;
  cursor: pointer;
}

.searchbar {
  width: 300px;
  float: right;
}

.display-block {
  display: block !important;
}

.link {
  color: #337ab7 !important;
}

.dropdown-menu > li > span {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

/* Share Button */
.share-btn {
  cursor: pointer;
}

.red-bg {
  background: red !important;
}

.dispute-doc {
  border: 1px solid #337ab7;
  border-radius: 5px;
  padding: 5px;
}

.white-btn {
  color: #000;
  background-color: #fff;
}

.receipt-summary {
  background-color: #e5f5f6;
  border-radius: 5px;
  padding: 30px 0px;
  text-align: center;
}

.receipt-container {
  display: flex;
  justify-content: center;
}

.receipt {
  width: 600px;
  padding: 10px;
  color: #333;
  border: 1px solid #eee;
}

.receipt .header-text {
  text-align: center;
  font-size: 24px;
  color: #333;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Feedback Widget */
.feedback-widget {
  position: fixed;
  padding: 7px 10px;
  right: -15px;
  bottom: 150px;
  transform: rotate(-90deg);
  font-size: 18px;
  z-index: 99999;
  background: #01a8af;
  border-radius: 4px;
  transition: all 0.4s;
  color: white;
  cursor: pointer;
}

.feedback-submitbut {
  padding: 15px 45px;
  min-width: 250px;
  border-radius: 35px;
  border: none;
  background: #007679;
  color: #fff;
  font-size: 16px;
  transition: all 0.2s ease-in-out 0s;
}

.feedback-modal .modal-header {
  padding: 15px;
  border-bottom: none !important;
}
.feedback-modal .modal-header .close {
  margin-top: -40px !important;
  margin-right: -105% !important;
  width: 30px !important;
  height: 30px !important;
  background: #fff !important;
  border-radius: 50% !important;
  opacity: 1 !important;
}

.feedback-modal #feedbackform .form-control {
  padding: 10px !important;
  border-radius: 5px !important;
  border: 1px solid #ccc !important;
  box-shadow: none !important;
  margin-bottom: 10px !important;
  height: unset !important;
}

.feedback-modal label {
  font-weight: unset;
}

/*--------------------------------------------------------------
# Voip C2C button
--------------------------------------------------------------*/
.c2c-widget {
  display: none;
  position: fixed;
  right: 15px;
  bottom: 70px;
  z-index: 99999;
  background: #01a8af;
  border-radius: 4px;
  transition: all 0.4s;
  width: 150px;
  height: 70px;
}

.c2c-widget-icon {
  position: fixed;
  right: 15px;
  bottom: 70px;
  z-index: 99999;
  background: #01a8af;
  border-radius: 4px;
  transition: all 0.4s;
  padding: 1px 10px;
  font-size: 26px;
  color: white;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.c2c-widget.active {
  display: block;
}

.c2c-widget iframe {
  width: inherit;
}

.c2c-widget .close {
  width: 25px !important;
  height: 25px !important;
  background: #fff !important;
  border-radius: 50% !important;
  opacity: 1 !important;
  position: absolute;
  right: -15px;
  top: -15px;
  border: 1px solid;
}

/* Pagination component related - start */
.pagination-row {
  display: flex;
  margin: 0 40px;
  justify-content: end;
}

.pagination-row .page-count-select {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
}

.pagination-row .page-count-select {
  margin: 20px 10px;
}

/* Pagination component related - end */

.approved-users-heading {
  justify-content: center;
  display: flex;
  align-items: baseline;
}

.approved-users-heading h1 {
  display: inline-block;
}

.approved-users-heading select {
  font-size: 36px;
  font-weight: 500;
  line-height: 30px;
  margin-left: 10px;
}

.approved-users-heading select option {
  font-size: 16px;
}

#lawear-page .approved-users-heading .searchbar {
  padding: 0 10px !important;
  margin-bottom: 0 !important;
  margin-left: 15px;
  height: 45px !important;
  align-self: flex-end;
}

/* Weinar Widget */
.webinar-banner {
  position: fixed;
  top: 0;
  width: 100%;
  font-size: 18px;
  z-index: 99999;
  background: #01a8af;
  transition: all 0.4s;
  color: white;
  background: white;
  text-align: center;
  padding: 5px 0;
  border-bottom: 1px solid black;
}

.webinar-banner a {
  font-size: 18px;
  color: #01a8af;
}

.webinar-banner a:hover {
  color: #007679;
}

.webinar-banner .close-btn {
  margin-right: 20px;
  color: #000;
  float: right;
  font-weight: bold;
}

.webinar-banner .new-tag {
  background-color: yellow;
  color: red;
  padding: 0 3px;
  border-radius: 3px;
  margin-right: 15px;
}
/* Webinar Banner related code, responsive code is placed in resposive.css file -- START */
[data-webinar-banner='true'] .header {
  top: 30px;
}

[data-webinar-banner='true'] #main,
[data-webinar-banner='true'] #landing-page {
  position: relative;
  top: 30px;
}

[data-webinar-banner='true'] .about-goagent {
  top: 110px;
}

[data-webinar-banner='true'] .about-goagent .about-goagent-block {
  margin-bottom: 20px;
}

[data-webinar-banner='true'] .header .navbar-mobile {
  top: 30px;
}

[data-webinar-banner='true'] .feedback-modal {
  margin-top: 80px;
}
/* Webinar Banner related code, responsive code is placed in resposive.css file -- END*/

.landing-solution-bg {
  background-color: #02a6aa;
}

#webinar-page .MuiAlert-filledSuccess,
#webinar-page .MuiAlert-filledError {
  top: -80px;
  position: relative;
}

.document-file-selected {
  background-color: #01a8af !important;
}

.portsofoperation-container {
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}

.swal-text {
  font-size: 18px;
  color: #565656;
}

.bid-undertaking-popup .swal-text {
  text-align: left;
}

/* Added this to fix bug: modal content looking blurry */
.swal-modal {
  will-change: unset !important;
}

.action-btn {
  background-color: #f3f2f2 !important;
  font-weight: bold;
  color: #666;
  border-radius: 5px;
  border: 1px solid #ebe7e7;
  padding: 8px 14px;
}

button.btn-red {
  background-color: #a94442 !important;
  color: white;
}

button.btn-green {
  background-color: green !important;
  color: white;
}

a.disabled-btn {
  background-color: #ccc !important;
  cursor: not-allowed;
}

#lawear-page .form-control.MuiInputBase-root {
  font-size: 14px;
}

.color-red {
  color: red !important;
}

.color-green {
  color: green !important;
}
