#service-form input[type='date']:before {
  display: none;
}

#service-form input[type='text'] {
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  padding: 6px 12px !important;
}

#service-form {
  text-align: left;
}
.mt {
  margin-top: 1rem !important;
}

@media (max-width: 768px) {
  .hero form {
    text-align: left;
  }
}

#service-form input[type='text'],
#service-form input[type='email'],
#service-form input[type='number'],
#service-form input[type='date'],
#service-form select {
  font-size: 16px !important;
  height: 50px !important;
}

#service-form select {
  appearance: auto !important;
}

.service-tab .nav-link.active {
  background-color: #007679 !important;
  border-radius: 0;
}
.nav-link:hover {
  color: #111 !important;
}

.service-tab .nav-link {
  font-size: 16px;
  padding-left: 3rem;
  padding-right: 3rem;
  font-size: 18px;
}

.service-tab .post-button {
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 10px;
  margin-top: 24px;
  border-width: 0;
}
.service-tab {
  margin-top: 8rem !important;
}

@media (max-width: 1200px) {
  .service-tab {
    margin-top: 0rem !important;
  }
}
@media (max-height: 768px) {
  #home-page {
    height: fit-content !important;
  }

  #root {
    height: fit-content !important;
  }
}

#home-page {
  height: fit-content !important;
}

@media (max-width: 768px) {
  .testimonial {
    margin-top: 10px !important;
  }
}

@media only screen and (max-height: 590px) {
  .video-banner {
    height: 110vh !important;
  }
}
@media only screen and (max-width: 450px) {
  .service-tab .nav-link {
    font-size: 12px;
  }
  .catagory-form {
    width: 100%;
  }
}

.videot1 {
  height: 110vh !important;
}

.service-tab .post-button:disabled {
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 10px;
  margin-top: 24px;
  border-width: 0;
  background-color: #02a6aa;
  color: #ccc;
}

.btn-modal-submit {
  color: white;
  border: 0 !important;
}

.btn-modal-submit:disabled {
  color: white;
  border: 0 !important;
  background-color: #ccc !important;
}

.modal-otp-field {
  margin-right: 10px !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: 5px !important;
  border: 1px solid #ccc !important;
  font-size: 2rem !important;
}
