/**
* Template Name: FlexStart - v1.0.0
* Template URL: https://bootstrapmade.com/flexstart-bootstrap-startup-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

:root {
  scroll-behavior: smooth;
  --secondary-color: #29bec1;
}

body {
  font-family: 'Poppins', sans-serif;
  color: #444444;
}

a {
  color: #4154f1;
  text-decoration: none;
}

a:hover {
  color: #717ff5;
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

@font-face {
  font-family: 'vladimir_scriptregular';
  src: url('font/vladimir-webfont.woff2') format('woff2'),
    url('font/vladimir-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/*--------------------------------------------------------------
# Sections
--------------------------------------------------------------*/
section {
  padding: 6px 0;
  overflow: hidden;
}

.section-header {
  text-align: center;
  padding-bottom: 40px;
}

.section-header h2 {
  font-size: 36px;
  font-weight: 400;
  margin: 0;
  color: #000;
  text-transform: uppercase;
}

.section-header p {
  margin: 10px 0 20px 0;
  padding: 0;
  font-size: 20px;
  line-height: 22px;
  font-weight: 400;
  color: #000;
}

.section-header a {
  margin: 10px 0 0 0;
  padding: 0;
  font-size: 20px;
  line-height: 22px;
  font-weight: 400;
  color: #b08f32;
}

@media (max-width: 768px) {
  .section-header p {
    font-size: 28px;
    line-height: 32px;
  }
}

.video-banner {
  overflow: hidden;
  background-color: #000;
  opacity: 0.7;
}

.video-banner1 {
  background-color: #000;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: #012970;
  min-height: 40px;
  margin-top: 82px;
  color: #fff;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 57px;
  }
}

.breadcrumbs h2 {
  font-size: 28px;
  font-weight: 500;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol a {
  color: #fff;
  transition: 0.3s;
}

.breadcrumbs ol a:hover {
  text-decoration: underline;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #8894f6;
  content: '/';
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background: #01a8af;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #333;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
  transition: all 0.5s;
  z-index: 997;
  padding: 20px 0;
}

.header.header-scrolled {
  background: #007679;
  padding: 0;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.header .logo {
  line-height: 0;
  margin-left: 40px;
}

.header.header-scrolled .logo {
  margin-left: 0;
}

.header .logo img {
  margin-right: 6px;
}

.header.header-scrolled .logo img {
  height: 30px;
}

.header .logo span {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #012970;
  font-family: 'Poppins', sans-serif;
  margin-top: 3px;
}

.header.header-scrolled .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.header.header-scrolled .navbar a:hover,
.header.header-scrolled .navbar a,
.header.header-scrolled .navbar .active,
.header.header-scrolled .navbar li:hover > a {
  padding: 5px 20px !important;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.videof,
.videot {
  width: 100% !important;
  height: 100% !important;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.header.header-scrolled .navbar ul {
  padding-left: 20px !important;
}

.navbar li {
  position: relative;
  padding: 10px 0 10px 10px;
}

.navbar a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  white-space: nowrap;
  transition: 0.3s;
  border-radius: 20px;
  list-style-type: none;
}

.navbar a i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar li:hover > a {
  color: #fff;
  background-color: #007679;
  padding: 10px 20px;
  border-radius: 20px;
  text-decoration: none;
}

.header.header-scrolled .navbar a:hover,
.header.header-scrolled .navbar .active,
.header.header-scrolled .navbar li:hover > a {
  background-color: #02a6aa;
  text-decoration: none;
}

.navbar a.login:hover {
  color: #fff;
  border-bottom: none;
  text-decoration: none;
}

.navbar .getstarted {
  background: #dbab47;
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 4px;
  color: #fff;
}

.navbar .getstarted:hover {
  color: #fff;
  background: #dbab47;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 15px;
  text-transform: none;
  font-weight: 400;
  color: #333333;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #fff;
  background-color: #007679;
  padding: 10px 20px;
  border-radius: 20px;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

.navbar .login {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  background-color: #007679;
  padding: 10px 20px;
  border-radius: 30px;
  margin-left: 10px;
}

.navbar {
  margin-bottom: 0 !important;
}

.header.header-scrolled .navbar .login {
  background-color: #02a6aa;
}

.inner-menu .navbar .login {
  background-color: #02a6aa;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #ffffff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }

  .header {
    background-color: #007679;
  }
}

@media (max-width: 767px) {
  .header .logo,
  .header.header-scrolled .logo {
    line-height: 0;
    margin-left: 0;
    position: absolute;
    width: 100%;
  }

  .header .navbar,
  .header.header-scrolled .navbar {
  }
}

.header .navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #02a6aa;
  transition: 0.3s;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  /* bottom: 15px; */
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a {
  padding: 10px 20px;
  font-size: 15px;
  color: #333;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #fff;
}

.navbar-mobile .getstarted {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #c7a12e;
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Supporting Sisters Section
--------------------------------------------------------------*/
.supporting-sisters {
  background-color: #dbaa20;
  line-height: 22px;
  font-size: 18px;
  color: #f5e6bc;
  padding: 50px 0;
}

.supporting-sisters h3 {
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700px;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  position: absolute;
  top: 20%;
  margin: auto;
  width: 100%;
}

.hero h1 {
  margin: 0 0 40px;
  font-size: 50px;
  font-weight: 600;
  color: #fff;
}

.hero p {
  font-size: 30px;
  line-height: 40px;
  color: #fff;
  width: 80%;
}

.hero h2 {
  color: #444444;
  margin: 15px 0 20px 0;
  font-size: 26px;
}

.hero .btn-get-started {
  line-height: 0;
  padding: 15px 40px;
  border-radius: 10px;
  transition: 0.5s;
  color: #fff;
  background: #01a8af;
  box-shadow: 0px 5px 30px rgba(65, 84, 241, 0.4);
}

.hero .btn-get-started span {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
}

.hero .btn-get-started i {
  margin-left: 5px;
  font-size: 18px;
  transition: 0.3s;
}

.hero .btn-get-started:hover i {
  transform: translateX(5px);
}

.hero .hero-img {
  text-align: right;
}

.hero input[type='text'] {
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 10px;
  font-size: 16px;
  /* background: url(../img/location.jpg) 95% 7px no-repeat white; */
  padding: 10px 20px;
  border: none;
}

.dropdown-input {
  margin: 0px auto;
  position: relative;
  width: calc(100%);
}

.dropdown-input label {
  display: block;
  padding: 10px 20px;
  background: #fff;
  color: #333;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border-radius: 10px;
  background: url(../img/arrow.png) 95% 14px no-repeat white;
}

.dropdown-input ul {
  list-style: none;
  position: absolute;
  width: 100%;
  padding: 0;
}

.dropdown-input ul li {
  margin: 0 10px;
  background: #ddd;
  color: #343838;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.8);
  height: 0px;
  overflow: hidden;
  cursor: pointer;
  transition: height 0.1s linear 0s;
}

.dropdown-input ul li:hover,
.dropdown-input ul li.selected {
  background: #01a8af;
  color: #fafafa;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.dropdown-input:hover ul li {
  padding: 0 20px;
  height: 34px;
  line-height: 34px;
}

@media (min-width: 1024px) {
}

@media (max-width: 1140px) {
  .navbar a {
    padding: 10px 0 10px 20px;
  }
}

@media (max-width: 1140px) and (min-width: 991px) {
  .navbar a {
    padding: 10px 5px;
  }
}

@media (max-width: 991px) {
  .hero {
    height: auto;
    /* padding: 120px 0 0 0; */
    /* margin-top: -400px; */
  }

  .hero .hero-img {
    text-align: center;
    margin-top: 80px;
  }

  .hero .hero-img img {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .hero {
    text-align: center;
  }

  .hero h1 {
    font-size: 32px;
  }

  .hero h2 {
    font-size: 24px;
  }

  .hero .hero-img img {
    width: 100%;
  }

  .dropdown-input,
  .hero input[type='text'] {
    margin-bottom: 10px;
  }

  .supporting-sisters-block {
    padding-top: 25px;
    padding-bottom: 10px;
    border-bottom: 1px solid #debe5b;
  }

  .supporting-sisters-block-last {
    padding-top: 25px;
  }
}

/*--------------------------------------------------------------
# Index Page
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Home Scroll Bar
--------------------------------------------------------------*/
.container-example1 {
  height: 300px;
  width: 100%;
  max-width: 100%;
  padding: 5px 10px;
  margin: auto;
}

.sb-container {
  position: relative;
  overflow: hidden;
  padding-right: 10px;
}

.sb-content {
  height: 100%;
  width: 120%;
  padding-right: 20%;
  overflow-y: scroll;
  box-sizing: border-box;
  border-right: 1px solid #fff;
}

.sb-scrollbar-container {
  position: absolute;
  right: 5px;
  bottom: 5px;
  top: 5px;
  width: 10px;
  /* background: url('../img/scroll-bg.jpg') center top repeat-y; */
}

.sb-container-noscroll .sb-scrollbar-container {
  right: -20px;
}

.sb-scrollbar {
  position: absolute;
  right: 0;
  height: 30px;
  width: 10px;
  border-radius: 10px;
  background: #fff;
}

.sb-scrollbar:hover {
  background: #fff;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  background: #000000;
  padding: 0 0 40px 0;
  font-size: 14px;
}

.footer .footer-top {
  background-size: contain;
  padding: 60px 0 30px 0;
  background-color: #0b0b0b;
  border-bottom: 1px solid #262626;
  margin-bottom: 40px;
}

.footer .padding-left {
  padding-left: 0;
}

.footer .padding-right {
  padding-right: 0;
}

@media (max-width: 992px) {
  .footer .footer-top {
    background-position: center bottom;
  }
}

.footer .footer-top .social-links a {
  font-size: 20px;
  display: inline-block;
  color: #9a9a99;
  line-height: 0;
  margin-right: 10px;
  transition: 0.3s;
}

.footer .footer-top .social-links a:hover {
  color: #01a8af;
}

.footer .footer-top .footer-links {
  margin-bottom: 30px;
}

.copyright {
  text-align: center;
}

.copyright a {
  margin: 0 5px;
}

.footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0 60px 0 0;
  margin: 0;
  float: left;
}

.footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #d0d4fc;
  font-size: 12px;
  line-height: 0;
}

.footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-top .footer-links ul a {
  color: #9a9a99;
  transition: 0.3s;
  display: inline-block;
  line-height: 1.5;
}

blockquote {
  padding: 10px 5px !important;
}

.footer .footer-top .footer-links ul a:hover {
  color: #01a8af;
}

.footer .footer-top .footer-contact p {
  line-height: 26px;
}

.footer a {
  color: #aeaeae;
}

.footer .footer-top h3 {
  color: #fff;
  font-size: 22px;
  margin-bottom: 30px;
}

.footer i {
  font-size: 24px;
  color: #fff;
  margin-right: 20px;
}

.footer .call {
  font-size: 18px;
  color: #fff;
  font-weight: normal;
  padding: 20px 0 0;
}

.footer .call i {
  background-color: #01a8af;
  border-radius: 50%;
  padding: 10px 12px;
}

.footer .call a {
  color: #fff;
}

@media (max-width: 768px) {
  .footer .padding-right,
  .footer .padding-left {
    padding: 0 15px;
  }

  .footer .footer-top .footer-links ul {
    padding: 0;
  }

  .footer .footer-top .footer-links ul {
    float: none;
  }
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services,
.our-values,
.inner-about {
  font-family: 'Poppins';
}

.inner-about .services-block1 {
  border: 1px solid #eee;
}

.services .services-block1 {
  border: 1px solid #eee;
  width: 49%;
}

.services .services-block2 {
  border: 1px solid #eee;
  width: 49%;
  margin-left: 1%;
}

.services .services-block,
.inner-about .services-block {
  padding: 0 36px;
  padding-bottom: 10px !important;
  /* height: 295px; */
  /* overflow: auto; */
}

.services .services-block1 .block-img {
  height: 100%;
  object-fit: cover;
}

.services h2,
.our-values h2,
.inner-about h2 {
  font-size: 48px;
  color: #007679;
  font-weight: 600;
  text-align: center;
  margin: 0 0 20px;
}

.services h2 {
  margin-top: 40px;
}

.services h3,
.our-values h3,
.inner-about h3,
.our-values h4,
.about-goagent h3 {
  font-size: 26px;
  color: #000;
  font-weight: 600;
  text-align: left;
  margin: 50px 0 20px;
}

.about-goagent h3 {
  margin: 20px 0 20px;
}

.services p,
.inner-about p,
.about-goagent p {
  font-size: 24px;
  line-height: 38px;
  color: #000;
  margin-bottom: 10px;
}

.about-goagent p {
  font-size: 22px;
}

#landing-page .services .container p {
  font-size: 20px;
  line-height: 30px;
}

.services .services-block a {
  color: #007679;
  font-size: 24px;
  font-weight: 400;
}

.services .services-block a:hover {
  color: #03a3a7;
}

.post-button {
  background-color: #007679;
  color: #fff;
  padding: 15px 20px;
  text-align: center;
  border-radius: 30px;
  width: 400px;
  margin: 20px auto 20px;
  border: 1px soild #161616 !important;
  font-size: 20px;
}

.post-button:hover {
  background-color: #02a6aa;
}

/*--------------------------------------------------------------
# our-values
--------------------------------------------------------------*/
.our-values-main-block {
  margin: 0 0px 18px 0px;
  background-color: #02a6aa;
  border-radius: 10px;
  padding: 0 0 0 15px !important;
}

.our-values .services-block1 {
  background-color: #02a6aa;
  padding: 0 0 0 36px;
  -webkit-border-top-right-radius: 10px;
  -webkit-border-bottom-right-radius: 10px;
  -moz-border-radius-topright: 10px;
  -moz-border-radius-bottomright: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.our-values .services-block1-1 {
  background-color: #02a6aa;
  padding: 0 0 0 36px;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.our-values h2 {
  margin: 50px 0 20px;
}

.our-values h3 {
  color: #fff;
}

.our-values p {
  font-size: 24px;
  line-height: 38px;
  color: #fff;
}

.our-values .round-img {
  border-radius: 10px;
}

/*--------------------------------------------------------------
# valuable-clients
--------------------------------------------------------------*/
.valuable-clients {
  padding-bottom: 25px;
}

.valuable-clients .container {
  width: 700px;
}

.container1 {
  width: 90%;
  margin: auto;
  background-color: #fff;
  z-index: 99;
  position: relative;
}

.valuable-clients .valuable-clients-block {
  padding: 70px 150px 30px;
  box-shadow: -2px 0px 14px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -2px 0px 14px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -2px 0px 14px 0px rgba(0, 0, 0, 0.1);
}

.valuable-clients .logo {
  float: left;
  /* width: 20%; */
  text-align: center;
  width: 115px;
  height: 100px;
  /* margin-bottom: 40px; */
}

.valuable-clients h2 {
  font-size: 48px;
  color: #007679;
  font-weight: 600;
  text-align: center;
  margin: 0 0 40px;
}

/*--------------------------------------------------------------
# testimonial-logo
--------------------------------------------------------------*/
.testimonial-logo {
  gap: 2rem;
  justify-content: center;
}

.testimonial-logo img {
  width: 3rem;
  height: 3rem;
  object-fit: contain;
  border-radius: 50%;
}

/*--------------------------------------------------------------
# Send Massage
--------------------------------------------------------------*/
.send-massage .services-block1 {
  border: 1px solid #eee;
  padding: 40px;
  text-align: center;
}

.send-massage .services-block1 h5 {
  font-size: 36px;
  color: #007679;
  font-weight: 600;
}

.send-massage .services-block1 p {
  font-size: 24px;
  line-height: 36px;
  color: #000;
  margin-bottom: 30px;
}

.send-massage .services-block1 input {
  font-size: 15px;
  color: #797979;
  padding: 20px 25px;
  border-radius: 30px;
  border: 1px solid #ccc;
  width: 100%;
  margin-bottom: 10px;
}

.send-massage .post-button {
  background-color: #007679;
  color: #fff;
  line-height: 26px;
  padding: 20px 25px;
  font-size: 24px;
  text-align: center;
  border-radius: 50px;
  width: 250px;
  margin: 40px auto 0;
  border: 1px soild #161616 !important;
}

.send-massage .post-button:hover {
  background-color: #03a3a7;
}

.padding-left1 {
  padding-left: 0;
}

.padding-right1 {
  padding-right: 0;
}

.send-massage .services-block1 textarea {
  font-size: 15px;
  color: #797979;
  padding: 20px 25px;
  border-radius: 30px;
  border: 1px solid #ccc;
  width: 100%;
  margin-bottom: 10px;
}

/*--------------------------------------------------------------
# testimonial
--------------------------------------------------------------*/
.testimonial {
  background-color: #f8f8f8;
  padding: 50px 130px 90px;
  position: relative;
  z-index: 9;
  margin-bottom: 30px;
}

.testimonial h5 {
  color: #02a6aa;
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.testimonial h6 {
  color: #000;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .testimonial {
    margin-top: 316px;
  }
}

@media (max-width: px) {
  .testimonial {
    margin-top: 180px;
  }
}

#quote-carousel p {
  text-align: left;
  font-size: 18px;
}

#quote-carousel .carousel-control {
  background: none;
  color: #cacaca;
  font-size: 2.3em;
  text-shadow: none;
}

#quote-carousel .carousel-indicators {
  position: absolute;
  top: auto;
  bottom: 0px;
  margin-top: 20px;
  margin-right: -19px;
  left: 130%;
}

#quote-carousel .carousel-indicators li {
  width: 50px;
  height: 50px;
  cursor: pointer;
  border: 10px solid #eee;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  opacity: 0.4;
  overflow: hidden;
  transition: all 0.4s ease-in;
  vertical-align: middle;
}

#quote-carousel .carousel-indicators .active {
  width: 128px;
  height: 128px;
  opacity: 1;
  transition: all 0.2s;
}

.item blockquote {
  border-left: none;
  margin: 0;
}

.testimonial h6:before {
  content: '';
  font-family: 'Fontawesome';
  float: left;
  margin-right: 10px;
  font-size: 60px;
  color: #eee;
  margin-left: -140px;
  position: absolute;
  background: url('../img/quote.png') left top no-repeat;
  width: 132px;
  height: 98px;
}

.testimonial .carousel-control {
  top: 200px !important;
  width: 90% !important;
}

/*--------------------------------------------------------------
# About us
--------------------------------------------------------------*/
.inner-menu {
  background-color: #007679;
}

.about-goagent {
  position: absolute;
  top: 70px;
  width: 100%;
}

.inner-about h3,
.our-values h4,
.about-goagent h3,
.clering-goagent h3 {
  color: #007679;
}

.inner-about .mission {
  margin-bottom: 30px;
  padding-left: 15px !important;
}

.inner-about .vision {
  padding-right: 15px !important;
}

.about-goagent h2,
.clering-goagent h2,
.payment-policy h2,
.privacy-policy h2,
.terms-conditions h2 {
  font-size: 33px;
  color: #007679;
  text-align: center;
  font-weight: 700;
}

.privacy-policy h3,
.terms-conditions h3 {
  font-size: 26px;
  color: #007679;
  /* font-weight: 700; */
}

.about-goagent .about-goagent-block {
  margin-bottom: 40px;
}

.clering-goagent .clering-goagent-block {
  margin-top: 60px;
}

.clering-goagent .career-block {
  margin-top: 10px;
}

.about-goagent p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Clearing
--------------------------------------------------------------*/
.clering-goagent {
  margin-top: 100px;
  text-align: center;
}

.clering-goagent img {
  margin-bottom: 30px;
}

.clering-goagent .post-button {
  background-color: #007679;
  color: #fff;
  padding: 10px 40px;
  text-align: center;
  border-radius: 30px;
  width: 400px;
  margin: 40px auto 30px;
  border: 1px soild #007679 !important;
  font-size: 18px;
}

.clering-goagent .post-button:hover {
  background-color: #02a6aa;
}

.clering-goagent p {
  font-size: 18px;
}

.clering-goagent .career-block p {
  font-size: 22px;
}

.service-cleaning {
  margin-bottom: 40px;
}

/*--------------------------------------------------------------
# contact-us
--------------------------------------------------------------*/
.contact-us .call-block {
  color: #000;
  font-weight: 500;
}

.services .contact h3,
.services .contact-us h3 {
  font-size: 30px;
  color: #007679;
  font-weight: 600;
  text-align: center;
  margin: 50px 0 10px;
}

.services .contact-us h3 {
  text-align: left;
}

.services .contact p {
  font-size: 20px;
  line-height: 26px;
}

/*--------------------------------------------------------------
# F.A.Q
--------------------------------------------------------------*/
.faq .accordion-item,
.faq .send-cv {
  box-shadow: -2px 0px 8px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -2px 0px 8px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -2px 0px 8px 0px rgba(0, 0, 0, 0.1);
  padding-left: 20px;
}

.faq .send-cv {
  padding: 30px;
  margin-top: 30px;
  font-size: 22px;
  color: #000;
  font-weight: 500;
}

.faq h3 {
  color: #007679;
  font-weight: 700;
  margin: 0;
}

.faq .accordion-item h5 {
  font-family: 'Poppins', sans-serif;
  color: #000;
  font-weight: 500;
  font-size: 18px !important;
  margin-top: 30px;
}

.faq .accordion-collapse {
  border: 0;
}

.faq .accordion-item button.post-button {
  background-color: #007679;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
  border-radius: 30px;
  width: 150px;
  margin: 33px 0 20px 0 !important;
  border: 1px soild #007679 !important;
}

.faq .upload-button {
  background: #01a8ac;
  color: #fff;
  padding: 10px 30px;
  border-radius: 30px;
  width: 100%;
  margin: 0 !important;
  border: 1px soild #01a8ac !important;
  text-align: center;
}

.faq .accordion-item button.post-button:hover,
.faq .upload-button:hover {
  background: #01a8ac;
}

.faq .accordion-button {
  padding: 15px 15px 20px 0;
  font-weight: 600;
  border: 0;
  font-size: 22px;
  color: #000;
  text-align: left;
}

.faq .accordion-button:focus {
  box-shadow: none;
}

.faq .accordion-button:not(.collapsed) {
  background: none;
  color: #000;
  border-bottom: 0;
}

.faq .accordion-body {
  padding: 0 0 25px 0;
  border: 0;
}

a:focus,
button:focus {
  outline: none;
}

/*--------------------------------------------------------------
# payment-policy
--------------------------------------------------------------*/
.payment-policy {
  text-align: center;
  margin-top: 100px;
}

.payment-policy .header-sub {
  line-height: 30px;
  font-size: 22px;
  margin: 20px 0;
}

.payment-policy p {
  font-size: 18px;
}

.blockquote .small,
blockquote footer,
blockquote small {
  text-align: left;
}

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
#blog .payment-policy {
  text-align: center;
}

.blog-section {
  padding: 10px 10px 30px 10px;
  box-shadow: -1px 2px 11px 2px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: -1px 2px 11px 2px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: -1px 2px 11px 2px rgba(0, 0, 0, 0.16);
  text-align: left;
  margin-bottom: 45px;
}

.blog-section1 {
  text-align: left;
}

.blog-section h5,
.blog-section1 h5,
.blog-section3 h5 {
  font-size: 22px;
  margin: 20px 0;
}

.blog-section span,
.blog-section1 span,
.blog-section3 span {
  font-size: 16px;
  color: #7e7e7e;
  margin: 0;
  padding: 0;
}

.blog-section,
.blog-section1 p,
.blog-section3 p {
  margin-top: 20px;
  margin-bottom: 40px;
}

.blog-section {
  margin-top: 0;
}

.blog-section a,
.blog-section1 a,
.blog-section3 a {
  color: #007679;
  font-size: 16px;
}

.blog-section2 {
  padding: 40px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin: 40px 0;
  text-align: left;
}

.blog-section2 ul {
  float: left;
}

.blog-section2 ul li {
  float: left;
  list-style-type: none;
}

.blog-section2 ul li a {
  font-size: 20px;
  color: #000;
  padding: 10px 25px;
}

.blog-section2 ul li a:hover,
.blog-section2 ul li a.active {
  font-size: 20px;
  color: #000;
  padding: 10px 25px;
  background-color: #eee;
  border-radius: 6px;
}

.tag-block {
  float: left;
  font-size: 24px;
  color: #000;
  font-weight: 400;
}

.blog-section2 a {
  color: #ccc;
  margin-left: 10px;
}

.blog-section3 {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin: 4px 0 40px 0;
  text-align: left;
}

.comment-img {
  border-radius: 50%;
}

.replay {
  position: absolute;
  right: 0;
  top: 20px;
}

.replay button {
  background-color: #535353;
  padding: 5px 20px;
  border-radius: 20px;
  color: #fff;
}

.clearfix {
  clear: both;
}

.blog-section img {
  height: 200px;
  object-fit: cover;
}

@media (max-width: 1140px) {
  .navbar a {
    padding: 10px 20px;
  }

  .services h2,
  .our-values h2 {
    font-size: 36px;
    margin: 20px 0;
  }

  .services .services-block {
    padding: 0;
  }

  .services h3,
  .our-values h3 {
    font-size: 28px;
    margin: 20px 0;
  }

  .services p {
    font-size: 20px;
    line-height: 38px;
    color: #000;
    margin-bottom: 50px;
  }

  .post-button {
    margin: 30px auto;
  }

  .our-values p {
    line-height: 28px;
  }

  .valuable-clients .valuable-clients-block {
    padding: 70px 50px 30px;
  }

  .valuable-clients h2 {
    font-size: 36px;
  }

  .testimonial .carousel-control {
    top: 220px !important;
    width: 90% !important;
  }

  .send-massage .services-block1 h5 {
    font-size: 26px;
  }

  .send-massage .services-block1 p {
    font-size: 18px;
    line-height: 20px;
    color: #000;
    margin-bottom: 0;
  }

  .send-massage .services-block1 {
    padding: 20px;
  }

  .send-massage .post-button {
    line-height: 26px;
    padding: 10px 25px;
    margin: 20px auto 0;
  }

  .footer .call {
    font-size: 14px;
  }

  .video-banner {
    /* min-height: 100vh; */
  }
}

@media (max-width: 980px) {
  .hero h1 {
    font-size: 32px;
    text-align: left;
  }

  .hero p {
    font-size: 18px;
    line-height: 20px;
    color: #fff;
    width: 80%;
    text-align: left;
  }

  .services p {
    font-size: 16px;
    line-height: 20px;
    color: #000;
    margin-bottom: 20px;
  }

  .services h3,
  .our-values h3 {
    font-size: 24px;
    margin: 20px 0 0;
  }

  .our-values p {
    font-size: 16px;
    line-height: 20px;
    color: #fff;
  }

  .valuable-clients .valuable-clients-block {
    padding: 20px;
  }

  .valuable-clients .valuable-clients-block .logo img {
    width: 100%;
  }

  .send-massage .services-block1 input {
    font-size: 15px;
    color: #797979;
    padding: 10px 25px;
    border-radius: 30px;
    border: 1px solid #ccc;
    width: 100%;
    margin-bottom: 10px;
  }

  .footer i {
    font-size: 24px;
    color: #fff;
    margin-right: 10px;
  }

  .footer .call {
    font-size: 11px;
  }

  .mobile-nav-toggle {
    color: #fff;
    position: absolute;
    right: 0;
  }

  .valuable-clients .logo {
    width: 50%;
  }

  .valuable-clients h2,
  .services h2,
  .our-values h2 {
    font-size: 26px;
  }

  .services .services-block1,
  .services .services-block2 {
    border: none;
    width: 100%;
  }

  #home-page .header .logo img {
    margin-right: 420px;
  }
}

@media (max-width: 767px) {
  .header {
    padding: 0;
  }

  .hero {
    height: 114rem !important;
    padding: 0 !important;
  }

  #header logo {
    width: 150px;
  }

  .navbar {
    margin-bottom: 0;
  }

  .navbar {
    position: relative;
    min-height: 50px;
    margin-bottom: 0;
    width: 100%;
  }

  .hero p {
    font-size: 14px;
    width: 100%;
  }

  .services h2,
  .our-values h2 {
    font-size: 20px;
    margin: 20px 0;
  }

  .padding-left1 {
    padding-right: 15px;
  }

  .padding-right1 {
    padding-right: 15px;
  }

  .post-button {
    width: 300px;
  }

  .valuable-clients h2 {
    font-size: 20px;
  }

  .valuable-clients h2 img {
    display: none;
  }

  section {
    padding: 0;
  }

  #quote-carousel .carousel-indicators {
    position: relative;
    left: 50%;
  }

  #quote-carousel .carousel-indicators .active {
    width: 128px;
    height: 78px;
  }

  .testimonial h6:before {
    display: none;
  }

  .testimonial .carousel-control {
    top: inherit !important;
    width: inherit !important;
  }

  .testimonial {
    padding: 0;
  }

  .testimonial h6 {
    color: #000;
    font-size: 26px;
  }

  .footer .call {
    font-size: 18px;
  }

  section.faq {
    padding: 30px 0;
  }

  .valuable-clients .logo {
    float: left;
    width: 50%;
  }

  .padding-left1 {
    padding-left: 15px;
  }

  .our-values-main-block div {
    padding: 5px 15px !important;
    border-radius: 15px;
  }

  .header .logo img {
    height: 30px;
  }

  .header.header-scrolled {
    padding: 0;
  }

  .valuable-clients {
    padding-top: 20px;
  }

  .clering-goagent {
    margin-top: 40px;
  }

  .about-goagent h2,
  .clering-goagent h2,
  .payment-policy h2 {
    font-size: 28px;
  }

  .services h3,
  .our-values h3,
  .inner-about h3,
  .our-values h4,
  .about-goagent h3 {
    margin: 20px 0 10px;
  }

  p {
    font-size: 16px !important;
    line-height: 20px !important;
  }

  .about-goagent .about-goagent-block,
  .clering-goagent .clering-goagent-block {
    margin-top: 0;
  }

  .inner-about .services-block1 {
    border: none;
  }

  .inner-about-block .padding-left1 {
    padding-left: 0;
  }

  .inner-about-block .padding-right1 {
    padding-right: 0;
  }

  .inner-about-block .services .services-block,
  .inner-about-block .services .services-block1,
  .inner-about-block .inner-about .services-block {
    padding: 0;
  }

  .inner-about-block .inner-about .services-block,
  .inner-about-block .inner-about .services-block1,
  .inner-about-block .inner-about .services-block {
    padding: 0 !important;
  }

  .inner-about-block .inner-about .mission {
    padding-right: 0 !important;
  }

  .inner-about-block .inner-about .vision {
    padding-left: 0 !important;
  }

  .inner-about-block .inner-about .vision .services-block {
    padding-left: 15px !important;
  }

  .faq .send-cv {
    font-size: 16px;
  }

  .clering-goagent .post-button {
    padding: 10px 10px;
    font-size: 18px;
    width: auto;
  }

  .post-button,
  .send-massage .post-button {
    padding: 7px 20px;
    font-size: 18px;
  }

  .navbar li {
    position: relative;
    padding: 10px;
  }

  .header.header-scrolled .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  .header.header-scrolled .navbar ul {
    padding-left: 0 !important;
  }

  .payment-policy {
    text-align: center;
    padding-top: 50px;
  }

  .replay button {
    background-color: #535353;
    padding: 5px 10px;
  }

  .services-block1 {
    margin-bottom: 20px;
  }

  .blog-section2 ul li a:hover,
  .blog-section2 ul li a.active {
    font-size: 14px;
    padding: 10px 5px;
  }

  .blog-section2 ul li a {
    font-size: 14px;
    padding: 10px 5px;
  }

  .blog-section2 ul {
    margin-left: 0;
    padding-left: 0;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .blog-section2 a {
    margin-left: 0;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .hero {
    height: auto;
    /* padding: 180px 0 60px 0; */
  }

  #quote-carousel .carousel-indicators .active {
    width: 128px;
    height: 128px;
  }

  .hero {
    height: auto;
    padding: 60px 0 120px 0;
    /* margin-top: -320px; */
  }

  .hero h1 {
    font-size: 30px;
    margin: 0;
  }
}

@media (max-width: 767px) {
  #hero {
    margin-top: -167px !important;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  #quote-carousel .carousel-indicators .active {
    width: 128px;
    height: 78px;
  }

  .hero {
    height: auto;
    padding: 60px 0 20px 0;
    /* margin-top: -220px; */
  }

  .hero h1 {
    font-size: 20px;
    margin: 0;
  }
}

/*--------------------------------------------------------------
# Client Page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
  transition: all 0.5s;
  z-index: 997;
  padding: 20px 0;
  background: #007679;
}

.website-home .header {
  background: none;
}

.header.header-scrolled,
.website-home .headerr.header-scrolled {
  background: #007679;
  padding: 0;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.header .logo {
  line-height: 0;
  float: left;
}

.header.header-scrolled .logo {
  margin-left: 0;
}

.header .logo img {
  margin-right: 6px;
}

.header.header-scrolled .logo img {
  height: 30px;
}

.dashboard-top.header-scrolled .logo img {
  padding-left: 10px !important;
  padding-top: 10px !important;
  height: 40px;
}

.dashboard-top.header-scrolled .loginright {
  padding-right: 10px;
}

.loginleft {
  float: right;
}

.dashboard-top.header-scrolled .loginright .notification {
  padding: 0;
}

.dashboard-top.header-scrolled .loginright .notification a {
  font-size: 28px;
}

.dashboard-top.header-scrolled .loginright span {
  position: absolute;
  right: 0;
  top: 2px;
  background: #fff;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 100%;
  color: #02a6aa;
}

.notification span {
  position: absolute;
  right: 0;
  top: 1px;
  background: #fff;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 100%;
  color: #02a6aa;
}

.header .logo span {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #012970;
  font-family: 'Poppins', sans-serif;
  margin-top: 3px;
}

.header.header-scrolled .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.btn {
  border: 2px solid #007679 !important;
  color: gray !important;
  background-color: #007679 !important;
  padding: 10px 15px !important;
  border-radius: 38px !important;
  font-size: 20px !important;
}

a.detailsdiv1 {
  float: none;
  background: #007679;
  padding: 10px 35px;
  color: #fff;
  border-radius: 30px;
  font-size: 22px;
}

a:hover.detailsdiv1 {
  background: #01a8af;
  color: #fff;
  text-decoration: none;
}

.tracking-textarea {
  border: 1px solid #ccc;
  border-radius: 12px;

  padding: 20px;
  width: 100%;
  margin-bottom: 30px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.header.header-scrolled .navbar ul {
  padding-left: 20px !important;
}

.navbar li {
  position: relative;
  padding: 10px 0 10px 10px;
}

.navbar a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  white-space: nowrap;
  transition: 0.3s;
  border-radius: 20px;
  list-style-type: none;
}

.navbar a i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar li:hover > a {
  color: #fff;
  background-color: #02a6aa;
  padding: 10px 20px;
  border-radius: 20px;
}

.header.header-scrolled .navbar a:hover,
.header.header-scrolled .navbar .active,
.header.header-scrolled .navbar li:hover > a {
  background-color: #02a6aa;
}

.navbar a.login:hover {
  color: #fff;
  border-bottom: none;
}

.navbar .getstarted {
  background: #dbab47;
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 4px;
  color: #fff;
}

.navbar .getstarted:hover {
  color: #fff;
  background: #dbab47;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 15px;
  text-transform: none;
  font-weight: 400;
  color: #333333;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #fff;
  background-color: #007679;
  padding: 10px 20px;
  border-radius: 20px;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

.navbar .login {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  background-color: #02a6aa;
  padding: 8px 20px;
  border-radius: 30px;
  margin-left: 10px;
}

.navbar {
  margin-bottom: 0 !important;
}

.header.header-scrolled .navbar .login {
  background-color: #02a6aa;
  padding: 5px 20px !important;
  font-size: 16px;
}

.inner-menu .navbar .login {
  background-color: #02a6aa;
}

.header.header-scrolled .navbar a:hover,
.header.header-scrolled .navbar a,
.header.header-scrolled .navbar .active,
.header.header-scrolled .navbar li:hover > a {
  padding: 5px 20px !important;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }

  .header .logo {
    margin-left: 0;
  }

  .navbar a,
  .navbar a:hover,
  .navbar .active,
  .navbar li:hover > a,
  .navbar a.login:hover,
  .navbar .login {
    padding: 10px 15px;
    border-radius: 50px;
  }

  .valuable-clients {
    padding-top: 0px;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #ffffff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }

  .header {
    background-color: #007679;
  }
}

@media (max-width: 767px) {
  .header .logo,
  .header.header-scrolled .logo {
    line-height: 0;
    margin-left: 0;
    position: absolute;
    width: 100%;
  }

  .header .navbar,
  .header.header-scrolled .navbar {
  }
}

.header .navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #02a6aa;
  transition: 0.3s;
  z-index: 99;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  /* bottom: 15px; */
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a {
  padding: 10px 20px;
  font-size: 15px;
  color: #333;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #fff;
}

.navbar-mobile .getstarted {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #c7a12e;
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

.main-block {
}

.loginright {
  float: right;
  text-align: right;
  position: relative;
}

a.topprofile img {
  width: 45px;
  border-radius: 100%;
  margin-right: 10px;
  border: 1px solid #7db9bb;
  height: 45px;
  margin: auto;
}

.notification {
  float: right;
  margin: 0 0 0 15px;
  position: relative;
}

.notification span {
  position: absolute;
  right: 0;
  top: -5px;
  background: #fff;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  border-radius: 100%;
  color: #02a6aa;
}

.notification a {
  font-size: 38px;
  color: #63abad;
}

#nonResponsiveTabs li.active a {
  background: #01a8ac !important;
  color: #fff;
}

/*--------------------------------------------------------------
# Supporting Sisters Section
--------------------------------------------------------------*/
.supporting-sisters {
  background-color: #dbaa20;
  line-height: 22px;
  font-size: 18px;
  color: #f5e6bc;
  padding: 50px 0;
}

.supporting-sisters h3 {
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700px;
}

/*--------------------------------------------------------------
# Home Scroll Bar
--------------------------------------------------------------*/
.container-example1 {
  height: 300px;
  width: 100%;
  max-width: 100%;
  padding: 5px 10px;
  margin: auto;
}

.sb-container {
  position: relative;
  overflow: hidden;
  padding-right: 10px;
}

.sb-content {
  height: 100%;
  width: 120%;
  padding-right: 20%;
  overflow-y: scroll;
  box-sizing: border-box;
  border-right: 1px solid #fff;
}

.sb-scrollbar-container {
  position: absolute;
  right: 5px;
  bottom: 5px;
  top: 5px;
  width: 10px;
  /* background: url('../img/scroll-bg.jpg') center top repeat-y; */
}

.sb-container-noscroll .sb-scrollbar-container {
  right: -20px;
}

.sb-scrollbar {
  position: absolute;
  right: 0;
  height: 30px;
  width: 10px;
  border-radius: 10px;
  background: #fff;
}

.sb-scrollbar:hover {
  background: #fff;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  background: #000000;
  padding: 0 0 40px 0;
  font-size: 14px;
}

.footer .footer-top {
  background-size: contain;
  padding: 60px 0 30px 0;
  background-color: #0b0b0b;
  border-bottom: 1px solid #262626;
  margin-bottom: 40px;
}

.footer .padding-left {
  padding-left: 0;
}

.footer .padding-right {
  padding-right: 0;
}

@media (max-width: 992px) {
  .footer .footer-top {
    background-position: center bottom;
  }
}

.footer .footer-top .social-links a {
  font-size: 20px;
  display: inline-block;
  color: #9a9a99;
  line-height: 0;
  margin-right: 10px;
  transition: 0.3s;
}

.footer .footer-top .social-links a:hover {
  color: #01a8af;
}

.footer .footer-top .footer-links {
  margin-bottom: 30px;
}

.copyright {
  text-align: center;
}

.footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0 60px 0 0;
  margin: 0;
  float: left;
}

.footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #d0d4fc;
  font-size: 12px;
  line-height: 0;
}

.footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-top .footer-links ul a {
  color: #9a9a99;
  transition: 0.3s;
  display: inline-block;
  line-height: 1.5;
}

blockquote {
  padding: 10px 5px !important;
}

.footer .footer-top .footer-links ul a:hover {
  color: #01a8af;
}

.footer .footer-top .footer-contact p {
  line-height: 26px;
}

.footer a {
  color: #aeaeae;
}

.footer .footer-top h3 {
  color: #fff;
  font-size: 22px;
  margin-bottom: 30px;
}

.footer i {
  font-size: 24px;
  color: #fff;
  margin-right: 20px;
}

.footer .call {
  font-size: 18px;
  color: #fff;
  font-weight: normal;
  padding: 20px 0 0;
}

.footer .call i {
  background-color: #01a8af;
  border-radius: 50%;
  padding: 10px 12px;
}

.footer .call a {
  color: #fff;
}

@media (max-width: 768px) {
  .footer .padding-right,
  .footer .padding-left {
    padding: 0 15px;
  }

  .footer .footer-top .footer-links ul {
    padding: 0;
  }

  .footer .footer-top .footer-links ul {
    float: none;
  }
}

@media (max-width: 1140px) {
  .navbar a {
    padding: 10px 20px;
  }

  .services h2,
  .our-values h2 {
    font-size: 36px;
    margin: 20px 0;
  }

  .services .services-block {
    padding: 0;
  }

  .services h3,
  .our-values h3 {
    font-size: 28px;
    margin: 20px 0;
  }

  .services p {
    font-size: 20px;
    line-height: 38px;
    color: #000;
    margin-bottom: 50px;
  }

  .post-button {
    margin: 30px auto;
  }

  .our-values p {
    line-height: 28px;
  }

  .valuable-clients .valuable-clients-block {
    padding: 70px 50px 30px;
  }

  .valuable-clients h2 {
    font-size: 36px;
  }

  .testimonial .carousel-control {
    top: 220px !important;
    width: 90% !important;
  }

  .send-massage .services-block1 h5 {
    font-size: 26px;
  }

  .send-massage .services-block1 p {
    font-size: 18px;
    line-height: 20px;
    color: #000;
    margin-bottom: 0;
  }

  .send-massage .services-block1 {
    padding: 20px;
  }

  .send-massage .post-button {
    line-height: 26px;
    padding: 10px 25px;
    margin: 20px auto 0;
  }

  .footer .call {
    font-size: 14px;
  }
}

@media (max-width: 980px) {
  .hero h1 {
    font-size: 32px;
    text-align: left;
  }

  .hero p {
    font-size: 18px;
    line-height: 20px;
    color: #fff;
    width: 80%;
    text-align: left;
  }

  .services p {
    font-size: 16px;
    line-height: 20px;
    color: #000;
    margin-bottom: 20px;
  }

  .services h3,
  .our-values h3 {
    font-size: 24px;
    margin: 20px 0 0;
  }

  .our-values p {
    font-size: 16px;
    line-height: 20px;
    color: #fff;
  }

  .valuable-clients .valuable-clients-block {
    padding: 20px;
  }

  .valuable-clients .valuable-clients-block .logo img {
    width: 100%;
  }

  .send-massage .services-block1 input {
    font-size: 15px;
    color: #797979;
    padding: 10px 25px;
    border-radius: 30px;
    border: 1px solid #ccc;
    width: 100%;
    margin-bottom: 10px;
  }

  .footer i {
    font-size: 24px;
    color: #fff;
    margin-right: 10px;
  }

  .footer .call {
    font-size: 11px;
  }

  .mobile-nav-toggle {
    color: #fff;
    position: absolute;
    right: 0;
  }

  .valuable-clients .logo {
    width: 50%;
  }

  .valuable-clients h2,
  .services h2,
  .our-values h2 {
    font-size: 26px;
  }

  .services .services-block1,
  .services .services-block2 {
    border: none;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .header {
    padding: 0;
    height: 60px;
  }

  #header logo {
    width: 150px;
  }

  .navbar {
    margin-bottom: 0;
  }

  .navbar {
    position: relative;
    min-height: 50px;
    margin-bottom: 0;
    width: 100%;
  }

  .hero {
    height: auto;
    padding: 60px 0 20px 0;
  }

  .hero h1 {
    font-size: 20px;
    margin: 0;
  }

  .hero p {
    font-size: 14px;
  }

  .services h2,
  .our-values h2 {
    font-size: 20px;
    margin: 20px 0;
  }

  .padding-left1 {
    padding-right: 15px;
  }

  .padding-right1 {
    padding-right: 15px;
  }

  .post-button {
    width: 300px;
  }

  .valuable-clients h2 {
    font-size: 20px;
  }

  .valuable-clients h2 img {
    display: none;
  }

  section {
    padding: 0;
  }

  #quote-carousel .carousel-indicators {
    position: relative;
    left: 50%;
  }

  #quote-carousel .carousel-indicators .active {
    width: 128px;
    height: 78px;
  }

  .testimonial h6:before {
    display: none;
  }

  .testimonial .carousel-control {
    top: inherit !important;
    width: inherit !important;
  }

  .testimonial {
    padding: 0;
  }

  .testimonial h6 {
    color: #000;
    font-size: 26px;
  }

  .footer .call {
    font-size: 18px;
  }

  section.faq {
    padding: 30px 0;
  }

  .valuable-clients .logo {
    float: left;
    width: 50%;
  }

  .padding-left1 {
    padding-left: 15px;
  }

  .our-values-main-block div {
    padding: 5px 15px !important;
    border-radius: 15px;
  }

  .header .logo img {
    height: 30px;
  }

  .header.header-scrolled {
    padding: 0;
    height: 50px;
  }

  .valuable-clients {
    padding-top: 40px;
  }

  .clering-goagent {
    margin-top: 40px;
  }

  .about-goagent h2,
  .clering-goagent h2,
  .payment-policy h2 {
    font-size: 28px;
    margin-top: 30px !important;
  }

  .services h3,
  .our-values h3,
  .inner-about h3,
  .our-values h4,
  .about-goagent h3 {
    margin: 20px 0 10px;
  }

  p {
    font-size: 16px !important;
    line-height: 20px !important;
  }

  .about-goagent .about-goagent-block,
  .clering-goagent .clering-goagent-block {
    margin-top: 0;
  }

  .inner-about .services-block1 {
    border: none;
  }

  .inner-about-block .padding-left1 {
    padding-left: 0;
  }

  .inner-about-block .padding-right1 {
    padding-right: 0;
  }

  .inner-about-block .services .services-block,
  .inner-about-block .services .services-block1,
  .inner-about-block .inner-about .services-block {
    padding: 0;
  }

  .inner-about-block .inner-about .services-block,
  .inner-about-block .inner-about .services-block1,
  .inner-about-block .inner-about .services-block {
    padding: 0 !important;
  }

  .inner-about-block .inner-about .mission {
    padding-right: 0 !important;
  }

  .inner-about-block .inner-about .vision {
    padding-left: 0 !important;
  }

  .inner-about-block .inner-about .vision .services-block {
    padding-left: 15px !important;
  }

  .faq .send-cv {
    font-size: 16px;
  }

  .clering-goagent .post-button {
    padding: 10px 10px;
    font-size: 18px;
    width: auto;
  }

  .post-button,
  .send-massage .post-button {
    padding: 7px 20px;
    font-size: 18px;
  }

  .navbar li {
    position: relative;
    padding: 10px;
  }

  .header.header-scrolled .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  .header.header-scrolled .navbar ul {
    padding-left: 0 !important;
  }

  .cargoheading1 {
    width: 150px !important;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .hero {
    height: auto;
    padding: 180px 0 60px 0;
  }

  #quote-carousel .carousel-indicators .active {
    width: 128px;
    height: 128px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  #quote-carousel .carousel-indicators .active {
    width: 128px;
    height: 85px;
  }

  #quote-carousel-single-item .carousel-indicators .active {
    width: 128px;
    height: 128px;
  }
}

/*--------------------------------------------------------------
# CLIENT SECTION
--------------------------------------------------------------*/
#client-page .bodycont {
  padding: 130px 0 35px 0;
}

#client-page .login-page {
  padding: 120px 0 35px 0;
}

#client-page .bodycont h2 {
  text-align: center;
  font-size: 30px;
  color: #007679;
  font-weight: 600;
  padding: 0 0 10px 0;
  margin-top: 0;
  margin-bottom: 0;
}

#client-page .bodycont h3 {
  text-align: center;
  font-size: 20px;
  color: #007679;
  font-weight: 600;
  padding: 10px 0;
  margin: 0px !important;
}

#client-page .personal-info {
  padding: 20px;
  border: 1px solid #eee;
  margin: 0 100px;
  text-align: center;
}

.personal-info .form-group {
  text-align: left;
  padding: 10px 20px;
  background-color: #eee;
  width: 100% !important;
  border-radius: 8px;
}

#client-page .form-control {
  padding: 10px 25px;
  height: 60px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: none;
  margin-bottom: 10px;
}

#client-page .personal-info .form-control {
  margin-bottom: 10px;
}

#client-page .buttonsec {
  padding: 15px 0 15px;
  text-align: center;
}

#client-page .submitbut {
  padding: 15px 45px;
  min-width: 250px;
  border-radius: 35px;
  border: none;
  background: #007679;
  color: #fff;
  font-size: 16px;
  margin: 0 8px 8px 0;
  transition: all 0.2s ease-in-out 0s;
}

#client-page .submitbut:hover {
  background: #01a8af;
  color: #fff;
}

#client-page .submitbut:disabled {
  background: #ccc;
  cursor: not-allowed;
}

#client-page .submitbut2 {
  padding: 15px 45px;
  min-width: 200px;
  border-radius: 35px;
  border: none;
  background: #007679;
  color: #fff;
  font-size: 16px;
  margin: 0 8px 8px 0;
  transition: all 0.2s ease-in-out 0s;
}

#client-page .submitbut2:hover {
  background: #01a8af;
  color: #fff;
}

#client-page .submitbut2:disabled {
  background: #ccc;
  cursor: not-allowed;
}

#client-page a.submitbut,
#client-page a.backbut {
  padding: 15px 45px;
  min-width: 180px;
  border-radius: 35px;
  border: none;
  background: #007679;
  color: #fff;
  font-size: 16px;
  margin: 0 8px 8px 0;
  display: inline-block;
  text-align: center;
}

#client-page a.backbut {
  background: #01a8af;
}

#client-page a:hover.backbut,
#client-page a:focus.backbut {
  background: #007679;
  color: #fff;
}

#client-page a:hover.submitbut,
#client-page a:focus.submitbut,
#client-page a:focus.detailsdiv,
#client-page a:focus.review {
  background: #01a8af;
  color: #fff;
}

#client-page .profilesec {
  padding: 0;
  font-size: 28px;
  font-weight: 500;
  /* margin-top: -145px; */
}

#client-page .profileimage img {
  border-radius: 100%;
  border: 8px solid #eee;
  width: 100%;
}

.profilecont .text-left {
  text-align: left;
  padding: 10px 20px;
  background-color: #eee;
  width: 100% !important;
  border-radius: 8px;
}

#client-page .profileimage {
  width: 163px;
  margin: 0 auto 20px;
  position: relative;
}

#client-page .profileimage span span {
  position: absolute;
  right: 0;
  top: 65%;
  background: #007679;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  font-size: 13px;
  line-height: 35px;
  color: #fff;
  display: block;
}

#client-page .profilecont {
  display: flex;
}

#client-page .profilecontleft,
#client-page .profilecontright {
  width: 50%;
  text-align: left;
  padding: 20px 40px;
  font-size: 17px;
  line-height: 22px;
}

#client-page .profilecontright {
  border-left: 1px solid #e3e3e3;
}

#client-page .loginright .dropdown-menu {
  left: inherit !important;
  right: 0;
  margin-top: 8px;
  font-size: 12px;
}

#client-page .payment-info {
  padding: 10px 60px;
  border: 1px solid #eee;
  text-align: center;
}

#client-page .payment-details-block,
#client-page .payment-details-block1 {
  width: 50%;
  padding: 0 25px;
  float: left;
  /* height: 50px; */
  line-height: 50px;
  border-bottom: 1px solid #ccc;
}

#client-page .payment-details-block {
  border-right: 1px solid #ccc;
  font-size: 20px;
}

#client-page .finish {
  font-size: 22px;
  line-height: 28px;
  font-weight: 500;
}

#client-page .finish h3 {
  padding: 0 0 10px;
}

#client-page .paymentstep {
  padding: 20px 0;
  text-align: center;
}

#client-page .paymentstep li {
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  background: url(../img/dotted.png) repeat-x 0 28px;
  width: 25%;
  vertical-align: top;
}

#client-page .paymentstep li span {
  width: 55px;
  height: 55px;
  display: block;
  margin: 0 auto 8px;
  background: #282828;
  color: #fff;
  border-radius: 100%;
  border: 9px solid #f5f5f5;
  font-weight: 600;
  line-height: 38px;
}

#client-page .paymentstep li.active {
  color: #01a8ac;
}

#client-page .paymentstep li.active span {
  background: #01a8ac;
}

#client-page .paymentstep li:first-child {
  background-position: 100% 28px;
  background-repeat: no-repeat;
}

#client-page .paymentstep li:first-child span {
  position: relative;
  left: -12px;
}

#client-page .paymentstep li:last-child {
  background-position: -6% 28px;
  background-repeat: no-repeat;
}

#client-page .paymentstep li:last-child span {
  position: relative;
  right: -12px;
}

#client-page .paymentstep li p {
  padding: 0;
  background: #fff;
}

#client-page .custom02 input[type='radio'] {
  display: none;
}

#client-page .custom02 label {
  position: relative;
  padding: 9px 3px 3px 28px;
  cursor: pointer;
  margin-left: 16px;
  text-align: left;
  float: left;
}

#client-page .custom02 label::before,
#client-page .custom02 label::after {
  position: absolute;
  content: '';
  top: 50%;
  border-radius: 100%;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

#client-page .custom02 label::before {
  left: 0;
  width: 18px;
  height: 18px;
  margin-top: -8px;
  background: #fff;
  border: 1px solid #ccc;
}

#client-page .custom02 label:hover::before {
  background: #fff;
}

#client-page .custom02 label::after {
  opacity: 0;
  left: 4px;
  width: 10px;
  height: 10px;
  margin-top: -4px;
  background: #4ad300;
  -webkit-transform: scale(2);
  transform: scale(2);
}

#client-page .custom02 input[type='radio']:checked + label::before {
  background: #fff;
}

#client-page .custom02 input[type='radio']:checked + label::after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

#client-page .payment-method {
  background: #f8f8f8;
  padding: 12px 18px 12px;
  border-radius: 47px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

#client-page .payment-method2 {
  background: #f8f8f8;
  padding: 18px 18px 12px;
  border-radius: 47px;
  border: 1px solid #ccc;
  margin-top: 10px;
}

#client-page .form-control {
  padding: 10px 25px !important;
  height: 60px !important;
  border-radius: 5px !important;
  border: 1px solid #ccc !important;
  box-shadow: none !important;
  margin-bottom: 10px !important;
}

#client-page .progressdiv1 {
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  margin-bottom: 15px;
}

#client-page .checkcontainer {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 17px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 500;
}

/* Hide the browser's default checkbox */
#client-page .checkcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
#client-page .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
#client-page .checkcontainer:hover input ~ .checkmark {
  background-color: #f8f8f8;
}

/* When the checkbox is checked, add a blue background */
#client-page .checkcontainer input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
#client-page .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
#client-page .checkcontainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
#client-page .checkcontainer .checkmark:after {
  left: 6px;
  top: 3px;
  width: 7px;
  height: 12px;
  border: solid #4ad300;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

#client-page .payment-method2 img {
  float: right;
  width: 120px;
  margin: 0 10px;
}

#client-page .cardicon {
  float: right;
}

#client-page .cardicon img {
  width: 40px;
  border-radius: 100%;
  margin: 0 10px;
}

#client-page .paymenttable .table > tbody > tr > td {
  border-top: none;
  padding: 8px 25px;
}

#client-page .paymenttable td:first-child {
  font-size: 20px;
  font-weight: 500;
  border-right: 1px solid #ccc;
  width: 50%;
}

#client-page .paymenttable {
  text-align: left;
}

#client-page .price {
  font-size: 20px;
  font-weight: 600;
  color: #01a8ac;
}

#client-page #nonResponsiveTabs {
  border: none;
}

#client-page #nonResponsiveTabs li.active > a {
  border: none;
}

#client-page #nonResponsiveTabs li {
  width: 22%;
  margin: 0 1%;
}

#client-page #nonResponsiveTabs > li > a {
  border-radius: 45px;
  background: #d1d1d1;
  color: #000;
  margin: 0;
  border: none;
  padding: 15px 10px;
}

#client-page #nonResponsiveTabs > li > a:hover {
  border: none;
}

#client-page #nonResponsiveTabs li.active a {
  background: #01a8ac;
  color: #fff;
}

#client-page .resistertab .tab-content {
  padding: 16px 0 0 0;
}

#client-page .resistertab .checkcontainer {
  margin: 25px 0;
}

#client-page .resistertab .panel-heading {
  padding: 15px;
}

#client-page .logindiv {
  padding: 90px;
  box-shadow: 0 0 5px #ddd;
  width: 675px;
  margin: 0 auto;
}

#client-page .logindiv .submitbut {
  width: 100%;
  padding: 15px 45px !important;
}

#client-page .logindiv a {
  text-decoration: underline;
}

#client-page .logindiv a:hover {
  text-decoration: none;
}

#client-page .logindiv p {
  padding: 0 0 30px;
}

#client-page .logindiv h3 {
  padding: 25px 0;
  color: #999;
  font-size: 24px;
}

#client-page .keepcheck {
  padding: 0 0 25px;
}

#client-page .keepcheck a {
  float: right;
  text-decoration: none;
}

#client-page .dashcont {
  display: flex;
}

#client-page .dashlink {
  width: 475px;
  background: #f4f4f4;
  padding: 85px 0;
}

#client-page .dashrightcont {
  width: 100%;
  padding: 80px 50px 50px;
}

#client-page .dashrightcont .bodycont {
  padding: 0;
}

#client-page .dashlink ul {
  padding-left: 0 !important;
}

#client-page .dashlink ul li {
  margin-bottom: 3px;
  list-style-type: none;
}

#client-page .dashlink ul li a {
  padding: 25px 15px 25px 75px;
  display: block;
  font-size: 23px;
  font-weight: 500;
  color: #000;
}

#client-page .dashlink ul li a:hover,
#client-page .dashlink ul li.active a {
  background: #fff;
  color: #007679;
}

#client-page .dashlink ul li:last-child a {
  margin-top: 70px;
  color: #df0000;
}

#client-page .dashlink h4 {
  padding: 25px 15px 45px 75px;
  font-size: 23px;
  font-weight: 500;
  color: #000;
}

#client-page .servicecheck {
  padding: 0 0 35px;
}

#client-page .servicecheck label {
  margin: 0 20px;
}

#client-page .serviceradio label {
  float: none;
  font-weight: normal;
}

#client-page .serviceradio {
  padding: 15px 0 25px;
}

#client-page .payment-info h5 {
  font-size: 24px;
  color: #000 !important;
  padding: 0 0 30px;
  font-weight: 600;
}

#client-page .attached {
  padding: 0 0 25px;
  text-align: left;
  color: #878787;
  font-size: 18px;
}

#client-page select.form-control {
  white-space: normal;
  background: url(../img/arrow1.png) no-repeat 95% 26px #f8f8f8;
}

#client-page .uploaddiv {
  padding: 18px 80px 18px 25px;
  border: 1px solid #ccc;
  border-radius: 55px;
  font-size: 16px;
  color: #000;
  margin-bottom: 8px;
  position: relative;
}

#client-page .upload-btn-wrapper {
  position: absolute;
  overflow: hidden;
  display: inline-block;
  float: right;
  top: 3px;
  right: 6px;
}

#client-page .btn {
  border: 2px solid #007679;
  color: gray;
  background-color: #007679;
  padding: 10px 15px;
  border-radius: 38px;
  font-size: 20px;
  font-weight: bold;
}

#client-page .upload-btn-wrapper input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

#client-page .servicetab {
  margin: 5px;
}

#client-page .servicetab .nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  align-items: center !important;
  justify-content: center !important;
}

#client-page .servicetab .nav > li > a {
  padding: 17px 15px;
  color: #1d1d1d;
  font-size: 20px;
  margin: 0;
  border: none;
}

#client-page .servicetab .nav-tabs > li {
  flex-grow: 1;
  text-align: center;
  margin-left: 0;
  margin-right: 0;
  position: relative;
  z-index: 9;
}

#client-page .servicetab .nav-tabs > li.active a {
  color: #007679;
  border: none;
  box-shadow: 0px -4px 5px #ddd;
}

#client-page .servicetab .nav-tabs {
  border-bottom: none;
}

#client-page .servicetab .nav > li > a:hover {
  background: none;
  color: #007679;
  border: none;
}

#client-page .servicetab .tab-content {
  box-shadow: 0 0 5px #ccc;
  padding: 25px 40px 40px;
  text-align: center;
}

#client-page .servicetab h3 {
  color: #000;
}

#client-page .progressdiv {
  padding: 20px 20px 15px;
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  margin-bottom: 15px;
}

#client-page .progressheading {
  position: relative;
  float: left;
  font-size: 20px;
  font-weight: 500;
  padding: 0 0 0 50px;
}

#client-page .progressheading span {
  position: absolute;
  left: 0;
  top: -4px;
  background: #01a8ac;
  width: 35px;
  height: 35px;
  color: #fff;
  font-size: 14px;
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
}

.badge {
  margin-left: 0 !important;
}

#client-page a.detailsdiv {
  float: right;
  background: #007679;
  padding: 10px 35px;
  color: #fff;
  border-radius: 30px;
}

#client-page :hover.detailsdiv {
  background: #01a8af;
  color: #fff;
  text-decoration: none;
}

#client-page .servicetabout {
  text-align: center;
}

#client-page .servicetabout #nonResponsiveTabs li {
  float: none;
  display: inline-block;
}

#client-page .servicetabout #nonResponsiveTabs {
  border: none;
  padding-bottom: 45px;
}

#client-page .servicetabout .tab-content {
  padding: 0;
  box-shadow: none;
  text-align: left;
}

#client-page .servicetabout #nonResponsiveTabs li.active::after {
  content: '';
  background: url(../img/arrow.png) no-repeat 0 0;
  width: 19px;
  height: 13px;
  display: block;
  left: 48%;
  position: absolute;
}

#client-page .clearing {
  padding: 0px 10px;
  border: 1px solid #cfcfcf;
  margin-bottom: 15px;
  border-radius: 8px;
  /*justify-content: center;*/
  /* align horizontal */
  align-items: center;
  text-align: left;
}

#client-page .clearing ul {
  margin-bottom: 0 !important;
}

#client-page .clearing li {
  list-style-type: none;
  font-size: 22px;
  color: #000;
  font-weight: 500;
  background: url(../img/devider.png) no-repeat right center;
  display: inline-block;
  min-height: 99px;
  vertical-align: top;
}

#client-page .heading1 {
  padding: 36px 15px 0 10px;
  font-size: 20px;
  font-weight: 700;
}

#client-page .heading {
  float: left;
  padding: 22px 15px 0 15px;
  line-height: 25px;
}

#client-page .cargoheading1,
.cargoheading2 {
  padding: 16px 15px;
  font-size: 20px;
  font-weight: 700;
}

#client-page .clearing p {
  font-size: 14px;
  font-weight: 300;
  padding: 0;
}

#client-page .batch {
  float: left;
}

#client-page .heading span {
  color: #8e8e8e;
  font-size: 18px;
}

#client-page .cargoheading {
  padding: 36px 34px;
}

#client-page .cargoprice {
  color: #01a8ac;
}

#client-page .clearing li:last-child {
  background: none;
  width: 30%;
}

#client-page .starsec {
  padding: 30px 0 0 37px;
}

#client-page .starsec a .fa,
#client-page .starsec1 a .fa {
  color: #ffc107;
  padding: 0 2px;
}

#client-page .starsec1 {
  padding: 30px 0 0 5px;
  text-align: center;
}

#client-page .clearingtable .starsec1 {
  padding: 0 0 0 5px;
  text-align: center;
  margin: 10px 0;
}

#client-page a.review {
  padding: 10px 20px;
  background: #007679;
  font-size: 15px;
  margin: 0;
  font-weight: 500;
  border-radius: 18px;
  color: #fff;
  white-space: nowrap;
}

#client-page a:hover.review {
  background: #01a8af;
  color: #fff;
}

#client-page .progressdiv1 {
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  margin-bottom: 15px;
}

#client-page .progressdiv1 .progressheading-new {
  width: 100%;
  padding: 15px 70px 10px 70px;
  font-size: 20px;
  font-weight: 600;
  position: relative;
}

#client-page .progressdiv1 .progressheading-new p {
  font-size: 18px;
  font-weight: 300;
}

#client-page .progressdiv1 .progressheading {
  width: 59%;
  background: url(../img/devider.png) no-repeat right center;
  padding: 36px 0 45px 70px;
}

#client-page .progressheading span,
#client-page .progressheading-new span {
  position: absolute;
  left: 0;
  top: -4px;
  background: #01a8ac;
  width: 35px;
  height: 35px;
  color: #fff;
  font-size: 14px;
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
}

#client-page .progressdiv1 .progressheading span,
#client-page .progressdiv1 .progressheading-new span {
  position: absolute;
  left: 20px;
  top: 29px;
}

#client-page .dayleft {
  width: 20%;
  float: left;
  text-align: center;
  background: url(../img/devider.png) no-repeat right center;
  padding: 30px;
  color: #01a8ac;
  font-size: 16px;
}

#client-page .progressdiv1 .progressheading span {
  position: absolute;
  left: 20px;
  top: 29px;
}

#client-page .progressdiv1 a.detailsdiv {
  margin: 29px 15px;
}

#client-page .detailsservice {
  border: 1px solid #cfcfcf;
  margin-bottom: 15px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  text-align: left;
}

#client-page .batleft img {
  margin-bottom: 5px;
}

#client-page .batleft {
  width: 15%;
  padding: 10px;
  float: left;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  background: url(../img/devider.png) no-repeat right center;
}

#client-page .battext {
  float: left;
  width: 25%;
  text-align: center;
  padding: 60px 25px;
  min-height: 143px;
  font-size: 20px;
  font-weight: 600;
  background: url(../img/devider.png) no-repeat right center;
}

#client-page .battext1 {
  float: left;
  width: 15%;
  text-align: center;
  padding: 43px 25px;
  min-height: 143px;
  font-size: 20px;
  font-weight: 600;
  background: url(../img/devider.png) no-repeat right center;
}

#client-page .batreview {
  width: 20%;
  padding: 30px 15px;
  float: left;
  text-align: center;
  background: url(../img/devider.png) no-repeat right center;
}

#client-page .batreview .fa {
  font-size: 22px;
  margin: 1px;
  color: #ffc107;
}

#client-page .batreview a.review {
  display: inline-block;
  margin-top: 9px;
  padding: 10px 25px;
  color: #007679;
  border: 1px solid #007679;
  background: #fff;
}

#client-page .batcurrent {
  width: 20%;
  padding: 43px 24px;
  float: left;
  background: url(../img/devider.png) no-repeat right center;
  font-size: 18px;
  font-weight: 500;
}

#client-page .batcurrent1 {
  width: 15%;
  padding: 43px 24px;
  float: left;
  background: url(../img/devider.png) no-repeat right center;
  font-size: 18px;
  font-weight: 500;
}

#client-page .batcurrent2 {
  width: 17%;
  padding: 60px 5px;
  float: left;
  background: url(../img/devider.png) no-repeat right center;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
}

#client-page .batcurrent span,
.batcurrent1 span {
  display: block;
  font-weight: 600;
  color: #01a8ac;
  font-size: 22px;
  padding-top: 5px;
}

#client-page .detailsservice a.detailsdiv {
  margin: 47px 5px;
}

#client-page .tabheading h3 {
  float: left;
  padding: 0 0 20px;
}

#client-page .tabheading h3:hover {
  color: #007679;
  font-size: 25px;
}

#client-page .tabheading h4 {
  float: right;
  font-size: 22px;
  padding: 8px;
  color: #007679;
}

#client-page .reson-reject {
  text-align: center;
  padding: 0 20px 20px;
}

#client-page .reson-reject h5 {
  font-size: 22px;
  font-weight: 600;
  color: #007679;
  margin: 0 0 10px;
}

#client-page .modal-header {
  padding: 15px;
  border-bottom: none !important;
}

#client-page .modal-dialog {
  width: 1000px !important;
  margin: 80px auto;
}

#client-page .modal-dialog1 {
  width: 500px !important;
}

#client-page .modal-header .close {
  margin-top: -40px !important;
  margin-right: -105% !important;
  width: 30px !important;
  height: 30px !important;
  background: #fff !important;
  border-radius: 50% !important;
  opacity: 1 !important;
}

#client-page .modal-dialog .clearing {
  padding: 0px 10px;
  border: 1px solid #cfcfcf;
  margin-bottom: 15px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  text-align: left;
}

#client-page .modal-dialog .clearing li {
  list-style-type: none;
  font-size: 22px;
  color: #000;
  font-weight: 500;
  background: url(../img/devider.png) no-repeat right center;
  display: inline-block;
  min-height: 99px;
  vertical-align: top;
}

#client-page .modal-dialog .heading1 {
  padding: 36px 15px 0 10px;
  font-size: 24px !important;
  font-weight: bold;
}

#client-page .modal-dialog .clearing p {
  font-size: 14px;
  font-weight: 300;
  padding: 0;
}

#client-page .modal-dialog .cargoheading {
  padding: 36px 15px;
  font-size: 18px;
}

#client-page .modal-dialog .cargoheading2 {
  padding: 10px !important;
}

#client-page .payment-info h5 {
  font-size: 24px;
  color: #000 !important;
  padding: 0 0 30px;
  font-weight: 600;
}

/* Style the tab */
#client-page .tab {
  float: left;
  background-color: #f4f4f4;
  width: 15%;
  padding: 80px 0;
  margin-top: -30px;
  position: fixed;
  overflow: hidden;
  height: 100%;
}

/* Style the buttons inside the tab */
#client-page .tab button {
  display: block;
  background-color: inherit;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 23px;
  font-weight: 500;
  color: #000;
  padding: 15px 25px 15px 30px;
}

/* Change background color of buttons on hover */
#client-page .tab button:hover {
  background-color: #ddd;
}

/* Create an active/current "tab button" class */
#client-page .tab button.active {
  background-color: #fff;
}

/* Style the tab content */
#client-page .tabcontent {
  float: right;
  padding: 0px 30px !important;
  width: 85%;
  border-left: none;
  margin-top: -140px;
  padding: 0;
}

#client-page .dashlink button {
  display: block;
  font-size: 23px;
  font-weight: 500;
  color: #000;
}

#client-page .tab button:hover,
#client-page .tab button.active {
  background: #fff;
  color: #007679;
}

#client-page .tab h4 {
  padding: 5px 15px 5px 75px;
  font-size: 23px;
  font-weight: 500;
  color: #000;
}

#client-page .progressdiv1 {
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  margin-bottom: 15px;
}

#client-page .progressdiv1 .progressheading {
  width: 59%;
  background: url(../img/devider.png) no-repeat right center;
  padding: 36px 0 45px 70px;
}

#client-page .dayleft {
  width: 20%;
  float: left;
  text-align: center;
  background: url(../img/devider.png) no-repeat right center;
  padding: 30px;
  color: #01a8ac;
  font-size: 16px;
}

#client-page .progressdiv1 .progressheading span,
#client-page .progressdiv1 .progressheading-new span {
  position: absolute;
  left: 20px;
  top: 29px;
}

#client-page .progressdiv1 a.detailsdiv {
  margin: 29px 15px;
}

#client-page .nav-tabs-dropdown {
  display: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding-top: 120px;
}

#client-page .nav-tabs-dropdown:before {
  content: '\e114';
  font-family: 'Glyphicons Halflings';
  position: absolute;
  right: 30px;
}

@media screen and (min-width: 769px) {
  #client-page #nav-tabs-wrapper {
    display: block !important;
  }
}

@media screen and (max-width: 768px) {
  #client-page .nav-tabs-dropdown {
    display: block;
  }

  #client-page #nav-tabs-wrapper {
    display: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    text-align: center;
  }

  #client-page .nav-tabs-horizontal {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  }

  #client-page .nav-tabs-horizontal > li {
    float: none;
  }

  #client-page .nav-tabs-horizontal > li + li {
    margin-left: 2px;
  }

  #client-page .nav-tabs-horizontal > li,
  #client-page .nav-tabs-horizontal > li > a {
    background: transparent;
    width: 100%;
  }

  #client-page .nav-tabs-horizontal > li > a {
    border-radius: 4px;
  }

  #client-page .nav-tabs-horizontal > li.active > a,
  #client-page .nav-tabs-horizontal > li.active > a:hover,
  #client-page .nav-tabs-horizontal > li.active > a:focus {
    color: #ffffff;
    background-color: #428bca;
  }
}

#client-page .content {
  float: left;
  width: 80%;
  padding-left: 1rem;
}

#client-page .cormen input[type='checkbox'],
#client-page .cormen label {
  display: none;
}

#client-page .cormen {
  /*not really necessary, only used to position the menu on desktop, use your own preferred method */
  display: inline-block;
  width: 100%;
  float: left;
  z-index: 10;
}

#client-page .cormen input[type='checkbox'] {
  display: none;
}

#client-page .cormen ul {
  list-style-type: none;
  width: 100%;
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  #client-page .cormen {
    display: flex;
    flex-direction: column-reverse;
    position: fixed;
    /*styling only below, used to position the hamburger, 'width' will change depending on the 'left' value*/
    bottom: 10px;
    left: 10px;
    width: calc(100% - 58px);
    /*add a max width if you don't want it spanning the page*/
    max-width: 300px;
  }

  #client-page .cormen label {
    display: inline-block;
    width: 60px;
    height: 60px;
    position: relative;
    text-indent: 999px;
    font-size: 0;
    color: transparent;
    overflow: hidden;
    border-radius: 50%;
    background-color: #02a6aa;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  #client-page .cormen label::before {
    content: url('../img/list.svg');
    display: inline-block;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0;
    left: 0;
    text-indent: 0;
    overflow: visible;
    font-size: 22px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    line-height: 60px;
    color: #fff;
    font-size: 20px;
  }

  #client-page .cormen ul {
    list-style-type: none;
    width: 100%;
    margin: 0;
    /*styling only, below*/
    padding: 1rem 1rem 0 1rem;
    background-color: #eee;
    border-radius: 0.25rem;
    margin-bottom: 0.25rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  #client-page .cormen ul {
    display: none;
    min-width: 100%;
    border-radius: 1rem;
  }

  #client-page div.cormen input[type='checkbox']:checked + ul {
    display: block;
    animation-name: fadein;
    animation-duration: 350ms;
  }

  #client-page .tab button {
    padding: 10px 15px;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

#client-page .operator-info {
  padding: 20px 50px 50px 50px;
  border: 1px solid #cfcfcf;
  text-align: left;
  border-radius: 14px;
}

#client-page .disputes-block {
  margin-bottom: 60px;
}

#client-page .operator-info h5 {
  font-size: 30px;
  color: #000;
  padding: 0 0 10px;
  font-weight: 600;
}

#client-page .operator-info h6 {
  font-size: 22px;
  color: #000;
  padding: 0 0 20px;
  font-weight: 600;
}

#client-page .operator-info h4 {
  font-size: 18px;
  color: #007679;
  padding: 0 0 10px;
  font-weight: 600;
}

#client-page .buttonsec1 {
  padding: 15px 0 15px;
}

#client-page a.approvebut {
  padding: 10px 25px;
  min-width: 200px;
  border-radius: 35px;
  border: none;
  background: #007679;
  color: #fff;
  font-size: 16px;
  margin: 0 8px 8px 0;
  display: inline-block;
  text-align: center;
}

#client-page a.rejectbut {
  padding: 10px 25px;
  min-width: 200px;
  border-radius: 35px;
  border: none;
  background: #01a8ac;
  color: #fff;
  font-size: 16px;
  margin: 0 8px 8px 0;
  display: inline-block;
  text-align: center;
}

#client-page .disputes-img {
  float: left;
  margin-right: 10px;
}

#client-page a.rejectbut {
  padding: 10px 25px;
  min-width: 200px;
  border-radius: 35px;
  border: none;
  background: #01a8ac;
  color: #fff;
  font-size: 16px;
  margin: 0 8px 8px 0;
  display: inline-block;
  text-align: center;
}

#client-page .chat {
  position: fixed;
  bottom: 20px;
  right: -100px;
  z-index: 9999;
}

/*--------------------------------------------------------------
# Clearing Agent Page
--------------------------------------------------------------*/
@media (max-width: 1140px) {
  #clearing-agent-page .navbar a {
    padding: 10px 20px;
  }

  #clearing-agent-page .services h2,
  #clearing-agent-page .our-values h2 {
    font-size: 36px;
    margin: 20px 0;
  }

  #clearing-agent-page .services .services-block {
    padding: 0;
  }

  #clearing-agent-page .services h3,
  .our-values h3 {
    font-size: 28px;
    margin: 20px 0;
  }

  #clearing-agent-page .services p {
    font-size: 20px;
    line-height: 38px;
    color: #000;
    margin-bottom: 50px;
  }

  #clearing-agent-page .post-button {
    margin: 30px auto;
  }

  #clearing-agent-page .our-values p {
    line-height: 28px;
  }

  #clearing-agent-page .valuable-clients .valuable-clients-block {
    padding: 70px 50px 30px;
  }

  #clearing-agent-page .valuable-clients h2 {
    font-size: 36px;
  }

  #clearing-agent-page .testimonial .carousel-control {
    top: 220px !important;
    width: 90% !important;
  }

  #clearing-agent-page .send-massage .services-block1 h5 {
    font-size: 26px;
  }

  #clearing-agent-page .send-massage .services-block1 p {
    font-size: 18px;
    line-height: 20px;
    color: #000;
    margin-bottom: 0;
  }

  #clearing-agent-page .send-massage .services-block1 {
    padding: 20px;
  }

  #clearing-agent-page .send-massage .post-button {
    line-height: 26px;
    padding: 10px 25px;
    margin: 20px auto 0;
  }

  #clearing-agent-page .footer .call {
    font-size: 14px;
  }
}

@media (max-width: 980px) {
  #clearing-agent-page .hero h1 {
    font-size: 32px;
    text-align: left;
  }

  #clearing-agent-page .hero p {
    font-size: 18px;
    line-height: 20px;
    color: #fff;
    width: 80%;
    text-align: left;
  }

  #clearing-agent-page .services p {
    font-size: 16px;
    line-height: 20px;
    color: #000;
    margin-bottom: 20px;
  }

  #clearing-agent-page .services h3,
  #clearing-agent-page .our-values h3 {
    font-size: 24px;
    margin: 20px 0 0;
  }

  #clearing-agent-page .our-values p {
    font-size: 16px;
    line-height: 20px;
    color: #fff;
  }

  #clearing-agent-page .valuable-clients .valuable-clients-block {
    padding: 20px;
  }

  #clearing-agent-page .valuable-clients .valuable-clients-block .logo img {
    width: 100%;
  }

  #clearing-agent-page .send-massage .services-block1 input {
    font-size: 15px;
    color: #797979;
    padding: 10px 25px;
    border-radius: 30px;
    border: 1px solid #ccc;
    width: 100%;
    margin-bottom: 10px;
  }

  #clearing-agent-page .footer i {
    font-size: 24px;
    color: #fff;
    margin-right: 10px;
  }

  #clearing-agent-page .footer .call {
    font-size: 11px;
  }

  #clearing-agent-page .mobile-nav-toggle {
    color: #fff;
    position: absolute;
    right: 0;
  }

  #clearing-agent-page .valuable-clients .logo {
    width: 50%;
  }

  #clearing-agent-page .valuable-clients h2,
  #clearing-agent-page .services h2,
  #clearing-agent-page .our-values h2 {
    font-size: 26px;
  }

  #clearing-agent-page .services .services-block1,
  #clearing-agent-page .services .services-block2 {
    border: none;
    width: 100%;
  }
}

@media (max-width: 767px) {
  #clearing-agent-page .header {
    padding: 20px 0;
    height: 100px;
  }

  #clearing-agent-page #header logo {
    width: 150px;
  }

  #clearing-agent-page .navbar {
    margin-bottom: 0;
  }

  #clearing-agent-page .navbar {
    position: relative;
    min-height: 50px;
    margin-bottom: 0;
    width: 100%;
  }

  #clearing-agent-page .hero {
    height: auto;
    padding: 60px 0 20px 0;
  }

  #clearing-agent-page .hero h1 {
    font-size: 20px;
    margin: 0;
  }

  #clearing-agent-page .hero p {
    font-size: 14px;
  }

  #clearing-agent-page .services h2,
  #clearing-agent-page .our-values h2 {
    font-size: 20px;
    margin: 20px 0;
  }

  #clearing-agent-page .padding-left1 {
    padding-right: 15px;
  }

  #clearing-agent-page .padding-right1 {
    padding-right: 15px;
  }

  #clearing-agent-page .post-button {
    width: 300px;
  }

  #clearing-agent-page .valuable-clients h2 {
    font-size: 20px;
  }

  #clearing-agent-page .valuable-clients h2 img {
    display: none;
  }

  #clearing-agent-page section {
    padding: 0;
  }

  #clearing-agent-page #quote-carousel .carousel-indicators {
    position: relative;
    left: 50%;
  }

  #clearing-agent-page #quote-carousel .carousel-indicators .active {
    width: 128px;
    height: 78px;
  }

  #clearing-agent-page .testimonial h6:before {
    display: none;
  }

  #clearing-agent-page .testimonial .carousel-control {
    top: inherit !important;
    width: inherit !important;
  }

  #clearing-agent-page .testimonial {
    padding: 0;
  }

  #clearing-agent-page .testimonial h6 {
    color: #000;
    font-size: 26px;
  }

  #clearing-agent-page .footer .call {
    font-size: 18px;
  }

  #clearing-agent-page section.faq {
    padding: 30px 0;
  }

  #clearing-agent-page .valuable-clients .logo {
    float: left;
    width: 50%;
  }

  #clearing-agent-page .padding-left1 {
    padding-left: 15px;
  }

  #clearing-agent-page .our-values-main-block div {
    padding: 5px 15px !important;
    border-radius: 15px;
  }

  #clearing-agent-page .header .logo img {
    height: 30px;
  }

  #clearing-agent-page .header.header-scrolled {
    padding: 0;
    height: 60px;
  }

  #clearing-agent-page .valuable-clients {
    padding-top: 20px;
  }

  #clearing-agent-page .about-goagent,
  #clearing-agent-page .clering-goagent {
    margin-top: 40px;
  }

  #clearing-agent-page .about-goagent h2,
  #clearing-agent-page .clering-goagent h2,
  #clearing-agent-page .payment-policy h2 {
    font-size: 28px;
  }

  #clearing-agent-page .services h3,
  #clearing-agent-page .our-values h3,
  #clearing-agent-page .inner-about h3,
  #clearing-agent-page .our-values h4,
  #clearing-agent-page .about-goagent h3 {
    margin: 20px 0 10px;
  }

  #clearing-agent-page p {
    font-size: 16px !important;
    line-height: 20px !important;
  }

  #clearing-agent-page .about-goagent .about-goagent-block,
  #clearing-agent-page .clering-goagent .clering-goagent-block {
    margin-top: 0;
  }

  #clearing-agent-page .inner-about .services-block1 {
    border: none;
  }

  #clearing-agent-page .inner-about-block .padding-left1 {
    padding-left: 0;
  }

  #clearing-agent-page .inner-about-block .padding-right1 {
    padding-right: 0;
  }

  #clearing-agent-page .inner-about-block .services .services-block,
  #clearing-agent-page .inner-about-block .services .services-block1,
  .inner-about-block .inner-about .services-block {
    padding: 0;
  }

  #clearing-agent-page .inner-about-block .inner-about .services-block,
  #clearing-agent-page .inner-about-block .inner-about .services-block1,
  #clearing-agent-page .inner-about-block .inner-about .services-block {
    padding: 0 !important;
  }

  #clearing-agent-page .inner-about-block .inner-about .mission {
    padding-right: 0 !important;
  }

  #clearing-agent-page .inner-about-block .inner-about .vision {
    padding-left: 0 !important;
  }

  #clearing-agent-page .inner-about-block .inner-about .vision .services-block {
    padding-left: 15px !important;
  }

  #clearing-agent-page .faq .send-cv {
    font-size: 16px;
  }

  #clearing-agent-page .clering-goagent .post-button {
    padding: 10px 10px;
    font-size: 18px;
    width: auto;
  }

  #clearing-agent-page .post-button,
  #clearing-agent-page .send-massage .post-button {
    padding: 7px 20px;
    font-size: 18px;
  }

  #clearing-agent-page .navbar li {
    position: relative;
    padding: 10px;
  }

  #clearing-agent-page .header.header-scrolled .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  #clearing-agent-page .header.header-scrolled .navbar ul {
    padding-left: 0 !important;
  }

  #clearing-agent-page .cargoheading1 {
    width: 150px !important;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  #clearing-agent-page .hero {
    height: auto;
    padding: 180px 0 60px 0;
  }

  #clearing-agent-page #quote-carousel .carousel-indicators .active {
    width: 128px;
    height: 128px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  #clearing-agent-page #quote-carousel .carousel-indicators .active {
    width: 128px;
    height: 78px;
  }
}

/*--------------------------------------------------------------
  # CLIENT SECTION
  --------------------------------------------------------------*/
#clearing-agent-page .bodycont {
  padding: 130px 0 0 0;
}

#clearing-agent-page .modal .bodycont {
  padding: 0;
}

#clearing-agent-page .bodycont h2 {
  text-align: center;
  font-size: 30px;
  color: #007679;
  font-weight: 600;
  padding: 0 0 10px 0;
  margin: 0px !important;
}

#clearing-agent-page .bodycont h3 {
  text-align: center;
  font-size: 20px;
  color: #007679;
  font-weight: 600;
  padding: 0 0 10px 0;
  margin: 0px !important;
}

#clearing-agent-page .personal-info {
  padding: 20px;
  border: 3px solid #eee;
  text-align: center;
  margin: 0 100px;
}

#clearing-agent-page .form-control {
  padding: 10px 25px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: none;
  margin-bottom: 0;
}

#clearing-agent-page .personal-info .form-control {
  margin-bottom: 10px;
}

#clearing-agent-page .buttonsec {
  padding: 15px 0 15px;
  text-align: center;
}

#clearing-agent-page .submitbut {
  padding: 15px 45px;
  min-width: 250px;
  border-radius: 35px;
  border: none;
  background: #007679;
  color: #fff;
  font-size: 16px;
  margin: 0 8px 8px 0;
  transition: all 0.2s ease-in-out 0s;
}

#clearing-agent-page .submitbut:hover {
  background: #01a8af;
  color: #fff;
}

#clearing-agent-page .submitbut:disabled {
  background: #ccc;
  cursor: not-allowed;
  border: none;
}

#clearing-agent-page a.submitbut,
#clearing-agent-page a.backbut {
  padding: 15px 45px;
  min-width: 250px;
  border-radius: 35px;
  border: none;
  background: #007679;
  color: #fff;
  font-size: 16px;
  margin: 0 8px 8px 0;
  display: inline-block;
  text-align: center;
}

#clearing-agent-page a.backbut {
  background: #01a8af;
}

#clearing-agent-page a:hover.backbut,
#clearing-agent-page a:focus.backbut {
  background: #007679;
  color: #fff;
}

#clearing-agent-page a:hover.submitbut,
#clearing-agent-page a:focus.submitbut,
#clearing-agent-page a:focus.detailsdiv,
#clearing-agent-page a:focus.review {
  background: #01a8af;
  color: #fff;
}

#clearing-agent-page .profilesec {
  padding: 0;
  font-size: 28px;
  font-weight: 500;
  /* margin-top: -145px; */
}

#clearing-agent-page .profileimage img {
  border-radius: 100%;
  border: 8px solid #eee;
  width: 100%;
}

#clearing-agent-page .profileimage {
  width: 163px;
  margin: 0 auto 20px;
  position: relative;
}

#clearing-agent-page .profileimage span a {
  position: absolute;
  right: 0;
  top: 65%;
  background: #007679;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  font-size: 13px;
  line-height: 35px;
  color: #fff;
  display: block;
}

#clearing-agent-page .profilecont {
  display: flex;
}

#clearing-agent-page .profilecontleft,
#clearing-agent-page .profilecontright {
  width: 50%;
  text-align: left;
  padding: 20px 40px;
  font-size: 17px;
  line-height: 22px;
}

#clearing-agent-page .profilecontright {
  border-left: 1px solid #e3e3e3;
}

#clearing-agent-page .loginright .dropdown-menu {
  left: inherit !important;
  right: 0;
  margin-top: 8px;
  font-size: 12px;
}

#clearing-agent-page .payment-info {
  padding: 10px 60px;
  text-align: center;
}

#clearing-agent-page .payment-details-block,
#clearing-agent-page .payment-details-block1 {
  width: 50%;
  padding: 0 25px;
  float: left;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #ccc;
}

#clearing-agent-page .payment-details-block {
  border-right: 1px solid #ccc;
  font-size: 20px;
}

#clearing-agent-page .finish {
  padding: 15% 0;
  font-size: 22px;
  line-height: 28px;
  font-weight: 500;
}

#clearing-agent-page .finish h3 {
  padding: 0 0 10px;
}

#clearing-agent-page .paymentstep {
  padding: 25px 0 45px;
  text-align: center;
}

#clearing-agent-page .modal .paymentstep {
  padding: 25px 0;
  text-align: center;
}

#clearing-agent-page .paymentstep li {
  display: inline-block;
  padding: 0 7%;
  font-size: 18px;
  font-weight: 500;
  background: url(../img/dotted.png) repeat-x 0 28px;
  width: 28%;
  vertical-align: top;
}

#clearing-agent-page .paymentstep li span {
  width: 55px;
  height: 55px;
  display: block;
  margin: 0 auto 8px;
  background: #282828;
  color: #fff;
  border-radius: 100%;
  border: 9px solid #f5f5f5;
  font-weight: 600;
  line-height: 38px;
}

#clearing-agent-page .paymentstep li.active {
  color: #01a8ac;
}

#clearing-agent-page .paymentstep li.active span {
  background: #01a8ac;
}

#clearing-agent-page .paymentstep li:first-child {
  background-position: 100% 28px;
  background-repeat: no-repeat;
}

#clearing-agent-page .paymentstep li:last-child {
  background-position: -6% 28px;
  background-repeat: no-repeat;
}

#clearing-agent-page .paymentstep li p {
  padding: 0;
  background: #fff;
}

#clearing-agent-page .custom02 input[type='radio'] {
  display: none;
}

#clearing-agent-page .custom02 label {
  position: relative;
  padding: 9px 3px 3px 28px;
  cursor: pointer;
  margin-left: 16px;
  text-align: left;
  float: left;
}

#clearing-agent-page .custom02 label::before,
#clearing-agent-page .custom02 label::after {
  position: absolute;
  content: '';
  top: 50%;
  border-radius: 100%;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

#clearing-agent-page .custom02 label::before {
  left: 0;
  width: 18px;
  height: 18px;
  margin-top: -8px;
  background: #fff;
  border: 1px solid #ccc;
}

#clearing-agent-page .custom02 label:hover::before {
  background: #fff;
}

#clearing-agent-page .custom02 label::after {
  opacity: 0;
  left: 4px;
  width: 10px;
  height: 10px;
  margin-top: -4px;
  background: #4ad300;
  -webkit-transform: scale(2);
  transform: scale(2);
}

#clearing-agent-page {
  background: #fff;
}

#clearing-agent-page .custom02 input[type='radio']:checked + label::after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

#clearing-agent-page .payment-method {
  background: #f8f8f8;
  padding: 12px 18px 12px;
  border-radius: 47px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

#clearing-agent-page .payment-method2 {
  background: #f8f8f8;
  padding: 18px 18px 12px;
  border-radius: 47px;
  border: 1px solid #ccc;
  margin-top: 10px;
}

#clearing-agent-page .form-control {
  padding: 10px 25px !important;
  height: 60px !important;
  border-radius: 5px !important;
  border: 1px solid #ccc !important;
  box-shadow: none !important;
  margin-bottom: 10 !important;
}

#clearing-agent-page .progressdiv1 {
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  margin-bottom: 15px;
}

#clearing-agent-page .checkcontainer {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 17px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 500;
}

/* Hide the browser's default checkbox */
#clearing-agent-page .checkcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
#clearing-agent-page .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
#clearing-agent-page .checkcontainer:hover input ~ .checkmark {
  background-color: #f8f8f8;
}

/* When the checkbox is checked, add a blue background */
#clearing-agent-page .checkcontainer input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
#clearing-agent-page .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
#clearing-agent-page .checkcontainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
#clearing-agent-page .checkcontainer .checkmark:after {
  left: 6px;
  top: 3px;
  width: 7px;
  height: 12px;
  border: solid #4ad300;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

#clearing-agent-page .payment-method2 img {
  float: right;
  width: 120px;
  margin: 0 10px;
}

#clearing-agent-page .cardicon {
  float: right;
}

#clearing-agent-page .cardicon img {
  width: 40px;
  border-radius: 100%;
  margin: 0 10px;
}

#clearing-agent-page .paymenttable .table > tbody > tr > td {
  border-top: none;
  padding: 8px 25px;
}

#clearing-agent-page .paymenttable td:first-child {
  font-size: 20px;
  font-weight: 500;
  border-right: 1px solid #ccc;
  width: 50%;
}

#clearing-agent-page .paymenttable {
  text-align: left;
}

#clearing-agent-page .price {
  font-size: 20px;
  font-weight: 600;
  color: #01a8ac;
}

#clearing-agent-page #nonResponsiveTabs {
  border: none;
}

#clearing-agent-page #nonResponsiveTabs li.active > a {
  border: none;
}

#clearing-agent-page #nonResponsiveTabs li {
  width: 31%;
  margin: 0 1%;
}

#clearing-agent-page #nonResponsiveTabs > li > a {
  border-radius: 45px;
  background: #d1d1d1;
  color: #000;
  margin: 0;
  border: none;
  padding: 15px 10px;
}

#clearing-agent-page #nonResponsiveTabs > li > a:hover {
  border: none;
}

#nonResponsiveTabs li.active a {
  background: #01a8ac;
  color: #fff;
}

#clearing-agent-page .resistertab .tab-content {
  padding: 16px 0 0 0;
}

#clearing-agent-page .resistertab .checkcontainer {
  margin: 25px 0;
}

#clearing-agent-page .resistertab .panel-heading {
  padding: 15px;
}

#clearing-agent-page .logindiv {
  padding: 90px;
  box-shadow: 0 0 5px #ddd;
  width: 675px;
  margin: 0 auto;
}

#clearing-agent-page .logindiv .submitbut {
  width: 100%;
}

#clearing-agent-page .logindiv a {
  text-decoration: underline;
}

#clearing-agent-page .logindiv a:hover {
  text-decoration: none;
}

#clearing-agent-page .logindiv p {
  padding: 0 0 30px;
}

#clearing-agent-page .logindiv h3 {
  padding: 25px 0;
  color: #999;
  font-size: 24px;
}

#clearing-agent-page .keepcheck {
  padding: 0 0 25px;
}

#clearing-agent-page .keepcheck a {
  float: right;
  text-decoration: none;
}

#clearing-agent-page .dashcont {
  display: flex;
}

#clearing-agent-page .dashlink {
  width: 475px;
  background: #f4f4f4;
  padding: 85px 0;
}

#clearing-agent-page .dashrightcont {
  width: 100%;
  padding: 80px 50px 50px;
}

#clearing-agent-page .dashrightcont .bodycont {
  padding: 0;
}

#clearing-agent-page .dashlink ul {
  padding-left: 0 !important;
}

#clearing-agent-page .dashlink ul li {
  margin-bottom: 3px;
  list-style-type: none;
}

#clearing-agent-page .dashlink ul li a {
  padding: 25px 15px 25px 75px;
  display: block;
  font-size: 23px;
  font-weight: 500;
  color: #000;
}

#clearing-agent-page .dashlink ul li a:hover,
#clearing-agent-page .dashlink ul li.active a {
  background: #fff;
  color: #007679;
}

#clearing-agent-page .dashlink ul li:last-child a {
  margin-top: 70px;
  color: #df0000;
}

#clearing-agent-page .dashlink h4 {
  padding: 25px 15px 45px 75px;
  font-size: 23px;
  font-weight: 500;
  color: #000;
}

#clearing-agent-page .servicecheck {
  padding: 0 0 35px;
}

#clearing-agent-page .servicecheck label {
  margin: 0 20px;
}

#clearing-agent-page .serviceradio label {
  float: none;
  font-weight: normal;
}

#clearing-agent-page .serviceradio {
  padding: 15px 0 25px;
}

#clearing-agent-page .payment-info h5 {
  font-size: 24px;
  color: #000 !important;
  padding: 0 0 30px;
  font-weight: 600;
}

#clearing-agent-page .attached {
  padding: 0 0 25px;
  text-align: left;
  color: #878787;
  font-size: 18px;
}

#clearing-agent-page select.form-control {
  background: url(../img/arrow1.png) no-repeat 95% 26px #f8f8f8;
}

#clearing-agent-page .uploaddiv {
  padding: 18px 80px 18px 25px;
  border: 1px solid #ccc;
  border-radius: 55px;
  font-size: 16px;
  color: #000;
  margin-bottom: 8px;
  position: relative;
}

#clearing-agent-page .upload-btn-wrapper {
  position: absolute;
  overflow: hidden;
  display: inline-block;
  float: right;
  top: 3px;
  right: 6px;
}

#clearing-agent-page .btn {
  border: 2px solid #007679;
  color: gray;
  background-color: #007679;
  padding: 10px 15px;
  border-radius: 38px;
  font-size: 20px;
  font-weight: bold;
}

#clearing-agent-page .upload-btn-wrapper input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

#clearing-agent-page .servicetab .nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  align-items: center !important;
  justify-content: center !important;
}

#clearing-agent-page .servicetab .nav > li > a {
  padding: 17px 15px;
  color: #1d1d1d;
  font-size: 20px;
  margin: 0;
  border: none;
}

#clearing-agent-page .servicetab .nav-tabs > li {
  width: 33.33%;
  text-align: center;
  margin-left: 0;
  margin-right: 0;
  position: relative;
  z-index: 9;
}

#clearing-agent-page .servicetab .nav-tabs > li.active a {
  color: #007679;
  border: none;
  box-shadow: 0px -4px 5px #ddd;
}

#clearing-agent-page .servicetab .nav-tabs {
  border-bottom: none;
}

#clearing-agent-page .servicetab .nav > li > a:hover {
  background: none;
  color: #007679;
  border: none;
}

#clearing-agent-page .servicetab .tab-content {
  box-shadow: 0 0 5px #ccc;
  padding: 25px 40px;
  text-align: center;
}

#clearing-agent-page .servicetab h3 {
  color: #000;
}

#clearing-agent-page .progressdiv {
  padding: 15px 20px;
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  margin-bottom: 15px;
}

#clearing-agent-page .progressheading {
  position: relative;
  float: left;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 0 0 50px;
}

#clearing-agent-page .progressheading span {
  position: absolute;
  left: 0;
  top: 4px;
  background: #01a8ac;
  width: 35px;
  height: 35px;
  color: #fff;
  font-size: 14px;
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
}

#clearing-agent-page a.detailsdiv {
  float: right;
  background: #007679;
  padding: 10px 20px;
  color: #fff;
  border-radius: 30px;
  font-size: 16px;
  min-width: 150px;
  text-align: center;
}

#clearing-agent-page a:hover.detailsdiv {
  background: #01a8af;
  color: #fff;
  text-decoration: none;
}

#clearing-agent-page .servicetabout {
  text-align: center;
}

#clearing-agent-page .servicetabout #nonResponsiveTabs li {
  float: none;
  display: inline-block;
}

#clearing-agent-page .margin-right {
  margin-right: 5px;
}

#clearing-agent-page .servicetabout #nonResponsiveTabs {
  border: none;
  padding-bottom: 45px;
}

#clearing-agent-page .servicetabout .tab-content {
  padding: 0;
  box-shadow: none;
  text-align: left;
}

#clearing-agent-page .servicetabout #nonResponsiveTabs li.active::after {
  content: '';
  background: url(../img/arrow.png) no-repeat 0 0;
  width: 19px;
  height: 13px;
  display: block;
  left: 48%;
  position: absolute;
}

#clearing-agent-page .clearing {
  padding: 0px 10px;
  border: 1px solid #cfcfcf;
  margin-bottom: 15px;
  border-radius: 8px;
  /*justify-content: center;*/
  /* align horizontal */
  align-items: center;
  text-align: left;
}

#clearing-agent-page .clearing li {
  list-style-type: none;
  font-size: 22px;
  color: #000;
  font-weight: 500;
  background: url(../img/devider.png) no-repeat right center;
  display: inline-block;
  min-height: 99px;
  vertical-align: top;
}

#clearing-agent-page .heading1 {
  padding: 36px 15px 0 10px;
  font-size: 20px;
  font-weight: 700;
}

#clearing-agent-page .heading {
  float: left;
  padding: 22px 15px 0 15px;
  line-height: 25px;
}

#clearing-agent-page .cargoheading1,
#clearing-agent-page .cargoheading2 {
  padding: 16px 15px;
  font-size: 20px;
  font-weight: 700;
}

#clearing-agent-page .clearing p {
  font-size: 14px;
  font-weight: 300;
  padding: 0;
}

#clearing-agent-page .batch {
  float: left;
}

#clearing-agent-page .heading span {
  color: #8e8e8e;
  font-size: 18px;
}

#clearing-agent-page .cargoheading {
  padding: 36px 44px;
}

#clearing-agent-page .cargoprice {
  color: #01a8ac;
}

#clearing-agent-page .clearing li:last-child {
  background: none;
}

#clearing-agent-page .starsec {
  padding: 30px 0 0 37px;
}

#clearing-agent-page .starsec a .fa,
#clearing-agent-page .starsec1 a .fa {
  color: #ffc107;
  padding: 0 2px;
}

#clearing-agent-page .starsec1 {
  padding: 30px 0 0 5px;
}

#clearing-agent-page a.review {
  padding: 10px 31px;
  background: #007679;
  font-size: 15px;
  margin: 0 15px;
  font-weight: 500;
  border-radius: 18px;
  color: #fff;
}

#clearing-agent-page a:hover.review {
  background: #01a8af;
  color: #fff;
}

#clearing-agent-page .progressdiv1 {
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  margin-bottom: 15px;
}

#clearing-agent-page .progressdiv1 .progressheading-new {
  width: 100%;
  padding: 26px 70px 10px 70px;
  font-size: 20px;
  font-weight: 600;
  position: relative;
}

#clearing-agent-page .progressdiv1 .progressheading-new p {
  font-size: 18px;
  font-weight: 300;
}

#clearing-agent-page .progressdiv1 .progressheading {
  width: 59%;
  background: url(../img/devider.png) no-repeat right center;
  padding: 36px 0 45px 70px;
}

#clearing-agent-page .progressheading span,
#clearing-agent-page .progressheading-new span {
  position: absolute;
  left: 0;
  top: 4px;
  background: #01a8ac;
  width: 35px;
  height: 35px;
  color: #fff;
  font-size: 14px;
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
}

#clearing-agent-page .progressdiv1 .progressheading span,
#clearing-agent-page .progressdiv1 .progressheading-new span {
  position: absolute;
  left: 20px;
  top: 29px;
}

#clearing-agent-page .dayleft {
  width: 20%;
  float: left;
  text-align: center;
  background: url(../img/devider.png) no-repeat right center;
  padding: 30px;
  color: #01a8ac;
  font-size: 16px;
}

#clearing-agent-page .progressdiv1 .progressheading span {
  position: absolute;
  left: 20px;
  top: 29px;
}

#clearing-agent-page .progressdiv1 a.detailsdiv {
  margin: 29px 15px;
}

#clearing-agent-page .detailsservice {
  border: 1px solid #cfcfcf;
  margin-bottom: 15px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  text-align: left;
}

#clearing-agent-page .batleft img {
  margin-bottom: 5px;
}

#clearing-agent-page .batleft {
  width: 15%;
  padding: 10px;
  float: left;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  background: url(../img/devider.png) no-repeat right center;
}

#clearing-agent-page .battext {
  float: left;
  width: 25%;
  text-align: center;
  padding: 60px 25px;
  min-height: 143px;
  font-size: 20px;
  font-weight: 600;
  background: url(../img/devider.png) no-repeat right center;
}

#clearing-agent-page .battext1 {
  float: left;
  width: 15%;
  text-align: center;
  padding: 43px 25px;
  min-height: 143px;
  font-size: 20px;
  font-weight: 600;
  background: url(../img/devider.png) no-repeat right center;
}

#clearing-agent-page .batreview {
  width: 20%;
  padding: 30px 15px;
  float: left;
  text-align: center;
  background: url(../img/devider.png) no-repeat right center;
}

#clearing-agent-page .batreview .fa {
  font-size: 22px;
  margin: 1px;
  color: #ffc107;
}

#clearing-agent-page .batreview a.review {
  display: inline-block;
  margin-top: 9px;
  padding: 10px 25px;
  color: #007679;
  border: 1px solid #007679;
  background: #fff;
}

#clearing-agent-page .batcurrent {
  width: 20%;
  padding: 43px 24px;
  float: left;
  background: url(../img/devider.png) no-repeat right center;
  font-size: 18px;
  font-weight: 500;
}

#clearing-agent-page .batcurrent1 {
  width: 15%;
  padding: 43px 24px;
  float: left;
  background: url(../img/devider.png) no-repeat right center;
  font-size: 18px;
  font-weight: 500;
}

#clearing-agent-page .batcurrent2 {
  width: 17%;
  padding: 60px 5px;
  float: left;
  background: url(../img/devider.png) no-repeat right center;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
}

#clearing-agent-page .batcurrent span,
#clearing-agent-page .batcurrent1 span {
  display: block;
  font-weight: 600;
  color: #01a8ac;
  font-size: 22px;
  padding-top: 5px;
}

#clearing-agent-page .detailsservice a.detailsdiv {
  margin: 47px 18px;
}

#clearing-agent-page .tabheading h3 {
  float: left;
  padding: 0 0 20px;
}

#clearing-agent-page .tabheading h4 {
  float: right;
  font-size: 22px;
  padding: 8px;
  color: #007679;
}

#clearing-agent-page .reson-reject {
  text-align: center;
  padding: 0 20px 20px;
}

#clearing-agent-page .reson-reject h5 {
  font-size: 22px;
  font-weight: 600;
  color: #007679;
  margin: 0 0 10px;
}

#clearing-agent-page .modal-header {
  padding: 15px;
  border-bottom: none !important;
}

#clearing-agent-page .modal-dialog {
  width: 1000px !important;
  margin: 80px auto;
}

#clearing-agent-page .modal-dialog1 {
  width: 500px !important;
}

#clearing-agent-page .modal-header .close {
  margin-top: -40px !important;
  margin-right: -105% !important;
  width: 30px !important;
  height: 30px !important;
  background: #fff !important;
  border-radius: 50% !important;
  opacity: 1 !important;
}

#clearing-agent-page .modal-dialog .clearing {
  padding: 0px 10px;
  border: 1px solid #cfcfcf;
  margin-bottom: 15px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  text-align: left;
}

#clearing-agent-page .modal-dialog .clearing li {
  list-style-type: none;
  font-size: 22px;
  color: #000;
  font-weight: 500;
  background: url(../img/devider.png) no-repeat right center;
  display: inline-block;
  min-height: 99px;
  vertical-align: top;
}

#clearing-agent-page .modal-dialog .heading1 {
  padding: 36px 15px 0 10px;
  font-size: 24px !important;
  font-weight: bold;
}

#clearing-agent-page .modal-dialog .clearing p {
  font-size: 14px;
  font-weight: 300;
  padding: 0;
}

#clearing-agent-page .modal-dialog .cargoheading {
  padding: 36px 15px;
  font-size: 18px;
}

#clearing-agent-page .modal-dialog .cargoheading2 {
  padding: 10px !important;
}

#clearing-agent-page .payment-info h5 {
  font-size: 24px;
  color: #000 !important;
  padding: 0 0 30px;
  font-weight: 600;
}

/* Style the tab */
#clearing-agent-page .tab {
  float: left;
  background-color: #f4f4f4;
  width: 15%;
  padding: 80px 0;
  margin-top: -30px;
  position: fixed;
  height: 100%;
}

/* Style the buttons inside the tab */
#clearing-agent-page .tab button {
  display: block;
  background-color: inherit;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 23px;
  font-weight: 500;
  color: #000;
  padding: 15px 25px 15px 30px;
}

/* Change background color of buttons on hover */
#clearing-agent-page .tab button:hover {
  background-color: #ddd;
}

#clearing-agent-page #myModal2 {
  background-color: rgba(0, 0, 0, 0.7);
}

/* Create an active/current "tab button" class */
#clearing-agent-page .tab button.active {
  background-color: #fff;
}

/* Style the tab content */
#clearing-agent-page .tabcontent {
  float: right;
  padding: 0px 30px !important;
  width: 85%;
  border-left: none;
  margin-top: -140px;
  padding: 0;
}

#clearing-agent-page .modal .tabcontent {
  float: left;
  padding: 0px 30px !important;
  width: 74%;
  border-left: none;
  margin-top: -140px;
  padding: 0;
}

#clearing-agent-page .dashlink button {
  display: block;
  font-size: 23px;
  font-weight: 500;
  color: #000;
}

#clearing-agent-page .tab button:hover,
#clearing-agent-page .tab button.active {
  background: #fff;
  color: #007679;
}

#clearing-agent-page .tab h4 {
  padding: 25px 15px 45px 75px;
  font-size: 23px;
  font-weight: 500;
  color: #000;
}

#clearing-agent-page .progressdiv1 {
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  margin-bottom: 15px;
}

#clearing-agent-page .progressdiv1 .progressheading {
  width: 59%;
  background: url(../img/devider.png) no-repeat right center;
  padding: 36px 0 45px 70px;
}

#clearing-agent-page .dayleft {
  width: 20%;
  float: left;
  text-align: center;
  background: url(../img/devider.png) no-repeat right center;
  padding: 30px;
  color: #01a8ac;
  font-size: 16px;
}

#clearing-agent-page .progressdiv1 .progressheading span,
#clearing-agent-page .progressdiv1 .progressheading-new span {
  position: absolute;
  left: 20px;
  top: 29px;
}

#clearing-agent-page .progressdiv1 a.detailsdiv {
  margin: 29px 15px;
}

#clearing-agent-page .nav-tabs-dropdown {
  display: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding-top: 120px;
}

#clearing-agent-page .nav-tabs-dropdown:before {
  content: '\e114';
  font-family: 'Glyphicons Halflings';
  position: absolute;
  right: 30px;
}

@media screen and (min-width: 769px) {
  #clearing-agent-page #nav-tabs-wrapper {
    display: block !important;
  }
}

@media screen and (max-width: 768px) {
  #clearing-agent-page .nav-tabs-dropdown {
    display: block;
  }

  #clearing-agent-page #nav-tabs-wrapper {
    display: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    text-align: center;
  }

  #clearing-agent-page .nav-tabs-horizontal {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  }

  #clearing-agent-page .nav-tabs-horizontal > li {
    float: none;
  }

  #clearing-agent-page .nav-tabs-horizontal > li + li {
    margin-left: 2px;
  }

  #clearing-agent-page .nav-tabs-horizontal > li,
  #clearing-agent-page .nav-tabs-horizontal > li > a {
    background: transparent;
    width: 100%;
  }

  #clearing-agent-page .nav-tabs-horizontal > li > a {
    border-radius: 4px;
  }

  #clearing-agent-page .nav-tabs-horizontal > li.active > a,
  #clearing-agent-page .nav-tabs-horizontal > li.active > a:hover,
  #clearing-agent-page .nav-tabs-horizontal > li.active > a:focus {
    color: #ffffff;
    background-color: #428bca;
  }
}

#clearing-agent-page .content {
  float: left;
  width: 80%;
  padding-left: 1rem;
}

#clearing-agent-page .cormen input[type='checkbox'],
#clearing-agent-page .cormen label {
  display: none;
}

#clearing-agent-page .cormen {
  /*not really necessary, only used to position the menu on desktop, use your own preferred method */
  display: inline-block;
  width: 100%;
  float: left;
  z-index: 9999;
}

#clearing-agent-page .cormen input[type='checkbox'] {
  display: none;
}

#clearing-agent-page .cormen ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  #clearing-agent-page .cormen {
    display: flex;
    flex-direction: column-reverse;
    position: fixed;
    /*styling only below, used to position the hamburger, 'width' will change depending on the 'left' value*/
    bottom: 10px;
    left: 10px;
    width: calc(100% - 58px);
    /*add a max width if you don't want it spanning the page*/
    max-width: 300px;
  }

  #clearing-agent-page .cormen label {
    display: inline-block;
    width: 60px;
    height: 60px;
    position: relative;
    text-indent: 999px;
    font-size: 0;
    color: transparent;
    overflow: hidden;
    border-radius: 50%;
    background-color: #02a6aa;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  #clearing-agent-page .cormen label::before {
    content: url('../img/list.svg');
    display: inline-block;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0;
    left: 0;
    text-indent: 0;
    overflow: visible;
    font-size: 22px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    line-height: 60px;
    color: #fff;
    font-size: 20px;
  }

  #clearing-agent-page .cormen ul {
    list-style-type: none;
    width: 100%;
    margin: 0;
    /*styling only, below*/
    padding: 1rem 1rem 0 1rem;
    background-color: #eee;
    border-radius: 0.25rem;
    margin-bottom: 0.25rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  #clearing-agent-page .cormen ul {
    display: none;
    min-width: 100%;
    border-radius: 1rem;
  }

  #clearing-agent-page div.cormen input[type='checkbox']:checked + ul {
    display: block;
    animation-name: fadein;
    animation-duration: 350ms;
  }

  #clearing-agent-page .tab button {
    padding: 10px 15px;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

#clearing-agent-page .operator-info {
  padding: 20px 50px 50px 50px;
  border: 1px solid #cfcfcf;
  text-align: left;
  border-radius: 14px;
}

#clearing-agent-page .disputes-block {
  margin-bottom: 60px;
}

#clearing-agent-page .operator-info h5 {
  font-size: 30px;
  color: #000;
  padding: 0 0 10px;
  font-weight: 600;
}

#clearing-agent-page .operator-info h6 {
  font-size: 22px;
  color: #000;
  padding: 0 0 20px;
  font-weight: 600;
}

#clearing-agent-page .operator-info h4 {
  font-size: 18px;
  color: #007679;
  padding: 0 0 10px;
  font-weight: 600;
}

#clearing-agent-page .buttonsec1 {
  padding: 15px 0 15px;
}

#clearing-agent-page a.approvebut {
  padding: 10px 25px;
  min-width: 200px;
  border-radius: 35px;
  border: none;
  background: #007679;
  color: #fff;
  font-size: 16px;
  margin: 0 8px 8px 0;
  display: inline-block;
  text-align: center;
}

button.update-job {
  padding: 10px 25px;
  min-width: 200px;
  border-radius: 35px;
  border: none;
  background: #007679;
  color: #fff;
  font-size: 16px;
  margin: 0 8px 8px 0;
  display: inline-block;
  text-align: center;
}

#clearing-agent-page a.rejectbut {
  padding: 10px 25px;
  min-width: 200px;
  border-radius: 35px;
  border: none;
  background: #01a8ac;
  color: #fff;
  font-size: 16px;
  margin: 0 8px 8px 0;
  display: inline-block;
  text-align: center;
}

#clearing-agent-page .disputes-img {
  float: left;
  margin-right: 10px;
}

#clearing-agent-page a.rejectbut {
  padding: 10px 25px;
  min-width: 200px;
  border-radius: 35px;
  border: none;
  background: #01a8ac;
  color: #fff;
  font-size: 16px;
  margin: 0 8px 8px 0;
  display: inline-block;
  text-align: center;
}

#clearing-agent-page .chat {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;
}

#clearing-agent-page .clearingtable table p {
  padding: 0;
  font-size: 15px;
  line-height: 20px;
}

#clearing-agent-page .clearingtable table {
  border-spacing: 1em 0.5em;
  text-align: center;
}

#clearing-agent-page .agent-bids-table table {
  border-spacing: 1em 0.5em;
  text-align: center;
  width: max-content;
  min-width: 100%;
}

#clearing-agent-page .clearingtable tr {
  border: 1px solid #ccc;
  padding: 10px;
}

#clearing-agent-page .clearingtable td {
  padding: 0 10px;
  font-size: 22px;
}

#clearing-agent-page .clearingtable td {
  background: url(../img/devider.png) no-repeat right center;
}

#clearing-agent-page .clearingtable td:last-child {
  background: none;
}

#clearing-agent-page .clearingtable thead tr {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  border-top: 1px solid #fff;
}

#clearing-agent-page .clearingtable thead tr th {
  padding: 20px 10px;
  font-size: 16px;
  color: #007679;
  text-align: center;
  font-weight: 600;
}

#clearing-agent-page a.detailsdiv1 {
  float: none;
  background: #007679;
  padding: 10px 35px;
  color: #fff;
  border-radius: 30px;
  font-size: 22px;
}

#clearing-agent-page a:hover.detailsdiv1 {
  background: #01a8af;
  color: #fff;
  text-decoration: none;
}

#clearing-agent-page .searchicon {
  background: url(../img/searchicon.png) no-repeat center 4px #007679;
  border: none;
  width: 33px;
  height: 33px;
  position: absolute;
  right: 10px;
  top: 3px;
  border-radius: 50%;
}

#clearing-agent-page .receiptsfield {
  padding: 0 0 20px;
  position: relative;
}

#clearing-agent-page .personal-info1 {
  padding: 20px;
  box-shadow: 0 0 5px #ddd;
  text-align: center;
}

#clearing-agent-page .clearingtable td {
  font-size: 22px;
}

#clearing-agent-page .description1 h4,
#clearing-agent-page .description h4 {
  font-weight: 600;
}

#clearing-agent-page .clearingtable table p {
  padding: 0;
  font-size: 15px;
  line-height: 20px;
}

#clearing-agent-page .datetext {
  display: block;
  font-size: 17px;
  color: #888;
  padding-top: 6px;
}

#clearing-agent-page .clearingtable td {
  background: url(../img/devider.png) no-repeat right center;
}

#clearing-agent-page .clearingtable td {
  padding: 0px 20px;
  font-size: 22px;
}

#clearing-agent-page .description1,
#clearing-agent-page .description {
  text-align: left;
}

#clearing-agent-page .resticted {
  background: #f4f4f4;
}

#clearing-agent-page .clearingtable tr {
  border: 1px solid #ccc;
  padding: 10px;
  position: relative;
}

#clearing-agent-page .resticted td span {
  background: url(../img/batch.png) no-repeat center 0;
  position: absolute;
  left: 0%;
  top: 22px;
  border-radius: 3px;
  font-size: 12px;
  background-color: green;
  padding: 5px;
  color: #fff;
  transform: rotate(-30deg);
}

#clearing-agent-page .clearingtable .resticted td {
  position: relative;
  padding: 40px 20px 20px;
  color: #9b9b9b;
}

#clearing-agent-page .tracking-textarea {
  border: 1px solid #ccc;
  border-radius: 12px;
  padding: 20px;
  width: 100%;
  margin-bottom: 30px;
}

#clearing-agent-page .clearingtable .approved td {
  position: relative;
  padding: 10px 20px;
}

#clearing-agent-page .approved td span {
  position: absolute;
  left: 0%;
  top: 22px;
  border-radius: 3px;
  font-size: 12px;
  background-color: green;
  padding: 5px;
  color: #fff;
  transform: rotate(-30deg);
}

#clearing-agent-page .clearingtable tr td .list-inline {
  width: 100%;
  padding: 5px 0;
}

/*--------------------------------------------------------------
# Lawear Page
--------------------------------------------------------------*/

@media (max-width: 1140px) {
  #lawear-page .navbar a {
    padding: 10px 20px;
  }

  #lawear-page .services h2,
  #lawear-page .our-values h2 {
    font-size: 36px;
    margin: 20px 0;
  }

  #lawear-page .services .services-block {
    padding: 0;
  }

  #lawear-page .services h3,
  .our-values h3 {
    font-size: 28px;
    margin: 20px 0;
  }

  #lawear-page .services p {
    font-size: 20px;
    line-height: 38px;
    color: #000;
    margin-bottom: 50px;
  }

  #lawear-page .post-button {
    margin: 30px auto;
  }

  #lawear-page .our-values p {
    line-height: 28px;
  }

  #lawear-page .valuable-clients .valuable-clients-block {
    padding: 70px 50px 30px;
  }

  #lawear-page .valuable-clients h2 {
    font-size: 36px;
  }

  #lawear-page .testimonial .carousel-control {
    top: 220px !important;
    width: 90% !important;
  }

  #lawear-page .send-massage .services-block1 h5 {
    font-size: 26px;
  }

  #lawear-page .send-massage .services-block1 p {
    font-size: 18px;
    line-height: 20px;
    color: #000;
    margin-bottom: 0;
  }

  #lawear-page .send-massage .services-block1 {
    padding: 20px;
  }

  #lawear-page .send-massage .post-button {
    line-height: 26px;
    padding: 10px 25px;
    margin: 20px auto 0;
  }

  #lawear-page .footer .call {
    font-size: 14px;
  }
}

@media (max-width: 980px) {
  #lawear-page .hero h1 {
    font-size: 32px;
    text-align: left;
  }

  #lawear-page .hero p {
    font-size: 18px;
    line-height: 20px;
    color: #fff;
    width: 80%;
    text-align: left;
  }

  #lawear-page .services p {
    font-size: 16px;
    line-height: 20px;
    color: #000;
    margin-bottom: 20px;
  }

  #lawear-page .services h3,
  .our-values h3 {
    font-size: 24px;
    margin: 20px 0 0;
  }

  #lawear-page .our-values p {
    font-size: 16px;
    line-height: 20px;
    color: #fff;
  }

  #lawear-page .valuable-clients .valuable-clients-block {
    padding: 20px;
  }

  #lawear-page .valuable-clients .valuable-clients-block .logo img {
    width: 100%;
  }

  #lawear-page .send-massage .services-block1 input {
    font-size: 15px;
    color: #797979;
    padding: 10px 25px;
    border-radius: 30px;
    border: 1px solid #ccc;
    width: 100%;
    margin-bottom: 10px;
  }

  #lawear-page .footer i {
    font-size: 24px;
    color: #fff;
    margin-right: 10px;
  }

  #lawear-page .footer .call {
    font-size: 11px;
  }

  #lawear-page .mobile-nav-toggle {
    color: #fff;
    position: absolute;
    right: 0;
  }

  #lawear-page .valuable-clients .logo {
    width: 50%;
  }

  #lawear-page .valuable-clients h2,
  #lawear-page .services h2,
  #lawear-page .our-values h2 {
    font-size: 26px;
  }

  #lawear-page .services .services-block1,
  #lawear-page .services .services-block2 {
    border: none;
    width: 100%;
  }
}

@media (max-width: 767px) {
  #lawear-page .header {
    padding: 20px 0;
    height: 100px;
  }

  #lawear-page #header .logo {
    width: 150px;
  }

  #lawear-page .navbar {
    margin-bottom: 0;
  }

  #lawear-page .navbar {
    position: relative;
    min-height: 50px;
    margin-bottom: 0;
    width: 100%;
  }

  #lawear-page .hero {
    height: auto;
    padding: 60px 0 20px 0;
  }

  #lawear-page .hero h1 {
    font-size: 20px;
    margin: 0;
  }

  #lawear-page .hero p {
    font-size: 14px;
  }

  #lawear-page .services h2,
  #lawear-page .our-values h2 {
    font-size: 20px;
    margin: 20px 0;
  }

  #lawear-page .padding-left1 {
    padding-right: 15px;
  }

  #lawear-page .padding-right1 {
    padding-right: 15px;
  }

  #lawear-page .post-button {
    width: 300px;
  }

  #lawear-page .valuable-clients h2 {
    font-size: 20px;
  }

  #lawear-page .valuable-clients h2 img {
    display: none;
  }

  #lawear-page section {
    padding: 0;
  }

  #lawear-page #quote-carousel .carousel-indicators {
    position: relative;
    left: 50%;
  }

  #lawear-page #quote-carousel .carousel-indicators .active {
    width: 128px;
    height: 78px;
  }

  #lawear-page .testimonial h6:before {
    display: none;
  }

  #lawear-page .testimonial .carousel-control {
    top: inherit !important;
    width: inherit !important;
  }

  #lawear-page .testimonial {
    padding: 0;
  }

  #lawear-page .testimonial h6 {
    color: #000;
    font-size: 26px;
  }

  #lawear-page .footer .call {
    font-size: 18px;
  }

  #lawear-page section.faq {
    padding: 30px 0;
  }

  #lawear-page .valuable-clients .logo {
    float: left;
    width: 50%;
  }

  #lawear-page .padding-left1 {
    padding-left: 15px;
  }

  #lawear-page .our-values-main-block div {
    padding: 5px 15px !important;
    border-radius: 15px;
  }

  #lawear-page .header .logo img {
    height: 30px;
  }

  #lawear-page .header.header-scrolled {
    padding: 0;
    height: 60px;
  }

  #lawear-page .valuable-clients {
    padding-top: 20px;
  }

  #lawear-page .about-goagent,
  .clering-goagent {
    margin-top: 40px;
  }

  #lawear-page .about-goagent h2,
  #lawear-page .clering-goagent h2,
  #lawear-page .payment-policy h2 {
    font-size: 28px;
  }

  #lawear-page .services h3,
  .our-values h3,
  #lawear-page .inner-about h3,
  #lawear-page .our-values h4,
  .about-goagent h3 {
    margin: 20px 0 10px;
  }

  #lawear-page p {
    font-size: 16px !important;
    line-height: 20px !important;
  }

  #lawear-page .about-goagent .about-goagent-block,
  #lawear-page .clering-goagent .clering-goagent-block {
    margin-top: 0;
  }

  #lawear-page .inner-about .services-block1 {
    border: none;
  }

  #lawear-page .inner-about-block .padding-left1 {
    padding-left: 0;
  }

  #lawear-page .inner-about-block .padding-right1 {
    padding-right: 0;
  }

  #lawear-page .inner-about-block .services .services-block,
  #lawear-page .inner-about-block .services .services-block1,
  #lawear-page .inner-about-block .inner-about .services-block {
    padding: 0;
  }

  #lawear-page .inner-about-block .inner-about .services-block,
  #lawear-page .inner-about-block .inner-about .services-block1,
  #lawear-page .inner-about-block .inner-about .services-block {
    padding: 0 !important;
  }

  #lawear-page .inner-about-block .inner-about .mission {
    padding-right: 0 !important;
  }

  #lawear-page .inner-about-block .inner-about .vision {
    padding-left: 0 !important;
  }

  #lawear-page .inner-about-block .inner-about .vision .services-block {
    padding-left: 15px !important;
  }

  #lawear-page .faq .send-cv {
    font-size: 16px;
  }

  #lawear-page .clering-goagent .post-button {
    padding: 10px 10px;
    font-size: 18px;
    width: auto;
  }

  #lawear-page .post-button,
  #lawear-page .send-massage .post-button {
    padding: 7px 20px;
    font-size: 18px;
  }

  #lawear-page .navbar li {
    position: relative;
    padding: 10px;
  }

  #lawear-page .header.header-scrolled .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  #lawear-page .header.header-scrolled .navbar ul {
    padding-left: 0 !important;
  }

  #lawear-page .cargoheading1 {
    width: 150px !important;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  #lawear-page .hero {
    height: auto;
    padding: 180px 0 60px 0;
  }

  #lawear-page #quote-carousel .carousel-indicators .active {
    width: 128px;
    height: 128px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  #lawear-page #quote-carousel .carousel-indicators .active {
    width: 128px;
    height: 78px;
  }
}

/*--------------------------------------------------------------
# CLIENT SECTION
--------------------------------------------------------------*/
#lawear-page .bodycont {
  padding: 130px 0 40px 0;
}

#lawear-page .bodycont h2 {
  text-align: center;
  font-size: 30px;
  color: #007679;
  font-weight: 600;
  padding: 0 0 10px 0;
  margin: 0px !important;
}

#lawear-page .bodycont h3 {
  text-align: center;
  font-size: 20px;
  color: #007679;
  font-weight: 600;
  padding: 0 0 10px 0;
  margin: 0px !important;
}

#lawear-page .personal-info {
  padding: 28px;
  border: 1px solid #eee;
  /* margin-top: 70px; */
  text-align: center;
}

#lawear-page .form-control {
  padding: 10px 25px;
  height: 60px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: none;
  margin-bottom: 10px;
}

#lawear-page .personal-info .form-control {
  margin-bottom: 10px;
}

#lawear-page .buttonsec {
  padding: 15px 0 15px;
}

#lawear-page .submitbut {
  padding: 15px 45px;
  min-width: 250px;
  border-radius: 35px;
  border: none;
  background: #007679;
  color: #fff;
  font-size: 16px;
  margin: 0 8px 8px 0;
  transition: all 0.2s ease-in-out 0s;
}

#lawear-page .submitbut:hover {
  background: #01a8af;
  color: #fff;
}

#lawear-page a.submitbut,
#lawear-page a.backbut {
  padding: 15px 45px !important;
  min-width: 250px !important;
  border-radius: 35px !important;
  border: none;
  background: #007679;
  color: #fff;
  font-size: 16px !important;
  margin: 0 8px 8px 0 !important;
  display: inline-block;
  text-align: center;
}

#lawear-page a.backbut {
  background: #01a8af;
}

#lawear-page a:hover.backbut,
#lawear-page a:focus.backbut {
  background: #007679;
  color: #fff;
}

#lawear-page a:hover.submitbut,
#lawear-page a:focus.submitbut,
#lawear-page a:focus.detailsdiv,
#lawear-page a:focus.review {
  background: #01a8af;
  color: #fff;
}

#lawear-page .profilesec {
  padding: 0;
  font-size: 28px;
  font-weight: 500;
  /* margin-top: -145px; */
}

#lawear-page .profileimage img {
  border-radius: 100%;
  border: 8px solid #eee;
}

#lawear-page .profileimage {
  width: 163px;
  margin: 0 auto 20px;
  position: relative;
}

#lawear-page .profileimage span a {
  position: absolute;
  right: 0;
  top: 65%;
  background: #007679;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  font-size: 13px;
  line-height: 35px;
  color: #fff;
  display: block;
}

#lawear-page .profilecont {
  display: flex;
}

#lawear-page .profilecontleft,
#lawear-page .profilecontright {
  width: 50%;
  text-align: left;
  padding: 20px 40px;
  font-size: 17px;
  line-height: 22px;
}

#lawear-page .profilecontright {
  border-left: 1px solid #e3e3e3;
}

#lawear-page .loginright .dropdown-menu {
  left: inherit !important;
  right: 0;
  margin-top: 8px;
  font-size: 12px;
}

#lawear-page .payment-info {
  padding: 10px 60px;
  border: 1px solid #eee;
  text-align: center;
}

#lawear-page .payment-details-block,
#lawear-page .payment-details-block1 {
  width: 50%;
  padding: 0 25px;
  float: left;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #ccc;
}

#lawear-page .payment-details-block {
  border-right: 1px solid #ccc;
  font-size: 20px;
}

#lawear-page .finish {
  padding: 15% 0;
  font-size: 22px;
  line-height: 28px;
  font-weight: 500;
}

#lawear-page .finish h3 {
  padding: 0 0 10px;
}

#lawear-page .paymentstep {
  padding: 25px 0 45px;
  text-align: center;
}

#lawear-page .paymentstep li {
  display: inline-block;
  padding: 0 7%;
  font-size: 18px;
  font-weight: 500;
  background: url(../img/dotted.png) repeat-x 0 28px;
  width: 28%;
  vertical-align: top;
}

#lawear-page .paymentstep li span {
  width: 55px;
  height: 55px;
  display: block;
  margin: 0 auto 8px;
  background: #282828;
  color: #fff;
  border-radius: 100%;
  border: 9px solid #f5f5f5;
  font-weight: 600;
  line-height: 38px;
}

#lawear-page .paymentstep li.active {
  color: #01a8ac;
}

#lawear-page .paymentstep li.active span {
  background: #01a8ac;
}

#lawear-page .paymentstep li:first-child {
  background-position: 100% 28px;
  background-repeat: no-repeat;
}

#lawear-page .paymentstep li:last-child {
  background-position: -6% 28px;
  background-repeat: no-repeat;
}

#lawear-page .paymentstep li p {
  padding: 0;
  background: #fff;
}

#lawear-page .custom02 input[type='radio'] {
  display: none;
}

#lawear-page .custom02 label {
  position: relative;
  padding: 9px 3px 3px 28px;
  cursor: pointer;
  margin-left: 16px;
  text-align: left;
  float: left;
}

#lawear-page .custom02 label::before,
#lawear-page .custom02 label::after {
  position: absolute;
  content: '';
  top: 50%;
  border-radius: 100%;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

#lawear-page .custom02 label::before {
  left: 0;
  width: 18px;
  height: 18px;
  margin-top: -8px;
  background: #fff;
  border: 1px solid #ccc;
}

#lawear-page .custom02 label:hover::before {
  background: #fff;
}

#lawear-page .custom02 label::after {
  opacity: 0;
  left: 4px;
  width: 10px;
  height: 10px;
  margin-top: -4px;
  background: #4ad300;
  -webkit-transform: scale(2);
  transform: scale(2);
}

#lawear-page .custom02 input[type='radio']:checked + label::before {
  background: #fff;
}

#lawear-page .custom02 input[type='radio']:checked + label::after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

#lawear-page .payment-method {
  background: #f8f8f8;
  padding: 12px 18px 12px;
  border-radius: 47px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

#lawear-page .payment-method2 {
  background: #f8f8f8;
  padding: 18px 18px 12px;
  border-radius: 47px;
  border: 1px solid #ccc;
  margin-top: 10px;
}

#lawear-page .form-control {
  padding: 10px 25px !important;
  height: 60px !important;
  border-radius: 5px !important;
  border: 1px solid #ccc !important;
  box-shadow: none !important;
  margin-bottom: 10px !important;
}

#lawear-page .progressdiv1 {
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  margin-bottom: 15px;
}

#lawear-page .checkcontainer {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 17px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 500;
}

/* Hide the browser's default checkbox */
#lawear-page .checkcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
#lawear-page .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
#lawear-page .checkcontainer:hover input ~ .checkmark {
  background-color: #f8f8f8;
}

/* When the checkbox is checked, add a blue background */
#lawear-page .checkcontainer input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
#lawear-page .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
#lawear-page .checkcontainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
#lawear-page .checkcontainer .checkmark:after {
  left: 6px;
  top: 3px;
  width: 7px;
  height: 12px;
  border: solid #4ad300;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

#lawear-page .payment-method2 img {
  float: right;
  width: 120px;
  margin: 0 10px;
}

#lawear-page .cardicon {
  float: right;
}

#lawear-page .cardicon img {
  width: 40px;
  border-radius: 100%;
  margin: 0 10px;
}

#lawear-page .paymenttable .table > tbody > tr > td {
  border-top: none;
  padding: 8px 25px;
}

#lawear-page .paymenttable td:first-child {
  font-size: 20px;
  font-weight: 500;
  border-right: 1px solid #ccc;
  width: 50%;
}

#lawear-page .paymenttable {
  text-align: left;
}

#lawear-page .price {
  font-size: 20px;
  font-weight: 600;
  color: #01a8ac;
}

#lawear-page #nonResponsiveTabs {
  border: none;
}

#lawear-page #nonResponsiveTabs li.active > a {
  border: none;
}

#lawear-page #nonResponsiveTabs li {
  width: 31%;
  margin: 0 1%;
}

#lawear-page #nonResponsiveTabs > li > a {
  border-radius: 45px;
  background: #d1d1d1;
  color: #000;
  margin: 0;
  border: none;
  padding: 15px 10px;
}

#lawear-page #nonResponsiveTabs > li > a:hover {
  border: none;
}

#lawear-page #nonResponsiveTabs li.active a {
  background: #01a8ac;
  color: #fff;
}

#lawear-page .resistertab .tab-content {
  padding: 16px 0 0 0;
}

#lawear-page .resistertab .checkcontainer {
  margin: 25px 0;
}

#lawear-page .resistertab .panel-heading {
  padding: 15px;
}

#lawear-page .logindiv {
  padding: 90px;
  box-shadow: 0 0 5px #ddd;
  width: 675px;
  margin: 0 auto;
}

#lawear-page .logindiv .submitbut {
  width: 100%;
}

#lawear-page .logindiv a {
  text-decoration: underline;
}

#lawear-page .logindiv a:hover {
  text-decoration: none;
}

#lawear-page .logindiv p {
  padding: 0 0 30px;
}

#lawear-page .logindiv h3 {
  padding: 25px 0;
  color: #999;
  font-size: 24px;
}

#lawear-page .keepcheck {
  padding: 0 0 25px;
}

#lawear-page .keepcheck a {
  float: right;
  text-decoration: none;
}

#lawear-page .dashcont {
  display: flex;
}

#lawear-page .dashlink {
  width: 475px;
  background: #f4f4f4;
  padding: 85px 0;
}

#lawear-page .dashrightcont {
  width: 100%;
  padding: 80px 50px 50px;
}

#lawear-page .dashrightcont .bodycont {
  padding: 0;
}

#lawear-page .dashlink ul {
  padding-left: 0 !important;
}

#lawear-page .dashlink ul li {
  margin-bottom: 3px;
  list-style-type: none;
}

#lawear-page .dashlink ul li a {
  padding: 25px 15px 25px 75px;
  display: block;
  font-size: 23px;
  font-weight: 500;
  color: #000;
}

#lawear-page .dashlink ul li a:hover,
#lawear-page .dashlink ul li.active a {
  background: #fff;
  color: #007679;
}

#lawear-page .dashlink ul li:last-child a {
  margin-top: 70px;
  color: #df0000;
}

#lawear-page .dashlink h4 {
  padding: 25px 15px 45px 75px;
  font-size: 23px;
  font-weight: 500;
  color: #000;
}

#lawear-page .servicecheck {
  padding: 0 0 35px;
}

#lawear-page .servicecheck label {
  margin: 0 20px;
}

#lawear-page .serviceradio label {
  float: none;
  font-weight: normal;
}

#lawear-page .serviceradio {
  padding: 15px 0 25px;
}

#lawear-page .payment-info h5 {
  font-size: 24px;
  color: #000 !important;
  padding: 0 0 30px;
  font-weight: 600;
}

#lawear-page .attached {
  padding: 0 0 25px;
  text-align: left;
  color: #878787;
  font-size: 18px;
}

#lawear-page select.form-control {
  background: url(../img/arrow1.png) no-repeat 95% 26px #f8f8f8;
}

#lawear-page .uploaddiv {
  padding: 18px 80px 18px 25px;
  border: 1px solid #ccc;
  border-radius: 55px;
  font-size: 16px;
  color: #000;
  margin-bottom: 8px;
  position: relative;
}

#lawear-page .upload-btn-wrapper {
  position: absolute;
  overflow: hidden;
  display: inline-block;
  float: right;
  top: 3px;
  right: 6px;
}

#lawear-page .btn {
  border: 2px solid #007679;
  color: gray;
  background-color: #007679;
  padding: 10px 15px;
  border-radius: 38px;
  font-size: 20px;
  font-weight: bold;
}

#lawear-page .upload-btn-wrapper input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

#lawear-page .servicetab .nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  align-items: center !important;
  justify-content: center !important;
}

#lawear-page .servicetab .nav > li > a {
  padding: 17px 15px;
  color: #1d1d1d;
  font-size: 20px;
  margin: 0;
  border: none;
}

#lawear-page .servicetab .nav-tabs > li {
  width: 33.33%;
  text-align: center;
  margin-left: 0;
  margin-right: 0;
  position: relative;
  z-index: 9;
}

#lawear-page .servicetab .nav-tabs > li.active a {
  color: #007679;
  border: none;
  box-shadow: 0px -4px 5px #ddd;
}

#lawear-page .servicetab .nav-tabs {
  border-bottom: none;
}

#lawear-page .servicetab .nav > li > a:hover {
  background: none;
  color: #007679;
  border: none;
}

#lawear-page .servicetab .tab-content {
  box-shadow: 0 0 5px #ccc;
  padding: 20px 40px;
  text-align: center;
}

#lawear-page .servicetab h3 {
  color: #000;
}

#lawear-page .progressdiv {
  padding: 15px 20px 15px;
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  margin-bottom: 15px;
}

#lawear-page .progressheading {
  position: relative;
  float: left;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 0 0 50px;
}

#lawear-page .progressheading span {
  position: absolute;
  left: 0;
  top: 4px;
  background: #01a8ac;
  width: 35px;
  height: 35px;
  color: #fff;
  font-size: 14px;
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
}

#lawear-page a.detailsdiv {
  float: right;
  background: #007679;
  padding: 10px 35px;
  color: #fff;
  border-radius: 30px;
  font-size: 16px;
}

#lawear-page a:hover.detailsdiv {
  background: #01a8af;
  color: #fff;
  text-decoration: none;
}

#lawear-page .servicetabout {
  text-align: center;
}

#lawear-page .servicetabout #nonResponsiveTabs li {
  float: none;
  display: inline-block;
}

#lawear-page .margin-right {
  margin-right: 5px;
}

#lawear-page .servicetabout #nonResponsiveTabs {
  border: none;
  padding-bottom: 45px;
}

#lawear-page .servicetabout .tab-content {
  padding: 0;
  box-shadow: none;
  text-align: left;
}

#lawear-page .servicetabout #nonResponsiveTabs li.active::after {
  content: '';
  background: url(../img/arrow.png) no-repeat 0 0;
  width: 19px;
  height: 13px;
  display: block;
  left: 48%;
  position: absolute;
}

#lawear-page .clearing {
  padding: 10px;
  border: 1px solid #cfcfcf;
  margin-bottom: 15px;
  border-radius: 8px;
  /*justify-content: center;*/
  /* align horizontal */
  align-items: center;
  text-align: left;
}

#lawear-page .clearing li {
  list-style-type: none;
  font-size: 22px;
  color: #000;
  font-weight: 500;
  background: url(../img/devider.png) no-repeat right center;
  display: inline-block;
  min-height: 99px;
  vertical-align: top;
}

#lawear-page .heading1 {
  padding: 36px 15px 0 10px;
  font-size: 20px;
  font-weight: 700;
}

#lawear-page .heading {
  float: left;
  padding: 22px 15px 0 15px;
  line-height: 25px;
}

#lawear-page .cargoheading1,
#lawear-page .cargoheading2 {
  padding: 16px 15px;
  font-size: 20px;
  font-weight: 700;
}

#lawear-page .clearing p {
  font-size: 14px;
  font-weight: 300;
  padding: 0;
}

#lawear-page .batch {
  float: left;
}

#lawear-page .heading span {
  color: #8e8e8e;
  font-size: 18px;
}

#lawear-page .cargoheading {
  padding: 36px 44px;
}

#lawear-page .cargoprice {
  color: #01a8ac;
}

#lawear-page .clearing li:last-child {
  background: none;
}

#lawear-page .starsec {
  padding: 30px 0 0 37px;
}

#lawear-page .starsec a .fa,
#lawear-page .starsec1 a .fa {
  color: #ffc107;
  padding: 0 2px;
}

#lawear-page .starsec1 {
  padding: 0 0 0 5px;
}

#lawear-page a.review {
  padding: 10px 31px;
  background: #007679;
  font-size: 15px;
  margin: 0 15px;
  font-weight: 500;
  border-radius: 18px;
  color: #fff;
  white-space: nowrap;
}

#lawear-page a:hover.review,
#lawear-page a:hover.review1 {
  background: #01a8af;
  color: #fff;
}

#lawear-page .progressdiv1 {
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  margin-bottom: 15px;
}

#lawear-page .progressdiv1 .progressheading-new {
  width: 100%;
  padding: 26px 70px 10px 70px;
  font-size: 20px;
  font-weight: 600;
  position: relative;
}

#lawear-page .progressdiv1 .progressheading-new p {
  font-size: 18px;
  font-weight: 300;
}

#lawear-page .progressdiv1 .progressheading {
  width: 59%;
  background: url(../img/devider.png) no-repeat right center;
  padding: 36px 0 45px 70px;
}

#lawear-page .progressheading span,
#lawear-page .progressheading-new span {
  position: absolute;
  left: 0;
  top: 4px;
  background: #01a8ac;
  width: 35px;
  height: 35px;
  color: #fff;
  font-size: 14px;
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
}

#lawear-page .progressdiv1 .progressheading span,
#lawear-page .progressdiv1 .progressheading-new span {
  position: absolute;
  left: 20px;
  top: 29px;
}

#lawear-page .dayleft {
  width: 20%;
  float: left;
  text-align: center;
  background: url(../img/devider.png) no-repeat right center;
  padding: 30px;
  color: #01a8ac;
  font-size: 16px;
}

#lawear-page .progressdiv1 .progressheading span {
  position: absolute;
  left: 20px;
  top: 29px;
}

#lawear-page .progressdiv1 a.detailsdiv {
  margin: 29px 15px;
}

#lawear-page .detailsservice {
  border: 1px solid #cfcfcf;
  margin-bottom: 15px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  text-align: left;
}

#lawear-page .batleft img {
  margin-bottom: 5px;
}

#lawear-page .batleft {
  width: 15%;
  padding: 10px;
  float: left;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  background: url(../img/devider.png) no-repeat right center;
}

#lawear-page .battext {
  float: left;
  width: 25%;
  text-align: center;
  padding: 60px 25px;
  min-height: 143px;
  font-size: 20px;
  font-weight: 600;
  background: url(../img/devider.png) no-repeat right center;
}

#lawear-page .battext1 {
  float: left;
  width: 15%;
  text-align: center;
  padding: 43px 25px;
  min-height: 143px;
  font-size: 20px;
  font-weight: 600;
  background: url(../img/devider.png) no-repeat right center;
}

#lawear-page .batreview {
  width: 20%;
  padding: 30px 15px;
  float: left;
  text-align: center;
  background: url(../img/devider.png) no-repeat right center;
}

#lawear-page .batreview .fa {
  font-size: 22px;
  margin: 1px;
  color: #ffc107;
}

#lawear-page .batreview a.review {
  display: inline-block;
  margin-top: 9px;
  padding: 10px 25px;
  color: #007679;
  border: 1px solid #007679;
  background: #fff;
}

#lawear-page .batcurrent {
  width: 20%;
  padding: 43px 24px;
  float: left;
  background: url(../img/devider.png) no-repeat right center;
  font-size: 18px;
  font-weight: 500;
}

#lawear-page .batcurrent1 {
  width: 15%;
  padding: 43px 24px;
  float: left;
  background: url(../img/devider.png) no-repeat right center;
  font-size: 18px;
  font-weight: 500;
}

#lawear-page .batcurrent2 {
  width: 17%;
  padding: 60px 5px;
  float: left;
  background: url(../img/devider.png) no-repeat right center;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
}

#lawear-page .batcurrent span,
.batcurrent1 span {
  display: block;
  font-weight: 600;
  color: #01a8ac;
  font-size: 22px;
  padding-top: 5px;
}

#lawear-page .detailsservice a.detailsdiv {
  margin: 47px 18px;
}

#lawear-page .tabheading h3 {
  float: left;
  padding: 0 0 20px;
}

#lawear-page .tabheading h4 {
  float: right;
  font-size: 22px;
  padding: 8px;
  color: #007679;
}

#lawear-page .reson-reject {
  text-align: center;
  padding: 0 20px 20px;
}

#lawear-page .reson-reject h5 {
  font-size: 22px;
  font-weight: 600;
  color: #007679;
  margin: 0 0 10px;
}

#lawear-page .modal-header {
  padding: 15px;
  border-bottom: none !important;
}

#lawear-page .modal-dialog {
  width: 1000px !important;
  margin: 80px auto;
}

#lawear-page .modal-dialog1 {
  width: 500px !important;
}

#lawear-page .modal-header .close {
  margin-top: -40px !important;
  margin-right: -105% !important;
  width: 30px !important;
  height: 30px !important;
  background: #fff !important;
  border-radius: 50% !important;
  opacity: 1 !important;
}

#lawear-page .modal-dialog .clearing {
  padding: 10px;
  border: 1px solid #cfcfcf;
  margin-bottom: 15px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  text-align: left;
}

#lawear-page .modal-dialog .clearing li {
  list-style-type: none;
  font-size: 22px;
  color: #000;
  font-weight: 500;
  background: url(../img/devider.png) no-repeat right center;
  display: inline-block;
  min-height: 99px;
  vertical-align: top;
}

#lawear-page .modal-dialog .heading1 {
  padding: 36px 15px 0 10px;
  font-size: 24px !important;
  font-weight: bold;
}

#lawear-page .modal-dialog .clearing p {
  font-size: 14px;
  font-weight: 300;
  padding: 0;
}

#lawear-page .modal-dialog .cargoheading {
  padding: 36px 15px;
  font-size: 18px;
}

#lawear-page .modal-dialog .cargoheading2 {
  padding: 10px !important;
}

#lawear-page .payment-info h5 {
  font-size: 24px;
  color: #000 !important;
  padding: 0 0 30px;
  font-weight: 600;
}

/* Style the tab */
#lawear-page .tab {
  float: left;
  background-color: #f4f4f4;
  width: 15%;
  padding: 80px 0;
  margin-top: -30px;
  position: fixed;
  height: 100%;
}

/* Style the buttons inside the tab */
#lawear-page .tab button {
  display: block;
  background-color: inherit;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 23px;
  font-weight: 500;
  color: #000;
  padding: 15px 25px 15px 30px;
}

/* Change background color of buttons on hover */
#lawear-page .tab button:hover {
  background-color: #ddd;
}

/* Create an active/current "tab button" class */
#lawear-page .tab button.active {
  background-color: #fff;
}

/* Style the tab content */
#lawear-page .tabcontent {
  float: right;
  padding: 0px 30px !important;
  width: 85%;
  border-left: none;
  margin-top: -140px;
  padding: 0;
}

#lawear-page .dashlink button {
  display: block;
  font-size: 23px;
  font-weight: 500;
  color: #000;
}

#lawear-page .tab button:hover,
#lawear-page .tab button.active {
  background: #fff;
  color: #007679;
}

#lawear-page .tab h4 {
  padding: 25px 15px 45px 75px;
  font-size: 23px;
  font-weight: 500;
  color: #000;
}

#lawear-page .progressdiv1 {
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  margin-bottom: 15px;
}

#lawear-page .progressdiv1 .progressheading {
  width: 59%;
  background: url(../img/devider.png) no-repeat right center;
  padding: 36px 0 45px 70px;
}

#lawear-page .dayleft {
  width: 20%;
  float: left;
  text-align: center;
  background: url(../img/devider.png) no-repeat right center;
  padding: 30px;
  color: #01a8ac;
  font-size: 16px;
}

#lawear-page .progressdiv1 .progressheading span,
#lawear-page .progressdiv1 .progressheading-new span {
  position: absolute;
  left: 20px;
  top: 29px;
}

#lawear-page .progressdiv1 a.detailsdiv {
  margin: 29px 15px;
}

#lawear-page .nav-tabs-dropdown {
  display: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding-top: 120px;
}

#lawear-page .nav-tabs-dropdown:before {
  content: '\e114';
  font-family: 'Glyphicons Halflings';
  position: absolute;
  right: 30px;
}

@media screen and (min-width: 769px) {
  #lawear-page #nav-tabs-wrapper {
    display: block !important;
  }
}

@media screen and (max-width: 768px) {
  #lawear-page .nav-tabs-dropdown {
    display: block;
  }

  #lawear-page #nav-tabs-wrapper {
    display: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    text-align: center;
  }

  #lawear-page .nav-tabs-horizontal {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  }

  #lawear-page .nav-tabs-horizontal > li {
    float: none;
  }

  #lawear-page .nav-tabs-horizontal > li + li {
    margin-left: 2px;
  }

  #lawear-page .nav-tabs-horizontal > li,
  #lawear-page .nav-tabs-horizontal > li > a {
    background: transparent;
    width: 100%;
  }

  #lawear-page .nav-tabs-horizontal > li > a {
    border-radius: 4px;
  }

  #lawear-page .nav-tabs-horizontal > li.active > a,
  #lawear-page .nav-tabs-horizontal > li.active > a:hover,
  #lawear-page .nav-tabs-horizontal > li.active > a:focus {
    color: #ffffff;
    background-color: #428bca;
  }
}

#lawear-page .content {
  float: left;
  width: 80%;
  padding-left: 1rem;
}

#lawear-page .cormen input[type='checkbox'],
#lawear-page .cormen label {
  display: none;
}

#lawear-page .cormen {
  /*not really necessary, only used to position the menu on desktop, use your own preferred method */
  display: inline-block;
  width: 100%;
  float: left;
  z-index: 9999;
}

#lawear-page .cormen input[type='checkbox'] {
  display: none;
}

#lawear-page .cormen ul {
  list-style-type: none;
  width: 100%;
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  #lawear-page .cormen {
    display: flex;
    flex-direction: column-reverse;
    position: fixed;
    /*styling only below, used to position the hamburger, 'width' will change depending on the 'left' value*/
    bottom: 10px;
    left: 10px;
    width: calc(100% - 58px);
    /*add a max width if you don't want it spanning the page*/
    max-width: 300px;
  }

  #lawear-page .cormen label {
    display: inline-block;
    width: 60px;
    height: 60px;
    position: relative;
    text-indent: 999px;
    font-size: 0;
    color: transparent;
    overflow: hidden;
    border-radius: 50%;
    background-color: #02a6aa;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  #lawear-page .cormen label::before {
    content: url('../img/list.svg');
    display: inline-block;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0;
    left: 0;
    text-indent: 0;
    overflow: visible;
    font-size: 22px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    line-height: 60px;
    color: #fff;
    font-size: 20px;
  }

  #lawear-page .cormen ul {
    list-style-type: none;
    width: 100%;
    margin: 0;
    /*styling only, below*/
    padding: 1rem 1rem 0 1rem;
    background-color: #eee;
    border-radius: 0.25rem;
    margin-bottom: 0.25rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  #lawear-page .cormen ul {
    display: none;
    min-width: 100%;
    border-radius: 1rem;
  }

  #lawear-page div.cormen input[type='checkbox']:checked + ul {
    display: block;
    animation-name: fadein;
    animation-duration: 350ms;
  }

  #lawear-page .tab button {
    padding: 10px 15px;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

#lawear-page .profileimage span span {
  position: absolute;
  right: 0;
  top: 65%;
  background: #007679;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  font-size: 13px;
  line-height: 35px;
  color: #fff;
  display: block;
}

#lawear-page .operator-info {
  padding: 20px 50px 50px 50px;
  border: 1px solid #cfcfcf;
  text-align: left;
  border-radius: 14px;
}

#lawear-page .disputes-block {
  margin-bottom: 60px;
}

#lawear-page .operator-info h5 {
  font-size: 30px;
  color: #000;
  padding: 0 0 10px;
  font-weight: 600;
}

#lawear-page .operator-info h6 {
  font-size: 22px;
  color: #000;
  padding: 0 0 20px;
  font-weight: 600;
}

#lawear-page .operator-info h4 {
  font-size: 18px;
  color: #007679;
  padding: 0 0 10px;
  font-weight: 600;
}

#lawear-page .buttonsec1 {
  padding: 15px 0 15px;
}

#lawear-page a.approvebut {
  padding: 10px 25px;
  min-width: 200px;
  border-radius: 35px;
  border: none;
  background: #007679;
  color: #fff;
  font-size: 16px;
  margin: 0 8px 8px 0;
  display: inline-block;
  text-align: center;
}

#lawear-page a.rejectbut {
  padding: 10px 25px;
  min-width: 200px;
  border-radius: 35px;
  border: none;
  background: #01a8ac;
  color: #fff;
  font-size: 16px;
  margin: 0 8px 8px 0;
  display: inline-block;
  text-align: center;
}

#lawear-page .disputes-img {
  float: left;
  margin-right: 10px;
}

#lawear-page a.rejectbut {
  padding: 10px 25px;
  min-width: 200px;
  border-radius: 35px;
  border: none;
  background: #01a8ac;
  color: #fff;
  font-size: 16px;
  margin: 0 8px 8px 0;
  display: inline-block;
  text-align: center;
}

#lawear-page .chat {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;
}

#lawear-page .clearingtable table p {
  padding: 0;
  font-size: 15px;
  line-height: 20px;
}

#lawear-page .clearingtable table {
  border-spacing: 1em 0.5em;
  text-align: center;
}

#lawear-page .clearingtable tr {
  border: 1px solid #ccc;
  padding: 10px;
}

#lawear-page .clearingtable td {
  padding: 0 20px;
  font-size: 22px;
}

#lawear-page .clearingtable td {
  background: url(../img/devider.png) no-repeat right center;
}

#lawear-page .clearingtable td:last-child {
  background: none;
}

#lawear-page .clearingtable thead tr {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  border-top: 1px solid #fff;
}

#lawear-page .clearingtable thead tr th {
  padding: 20px 10px;
  font-size: 16px;
  color: #007679;
  text-align: center;
  font-weight: 600;
}

#lawear-page a.detailsdiv1 {
  float: none;
  background: #007679;
  padding: 10px 35px;
  color: #fff;
  border-radius: 30px;
  font-size: 22px;
}

#lawear-page a:hover.detailsdiv1 {
  background: #01a8af;
  color: #fff;
  text-decoration: none;
}

#lawear-page .searchicon {
  background: url(../img/searchicon.png) no-repeat center 13px #007679;
  border: none;
  width: 48px;
  height: 48px;
  position: absolute;
  right: 10px;
  top: 6px;
  border-radius: 50%;
}

#lawear-page .receiptsfield {
  padding: 0 0 20px;
  position: relative;
}

#lawear-page .personal-info1 {
  padding: 60px;
  box-shadow: 0 0 5px #ddd;
  text-align: center;
}

#lawear-page .clearingtable td {
  font-size: 22px;
}

#lawear-page .description1 h4 {
  font-weight: 600;
  padding: 0 0 5px;
}

#lawear-page .clearingtable table p {
  padding: 0;
  font-size: 15px;
  line-height: 20px;
}

#lawear-page .datetext {
  display: block;
  font-size: 17px;
  color: #888;
  padding-top: 6px;
}

#lawear-page .clearingtable td {
  background: url(../img/devider.png) no-repeat right center;
}

#lawear-page .clearingtable td {
  padding: 5px 20px;
  font-size: 22px;
}

#lawear-page .description1,
#lawear-page .description {
  text-align: left;
}

#lawear-page .resticted {
  background: #f4f4f4;
}

#lawear-page .clearingtable tr {
  border: 1px solid #ccc;
  padding: 10px;
  position: relative;
}

#lawear-page .resticted td span {
  position: absolute;
  width: 80%;
  left: 10%;
  top: 15px;
  font-size: 17px;
  background: url(../img/batch.png) no-repeat center 0;
  padding: 5px;
  color: #fff;
}

#lawear-page .clearingtable .resticted td {
  position: relative;
  padding: 60px 20px;
  color: #9b9b9b;
}

#lawear-page .heading1-1 {
  padding: 10px 50px 10px 30px;
  font-size: 20px;
  font-weight: 700;
  line-height: 80px;
}

#lawear-page .cargoheading-1 {
  padding: 10px 28px;
  line-height: 30px;
}

#lawear-page .cargoheading-1 p {
  font-size: 18px;
  font-weight: 500;
}

#lawear-page .cargoheading1-1 {
  padding: 10px 30px;
  font-size: 20px;
  font-weight: 700;
  line-height: 80px;
}

/* #lawear-page a:focus */

#lawear-page a.review1 {
  padding: 10px 20px;
  background: #007679;
  font-size: 15px;
  margin: 0;
  font-weight: 500;
  border-radius: 18px;
  color: #fff;
}

#lawear-page .tracking-textarea {
  border: 1px solid #ccc;
  border-radius: 12px;
  padding: 20px;
  width: 100%;
  margin-bottom: 30px;
}

/*--------------------------------------------------------------
# Superviser Dashboard page
--------------------------------------------------------------*/
@media (max-width: 1140px) {
  #superviser-page .navbar a {
    padding: 10px 20px;
  }

  #superviser-page .services h2,
  .our-values h2 {
    font-size: 36px;
    margin: 20px 0;
  }

  #superviser-page .services .services-block {
    padding: 0;
  }

  #superviser-page .services h3,
  .our-values h3 {
    font-size: 28px;
    margin: 20px 0;
  }

  #superviser-page .services p {
    font-size: 20px;
    line-height: 38px;
    color: #000;
    margin-bottom: 50px;
  }

  #superviser-page .post-button {
    margin: 30px auto;
  }

  #superviser-page .our-values p {
    line-height: 28px;
  }

  #superviser-page .valuable-clients .valuable-clients-block {
    padding: 70px 50px 30px;
  }

  #superviser-page .valuable-clients h2 {
    font-size: 36px;
  }

  #superviser-page .testimonial .carousel-control {
    top: 220px !important;
    width: 90% !important;
  }

  #superviser-page .send-massage .services-block1 h5 {
    font-size: 26px;
  }

  #superviser-page .send-massage .services-block1 p {
    font-size: 18px;
    line-height: 20px;
    color: #000;
    margin-bottom: 0;
  }

  #superviser-page .send-massage .services-block1 {
    padding: 20px;
  }

  #superviser-page .send-massage .post-button {
    line-height: 26px;
    padding: 10px 25px;
    margin: 20px auto 0;
  }

  #superviser-page .footer .call {
    font-size: 14px;
  }
}

@media (max-width: 980px) {
  #superviser-page .hero h1 {
    font-size: 32px;
    text-align: left;
  }

  #superviser-page .hero p {
    font-size: 18px;
    line-height: 20px;
    color: #fff;
    width: 80%;
    text-align: left;
  }

  #superviser-page .services p {
    font-size: 16px;
    line-height: 20px;
    color: #000;
    margin-bottom: 20px;
  }

  #superviser-page .services h3,
  #superviser-page .our-values h3 {
    font-size: 24px;
    margin: 20px 0 0;
  }

  #superviser-page .our-values p {
    font-size: 16px;
    line-height: 20px;
    color: #fff;
  }

  #superviser-page .valuable-clients .valuable-clients-block {
    padding: 20px;
  }

  #superviser-page .valuable-clients .valuable-clients-block .logo img {
    width: 100%;
  }

  #superviser-page .send-massage .services-block1 input {
    font-size: 15px;
    color: #797979;
    padding: 10px 25px;
    border-radius: 30px;
    border: 1px solid #ccc;
    width: 100%;
    margin-bottom: 10px;
  }

  #superviser-page .footer i {
    font-size: 24px;
    color: #fff;
    margin-right: 10px;
  }

  #superviser-page .footer .call {
    font-size: 11px;
  }

  #superviser-page .mobile-nav-toggle {
    color: #fff;
    position: absolute;
    right: 0;
  }

  #superviser-page .valuable-clients .logo {
    width: 50%;
  }

  #superviser-page .valuable-clients h2,
  #superviser-page .services h2,
  #superviser-page .our-values h2 {
    font-size: 26px;
  }

  #superviser-page .services .services-block1,
  #superviser-page .services .services-block2 {
    border: none;
    width: 100%;
  }
}

@media (max-width: 767px) {
  #superviser-page .header {
    padding: 20px 0;
    height: 100px;
  }

  #superviser-page #header .logo {
    width: 150px;
  }

  #superviser-page .navbar {
    margin-bottom: 0;
  }

  #superviser-page .navbar {
    position: relative;
    min-height: 50px;
    margin-bottom: 0;
    width: 100%;
  }

  #superviser-page .hero {
    height: auto;
    padding: 60px 0 20px 0;
  }

  #superviser-page .hero h1 {
    font-size: 20px;
    margin: 0;
  }

  #superviser-page .hero p {
    font-size: 14px;
  }

  #superviser-page .services h2,
  #superviser-page .our-values h2 {
    font-size: 20px;
    margin: 20px 0;
  }

  #superviser-page .padding-left1 {
    padding-right: 15px;
  }

  #superviser-page .padding-right1 {
    padding-right: 15px;
  }

  #superviser-page .post-button {
    width: 300px;
  }

  #superviser-page .valuable-clients h2 {
    font-size: 20px;
  }

  #superviser-page .valuable-clients h2 img {
    display: none;
  }

  section {
    padding: 0;
  }

  #superviser-page #quote-carousel .carousel-indicators {
    position: relative;
    left: 50%;
  }

  #superviser-page #quote-carousel .carousel-indicators .active {
    width: 128px;
    height: 78px;
  }

  #superviser-page .testimonial h6:before {
    display: none;
  }

  #superviser-page .testimonial .carousel-control {
    top: inherit !important;
    width: inherit !important;
  }

  #superviser-page .testimonial {
    padding: 0;
  }

  #superviser-page .testimonial h6 {
    color: #000;
    font-size: 26px;
  }

  #superviser-page .footer .call {
    font-size: 18px;
  }

  #superviser-page section.faq {
    padding: 30px 0;
  }

  #superviser-page .valuable-clients .logo {
    float: left;
    width: 50%;
  }

  #superviser-page .padding-left1 {
    padding-left: 15px;
  }

  #superviser-page .our-values-main-block div {
    padding: 5px 15px !important;
    border-radius: 15px;
  }

  #superviser-page .header .logo img {
    height: 30px;
  }

  #superviser-page .header.header-scrolled {
    padding: 0;
    height: 60px;
  }

  #superviser-page .valuable-clients {
    padding-top: 20px;
  }

  #superviser-page .about-goagent,
  #superviser-page .clering-goagent {
    margin-top: 40px;
  }

  #superviser-page .about-goagent h2,
  #superviser-page .clering-goagent h2,
  #superviser-page .payment-policy h2 {
    font-size: 28px;
  }

  #superviser-page .services h3,
  #superviser-page .our-values h3,
  #superviser-page .inner-about h3,
  #superviser-page .our-values h4,
  #superviser-page .about-goagent h3 {
    margin: 20px 0 10px;
  }

  #superviser-page p {
    font-size: 16px !important;
    line-height: 20px !important;
  }

  #superviser-page .about-goagent .about-goagent-block,
  #superviser-page .clering-goagent .clering-goagent-block {
    margin-top: 0;
  }

  #superviser-page .inner-about .services-block1 {
    border: none;
  }

  #superviser-page .inner-about-block .padding-left1 {
    padding-left: 0;
  }

  #superviser-page .inner-about-block .padding-right1 {
    padding-right: 0;
  }

  #superviser-page .inner-about-block .services .services-block,
  #superviser-page .inner-about-block .services .services-block1,
  #superviser-page .inner-about-block .inner-about .services-block {
    padding: 0;
  }

  #superviser-page .inner-about-block .inner-about .services-block,
  #superviser-page .inner-about-block .inner-about .services-block1,
  #superviser-page .inner-about-block .inner-about .services-block {
    padding: 0 !important;
  }

  #superviser-page .inner-about-block .inner-about .mission {
    padding-right: 0 !important;
  }

  #superviser-page .inner-about-block .inner-about .vision {
    padding-left: 0 !important;
  }

  #superviser-page .inner-about-block .inner-about .vision .services-block {
    padding-left: 15px !important;
  }

  #superviser-page .faq .send-cv {
    font-size: 16px;
  }

  #superviser-page .clering-goagent .post-button {
    padding: 10px 10px;
    font-size: 18px;
    width: auto;
  }

  #superviser-page .post-button,
  #superviser-page .send-massage .post-button {
    padding: 7px 20px;
    font-size: 18px;
  }

  #superviser-page .navbar li {
    position: relative;
    padding: 10px;
  }

  #superviser-page .header.header-scrolled .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  #superviser-page .header.header-scrolled .navbar ul {
    padding-left: 0 !important;
  }

  #superviser-page .cargoheading1 {
    width: 150px !important;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  #superviser-page .hero {
    height: auto;
    padding: 180px 0 60px 0;
  }

  #superviser-page #quote-carousel .carousel-indicators .active {
    width: 128px;
    height: 128px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  #superviser-page #quote-carousel .carousel-indicators .active {
    width: 128px;
    height: 78px;
  }
}

/*--------------------------------------------------------------
  # CLIENT SECTION
  --------------------------------------------------------------*/
#superviser-page .bodycont {
  padding: 130px 0 0 0;
  height: 100%;
}

#superviser-page .bodycont h2 {
  text-align: center;
  font-size: 30px;
  color: #007679;
  font-weight: 600;
  padding: 0 0 10px 0;
  margin: 0px !important;
}

#superviser-page .bodycont h3 {
  text-align: center;
  font-size: 20px;
  color: #007679;
  font-weight: 600;
  padding: 0 0 10px 0;
  margin: 0px !important;
}

#superviser-page .personal-info {
  padding: 60px;
  border: 1px solid #eee;
  margin-top: 70px;
  text-align: center;
}

#superviser-page .form-control {
  padding: 10px 25px;
  height: 60px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: none;
  margin-bottom: 10px;
}

#superviser-page .personal-info .form-control {
  margin-bottom: 10px;
}

#superviser-page .buttonsec {
  padding: 15px 0 15px;
}

#superviser-page .submitbut {
  padding: 15px 45px;
  min-width: 250px;
  border-radius: 35px;
  border: none;
  background: #007679;
  color: #fff;
  font-size: 16px;
  margin: 0 8px 8px 0;
  transition: all 0.2s ease-in-out 0s;
}

#superviser-page .submitbut:hover {
  background: #01a8af;
  color: #fff;
}

#superviser-page a.submitbut,
#superviser-page a.backbut {
  padding: 15px 45px;
  min-width: 250px;
  border-radius: 35px;
  border: none;
  background: #007679;
  color: #fff;
  font-size: 16px;
  margin: 0 8px 8px 0;
  display: inline-block;
  text-align: center;
}

#superviser-page a.backbut {
  background: #01a8af;
}

#superviser-page a:hover.backbut,
#superviser-page a:focus.backbut {
  background: #007679;
  color: #fff;
}

#superviser-page a:hover.submitbut,
#superviser-page a:focus.submitbut,
#superviser-page a:focus.detailsdiv,
#superviser-page a:focus.review {
  background: #01a8af;
  color: #fff;
}

#superviser-page .profilesec {
  padding: 0;
  font-size: 28px;
  font-weight: 500;
  margin-top: -145px;
}

#superviser-page .profileimage img {
  border-radius: 100%;
  border: 8px solid #eee;
}

#superviser-page .profileimage {
  width: 163px;
  margin: 0 auto 20px;
  position: relative;
}

#superviser-page .profileimage span a {
  position: absolute;
  right: 0;
  top: 65%;
  background: #007679;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  font-size: 13px;
  line-height: 35px;
  color: #fff;
  display: block;
}

#superviser-page .profilecont {
  display: flex;
}

#superviser-page .profilecontleft,
#superviser-page .profilecontright {
  width: 50%;
  text-align: left;
  padding: 20px 40px;
  font-size: 17px;
  line-height: 22px;
}

#superviser-page .profilecontright {
  border-left: 1px solid #e3e3e3;
}

#superviser-page .loginright .dropdown-menu {
  left: inherit !important;
  right: 0;
  margin-top: 8px;
  font-size: 12px;
}

#superviser-page .payment-info {
  padding: 10px 60px;
  border: 1px solid #eee;
  text-align: center;
}

#superviser-page .payment-details-block,
#superviser-page .payment-details-block1 {
  width: 50%;
  padding: 0 25px;
  float: left;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #ccc;
}

#superviser-page .payment-details-block {
  border-right: 1px solid #ccc;
  font-size: 20px;
}

#superviser-page .finish {
  padding: 15% 0;
  font-size: 22px;
  line-height: 28px;
  font-weight: 500;
}

#superviser-page .finish h3 {
  padding: 0 0 10px;
}

#superviser-page .paymentstep {
  padding: 25px 0 45px;
  text-align: center;
}

#superviser-page .paymentstep li {
  display: inline-block;
  padding: 0 7%;
  font-size: 18px;
  font-weight: 500;
  background: url(../img/dotted.png) repeat-x 0 28px;
  width: 28%;
  vertical-align: top;
}

#superviser-page .paymentstep li span {
  width: 55px;
  height: 55px;
  display: block;
  margin: 0 auto 8px;
  background: #282828;
  color: #fff;
  border-radius: 100%;
  border: 9px solid #f5f5f5;
  font-weight: 600;
  line-height: 38px;
}

#superviser-page .paymentstep li.active {
  color: #01a8ac;
}

#superviser-page .paymentstep li.active span {
  background: #01a8ac;
}

#superviser-page .paymentstep li:first-child {
  background-position: 100% 28px;
  background-repeat: no-repeat;
}

#superviser-page .paymentstep li:last-child {
  background-position: -6% 28px;
  background-repeat: no-repeat;
}

#superviser-page .paymentstep li p {
  padding: 0;
  background: #fff;
}

#superviser-page .custom02 input[type='radio'] {
  display: none;
}

#superviser-page .custom02 label {
  position: relative;
  padding: 9px 3px 3px 28px;
  cursor: pointer;
  margin-left: 16px;
  text-align: left;
  float: left;
}

#superviser-page .custom02 label::before,
#superviser-page .custom02 label::after {
  position: absolute;
  content: '';
  top: 50%;
  border-radius: 100%;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

#superviser-page .custom02 label::before {
  left: 0;
  width: 18px;
  height: 18px;
  margin-top: -8px;
  background: #fff;
  border: 1px solid #ccc;
}

#superviser-page .custom02 label:hover::before {
  background: #fff;
}

#superviser-page .custom02 label::after {
  opacity: 0;
  left: 4px;
  width: 10px;
  height: 10px;
  margin-top: -4px;
  background: #4ad300;
  -webkit-transform: scale(2);
  transform: scale(2);
}

#superviser-page .custom02 input[type='radio']:checked + label::before {
  background: #fff;
}

#superviser-page .custom02 input[type='radio']:checked + label::after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

#superviser-page .payment-method {
  background: #f8f8f8;
  padding: 12px 18px 12px;
  border-radius: 47px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

#superviser-page .payment-method2 {
  background: #f8f8f8;
  padding: 18px 18px 12px;
  border-radius: 47px;
  border: 1px solid #ccc;
  margin-top: 10px;
}

#superviser-page .form-control {
  padding: 10px 25px !important;
  height: 60px !important;
  border-radius: 5px !important;
  border: 1px solid #ccc !important;
  box-shadow: none !important;
  margin-bottom: 10px !important;
}

#superviser-page .progressdiv1 {
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  margin-bottom: 15px;
}

#superviser-page .checkcontainer {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 17px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 500;
}

/* Hide the browser's default checkbox */
#superviser-page .checkcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
#superviser-page .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
#superviser-page .checkcontainer:hover input ~ .checkmark {
  background-color: #f8f8f8;
}

/* When the checkbox is checked, add a blue background */
#superviser-page .checkcontainer input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
#superviser-page .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
#superviser-page .checkcontainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
#superviser-page .checkcontainer .checkmark:after {
  left: 6px;
  top: 3px;
  width: 7px;
  height: 12px;
  border: solid #4ad300;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

#superviser-page .payment-method2 img {
  float: right;
  width: 120px;
  margin: 0 10px;
}

#superviser-page .cardicon {
  float: right;
}

#superviser-page .cardicon img {
  width: 40px;
  border-radius: 100%;
  margin: 0 10px;
}

#superviser-page .paymenttable .table > tbody > tr > td {
  border-top: none;
  padding: 8px 25px;
}

#superviser-page .paymenttable td:first-child {
  font-size: 20px;
  font-weight: 500;
  border-right: 1px solid #ccc;
  width: 50%;
}

#superviser-page .paymenttable {
  text-align: left;
}

#superviser-page .price {
  font-size: 20px;
  font-weight: 600;
  color: #01a8ac;
}

#superviser-page #nonResponsiveTabs {
  border: none;
}

#superviser-page #nonResponsiveTabs li.active > a {
  border: none;
}

#superviser-page #nonResponsiveTabs li {
  width: 31%;
  margin: 0 1%;
}

#superviser-page #nonResponsiveTabs > li > a {
  border-radius: 45px;
  background: #d1d1d1;
  color: #000;
  margin: 0;
  border: none;
  padding: 15px 10px;
}

#superviser-page #nonResponsiveTabs > li > a:hover {
  border: none;
}

#superviser-page #nonResponsiveTabs li.active a {
  background: #01a8ac;
  color: #fff;
}

#superviser-page .resistertab .tab-content {
  padding: 16px 0 0 0;
}

#superviser-page .resistertab .checkcontainer {
  margin: 25px 0;
}

#superviser-page .resistertab .panel-heading {
  padding: 15px;
}

#superviser-page .logindiv {
  padding: 90px;
  box-shadow: 0 0 5px #ddd;
  width: 675px;
  margin: 0 auto;
}

#superviser-page .logindiv .submitbut {
  width: 100%;
}

#superviser-page .logindiv a {
  text-decoration: underline;
}

#superviser-page .logindiv a:hover {
  text-decoration: none;
}

#superviser-page .logindiv p {
  padding: 0 0 30px;
}

#superviser-page .logindiv h3 {
  padding: 25px 0;
  color: #999;
  font-size: 24px;
}

#superviser-page .keepcheck {
  padding: 0 0 25px;
}

#superviser-page .keepcheck a {
  float: right;
  text-decoration: none;
}

#superviser-page .dashcont {
  display: flex;
}

#superviser-page .dashlink {
  width: 475px;
  background: #f4f4f4;
  padding: 85px 0;
}

#superviser-page .dashrightcont {
  width: 100%;
  padding: 80px 50px 50px;
}

#superviser-page .dashrightcont .bodycont {
  padding: 0;
}

#superviser-page .dashlink ul {
  padding-left: 0 !important;
}

#superviser-page .dashlink ul li {
  margin-bottom: 3px;
  list-style-type: none;
}

#superviser-page .dashlink ul li a {
  padding: 25px 15px 25px 75px;
  display: block;
  font-size: 23px;
  font-weight: 500;
  color: #000;
}

#superviser-page .dashlink ul li a:hover,
#superviser-page .dashlink ul li.active a {
  background: #fff;
  color: #007679;
}

#superviser-page .dashlink ul li:last-child a {
  margin-top: 70px;
  color: #df0000;
}

#superviser-page .dashlink h4 {
  padding: 25px 15px 45px 75px;
  font-size: 23px;
  font-weight: 500;
  color: #000;
}

#superviser-page .servicecheck {
  padding: 0 0 35px;
}

#superviser-page .servicecheck label {
  margin: 0 20px;
}

#superviser-page .serviceradio label {
  float: none;
  font-weight: normal;
}

#superviser-page .serviceradio {
  padding: 15px 0 25px;
}

#superviser-page .payment-info h5 {
  font-size: 24px;
  color: #000 !important;
  padding: 0 0 30px;
  font-weight: 600;
}

#superviser-page .attached {
  padding: 0 0 25px;
  text-align: left;
  color: #878787;
  font-size: 18px;
}

#superviser-page .form-control {
  background: url(../img/arrow1.png) no-repeat 95% 26px #f8f8f8;
}

#superviser-page .uploaddiv {
  padding: 18px 80px 18px 25px;
  border: 1px solid #ccc;
  border-radius: 55px;
  font-size: 16px;
  color: #000;
  margin-bottom: 8px;
  position: relative;
}

#superviser-page .upload-btn-wrapper {
  position: absolute;
  overflow: hidden;
  display: inline-block;
  float: right;
  top: 3px;
  right: 6px;
}

#superviser-page .btn {
  border: 2px solid #007679;
  color: gray;
  background-color: #007679;
  padding: 10px 15px;
  border-radius: 38px;
  font-size: 20px;
  font-weight: bold;
}

#superviser-page .upload-btn-wrapper input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

#superviser-page .servicetab .nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  align-items: center !important;
  justify-content: center !important;
}

#superviser-page .servicetab .nav > li > a {
  padding: 17px 15px;
  color: #1d1d1d;
  font-size: 20px;
  margin: 0;
  border: none;
}

#superviser-page .servicetab .nav-tabs > li {
  width: 33.33%;
  text-align: center;
  margin-left: 0;
  margin-right: 0;
  position: relative;
  z-index: 9;
}

#superviser-page .servicetab .nav-tabs > li.active a {
  color: #007679;
  border: none;
  box-shadow: 0px -4px 5px #ddd;
}

#superviser-page .servicetab .nav-tabs {
  border-bottom: none;
}

#superviser-page .servicetab .nav > li > a:hover {
  background: none;
  color: #007679;
  border: none;
}

#superviser-page .servicetab .tab-content {
  box-shadow: 0 0 5px #ccc;
  padding: 75px 40px;
  text-align: center;
}

#superviser-page .servicetab h3 {
  color: #000;
}

#superviser-page .progressdiv {
  padding: 28px 20px;
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  margin-bottom: 15px;
}

#superviser-page .progressheading {
  position: relative;
  float: left;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 0 0 50px;
}

#superviser-page .progressheading span {
  position: absolute;
  left: 0;
  top: 4px;
  background: #01a8ac;
  width: 35px;
  height: 35px;
  color: #fff;
  font-size: 14px;
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
}

#superviser-page a.detailsdiv {
  float: right;
  background: #007679;
  padding: 10px 35px;
  color: #fff;
  border-radius: 30px;
  font-size: 16px;
}

#superviser-page a:hover.detailsdiv {
  background: #01a8af;
  color: #fff;
  text-decoration: none;
}

#superviser-page .servicetabout {
  text-align: center;
}

#superviser-page .servicetabout #nonResponsiveTabs li {
  float: none;
  display: inline-block;
}

#superviser-page .margin-right {
  margin-right: 5px;
}

#superviser-page .servicetabout #nonResponsiveTabs {
  border: none;
  padding-bottom: 45px;
}

#superviser-page .servicetabout .tab-content {
  padding: 0;
  box-shadow: none;
  text-align: left;
}

#superviser-page .servicetabout #nonResponsiveTabs li.active::after {
  content: '';
  background: url(../img/arrow.png) no-repeat 0 0;
  width: 19px;
  height: 13px;
  display: block;
  left: 48%;
  position: absolute;
}

#superviser-page .clearing {
  padding: 0 10px;
  border: 1px solid #cfcfcf;
  margin-bottom: 15px;
  border-radius: 8px;
  /*justify-content: center;*/
  /* align horizontal */
  align-items: center;
  text-align: left;
}

#superviser-page .clearing ul {
  margin-bottom: 0 !important;
}

#superviser-page .clearing li {
  list-style-type: none;
  font-size: 22px;
  color: #000;
  font-weight: 500;
  background: url(../img/devider.png) no-repeat right center;
  display: inline-block;
  min-height: 99px;
  vertical-align: top;
}

#superviser-page .heading1 {
  padding: 36px 15px 0 10px;
  font-size: 20px;
  font-weight: 700;
}

#superviser-page .heading {
  float: left;
  padding: 22px 15px 0 15px;
  line-height: 25px;
}

#superviser-page .cargoheading1,
#superviser-page .cargoheading2 {
  padding: 16px 15px;
  font-size: 20px;
  font-weight: 700;
}

#superviser-page .clearing p {
  font-size: 14px;
  font-weight: 300;
  padding: 0;
}

#superviser-page .batch {
  float: left;
}

#superviser-page .heading span {
  color: #8e8e8e;
  font-size: 18px;
}

#superviser-page .cargoheading {
  padding: 36px 44px;
}

#superviser-page .cargoprice {
  color: #01a8ac;
}

#superviser-page .clearing li:last-child {
  background: none;
}

#superviser-page .starsec {
  padding: 30px 0 0 37px;
}

#superviser-page .starsec a .fa,
#superviser-page .starsec1 a .fa {
  color: #ffc107;
  padding: 0 2px;
}

#superviser-page a.review {
  padding: 10px 31px;
  background: #007679;
  font-size: 15px;
  margin: 0 15px;
  font-weight: 500;
  border-radius: 18px;
  color: #fff;
  display: inline-block;
  white-space: nowrap;
}

#superviser-page a:hover.review {
  background: #01a8af;
  color: #fff;
}

#superviser-page .progressdiv1 {
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  margin-bottom: 15px;
}

#superviser-page .progressdiv1 .progressheading-new {
  width: 100%;
  padding: 26px 70px 10px 70px;
  font-size: 20px;
  font-weight: 600;
  position: relative;
}

#superviser-page .progressdiv1 .progressheading-new p {
  font-size: 18px;
  font-weight: 300;
}

#superviser-page .progressdiv1 .progressheading {
  width: 59%;
  background: url(../img/devider.png) no-repeat right center;
  padding: 36px 0 45px 70px;
}

#superviser-page .progressheading span,
.progressheading-new span {
  position: absolute;
  left: 0;
  top: 4px;
  background: #01a8ac;
  width: 35px;
  height: 35px;
  color: #fff;
  font-size: 14px;
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
}

#superviser-page .progressdiv1 .progressheading span,
#superviser-page .progressdiv1 .progressheading-new span {
  position: absolute;
  left: 20px;
  top: 29px;
}

#superviser-page .dayleft {
  width: 20%;
  float: left;
  text-align: center;
  background: url(../img/devider.png) no-repeat right center;
  padding: 30px;
  color: #01a8ac;
  font-size: 16px;
}

#superviser-page .progressdiv1 .progressheading span {
  position: absolute;
  left: 20px;
  top: 29px;
}

#superviser-page .progressdiv1 a.detailsdiv {
  margin: 29px 15px;
}

#superviser-page .detailsservice {
  border: 1px solid #cfcfcf;
  margin-bottom: 15px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  text-align: left;
}

#superviser-page .batleft img {
  margin-bottom: 5px;
}

#superviser-page .batleft {
  width: 15%;
  padding: 10px;
  float: left;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  background: url(../img/devider.png) no-repeat right center;
}

#superviser-page .battext {
  float: left;
  width: 25%;
  text-align: center;
  padding: 60px 25px;
  min-height: 143px;
  font-size: 20px;
  font-weight: 600;
  background: url(../img/devider.png) no-repeat right center;
}

#superviser-page .battext1 {
  float: left;
  width: 15%;
  text-align: center;
  padding: 43px 25px;
  min-height: 143px;
  font-size: 20px;
  font-weight: 600;
  background: url(../img/devider.png) no-repeat right center;
}

#superviser-page .batreview {
  width: 20%;
  padding: 30px 15px;
  float: left;
  text-align: center;
  background: url(../img/devider.png) no-repeat right center;
}

#superviser-page .batreview .fa {
  font-size: 22px;
  margin: 1px;
  color: #ffc107;
}

#superviser-page .batreview a.review {
  display: inline-block;
  margin-top: 9px;
  padding: 10px 25px;
  color: #007679;
  border: 1px solid #007679;
  background: #fff;
}

#superviser-page .batcurrent {
  width: 20%;
  padding: 43px 24px;
  float: left;
  background: url(../img/devider.png) no-repeat right center;
  font-size: 18px;
  font-weight: 500;
}

#superviser-page .batcurrent1 {
  width: 15%;
  padding: 43px 24px;
  float: left;
  background: url(../img/devider.png) no-repeat right center;
  font-size: 18px;
  font-weight: 500;
}

#superviser-page .batcurrent2 {
  width: 17%;
  padding: 60px 5px;
  float: left;
  background: url(../img/devider.png) no-repeat right center;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
}

#superviser-page .batcurrent span,
.batcurrent1 span {
  display: block;
  font-weight: 600;
  color: #01a8ac;
  font-size: 22px;
  padding-top: 5px;
}

#superviser-page .detailsservice a.detailsdiv {
  margin: 47px 18px;
}

#superviser-page .tabheading h3 {
  float: left;
  padding: 0 0 20px;
}

#superviser-page .tabheading h4 {
  float: right;
  font-size: 22px;
  padding: 8px;
  color: #007679;
}

#superviser-page .reson-reject {
  text-align: center;
  padding: 0 20px 20px;
}

#superviser-page .reson-reject h5 {
  font-size: 22px;
  font-weight: 600;
  color: #007679;
  margin: 0 0 10px;
}

#superviser-page .modal-header {
  padding: 15px;
  border-bottom: none !important;
}

#superviser-page .modal-dialog {
  width: 1000px !important;
  margin: 80px auto;
}

#superviser-page .modal-dialog1 {
  width: 500px !important;
}

#superviser-page .modal-header .close {
  margin-top: -40px !important;
  margin-right: -105% !important;
  width: 30px !important;
  height: 30px !important;
  background: #fff !important;
  border-radius: 50% !important;
  opacity: 1 !important;
}

#superviser-page .modal-dialog .clearing {
  padding: 10px;
  border: 1px solid #cfcfcf;
  margin-bottom: 15px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  text-align: left;
}

#superviser-page .modal-dialog .clearing li {
  list-style-type: none;
  font-size: 22px;
  color: #000;
  font-weight: 500;
  background: url(../img/devider.png) no-repeat right center;
  display: inline-block;
  min-height: 99px;
  vertical-align: top;
}

#superviser-page .modal-dialog .heading1 {
  padding: 36px 15px 0 10px;
  font-size: 24px !important;
  font-weight: bold;
}

#superviser-page .modal-dialog .clearing p {
  font-size: 14px;
  font-weight: 300;
  padding: 0;
}

#superviser-page .modal-dialog .cargoheading {
  padding: 36px 15px;
  font-size: 18px;
}

#superviser-page .modal-dialog .cargoheading2 {
  padding: 10px !important;
}

#superviser-page .payment-info h5 {
  font-size: 24px;
  color: #000 !important;
  padding: 0 0 30px;
  font-weight: 600;
}

/* Style the tab */
#superviser-page .tab {
  float: left;
  background-color: #f4f4f4;
  width: 15%;
  padding: 80px 0;
  margin-top: -30px;
  position: fixed;
  overflow: hidden;
  height: 100%;
}

/* Style the buttons inside the tab */
#superviser-page .tab button {
  display: block;
  background-color: inherit;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 23px;
  font-weight: 500;
  color: #000;
  padding: 15px 25px 15px 30px;
}

/* Change background color of buttons on hover */
#superviser-page .tab button:hover {
  background-color: #ddd;
}

/* Create an active/current "tab button" class */
#superviser-page .tab button.active {
  background-color: #fff;
}

/* Style the tab content */
#superviser-page .tabcontent {
  float: right;
  padding: 0px 30px !important;
  width: 85%;
  border-left: none;
  margin-top: -140px;
  padding: 0;
  height: 100%;
}

#superviser-page .dashlink button {
  display: block;
  font-size: 23px;
  font-weight: 500;
  color: #000;
}

#superviser-page .tab button:hover,
#superviser-page .tab button.active {
  background: #fff;
  color: #007679;
}

#superviser-page .tab h4 {
  padding: 25px 15px 45px 75px;
  font-size: 23px;
  font-weight: 500;
  color: #000;
}

#superviser-page .progressdiv1 {
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  margin-bottom: 15px;
}

#superviser-page .progressdiv1 .progressheading {
  width: 59%;
  background: url(../img/devider.png) no-repeat right center;
  padding: 36px 0 45px 70px;
}

#superviser-page .dayleft {
  width: 20%;
  float: left;
  text-align: center;
  background: url(../img/devider.png) no-repeat right center;
  padding: 30px;
  color: #01a8ac;
  font-size: 16px;
}

#superviser-page .progressdiv1 .progressheading span,
#superviser-page .progressdiv1 .progressheading-new span {
  position: absolute;
  left: 20px;
  top: 29px;
}

#superviser-page .progressdiv1 a.detailsdiv {
  margin: 29px 15px;
}

#superviser-page .nav-tabs-dropdown {
  display: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding-top: 120px;
}

#superviser-page .nav-tabs-dropdown:before {
  content: '\e114';
  font-family: 'Glyphicons Halflings';
  position: absolute;
  right: 30px;
}

@media screen and (min-width: 769px) {
  #superviser-page #nav-tabs-wrapper {
    display: block !important;
  }
}

@media screen and (max-width: 768px) {
  #superviser-page .nav-tabs-dropdown {
    display: block;
  }

  #superviser-page #nav-tabs-wrapper {
    display: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    text-align: center;
  }

  .popover {
    width: 320px !important;
  }

  #superviser-page .nav-tabs-horizontal {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  }

  #superviser-page .nav-tabs-horizontal > li {
    float: none;
  }

  #superviser-page .nav-tabs-horizontal > li + li {
    margin-left: 2px;
  }

  #superviser-page .nav-tabs-horizontal > li,
  #superviser-page .nav-tabs-horizontal > li > a {
    background: transparent;
    width: 100%;
  }

  #superviser-page .nav-tabs-horizontal > li > a {
    border-radius: 4px;
  }

  #superviser-page .nav-tabs-horizontal > li.active > a,
  #superviser-page .nav-tabs-horizontal > li.active > a:hover,
  #superviser-page .nav-tabs-horizontal > li.active > a:focus {
    color: #ffffff;
    background-color: #428bca;
  }
}

#superviser-page .content {
  float: left;
  width: 80%;
  padding-left: 1rem;
}

#superviser-page .cormen input[type='checkbox'],
#superviser-page .cormen label {
  display: none;
}

#superviser-page .cormen {
  /*not really necessary, only used to position the menu on desktop, use your own preferred method */
  display: inline-block;
  width: 100%;
  float: left;
  z-index: 9999;
}

#superviser-page .cormen input[type='checkbox'] {
  display: none;
}

#superviser-page .cormen ul {
  list-style-type: none;
  width: 100%;
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  #superviser-page .cormen {
    display: flex;
    flex-direction: column-reverse;
    position: fixed;
    /*styling only below, used to position the hamburger, 'width' will change depending on the 'left' value*/
    bottom: 10px;
    left: 10px;
    width: calc(100% - 58px);
    /*add a max width if you don't want it spanning the page*/
    max-width: 300px;
  }

  #superviser-page .cormen label {
    display: inline-block;
    width: 60px;
    height: 60px;
    position: relative;
    text-indent: 999px;
    font-size: 0;
    color: transparent;
    overflow: hidden;
    border-radius: 50%;
    background-color: #02a6aa;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  #superviser-page .cormen label::before {
    content: url('../img/list.svg');
    display: inline-block;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0;
    left: 0;
    text-indent: 0;
    overflow: visible;
    font-size: 22px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    line-height: 60px;
    color: #fff;
    font-size: 20px;
  }

  #superviser-page .cormen ul {
    list-style-type: none;
    width: 100%;
    margin: 0;
    /*styling only, below*/
    padding: 1rem 1rem 0 1rem;
    background-color: #eee;
    border-radius: 0.25rem;
    margin-bottom: 0.25rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  #superviser-page .cormen ul {
    display: none;
    min-width: 100%;
    border-radius: 1rem;
  }

  #superviser-page div.cormen input[type='checkbox']:checked + ul {
    display: block;
    animation-name: fadein;
    animation-duration: 350ms;
  }

  #superviser-page .tab button {
    padding: 10px 15px;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

#superviser-page .operator-info {
  padding: 20px 50px 50px 50px;
  border: 1px solid #cfcfcf;
  text-align: left;
  border-radius: 14px;
}

#superviser-page .disputes-block {
  margin-bottom: 60px;
}

#superviser-page .operator-info h5 {
  font-size: 30px;
  color: #000;
  padding: 0 0 10px;
  font-weight: 600;
}

#superviser-page .operator-info h6 {
  font-size: 22px;
  color: #000;
  padding: 0 0 20px;
  font-weight: 600;
}

#superviser-page .operator-info h4 {
  font-size: 18px;
  color: #007679;
  padding: 0 0 10px;
  font-weight: 600;
}

#superviser-page .buttonsec1 {
  padding: 15px 0 15px;
}

#superviser-page a.approvebut {
  padding: 10px 25px;
  min-width: 200px;
  border-radius: 35px;
  border: none;
  background: #007679;
  color: #fff;
  font-size: 16px;
  margin: 0 8px 8px 0;
  display: inline-block;
  text-align: center;
}

#superviser-page a.rejectbut {
  padding: 10px 25px;
  min-width: 200px;
  border-radius: 35px;
  border: none;
  background: #01a8ac;
  color: #fff;
  font-size: 16px;
  margin: 0 8px 8px 0;
  display: inline-block;
  text-align: center;
}

#superviser-page .disputes-img {
  float: left;
  margin-right: 10px;
}

#superviser-page a.rejectbut {
  padding: 10px 25px;
  min-width: 200px;
  border-radius: 35px;
  border: none;
  background: #01a8ac;
  color: #fff;
  font-size: 16px;
  margin: 0 8px 8px 0;
  display: inline-block;
  text-align: center;
}

#superviser-page .chat {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;
}

#superviser-page .clearingtable table p {
  padding: 0;
  font-size: 15px;
  line-height: 20px;
}

#superviser-page .clearingtable table {
  border-spacing: 1em 0.5em;
  text-align: center;
}

#superviser-page .clearingtable tr {
  border: 1px solid #ccc;
  padding: 10px;
}

#superviser-page .clearingtable td {
  padding: 0 20px;
  font-size: 22px;
}

#superviser-page .clearingtable td {
  background: url(../img/devider.png) no-repeat right center;
}

#superviser-page .clearingtable td:last-child {
  background: none;
}

#superviser-page .clearingtable thead tr {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  border-top: 1px solid #fff;
}

#superviser-page .clearingtable thead tr th {
  padding: 20px 10px;
  font-size: 16px;
  color: #007679;
  text-align: center;
  font-weight: 600;
}

#superviser-page a.detailsdiv1 {
  float: none;
  background: #007679;
  padding: 10px 35px;
  color: #fff;
  border-radius: 30px;
  font-size: 22px;
}

#superviser-page a:hover.detailsdiv1 {
  background: #01a8af;
  color: #fff;
  text-decoration: none;
}

#superviser-page .searchicon {
  background: url(../img/searchicon.png) no-repeat center 13px #007679;
  border: none;
  width: 48px;
  height: 48px;
  position: absolute;
  right: 10px;
  top: 6px;
  border-radius: 50%;
}

#superviser-page .receiptsfield {
  padding: 0 0 20px;
  position: relative;
}

#superviser-page .personal-info1 {
  padding: 60px;
  box-shadow: 0 0 5px #ddd;
  text-align: center;
}

#superviser-page .clearingtable td {
  font-size: 22px;
}

#superviser-page .description1 h4 {
  font-weight: 600;
  padding: 0 0 5px;
}

#superviser-page .clearingtable table p {
  padding: 0;
  font-size: 15px;
  line-height: 20px;
}

#superviser-page .datetext {
  display: block;
  font-size: 17px;
  color: #888;
  padding-top: 6px;
}

#superviser-page .clearingtable td {
  background: url(../img/devider.png) no-repeat right center;
}

#superviser-page .clearingtable td {
  padding: 0 20px;
  font-size: 22px;
}

#superviser-page .description1,
.description {
  text-align: left;
}

#superviser-page .resticted {
  background: #f4f4f4;
}

#superviser-page .clearingtable tr {
  border: 1px solid #ccc;
  padding: 10px;
  position: relative;
}

#superviser-page .resticted td span {
  position: absolute;
  width: 80%;
  left: 10%;
  top: 15px;
  font-size: 17px;
  background: url(../img/batch.png) no-repeat center 0;
  padding: 5px;
  color: #fff;
}

#superviser-page .clearingtable .resticted td {
  position: relative;
  padding: 60px 20px;
  color: #9b9b9b;
}

#superviser-page .oparator1,
#superviser-page .oparator2 {
  width: 200px;
  float: left;
  color: #01a8ac;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}

#superviser-page .oparator2 {
  width: 270px;
}

#superviser-page h5.operator-heading {
  font-size: 24px;
  color: #000;
  padding: 0 0 30px;
  font-weight: 600;
  text-align: center;
}

/*--------------------------------------------------------------
# Truck Inspector
--------------------------------------------------------------*/

@media (max-width: 1140px) {
  #truck-inspector-page .navbar a {
    padding: 10px 20px;
  }

  #truck-inspector-page .services h2,
  #truck-inspector-page .our-values h2 {
    font-size: 36px;
    margin: 20px 0;
  }

  #truck-inspector-page .services .services-block {
    padding: 0;
  }

  #truck-inspector-page .services h3,
  .our-values h3 {
    font-size: 28px;
    margin: 20px 0;
  }

  #truck-inspector-page .services p {
    font-size: 20px;
    line-height: 38px;
    color: #000;
    margin-bottom: 50px;
  }

  #truck-inspector-page .post-button {
    margin: 30px auto;
  }

  #truck-inspector-page .our-values p {
    line-height: 28px;
  }

  #truck-inspector-page .valuable-clients .valuable-clients-block {
    padding: 70px 50px 30px;
  }

  #truck-inspector-page .valuable-clients h2 {
    font-size: 36px;
  }

  #truck-inspector-page .testimonial .carousel-control {
    top: 220px !important;
    width: 90% !important;
  }

  #truck-inspector-page .send-massage .services-block1 h5 {
    font-size: 26px;
  }

  #truck-inspector-page .send-massage .services-block1 p {
    font-size: 18px;
    line-height: 20px;
    color: #000;
    margin-bottom: 0;
  }

  #truck-inspector-page .send-massage .services-block1 {
    padding: 20px;
  }

  #truck-inspector-page .send-massage .post-button {
    line-height: 26px;
    padding: 10px 25px;
    margin: 20px auto 0;
  }

  #truck-inspector-page .footer .call {
    font-size: 14px;
  }
}

@media (max-width: 980px) {
  #truck-inspector-page .hero h1 {
    font-size: 32px;
    text-align: left;
  }

  #truck-inspector-page .hero p {
    font-size: 18px;
    line-height: 20px;
    color: #fff;
    width: 80%;
    text-align: left;
  }

  #truck-inspector-page .services p {
    font-size: 16px;
    line-height: 20px;
    color: #000;
    margin-bottom: 20px;
  }

  #truck-inspector-page .services h3,
  .our-values h3 {
    font-size: 24px;
    margin: 20px 0 0;
  }

  #truck-inspector-page .our-values p {
    font-size: 16px;
    line-height: 20px;
    color: #fff;
  }

  #truck-inspector-page .valuable-clients .valuable-clients-block {
    padding: 20px;
  }

  #truck-inspector-page .valuable-clients .valuable-clients-block .logo img {
    width: 100%;
  }

  #truck-inspector-page .send-massage .services-block1 input {
    font-size: 15px;
    color: #797979;
    padding: 10px 25px;
    border-radius: 30px;
    border: 1px solid #ccc;
    width: 100%;
    margin-bottom: 10px;
  }

  #truck-inspector-page .footer i {
    font-size: 24px;
    color: #fff;
    margin-right: 10px;
  }

  #truck-inspector-page .footer .call {
    font-size: 11px;
  }

  #truck-inspector-page .mobile-nav-toggle {
    color: #fff;
    position: absolute;
    right: 0;
  }

  #truck-inspector-page .valuable-clients .logo {
    width: 50%;
  }

  #truck-inspector-page .valuable-clients h2,
  #truck-inspector-page .services h2,
  #truck-inspector-page .our-values h2 {
    font-size: 26px;
  }

  #truck-inspector-page .services .services-block1,
  #truck-inspector-page .services .services-block2 {
    border: none;
    width: 100%;
  }
}

@media (max-width: 767px) {
  #truck-inspector-page .header {
    padding: 20px 0;
    height: 100px;
  }

  #truck-inspector-page #header logo {
    width: 150px;
  }

  #truck-inspector-page .navbar {
    margin-bottom: 0;
  }

  #truck-inspector-page .navbar {
    position: relative;
    min-height: 50px;
    margin-bottom: 0;
    width: 100%;
  }

  #truck-inspector-page .hero {
    height: auto;
    padding: 60px 0 20px 0;
  }

  #truck-inspector-page .hero h1 {
    font-size: 20px;
    margin: 0;
  }

  #truck-inspector-page .hero p {
    font-size: 14px;
  }

  #truck-inspector-page .services h2,
  #truck-inspector-page .our-values h2 {
    font-size: 20px;
    margin: 20px 0;
  }

  #truck-inspector-page .padding-left1 {
    padding-right: 15px;
  }

  #truck-inspector-page .padding-right1 {
    padding-right: 15px;
  }

  #truck-inspector-page .post-button {
    width: 300px;
  }

  #truck-inspector-page .valuable-clients h2 {
    font-size: 20px;
  }

  #truck-inspector-page .valuable-clients h2 img {
    display: none;
  }

  #truck-inspector-page section {
    padding: 0;
  }

  #truck-inspector-page #quote-carousel .carousel-indicators {
    position: relative;
    left: 50%;
  }

  #truck-inspector-page #quote-carousel .carousel-indicators .active {
    width: 128px;
    height: 78px;
  }

  #truck-inspector-page .testimonial h6:before {
    display: none;
  }

  #truck-inspector-page .testimonial .carousel-control {
    top: inherit !important;
    width: inherit !important;
  }

  #truck-inspector-page .testimonial {
    padding: 0;
  }

  #truck-inspector-page .testimonial h6 {
    color: #000;
    font-size: 26px;
  }

  #truck-inspector-page .footer .call {
    font-size: 18px;
  }

  #truck-inspector-page section.faq {
    padding: 30px 0;
  }

  #truck-inspector-page .valuable-clients .logo {
    float: left;
    width: 50%;
  }

  #truck-inspector-page .padding-left1 {
    padding-left: 15px;
  }

  #truck-inspector-page .our-values-main-block div {
    padding: 5px 15px !important;
    border-radius: 15px;
  }

  #truck-inspector-page .header .logo img {
    height: 30px;
  }

  #truck-inspector-page .header.header-scrolled {
    padding: 0;
    height: 60px;
  }

  #truck-inspector-page .valuable-clients {
    padding-top: 20px;
  }

  #truck-inspector-page .about-goagent,
  .clering-goagent {
    margin-top: 40px;
  }

  #truck-inspector-page .about-goagent h2,
  .clering-goagent h2,
  .payment-policy h2 {
    font-size: 28px;
  }

  #truck-inspector-page .services h3,
  #truck-inspector-page .our-values h3,
  #truck-inspector-page .inner-about h3,
  #truck-inspector-page .our-values h4,
  #truck-inspector-page .about-goagent h3 {
    margin: 20px 0 10px;
  }

  #truck-inspector-page p {
    font-size: 16px !important;
    line-height: 20px !important;
  }

  #truck-inspector-page .about-goagent .about-goagent-block,
  #truck-inspector-page .clering-goagent .clering-goagent-block {
    margin-top: 0;
  }

  #truck-inspector-page .inner-about .services-block1 {
    border: none;
  }

  #truck-inspector-page .inner-about-block .padding-left1 {
    padding-left: 0;
  }

  #truck-inspector-page .inner-about-block .padding-right1 {
    padding-right: 0;
  }

  #truck-inspector-page .inner-about-block .services .services-block,
  #truck-inspector-page .inner-about-block .services .services-block1,
  #truck-inspector-page .inner-about-block .inner-about .services-block {
    padding: 0;
  }

  #truck-inspector-page .inner-about-block .inner-about .services-block,
  #truck-inspector-page .inner-about-block .inner-about .services-block1,
  #truck-inspector-page .inner-about-block .inner-about .services-block {
    padding: 0 !important;
  }

  #truck-inspector-page .inner-about-block .inner-about .mission {
    padding-right: 0 !important;
  }

  #truck-inspector-page .inner-about-block .inner-about .vision {
    padding-left: 0 !important;
  }

  #truck-inspector-page .inner-about-block .inner-about .vision .services-block {
    padding-left: 15px !important;
  }

  #truck-inspector-page .faq .send-cv {
    font-size: 16px;
  }

  #truck-inspector-page .clering-goagent .post-button {
    padding: 10px 10px;
    font-size: 18px;
    width: auto;
  }

  #truck-inspector-page .post-button,
  #truck-inspector-page .send-massage .post-button {
    padding: 7px 20px;
    font-size: 18px;
  }

  #truck-inspector-page .navbar li {
    position: relative;
    padding: 10px;
  }

  #truck-inspector-page .header.header-scrolled .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  #truck-inspector-page .header.header-scrolled .navbar ul {
    padding-left: 0 !important;
  }

  #truck-inspector-page .cargoheading1 {
    width: 150px !important;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  #truck-inspector-page .hero {
    height: auto;
    padding: 180px 0 60px 0;
  }

  #truck-inspector-page #quote-carousel .carousel-indicators .active {
    width: 128px;
    height: 128px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  #truck-inspector-page #quote-carousel .carousel-indicators .active {
    width: 128px;
    height: 78px;
  }
}

/*--------------------------------------------------------------
  # CLIENT SECTION
  --------------------------------------------------------------*/
#truck-inspector-page .bodycont {
  padding: 130px 0 0 0;
}

#truck-inspector-page .bodycont h2 {
  text-align: center;
  font-size: 30px;
  color: #007679;
  font-weight: 600;
  padding: 0 0 10px 0;
  margin: 0px !important;
}

#truck-inspector-page .bodycont h3 {
  text-align: center;
  font-size: 20px;
  color: #007679;
  font-weight: 600;
  padding: 0 0 10px 0;
  margin: 0px !important;
}

#truck-inspector-page .personal-info {
  padding: 60px;
  border: 1px solid #eee;
  /* margin-top: 70px; */
  text-align: center;
}

#truck-inspector-page .form-control {
  padding: 10px 25px;
  height: 60px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: none;
  margin-bottom: 10px;
}

#truck-inspector-page .personal-info .form-control {
  margin-bottom: 10px;
}

#truck-inspector-page .buttonsec {
  padding: 15px 0 15px;
}

#truck-inspector-page .submitbut {
  padding: 15px 45px;
  min-width: 250px;
  border-radius: 35px;
  border: none;
  background: #007679;
  color: #fff;
  font-size: 16px;
  margin: 0 8px 8px 0;
  transition: all 0.2s ease-in-out 0s;
}

#truck-inspector-page .submitbut:hover {
  background: #01a8af;
  color: #fff;
}

#truck-inspector-page a.submitbut,
#truck-inspector-page a.backbut {
  padding: 15px 45px;
  min-width: 250px;
  border-radius: 35px;
  border: none;
  background: #007679;
  color: #fff;
  font-size: 16px;
  margin: 0 8px 8px 0;
  display: inline-block;
  text-align: center;
}

#truck-inspector-page a.backbut {
  background: #01a8af;
}

#truck-inspector-page a:hover.backbut,
#truck-inspector-page #truck-inspector-page a:focus.backbut {
  background: #007679;
  color: #fff;
}

#truck-inspector-page a:hover.submitbut,
#truck-inspector-page a:focus.submitbut,
#truck-inspector-page a:focus.detailsdiv,
#truck-inspector-page a:focus.review {
  background: #01a8af;
  color: #fff;
}

#truck-inspector-page .profilesec {
  padding: 0;
  font-size: 28px;
  font-weight: 500;
  /* margin-top: -145px; */
}

#truck-inspector-page .profileimage img {
  border-radius: 100%;
  border: 8px solid #eee;
}

#truck-inspector-page .profileimage {
  width: 163px;
  margin: 0 auto 20px;
  position: relative;
}

#truck-inspector-page .profileimage span a {
  position: absolute;
  right: 0;
  top: 65%;
  background: #007679;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  font-size: 13px;
  line-height: 35px;
  color: #fff;
  display: block;
}

#truck-inspector-page .profilecont {
  display: flex;
}

#truck-inspector-page .profilecontleft,
#truck-inspector-page .profilecontright {
  width: 50%;
  text-align: left;
  padding: 20px 40px;
  font-size: 17px;
  line-height: 22px;
}

#truck-inspector-page .profilecontright {
  border-left: 1px solid #e3e3e3;
}

#truck-inspector-page .loginright .dropdown-menu {
  left: inherit !important;
  right: 0;
  margin-top: 8px;
  font-size: 12px;
}

#truck-inspector-page .payment-info {
  padding: 10px 60px;
  border: 1px solid #eee;
  text-align: center;
}

#truck-inspector-page .payment-details-block,
#truck-inspector-page .payment-details-block1 {
  width: 50%;
  padding: 0 25px;
  float: left;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #ccc;
}

#truck-inspector-page .payment-details-block {
  border-right: 1px solid #ccc;
  font-size: 20px;
}

#truck-inspector-page .finish {
  padding: 15% 0;
  font-size: 22px;
  line-height: 28px;
  font-weight: 500;
}

#truck-inspector-page .finish h3 {
  padding: 0 0 10px;
}

#truck-inspector-page .paymentstep {
  padding: 25px 0 45px;
  text-align: center;
}

#truck-inspector-page .paymentstep li {
  display: inline-block;
  padding: 0 7%;
  font-size: 18px;
  font-weight: 500;
  background: url(../img/dotted.png) repeat-x 0 28px;
  width: 28%;
  vertical-align: top;
}

#truck-inspector-page .paymentstep li span {
  width: 55px;
  height: 55px;
  display: block;
  margin: 0 auto 8px;
  background: #282828;
  color: #fff;
  border-radius: 100%;
  border: 9px solid #f5f5f5;
  font-weight: 600;
  line-height: 38px;
}

#truck-inspector-page .paymentstep li.active {
  color: #01a8ac;
}

#truck-inspector-page .paymentstep li.active span {
  background: #01a8ac;
}

#truck-inspector-page .paymentstep li:first-child {
  background-position: 100% 28px;
  background-repeat: no-repeat;
}

#truck-inspector-page .paymentstep li:last-child {
  background-position: -6% 28px;
  background-repeat: no-repeat;
}

#truck-inspector-page .paymentstep li p {
  padding: 0;
  background: #fff;
}

#truck-inspector-page .custom02 input[type='radio'] {
  display: none;
}

#truck-inspector-page .custom02 label {
  position: relative;
  padding: 9px 3px 3px 28px;
  cursor: pointer;
  margin-left: 16px;
  text-align: left;
  float: left;
}

#truck-inspector-page .custom02 label::before,
#truck-inspector-page .custom02 label::after {
  position: absolute;
  content: '';
  top: 50%;
  border-radius: 100%;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

#truck-inspector-page .custom02 label::before {
  left: 0;
  width: 18px;
  height: 18px;
  margin-top: -8px;
  background: #fff;
  border: 1px solid #ccc;
}

#truck-inspector-page .custom02 label:hover::before {
  background: #fff;
}

#truck-inspector-page .custom02 label::after {
  opacity: 0;
  left: 4px;
  width: 10px;
  height: 10px;
  margin-top: -4px;
  background: #4ad300;
  -webkit-transform: scale(2);
  transform: scale(2);
}

#truck-inspector-page .custom02 input[type='radio']:checked + label::before {
  background: #fff;
}

#truck-inspector-page .custom02 input[type='radio']:checked + label::after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

#truck-inspector-page .payment-method {
  background: #f8f8f8;
  padding: 12px 18px 12px;
  border-radius: 47px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

#truck-inspector-page .payment-method2 {
  background: #f8f8f8;
  padding: 18px 18px 12px;
  border-radius: 47px;
  border: 1px solid #ccc;
  margin-top: 10px;
}

#truck-inspector-page .form-control {
  padding: 10px 25px !important;
  height: 60px !important;
  border-radius: 5px !important;
  border: 1px solid #ccc !important;
  box-shadow: none !important;
  margin-bottom: 10px !important;
}

#truck-inspector-page .progressdiv1 {
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  margin-bottom: 15px;
}

#truck-inspector-page .checkcontainer {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 17px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 500;
}

/* Hide the browser's default checkbox */
#truck-inspector-page .checkcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
#truck-inspector-page .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
#truck-inspector-page .checkcontainer:hover input ~ .checkmark {
  background-color: #f8f8f8;
}

/* When the checkbox is checked, add a blue background */
#truck-inspector-page .checkcontainer input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
#truck-inspector-page .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
#truck-inspector-page .checkcontainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
#truck-inspector-page .checkcontainer .checkmark:after {
  left: 6px;
  top: 3px;
  width: 7px;
  height: 12px;
  border: solid #4ad300;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

#truck-inspector-page .payment-method2 img {
  float: right;
  width: 120px;
  margin: 0 10px;
}

#truck-inspector-page .cardicon {
  float: right;
}

#truck-inspector-page .cardicon img {
  width: 40px;
  border-radius: 100%;
  margin: 0 10px;
}

#truck-inspector-page .paymenttable .table > tbody > tr > td {
  border-top: none;
  padding: 8px 25px;
}

#truck-inspector-page .paymenttable td:first-child {
  font-size: 20px;
  font-weight: 500;
  border-right: 1px solid #ccc;
  width: 50%;
}

#truck-inspector-page .paymenttable {
  text-align: left;
}

#truck-inspector-page .price {
  font-size: 20px;
  font-weight: 600;
  color: #01a8ac;
}

#truck-inspector-page #nonResponsiveTabs {
  border: none;
}

#truck-inspector-page #nonResponsiveTabs li.active > a {
  border: none;
}

#truck-inspector-page #nonResponsiveTabs li {
  width: 31%;
  margin: 0 1%;
}

#truck-inspector-page #nonResponsiveTabs > li > a {
  border-radius: 45px;
  background: #d1d1d1;
  color: #000;
  margin: 0;
  border: none;
  padding: 15px 10px;
}

#truck-inspector-page #nonResponsiveTabs > li > a:hover {
  border: none;
}

#truck-inspector-page #nonResponsiveTabs li.active a {
  background: #01a8ac;
  color: #fff;
}

#truck-inspector-page .resistertab .tab-content {
  padding: 16px 0 0 0;
}

#truck-inspector-page .resistertab .checkcontainer {
  margin: 25px 0;
}

#truck-inspector-page .resistertab .panel-heading {
  padding: 15px;
}

#truck-inspector-page .logindiv {
  padding: 90px;
  box-shadow: 0 0 5px #ddd;
  width: 675px;
  margin: 0 auto;
}

#truck-inspector-page .logindiv .submitbut {
  width: 100%;
}

#truck-inspector-page .logindiv a {
  text-decoration: underline;
}

#truck-inspector-page .logindiv a:hover {
  text-decoration: none;
}

#truck-inspector-page .logindiv p {
  padding: 0 0 30px;
}

#truck-inspector-page .logindiv h3 {
  padding: 25px 0;
  color: #999;
  font-size: 24px;
}

#truck-inspector-page .keepcheck {
  padding: 0 0 25px;
}

#truck-inspector-page .keepcheck a {
  float: right;
  text-decoration: none;
}

#truck-inspector-page .dashcont {
  display: flex;
}

#truck-inspector-page .dashlink {
  width: 475px;
  background: #f4f4f4;
  padding: 85px 0;
}

#truck-inspector-page .dashrightcont {
  width: 100%;
  padding: 130px 0px 50px;
}

#truck-inspector-page .dashrightcont .bodycont {
  padding: 0;
}

#truck-inspector-page .dashlink ul {
  padding-left: 0 !important;
}

#truck-inspector-page .dashlink ul li {
  margin-bottom: 3px;
  list-style-type: none;
}

#truck-inspector-page .dashlink ul li a {
  padding: 25px 15px 25px 75px;
  display: block;
  font-size: 23px;
  font-weight: 500;
  color: #000;
}

#truck-inspector-page .dashlink ul li a:hover,
#truck-inspector-page .dashlink ul li.active a {
  background: #fff;
  color: #007679;
}

#truck-inspector-page .dashlink ul li:last-child a {
  margin-top: 70px;
  color: #df0000;
}

#truck-inspector-page .dashlink h4 {
  padding: 25px 15px 45px 75px;
  font-size: 23px;
  font-weight: 500;
  color: #000;
}

#truck-inspector-page .servicecheck {
  padding: 0 0 35px;
}

#truck-inspector-page .servicecheck label {
  margin: 0 20px;
}

#truck-inspector-page .serviceradio label {
  float: none;
  font-weight: normal;
}

#truck-inspector-page .serviceradio {
  padding: 15px 0 25px;
}

#truck-inspector-page .payment-info h5 {
  font-size: 24px;
  color: #000 !important;
  padding: 0 0 30px;
  font-weight: 600;
}

#truck-inspector-page {
  padding: 0 0 25px;
  text-align: left;
  /* color: #878787; */
}

#truck-inspector-page select.form-control {
  background: url(../img/arrow1.png) no-repeat 98% 26px #f8f8f8;
}

#truck-inspector-page .uploaddiv {
  padding: 18px 80px 18px 25px;
  border: 1px solid #ccc;
  border-radius: 55px;
  font-size: 16px;
  color: #000;
  margin-bottom: 8px;
  position: relative;
}

#truck-inspector-page .upload-btn-wrapper {
  position: absolute;
  overflow: hidden;
  display: inline-block;
  float: right;
  top: 3px;
  right: 6px;
}

#truck-inspector-page .btn {
  border: 2px solid #007679;
  color: gray;
  background-color: #007679;
  padding: 10px 15px;
  border-radius: 38px;
  font-size: 20px;
  font-weight: bold;
}

#truck-inspector-page .upload-btn-wrapper input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

#truck-inspector-page .servicetab .nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  align-items: center !important;
  justify-content: center !important;
}

#truck-inspector-page .servicetab .nav > li > a {
  padding: 17px 15px;
  color: #1d1d1d;
  font-size: 20px;
  margin: 0;
  border: none;
}

#truck-inspector-page .servicetab .nav-tabs > li {
  width: 33.33%;
  text-align: center;
  margin-left: 0;
  margin-right: 0;
  position: relative;
  z-index: 9;
}

#truck-inspector-page .servicetab .nav-tabs > li.active a {
  color: #007679;
  border: none;
  box-shadow: 0px -4px 5px #ddd;
}

#truck-inspector-page .servicetab .nav-tabs {
  border-bottom: none;
}

#truck-inspector-page .servicetab .nav > li > a:hover {
  background: none;
  color: #007679;
  border: none;
}

#truck-inspector-page .servicetab .tab-content {
  box-shadow: 0 0 5px #ccc;
  padding: 75px 40px;
  text-align: center;
}

#truck-inspector-page .servicetab h3 {
  color: #000;
}

#truck-inspector-page .progressdiv {
  padding: 28px 20px;
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  margin-bottom: 15px;
}

#truck-inspector-page .progressheading {
  position: relative;
  float: left;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 0 0 50px;
}

#truck-inspector-page .progressheading span {
  position: absolute;
  left: 0;
  top: 4px;
  background: #01a8ac;
  width: 35px;
  height: 35px;
  color: #fff;
  font-size: 14px;
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
}

#truck-inspector-page a.detailsdiv {
  float: right;
  background: #007679;
  padding: 10px 35px;
  color: #fff;
  border-radius: 30px;
  font-size: 16px;
}

#truck-inspector-page a:hover.detailsdiv {
  background: #01a8af;
  color: #fff;
  text-decoration: none;
}

#truck-inspector-page .servicetabout {
  text-align: center;
}

#truck-inspector-page .servicetabout #nonResponsiveTabs li {
  float: none;
  display: inline-block;
}

#truck-inspector-page .margin-right {
  margin-right: 5px;
}

#truck-inspector-page .servicetabout #nonResponsiveTabs {
  border: none;
  padding-bottom: 45px;
}

#truck-inspector-page .servicetabout .tab-content {
  padding: 0;
  box-shadow: none;
  text-align: left;
}

#truck-inspector-page .servicetabout #nonResponsiveTabs li.active::after {
  content: '';
  background: url(../img/arrow.png) no-repeat 0 0;
  width: 19px;
  height: 13px;
  display: block;
  left: 48%;
  position: absolute;
}

#truck-inspector-page .clearing {
  padding: 10px;
  border: 1px solid #cfcfcf;
  margin-bottom: 15px;
  border-radius: 8px;
  /*justify-content: center;*/
  /* align horizontal */
  align-items: center;
  text-align: left;
}

#truck-inspector-page .clearing li {
  list-style-type: none;
  font-size: 22px;
  color: #000;
  font-weight: 500;
  background: url(../img/devider.png) no-repeat right center;
  display: inline-block;
  min-height: 99px;
  vertical-align: top;
}

#truck-inspector-page .heading1 {
  padding: 36px 15px 0 10px;
  font-size: 20px;
  font-weight: 700;
}

#truck-inspector-page .heading {
  float: left;
  padding: 22px 15px 0 15px;
  line-height: 25px;
}

#truck-inspector-page .cargoheading1,
#truck-inspector-page .cargoheading2 {
  padding: 16px 15px;
  font-size: 20px;
  font-weight: 700;
}

#truck-inspector-page .clearing p {
  font-size: 14px;
  font-weight: 300;
  padding: 0;
}

#truck-inspector-page .batch {
  float: left;
}

#truck-inspector-page .heading span {
  color: #8e8e8e;
  font-size: 18px;
}

#truck-inspector-page .cargoheading {
  padding: 36px 44px;
}

#truck-inspector-page .cargoprice {
  color: #01a8ac;
}

#truck-inspector-page .clearing li:last-child {
  background: none;
}

#truck-inspector-page .starsec {
  padding: 30px 0 0 37px;
}

#truck-inspector-page .starsec a .fa,
#truck-inspector-page .starsec1 a .fa {
  color: #ffc107;
  padding: 0 2px;
}

#truck-inspector-page .starsec1 {
  padding: 30px 0 0 5px;
}

#truck-inspector-page a.review {
  padding: 10px 31px;
  background: #007679;
  font-size: 15px;
  margin: 0 15px;
  font-weight: 500;
  border-radius: 18px;
  color: #fff;
}

#truck-inspector-page a:hover.review {
  background: #01a8af;
  color: #fff;
}

#truck-inspector-page .progressdiv1 {
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  margin-bottom: 15px;
}

#truck-inspector-page .progressdiv1 .progressheading-new {
  width: 100%;
  padding: 26px 70px 10px 70px;
  font-size: 20px;
  font-weight: 600;
  position: relative;
}

#truck-inspector-page .progressdiv1 .progressheading-new p {
  font-size: 18px;
  font-weight: 300;
}

#truck-inspector-page .progressdiv1 .progressheading {
  width: 59%;
  background: url(../img/devider.png) no-repeat right center;
  padding: 36px 0 45px 70px;
}

#truck-inspector-page .progressheading span,
#truck-inspector-page .progressheading-new span {
  position: absolute;
  left: 0;
  top: 4px;
  background: #01a8ac;
  width: 35px;
  height: 35px;
  color: #fff;
  font-size: 14px;
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
}

#truck-inspector-page .progressdiv1 .progressheading span,
#truck-inspector-page .progressdiv1 .progressheading-new span {
  position: absolute;
  left: 20px;
  top: 29px;
}

#truck-inspector-page .dayleft {
  width: 20%;
  float: left;
  text-align: center;
  background: url(../img/devider.png) no-repeat right center;
  padding: 30px;
  color: #01a8ac;
  font-size: 16px;
}

#truck-inspector-page .progressdiv1 .progressheading span {
  position: absolute;
  left: 20px;
  top: 29px;
}

#truck-inspector-page .progressdiv1 a.detailsdiv {
  margin: 29px 15px;
}

#truck-inspector-page .detailsservice {
  border: 1px solid #cfcfcf;
  margin-bottom: 15px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  text-align: left;
}

#truck-inspector-page .batleft img {
  margin-bottom: 5px;
}

#truck-inspector-page .batleft {
  width: 15%;
  padding: 10px;
  float: left;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  background: url(../img/devider.png) no-repeat right center;
}

#truck-inspector-page .battext {
  float: left;
  width: 25%;
  text-align: center;
  padding: 60px 25px;
  min-height: 143px;
  font-size: 20px;
  font-weight: 600;
  background: url(../img/devider.png) no-repeat right center;
}

#truck-inspector-page .battext1 {
  float: left;
  width: 15%;
  text-align: center;
  padding: 43px 25px;
  min-height: 143px;
  font-size: 20px;
  font-weight: 600;
  background: url(../img/devider.png) no-repeat right center;
}

#truck-inspector-page .batreview {
  width: 20%;
  padding: 30px 15px;
  float: left;
  text-align: center;
  background: url(../img/devider.png) no-repeat right center;
}

#truck-inspector-page .batreview .fa {
  font-size: 22px;
  margin: 1px;
  color: #ffc107;
}

#truck-inspector-page .batreview a.review {
  display: inline-block;
  margin-top: 9px;
  padding: 10px 25px;
  color: #007679;
  border: 1px solid #007679;
  background: #fff;
}

#truck-inspector-page .batcurrent {
  width: 20%;
  padding: 43px 24px;
  float: left;
  background: url(../img/devider.png) no-repeat right center;
  font-size: 18px;
  font-weight: 500;
}

#truck-inspector-page .batcurrent1 {
  width: 15%;
  padding: 43px 24px;
  float: left;
  background: url(../img/devider.png) no-repeat right center;
  font-size: 18px;
  font-weight: 500;
}

#truck-inspector-page .batcurrent2 {
  width: 17%;
  padding: 60px 5px;
  float: left;
  background: url(../img/devider.png) no-repeat right center;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
}

#truck-inspector-page .batcurrent span,
#truck-inspector-page .batcurrent1 span {
  display: block;
  font-weight: 600;
  color: #01a8ac;
  font-size: 22px;
  padding-top: 5px;
}

#truck-inspector-page .detailsservice a.detailsdiv {
  margin: 47px 18px;
}

#truck-inspector-page .tabheading h3 {
  float: left;
  padding: 0 0 20px;
}

#truck-inspector-page .tabheading h4 {
  float: right;
  font-size: 22px;
  padding: 8px;
  color: #007679;
}

#truck-inspector-page .reson-reject {
  text-align: center;
  padding: 0 20px 20px;
}

#truck-inspector-page .reson-reject h5 {
  font-size: 22px;
  font-weight: 600;
  color: #007679;
  margin: 0 0 10px;
}

#truck-inspector-page .modal-header {
  padding: 15px;
  border-bottom: none !important;
}

#truck-inspector-page .modal-dialog {
  width: 1000px !important;
  margin: 80px auto;
}

#truck-inspector-page .modal-dialog1 {
  width: 500px !important;
}

#truck-inspector-page .modal-header .close {
  margin-top: -40px !important;
  margin-right: -105% !important;
  width: 30px !important;
  height: 30px !important;
  background: #fff !important;
  border-radius: 50% !important;
  opacity: 1 !important;
}

#truck-inspector-page .modal-dialog .clearing {
  padding: 10px;
  border: 1px solid #cfcfcf;
  margin-bottom: 15px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  text-align: left;
}

#truck-inspector-page .modal-dialog .clearing li {
  list-style-type: none;
  font-size: 22px;
  color: #000;
  font-weight: 500;
  background: url(../img/devider.png) no-repeat right center;
  display: inline-block;
  min-height: 99px;
  vertical-align: top;
}

#truck-inspector-page .modal-dialog .heading1 {
  padding: 36px 15px 0 10px;
  font-size: 24px !important;
  font-weight: bold;
}

#truck-inspector-page .modal-dialog .clearing p {
  font-size: 14px;
  font-weight: 300;
  padding: 0;
}

#truck-inspector-page .modal-dialog .cargoheading {
  padding: 36px 15px;
  font-size: 18px;
}

#truck-inspector-page .modal-dialog .cargoheading2 {
  padding: 10px !important;
}

#truck-inspector-page .payment-info h5 {
  font-size: 24px;
  color: #000 !important;
  padding: 0 0 30px;
  font-weight: 600;
}

/* Style the tab */
#truck-inspector-page .tab {
  float: left;
  background-color: #f4f4f4;
  width: 15%;
  padding: 80px 0;
  margin-top: -30px;
  height: 100%;
  position: fixed;
}

/* Style the buttons inside the tab */
#truck-inspector-page .tab button {
  display: block;
  background-color: inherit;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 23px;
  font-weight: 500;
  color: #000;
  padding: 15px 25px 15px 30px;
}

/* Change background color of buttons on hover */
#truck-inspector-page .tab button:hover {
  background-color: #ddd;
}

/* Create an active/current "tab button" class */
#truck-inspector-page .tab button.active {
  background-color: #fff;
}

/* Style the tab content */
#truck-inspector-page .tabcontent {
  float: right;
  padding: 0px 30px !important;
  width: 85%;
  border-left: none;
  margin-top: -140px;
  padding: 0;
}

#truck-inspector-page .dashlink button {
  display: block;
  font-size: 23px;
  font-weight: 500;
  color: #000;
}

#truck-inspector-page .tab button:hover,
#truck-inspector-page .tab button.active {
  background: #fff;
  color: #007679;
}

#truck-inspector-page .tab h4 {
  padding: 25px 15px 45px 75px;
  font-size: 23px;
  font-weight: 500;
  color: #000;
}

#truck-inspector-page .progressdiv1 {
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  margin-bottom: 15px;
}

#truck-inspector-page .progressdiv1 .progressheading {
  width: 59%;
  background: url(../img/devider.png) no-repeat right center;
  padding: 36px 0 45px 70px;
}

#truck-inspector-page .dayleft {
  width: 20%;
  float: left;
  text-align: center;
  background: url(../img/devider.png) no-repeat right center;
  padding: 30px;
  color: #01a8ac;
  font-size: 16px;
}

#truck-inspector-page .progressdiv1 .progressheading span,
#truck-inspector-page .progressdiv1 .progressheading-new span {
  position: absolute;
  left: 20px;
  top: 29px;
}

#truck-inspector-page .progressdiv1 a.detailsdiv {
  margin: 29px 15px;
}

#truck-inspector-page .nav-tabs-dropdown {
  display: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding-top: 120px;
}

#truck-inspector-page .nav-tabs-dropdown:before {
  content: '\e114';
  font-family: 'Glyphicons Halflings';
  position: absolute;
  right: 30px;
}

@media screen and (min-width: 769px) {
  #truck-inspector-page #nav-tabs-wrapper {
    display: block !important;
  }
}

@media screen and (max-width: 768px) {
  #truck-inspector-page .nav-tabs-dropdown {
    display: block;
  }

  #truck-inspector-page #nav-tabs-wrapper {
    display: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    text-align: center;
  }

  #truck-inspector-page .nav-tabs-horizontal {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  }

  #truck-inspector-page .nav-tabs-horizontal > li {
    float: none;
  }

  #truck-inspector-page .nav-tabs-horizontal > li + li {
    margin-left: 2px;
  }

  #truck-inspector-page .nav-tabs-horizontal > li,
  #truck-inspector-page .nav-tabs-horizontal > li > a {
    background: transparent;
    width: 100%;
  }

  #truck-inspector-page .nav-tabs-horizontal > li > a {
    border-radius: 4px;
  }

  #truck-inspector-page .nav-tabs-horizontal > li.active > a,
  #truck-inspector-page .nav-tabs-horizontal > li.active > a:hover,
  #truck-inspector-page .nav-tabs-horizontal > li.active > a:focus {
    color: #ffffff;
    background-color: #428bca;
  }
}

#truck-inspector-page .content {
  float: left;
  width: 80%;
  padding-left: 1rem;
}

#truck-inspector-page .cormen input[type='checkbox'],
#truck-inspector-page .cormen label {
  display: none;
}

#truck-inspector-page .cormen {
  /*not really necessary, only used to position the menu on desktop, use your own preferred method */
  display: inline-block;
  width: 100%;
  float: left;
  z-index: 9999;
}

#truck-inspector-page .cormen input[type='checkbox'] {
  display: none;
}

#truck-inspector-page .cormen ul {
  list-style-type: none;
  width: 100%;
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  #truck-inspector-page .cormen {
    display: flex;
    flex-direction: column-reverse;
    position: fixed;
    /*styling only below, used to position the hamburger, 'width' will change depending on the 'left' value*/
    bottom: 10px;
    left: 10px;
    width: calc(100% - 58px);
    /*add a max width if you don't want it spanning the page*/
    max-width: 300px;
  }

  #truck-inspector-page .cormen label {
    display: inline-block;
    width: 60px;
    height: 60px;
    position: relative;
    text-indent: 999px;
    font-size: 0;
    color: transparent;
    overflow: hidden;
    border-radius: 50%;
    background-color: #02a6aa;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  #truck-inspector-page .cormen label::before {
    content: url('../img/list.svg');
    display: inline-block;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0;
    left: 0;
    text-indent: 0;
    overflow: visible;
    font-size: 22px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    line-height: 60px;
    color: #fff;
    font-size: 20px;
  }

  #truck-inspector-page .cormen ul {
    list-style-type: none;
    width: 100%;
    margin: 0;
    /*styling only, below*/
    padding: 1rem 1rem 0 1rem;
    background-color: #eee;
    border-radius: 0.25rem;
    margin-bottom: 0.25rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  #truck-inspector-page .cormen ul {
    display: none;
    min-width: 100%;
    border-radius: 1rem;
  }

  #truck-inspector-page div.cormen input[type='checkbox']:checked + ul {
    display: block;
    animation-name: fadein;
    animation-duration: 350ms;
  }

  #truck-inspector-page .tab button {
    padding: 10px 15px;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

#truck-inspector-page .operator-info {
  padding: 20px 50px 50px 50px;
  border: 1px solid #cfcfcf;
  text-align: left;
  border-radius: 14px;
}

#truck-inspector-page .disputes-block {
  margin-bottom: 60px;
}

#truck-inspector-page .operator-info h5 {
  font-size: 30px;
  color: #000;
  padding: 0 0 10px;
  font-weight: 600;
}

#truck-inspector-page .operator-info h6 {
  font-size: 22px;
  color: #000;
  padding: 0 0 20px;
  font-weight: 600;
}

#truck-inspector-page .operator-info h4 {
  font-size: 18px;
  color: #007679;
  padding: 0 0 10px;
  font-weight: 600;
}

#truck-inspector-page .buttonsec1 {
  padding: 15px 0 15px;
}

#truck-inspector-page a.approvebut {
  padding: 10px 25px;
  min-width: 200px;
  border-radius: 35px;
  border: none;
  background: #007679;
  color: #fff;
  font-size: 16px;
  margin: 0 8px 8px 0;
  display: inline-block;
  text-align: center;
}

#truck-inspector-page a.rejectbut {
  padding: 10px 25px;
  min-width: 200px;
  border-radius: 35px;
  border: none;
  background: #01a8ac;
  color: #fff;
  font-size: 16px;
  margin: 0 8px 8px 0;
  display: inline-block;
  text-align: center;
}

#truck-inspector-page .disputes-img {
  float: left;
  margin-right: 10px;
}

#truck-inspector-page a.rejectbut {
  padding: 10px 25px;
  min-width: 200px;
  border-radius: 35px;
  border: none;
  background: #01a8ac;
  color: #fff;
  font-size: 16px;
  margin: 0 8px 8px 0;
  display: inline-block;
  text-align: center;
}

#truck-inspector-page .chat {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;
}

#truck-inspector-page .clearingtable table p {
  padding: 0;
  font-size: 15px;
  line-height: 20px;
}

#truck-inspector-page .clearingtable table {
  border-spacing: 1em 0.5em;
  text-align: center;
}

#truck-inspector-page .clearingtable tr {
  border: 1px solid #ccc;
  padding: 10px;
}

#truck-inspector-page .clearingtable td {
  padding: 20px;
  font-size: 22px;
}

#truck-inspector-page .clearingtable td {
  background: url(../img/devider.png) no-repeat right center;
}

#truck-inspector-page .clearingtable td:last-child {
  background: none;
}

#truck-inspector-page .clearingtable thead tr {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  border-top: 1px solid #fff;
}

#truck-inspector-page .clearingtable thead tr th {
  padding: 20px 10px;
  font-size: 16px;
  color: #007679;
  text-align: center;
  font-weight: 600;
}

#truck-inspector-page a.detailsdiv1 {
  float: none;
  background: #007679;
  padding: 10px 35px;
  color: #fff;
  border-radius: 30px;
  font-size: 22px;
}

#truck-inspector-page a:hover.detailsdiv1 {
  background: #01a8af;
  color: #fff;
  text-decoration: none;
}

#truck-inspector-page .searchicon {
  background: url(../img/searchicon.png) no-repeat center 13px #007679;
  border: none;
  width: 48px;
  height: 48px;
  position: absolute;
  right: 10px;
  top: 6px;
  border-radius: 50%;
}

#truck-inspector-page .receiptsfield {
  padding: 0 0 20px;
  position: relative;
}

#truck-inspector-page .personal-info1 {
  padding: 60px;
  box-shadow: 0 0 5px #ddd;
  text-align: center;
}

#truck-inspector-page .clearingtable td {
  font-size: 22px;
}

#truck-inspector-page .description1 h4 {
  font-weight: 600;
  padding: 0 0 5px;
}

#truck-inspector-page .clearingtable table p {
  padding: 0;
  font-size: 15px;
  line-height: 20px;
}

#truck-inspector-page .datetext {
  display: block;
  font-size: 17px;
  color: #888;
  padding-top: 6px;
}

#truck-inspector-page .clearingtable td {
  background: url(../img/devider.png) no-repeat right center;
}

#truck-inspector-page .clearingtable td {
  padding: 0 20px;
  font-size: 22px;
}

#truck-inspector-page .description1,
#truck-inspector-page .description {
  text-align: left;
}

#truck-inspector-page .resticted {
  background: #f4f4f4;
}

#truck-inspector-page .clearingtable tr {
  border: 1px solid #ccc;
  padding: 10px;
  position: relative;
}

#truck-inspector-page .resticted td span {
  position: absolute;
  width: 80%;
  left: 10%;
  top: 15px;
  font-size: 17px;
  background: url(../img/batch.png) no-repeat center 0;
  padding: 5px;
  color: #fff;
}

#truck-inspector-page .clearingtable .resticted td {
  position: relative;
  padding: 60px 20px;
  color: #9b9b9b;
}

#truck-inspector-page .graybg {
  background: #f2f2f2;
}

#truck-inspector-page .trackcount {
  position: relative;
  padding: 0 0 0 40px;
}

#truck-inspector-page .trackcount span {
  position: absolute;
  left: 0;
  top: -8px;
  width: 37px;
  height: 37px;
  background: #01a8ac;
  line-height: 36px;
  border-radius: 50%;
  font-size: 17px;
  font-weight: 500;
  color: #fff;
}

#truck-inspector-page .clearingtable td {
  background: url(../img/devider.png) no-repeat right center;
}

#truck-inspector-page .clearingtable tr {
  border: 1px solid #ccc;
  padding: 10px;
}

#truck-inspector-page .clearingtable td {
  padding: 20px;
  font-size: 22px;
}

#truck-inspector-page td.trackdate p {
  padding: 0 0 8px;
  font-size: 17px;
  line-height: 20px;
}

#truck-inspector-page a.schedule {
  background: #007679;
  padding: 10px 45px;
  color: #fff;
  border-radius: 30px;
  font-size: 15px;
  display: inline-block;
  white-space: nowrap;
  margin: 0 5px 5px;
}

#truck-inspector-page .graybg a.schedule {
  background: #01a8ac;
}

#truck-inspector-page .graybg a.detailsdiv1 {
  background: #007679;
  padding: 10px 45px;
  color: #fff;
  border-radius: 30px;
  font-size: 15px;
  display: inline-block;
  white-space: nowrap;
  margin: 0 5px 5px;
}

#truck-inspector-page .clearingtable td:last-child {
  background: none;
}

#truck-inspector-page .detailsdiv1 {
  background: #848484;
  padding: 10px 45px;
  color: #fff;
  border-radius: 30px;
  font-size: 15px;
  display: inline-block;
  white-space: nowrap;
  margin: 0 5px 5px;
}

#truck-inspector-page a:hover.detailsdiv1 {
  background: #0b0b0b;
}

#client-page .operator-block .cargoheading {
  padding: 36px 34px;
  width: 300px;
}

#client-page .operator-block .starsec1 {
  padding: 30px 70px;
}

#client-page .operator-block .servicetab .nav-tabs li {
  /* width: 33.33%; */
  flex-grow: 1;
}

#client-page .tabcontent {
  float: right;
  padding: 0px 20px !important;
  width: 85%;
  border-left: none;
  margin-top: -140px;
  padding: 0;
}

#client-page .clearingtable table p {
  padding: 0;
  font-size: 15px;
  line-height: 20px;
}

#client-page .clearingtable table {
  border-spacing: 1em 0.5em;
  text-align: center;
}

#client-page .clearingtable tr {
  border: 1px solid #ccc;
  padding: 10px;
}

#client-page .clearingtable td {
  padding: 0 20px;
  font-size: 22px;
  height: 20px;
  max-height: 20px;
  line-height: 45px;
}

a.hyper-a {
  font-size: 16px;
  color: #007679 !important;
  padding: 0 10px;
}

#client-page .clearingtable td {
  background: url(../img/devider.png) no-repeat right center;
}

#client-page .clearingtable td:last-child {
  background: none;
}

#client-page .clearingtable thead tr {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  border-top: 1px solid #fff;
}

#client-page .clearingtable thead tr th {
  padding: 20px 10px;
  font-size: 16px;
  color: #007679;
  text-align: center;
  font-weight: 600;
}

.success-color {
  background-color: green !important;
}

.reject-color {
  background-color: red !important;
}

.fileUpload {
  background: #eee;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  font-size: 1em;
  font-weight: bold;
  margin: 1.25em auto;
  /*20px/16px 0*/
  overflow: hidden;
  padding: 0.875em;
  /*14px/16px*/
  position: relative;
  text-align: center;
  width: 300px !important;
  cursor: pointer;
  color: #333;
}

.modal .fileUpload {
  width: 260px !important;
}

.modal .container {
  width: 100% !important;
}

.fileUpload:hover,
.fileUpload:active,
.fileUpload:focus {
  background: #ccc;
  cursor: pointer;
}

.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 148px;
  height: 46px;
  cursor: pointer;
  text-align: center;
  background: #eee;
  color: #333;
  border: none;
}

input[type='file'] {
  right: 100%;
  bottom: 100%;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.full-width {
  width: 50% !important;
}

.full-modal-width {
  width: 100% !important;
}

.clearing-varification-mt {
  margin-top: 0px !important;
}

.mapouter {
  position: relative;
  text-align: right;
  width: 100%;
  height: 500px;
  margin-bottom: 20px;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 100%;
  height: 500px;
}

.gmap_iframe {
  height: 317px !important;
}

.complian-block {
  padding: 20px;
  margin-top: 0;
  width: 400px;
  margin: auto;
  text-align: left;
  border: 1px solid #eee;
}

.complian-block h3 {
  font-size: 16px !important;
  text-align: left !important;
}

.tab-complian ul {
  padding: 0;
  margin: 0 0 0 60px;
}

.tab-complian ul li {
  padding: 10px 0;
  margin: 0;
  font-weight: bold;
}

.tab-complian ul::before {
  content: '';
  position: absolute;
  width: 2px;
  height: 140px;
  background-color: #ccc;
  margin-top: 10px;
  margin-left: -16px;
}

.tab-complian.no-payment ul::before {
  height: 100px;
}

.tab-complian .tab-icon,
.tab-complian .tab-icon1 {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  font-size: 13px;
  text-align: center;
  color: #fff;
  position: absolute;
  margin: 0 0 0 -25px;
}

.tab-complian .tab-icon {
  background-color: green;
}

.tab-complian .tab-icon1 {
  background-color: #fff;
  border: 1px solid #ccc;
}

.complian-block .button-left {
  min-width: 100px;
  padding: 5px 15px;
  float: left;
}

.complian-block .button-right {
  min-width: 100px;
  padding: 5px 15px;
  float: right;
}

.tab-complian1 ul {
  padding: 0;
  margin: 0 0 0 60px;
}

.tab-complian1 ul li {
  padding: 10px 0;
  margin: 0;
  font-weight: bold;
}

.tab-complian1 ul::before {
  content: '';
  position: absolute;
  width: 2px;
  height: 96px;
  background-color: #ccc;
  margin-top: 10px;
  margin-left: -16px;
}

.tab-complian1 .tab-icon,
.tab-complian1 .tab-icon1 {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  font-size: 13px;
  text-align: center;
  color: #fff;
  position: absolute;
  margin: 0 0 0 -25px;
}

.tab-complian1 .tab-icon {
  background-color: green;
}

.tab-complian1 .tab-icon1 {
  background-color: #fff;
  border: 1px solid #ccc;
}

.no-found-div {
  height: 100px;
  border: 1px solid;
  border-color: #f1ecec;
}

.checkbox-cls label {
  margin-left: 10px;
}

.checkbox-cls .inner-box {
  width: 36px;
  height: 22px;
}

#nonResponsiveTabs label {
  margin-left: 10px;
  top: -6px;
  position: inherit;
}

.heading-home {
  color: white !important;
  width: 75% !important;
  margin-left: 163px !important;
  font-size: 25px !important;
  line-height: 36px !important;
}

.heading-home1 {
  color: #ffffff !important;
  font-size: 35px !important;
  font-weight: 700 !important;
}

.flexx {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Style The Dropdown Button */
.dropbtn {
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  background: none;
  padding: 10px 20px;
  border-radius: 20px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  color: #f9f9f9 !important;
  background-color: #007679;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: #f9f9f9 !important;

  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #02a6aa;
  border-radius: 0px !important;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #02a6aa;
  padding: 10px 20px;
  border-radius: 20px;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-30 {
  padding: 30px !important;
}

.job-id {
  cursor: pointer;
}

.job-id:hover {
  font-size: 25px;
  color: #007679;
}

ul.genaral-block {
  margin: 30px auto;
  text-align: center;
  width: 1060px;
}

ul.genaral-block li {
  float: left;
  width: 130px;
  list-style-type: none;
}

ul.genaral-block a {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  min-height: 260px;
  display: block;
}

ul.genaral-block a.ptc-svg {
  position: relative;
  top: -3px;
}

ul.genaral-block a span {
  font-size: 18px;
  color: #777777;
  font-weight: normal;
  display: block;
  height: 68px;
  position: relative;
  top: -15px;
  left: -5px;
}

ul.genaral-block a.active span {
  color: #01a8ac;
}

ul.genaral-block a:hover,
ul.genaral-block a:hover span {
  color: #01a8ac;
  text-decoration: none;
}

.cls-1 {
  fill: #adadad;
}

.cls-1,
.cls-2 {
  fill-rule: evenodd;
}

.cls-2 {
  fill: #fff;
  filter: url(#drop-shadow-1);
}

.clsl-1 {
  fill: #adadad;
}

.clsl-1,
.clsl-2,
.clsl-3,
.clsl-4,
.clsl-5 {
  fill-rule: evenodd;
}

.clsl-2 {
  fill: #fff;
  filter: url(#drop-shadow-1);
}

.clsl-3,
.clsl-4 {
  fill: #01a8ac;
}

.clsl-3 {
  filter: url(#color-overlay-1);
}

.clsl-4 {
  filter: url(#color-overlay-3);
}

.clsl-5 {
  stroke: #727272;
  stroke-width: 3px;
  filter: url(#color-overlay-4);
  fill: none;
}

.clss-1 {
  fill: #adadad;
}

.clss-1,
.clss-2,
.clss-3,
.clss-4,
.clss-5 {
  fill-rule: evenodd;
}

.clss-2 {
  fill: #fff;
  filter: url(#drop-shadow-1);
}

.clss-3,
.clss-4 {
  fill: #01a8ac;
}

.clss-3 {
  filter: url(#color-overlay-1);
}

.clss-4 {
  filter: url(#color-overlay-3);
}

.clss-5 {
  stroke: #727272;
  stroke-width: 3px;
  filter: url(#color-overlay-4);
  fill: none;
}

.stroke1 {
  stroke: #727272;
  filter: none;
  fill: none;
}

.stroke2 {
  stroke: inherit;
  filter: none;
  fill: inherit;
}

.fill1 {
  fill: none;
  filter: url(#color-overlay-4);
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.cls4-1 {
  fill: #adadad;
}

.cls4-1,
.cls4-2,
.cls4-3,
.cls4-4,
.cls4-5 {
  fill-rule: evenodd;
}

.cls4-2 {
  fill: #fff;
  filter: url(#drop-shadow-1);
}

.cls4-3,
.cls4-4 {
  fill: #01a8ac;
}

.cls4-3 {
  filter: url(#color-overlay-1);
}

.cls4-4 {
  filter: url(#color-overlay-3);
}

.cls4-5 {
  stroke: #727272;
  stroke-width: 3px;
  filter: url(#color-overlay-4);
  fill: none;
}

.g-4 {
  fill: none;
  filter: url(#color-overlay-4);
}

.u-4 {
  stroke: #727272;
  filter: none;
  fill: none;
}

.p-4 {
  stroke: inherit;
  filter: none;
  fill: inherit;
}

.cls5-1 {
  fill: #adadad;
}

.cls5-1,
.cls5-2,
.cls5-3,
.cls5-4,
.cls5-5 {
  fill-rule: evenodd;
}

.cls5-2 {
  fill: #fff;
  filter: url(#drop-shadow-1);
}

.cls5-3,
.cls5-4 {
  fill: #01a8ac;
}

.cls5-3 {
  filter: url(#color-overlay-1);
}

.cls5-4 {
  filter: url(#color-overlay-3);
}

.cls5-5 {
  stroke: #727272;
  stroke-width: 3px;
  filter: url(#color-overlay-4);
  fill: none;
}

.cls6-1 {
  fill: #adadad;
}

.cls6-1,
.cls6-2,
.cls6-3,
.cls6-4,
.cls6-5 {
  fill-rule: evenodd;
}

.cls6-2 {
  fill: #fff;
  filter: url(#drop-shadow-1);
}

.cls6-3,
.cls6-4 {
  fill: #01a8ac;
}

.cls6-3 {
  filter: url(#color-overlay-1);
}

.cls6-4 {
  filter: url(#color-overlay-3);
}

.cls6-5 {
  stroke: #727272;
  stroke-width: 3px;
  filter: url(#color-overlay-4);
  fill: none;
}

.cls7-1 {
  fill: #adadad;
}

.cls7-1,
.cls7-2,
.cls7-3,
.cls7-4,
.cls7-5 {
  fill-rule: evenodd;
}

.cls7-2 {
  fill: #fff;
  filter: url(#drop-shadow-1);
}

.cls7-3,
.cls7-4 {
  fill: #01a8ac;
}

.cls7-3 {
  filter: url(#color-overlay-1);
}

.cls7-4 {
  filter: url(#color-overlay-3);
}

.cls7-5 {
  stroke: #727272;
  stroke-width: 3px;
  filter: url(#color-overlay-4);
  fill: none;
}

.cls3-1 {
  fill: #adadad;
}

.cls3-1,
.cls3-2,
.cls3-3,
.cls3-4,
.cls3-5 {
  fill-rule: evenodd;
}

.cls3-2 {
  fill: #fff;
  filter: url(#drop-shadow-1);
}

.cls3-3,
.cls3-4 {
  fill: #01a8ac;
}

.cls3-3 {
  filter: url(#color-overlay-1);
}

.cls3-4 {
  filter: url(#color-overlay-3);
}

.cls3-5 {
  stroke: #727272;
  stroke-width: 3px;
  filter: url(#color-overlay-4);
  fill: none;
}

a:hover .cls-1,
a:hover .clsl-3,
a:hover .cls3-4,
a:hover .cls4-1,
a:hover .cls5-1,
a:hover .cls6-3,
a:hover .cls7-1,
a.active1,
a .active1 {
  fill: #01a8ac !important;
  filter: none !important;
  color: #01a8ac !important;
}

#resp-tab7 .card-body {
  flex: 1 1 auto;
  padding: 0 30px 30px;
}

.job-block {
  padding: 20px;
}

.onoffswitch {
  position: relative;
  width: 46px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  margin: 40px auto 0;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #ffffff;
  border-radius: 20px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 22px;
  padding: 0;
  line-height: 22px;
  font-size: 24px;
  color: black;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: '\2713';
  padding-left: 6px;
  background-color: #3ac5c9;
  color: #ffffff;
  font-size: 16px;
  text-align: left;
}

.onoffswitch-inner:after {
  content: '\00d7';
  padding-right: 6px;
  background-color: #ccc;
  color: #ffffff;
  text-align: right;
}

.onoffswitch-switch {
  display: block;
  width: 18px;
  margin: 5px;
  background: #ffffff;
  position: absolute;
  top: -2px;
  bottom: 0;
  right: 20px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  transition: all 0.3s ease-in 0s;
  height: 18px;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

.complian-block .textbox-control,
.complian-block .select-controls {
  padding: 10px !important;
  height: 55px !important;
  border-radius: 5px !important;
  border: 1px solid #eee !important;
  box-shadow: none !important;
  margin-bottom: 10px !important;
  display: block;
  width: 100% !important;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
}

.complian-block .textarea-control {
  padding: 10px !important;
  border-radius: 5px !important;
  border: 1px solid #eee !important;
  box-shadow: none !important;
  margin-bottom: 10px !important;
  width: 100%;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
}

.complian-block label {
  color: #333;
}

.complian-block .fileUploads {
  background: #f3f2f2;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color: #333;
  font-size: 16px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px;
  position: relative;
  text-align: left;
  width: 100%;
  cursor: pointer;
  margin-bottom: 5px;
}

.complian-block .fileUploads:hover,
.complian-block .fileUploads:active,
.complian-block .fileUploads:focus {
  background: #007679;
  cursor: pointer;
  color: #fff;
}

/* .complian-block .fileUploads input.uploads {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 100%;
  height: 46px;
  cursor: pointer;
}

.complian-block input[type='file'] {
  position: fixed;
  right: 100%;
  bottom: 100%;
} */
.complian-block .custom-file-uploads {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #6d6d6d;
}

#news {
  margin: 0;
}

.notification .notification-block span {
  position: relative !important;
  top: 0 !important;
  text-align: left !important;
  line-height: 0 !important;
}

.alert_list {
  font-size: 11px;
  color: grey;
  width: 800px !important;
}

.popover {
  width: 500px !important;
}

.popover-content {
  max-height: 350px;
  overflow-y: auto;
  padding: 9px 16px !important;
  min-height: 40px;
}

li.alert_li {
  font-size: 11px;
  color: grey;
  padding: 10px 0px 2px 0px;
  border-bottom: thin solid #c0c0c0;
}

li.alert_li:hover {
  background-color: #eee;
}

.turn_off_alert {
  float: right;
  margin-bottom: 1px;
}

a.alert_message {
  color: grey;
}

a.alert_message:hover {
  color: grey;
}

.modal-block {
  margin-top: -30px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.notification-block {
  border-bottom: 1px solid #eee;
  padding: 10px 0 0;
}

.min-ago {
  color: #ccc;
  text-align: left;
  font-size: 11px;
  padding-left: 0 !important;
}

.notification-mid h5 {
  font-size: 13px;
  font-weight: bold;
  color: #000;
  margin-bottom: 0 !important;
}

.notification-mid {
  margin-top: -6px;
  padding-right: 0 !important;
}

.notification-mid p {
  color: #666;
  font-size: 12px;
}

.notification-mid p span {
  color: #007679;
}

.notification-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/

.modal.right .modal-dialog {
  position: fixed !important;
  margin: auto !important;
  width: 320px !important;
  height: 100% !important;
  -webkit-transform: translate3d(0%, 0, 0) !important;
  -ms-transform: translate3d(0%, 0, 0) !important;
  -o-transform: translate3d(0%, 0, 0) !important;
  transform: translate3d(0%, 0, 0) !important;
}

.modal.right .modal-content {
  height: 100% !important;
  overflow-y: auto !important;
}

.modal.right .modal-body {
  padding: 15px 15px 80px !important;
}

#clearing-agent-page .right-slide .modal-dialog {
  width: 1000px !important;
  margin: auto !important;
  border-radius: 0 !important;
}

#clearing-agent-page .right-slide .modal-content {
  border-radius: 0 !important;
}

#clearing-agent-page .right-slide .modal-header .close {
  margin-top: -4px !important;
  margin-right: 20px !important;
  width: 30px !important;
  height: 30px !important;
  background: #fff !important;
  border-radius: 50% !important;
  opacity: 1 !important;
  right: 0 !important;
  position: absolute !important;
}

#clearing-agent-page .right-slide .modal-header {
  display: block !important;
  align-items: left !important;
  justify-content: normal !important;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: -320px !important;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out !important;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out !important;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out !important;
  transition: opacity 0.3s linear, right 0.3s ease-out !important;
}

.modal.right.fade.in .modal-dialog {
  right: 0 !important;
}

/* ----- MODAL STYLE ----- */
.modal-content {
  border-radius: 0;
  border: none;
}

.modal-header {
  border-bottom-color: #eeeeee;
  background-color: #fafafa;
}

/* ----- v CAN BE DELETED v ----- */
body {
  background-color: #78909c;
}

.demo {
  padding-top: 60px;
  padding-bottom: 110px;
}

.btn-demo {
  margin: 15px;
  padding: 10px 15px;
  border-radius: 0;
  font-size: 16px;
  background-color: #ffffff;
}

.btn-demo:focus {
  outline: 0;
}

.demo-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 15px;
  background-color: #212121;
  text-align: center;
}

.demo-footer > a {
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
}

.nopadding {
  padding: 0 !important;
}

.nomargin {
  margin: 0 !important;
}

#clearing-agent-page .complian-block .profilecont {
  display: block !important;
}

.complian-block .del-button {
  background: #f3f2f2;
  border-radius: 5px;
  color: #333;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px;
  position: relative;
  text-align: left;
  width: 100%;
  cursor: pointer;
  height: 44px;
  margin-bottom: 5px;
  text-align: center;
  border: 1px solid #ebe7e7;
  margin-left: 10px !important;
}

.complian-block .profilecont .select-controls {
  background-color: #f3f2f2 !important;
  white-space: normal;
  font-size: 16px;
}

.complian-block .profilecont button {
  background-color: #f3f2f2 !important;
  font-weight: bold;
  color: #666;
  border-radius: 5px;
  border: 1px solid #ebe7e7;
  padding: 8px 14px;
}

.complian-block .profilecont button.btn-red {
  background-color: tomato !important;
  color: white;
}

.complian-block .profilecont button.btn-green {
  background-color: green !important;
  color: white;
}

#hero .flexx a {
  width: 10%;
}

#hero .flexx a img {
  width: 100%;
}

#hero .logo img {
  /* width: 100px; */
  height: 80px;
  object-fit: contain;
  margin: 0 auto;
}

.complian-block1 {
  margin-top: 0;
  width: 400px;
  margin: auto;
  text-align: left;
}

.trusted-text {
  margin-bottom: 10px;
  /* color: #fff; */
  font-weight: 700;
  text-align: center;
  font-size: 24px;
  opacity: 1;
}

.popover.bottom {
  margin-top: 10px;
  position: absolute !important;
  left: inherit !important;
  right: 0 !important;
}

/*--------------------------------------------------------------
# Landing Page
--------------------------------------------------------------*/

#landing-page .header {
  background: url('../img/landing-bg.png') left top no-repeat;
  height: 730px;
  background-size: cover;
  padding-top: 20px;
  margin-bottom: 0px;
  margin-top: 100px;
}

#landing-page .header .logo {
  margin-bottom: 40px;
  float: none;
}

#landing-page .text-align {
  text-align: center;
}

#landing-page .header-block {
  text-align: center;
}

#landing-page .header .header-text {
  padding-top: 40px;
}

#landing-page .header .header-text h5 {
  color: #fff;
  font-family: 'Poppins', sans-serif;
}

#landing-page .header .header-text h1,
#landing-page .header .header-text h3 {
  color: #fff;
  font-family: 'Poppins', sans-serif !important;
  font-size: 40px;
}

#landing-page .header .header-text h3 {
  font-size: 30px;
}

#landing-page button {
  background-color: white;
  padding: 10px 20px 10px 20px;
  border-radius: 30px;
  text-align: center;
  font-size: 30px;
  color: #000;
  font-weight: 300;
  border: none;
  margin-top: 30px;
}

#landing-page button.callToActionBtn {
  background-color: #007679;
  padding: 10px 20px 10px 20px;
  border-radius: 30px;
  text-align: center;
  font-size: 30px;
  color: #fff;
  font-weight: 300;
  border: none;
  margin-top: 10px;
}

#landing-page .body-block h2 {
  color: #007679;
  font-family: 'Poppins', sans-serif;
  font-size: 48px;
  margin: 25px 0 40px 0;
  font-weight: 600;
}

#landing-page .body-block h4 {
  color: #333;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  margin: 25px 0;
  font-weight: 600;
  text-align: center;
}

#landing-page .padding-left {
  padding-right: 90px !important;
}

#landing-page .body-block p {
  font-size: 20px;
}

#landing-page .simple-block {
  background: url('../img/landing-bg3.png') right top no-repeat;
  height: 500px;
  padding: 80px;
  background-size: cover;
  margin-bottom: 120px;
}

#landing-page .simple-block h2 {
  color: #007679;
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  margin: 110px 0 40px 0;
  line-height: 60px;
}

#landing-page .simple-block p {
  font-size: 20px;
}

#landing-page .why-block {
  font-size: 22px;
}

#landing-page .why-block h3 {
  font-size: 24px;
  text-decoration: underline;
  margin-top: 40px;
}

#landing-page .why-block h2 {
  color: #007679;
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  margin: 80px 0 40px 0;
  font-weight: 600;
}

#landing-page .app-ready {
  background: url('../img/landing-bg4.jpg') right top no-repeat;
  padding: 60px 0;
  color: #fff;
}

#landing-page .services .services-block1 {
  width: 100% !important;
  border: 1px solid #eee;
  margin-bottom: 20px;
}

#landing-page .app-ready h3 {
  font-size: 36px;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  margin-bottom: 30px;
}

#landing-page .app-ready h6 {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.65);
  font-family: 'Poppins', sans-serif;
  text-align: center;
  margin-bottom: 40px;
}

#landing-page .app-ready .button {
  width: 50%;
  margin: auto;
}

#landing-page .app-ready p {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
  text-align: center;
}

#landing-page .services .services-block p {
  font-size: 20px;
  line-height: 30px;
  color: #000;
  margin-bottom: 20px;
}

#landing-page .our-values .value-text {
  font-size: 20px;
  line-height: 28px;
  color: #000 !important;
  text-align: center !important;
}

#landing-page .our-values-main-block .services-block {
  /* height: 320px; overflow: auto; */
  padding-bottom: 10px;
}

#landing-page footer {
  background-color: #fff;
  text-align: center;
  padding: 60px 0;
  color: #666;
}

#landing-page .our-values-main-block p {
  font-size: 20px;
  line-height: 30px;
}

#landing-page .our-values-main-block .block-img {
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  #landing-page .header .logo {
    width: 150px;
    line-height: 10px;
    margin-bottom: 30px;
    height: 150px;
  }

  #landing-page .header .header-text {
    padding-top: 0;
  }

  #landing-page .header .header-text h1 {
    font-size: 20px;
    /* margin-top: 50px; */
  }

  #landing-page button {
    width: auto;
    margin: 0 0 20px 0;
  }

  #landing-page .body-block h2 {
    font-size: 24px;
    margin: 0 0 20px 0;
  }

  #landing-page .body-block {
    text-align: center;
  }

  #landing-page .header img,
  #landing-page .simple-block img {
    width: 80%;
  }

  #landing-page .padding-left {
    padding-right: 0 !important;
  }

  #landing-page .header {
    padding-top: 20px;
    height: auto;
    margin-top: 60px;
  }

  #landing-page .simple-block {
    padding: 0;
    height: auto;
  }

  #landing-page .simple-block h2 {
    font-size: 24px;
    margin: 40px 0 10px 0;
    line-height: 26px;
  }

  #landing-page .simple-block {
    margin-bottom: 0;
  }

  #superviser-page .services h2,
  .our-values h2 {
    font-size: 24px;
  }

  #landing-page .our-values-main-block div {
    padding: 0 !important;
  }

  #landing-page .our-values h3 {
    font-size: 20px;
    margin: 20px 0 20px;
  }

  #landing-page .our-values-main-block {
    text-align: center;
  }

  #landing-page .app-ready h3 {
    font-size: 18px;
  }

  #landing-page .app-ready .button {
    width: 100%;
    margin: auto;
  }

  #landing-page .our-values .round-img {
    width: 100%;
  }

  #landing-page .our-values-main-block {
    padding: 0 !important;
  }

  #landing-page .our-values-main-block .col-lg-4 {
    padding: 0;
    margin: 0 !important;
  }
}

@media (max-width: 980px) {
  #landing-page .header .logo {
    height: auto;
  }
}

#countdown {
  width: 100%;
}

#countdown label {
  clear: both;
  display: block;
}

#countdown span {
  background: rgba(0, 0, 0, 0);
  color: #fff;
  font-size: 36px;
  font-weight: normal;
  text-align: center;
  width: 25%;
  display: block;
  float: left;
}

@media (max-width: 768px) {
  #landing-page .header .logo {
    width: 150px;
    height: 30px;
    line-height: 30px;
  }

  #landing-page .header .header-text {
    padding-top: 0px;
  }

  #landing-page .header .header-text h1 {
    font-size: 20px;
  }

  #landing-page button {
    width: auto;
    margin: 0 0 20px 0;
    font-size: 25px;
  }

  #landing-page .header img,
  #landing-page .simple-block img {
    width: 80%;
  }

  #landing-page .padding-left {
    padding-right: 0 !important;
  }

  #landing-page .header {
    padding-top: 20px;
    height: auto;
  }

  #landing-page .simple-block {
    padding: 0;
    height: auto;
  }

  #countdown span {
    background: rgba(0, 0, 0, 0);
    color: #fff;
    font-size: 30px;
    font-weight: normal;
    text-align: center;
    width: 50%;
    display: block;
    float: left;
  }

  #landing-page .simple-block h2 {
    color: #93a5cf;
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    margin: 40px 0;
    line-height: 36px;
  }

  #landing-page .our-values-main-block .services-block {
    height: auto;
    padding: 0 20px !important;
    padding-bottom: 10px !important;
  }
}

#truck-inspector-page .profileimage span span {
  position: absolute;
  right: 0;
  top: 65%;
  background: #007679;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  font-size: 13px;
  line-height: 35px;
  color: #fff;
  display: block;
}

#truck-inspector-page .clearingtable table p.badge-danger {
  float: none;
  background: red;
  padding: 5px 35px;
  color: #fff;
  border-radius: 30px;
  font-size: 16px;
  border: none;
}

.clearingtable br {
  display: none;
}

#clearing-agent-page .profileimage span span {
  position: absolute;
  right: 0;
  top: 65%;
  background: #007679;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  font-size: 13px;
  line-height: 35px;
  color: #fff;
  display: block;
}

#superviser-page .cargoheading {
  padding: 20px 64px !important;
}

#superviser-page .servicetab .nav-tabs > li {
  width: 33.33%;
  text-align: center;
  margin-left: 0;
  margin-right: 0;
  position: relative;
  z-index: 9;
  margin-top: -2px;
}

#superviser-page .servicetab .nav > li > button {
  padding: 17px 110px !important;
  color: #1d1d1d;
  font-size: 20px;
  margin: 0;
  border: none;
  background-color: #fff;
  margin-top: -2px;
}

#superviser-page .servicetab .nav > li > button:hover {
  background: none;
  color: #007679;
  border: none;
}

#superviser-page .servicetab .nav-tabs > li.active button {
  color: #007679;
  border: none;
  box-shadow: 0px -4px 5px #ddd;
}

/* privacy-policy */
.privacy-policy {
  margin-top: 100px;
}

.privacy-policy .header-sub {
  line-height: 30px;
  font-size: 22px;
  margin: 20px 0;
}

.privacy-policy p {
  font-size: 18px;
}

/* terms-conditions */
.terms-conditions {
  margin-top: 100px;
}

.terms-conditions .header-sub {
  line-height: 30px;
  font-size: 22px;
  margin: 20px 0;
}

.terms-conditions p {
  font-size: 18px;
}

/* Career Page */

.modal-xl .modal-header {
  padding: 15px;
  border-bottom: none !important;
}

.modal-xl .modal-header .close {
  margin-top: -40px !important;
  margin-right: -105% !important;
  width: 30px !important;
  height: 30px !important;
  background: #fff !important;
  border-radius: 50% !important;
  opacity: 1 !important;
}

.modal-xl button.post-button {
  background-color: #007679;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
  border-radius: 30px;
  width: 150px;
  margin: 10px 0 10px 0 !important;
  border: 1px soild #007679 !important;
}

.modal-xl button.post-button:hover {
  background: #01a8ac;
}

/* Notifications */

.notification {
}

/* Landing page */

#landing-page .arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 7px;
  margin-left: 15px;
}

#landing-page .right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

#landing-page .services .services-block h3 {
  margin: 20px 0;
}

#landing-page .our-values .services-block h3 {
  margin: 20px 0;
}

#client-page #authRepModal .modal-header {
  position: relative;
}

#client-page #authRepModal .modal-header > button {
  margin-top: -30px !important;
  right: 0;
  position: absolute;
  margin-right: 0 !important;
}

#client-page .profilecont .director {
  padding: 10px;
  background: #f3f2f2;
  border-radius: 5px;
  font-weight: bold;
}

.swal-text {
  text-align: center;
}

.about-goagent h3,
.about-goagent h2,
.about-goagent p {
  color: #fff !important;
}

.about-goagent-video {
  height: 110%;
  width: 100%;
  right: 0;
  bottom: 0;
  position: absolute;
  object-fit: cover;
  object-position: 0 10%;
}

/* country code picker */
.country-input {
  height: 60px;
  padding: 10px 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.country-input input {
  border-color: transparent;
}
